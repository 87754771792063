import { useCallback } from 'react';
import { operatorsQueryKeys } from '@hooks/operators/useOperatorQueries';
import { isString } from 'lodash';
import { useQueryClient } from '@tanstack/react-query';
import useWorkflow from '@hooks/workflows/useWorkflow';

export default function useOperatorUtils() {
  const { getSelectedWorkflowOperators, getSelectedWorkflow } = useWorkflow();
  const queryClient = useQueryClient();
  const selectedWorkflow = getSelectedWorkflow();
  const selectedWorkflowId = selectedWorkflow?.id;

  const getDefaultOperators = useCallback(
    () =>
      queryClient.getQueryData(
        operatorsQueryKeys.defaultOperators(selectedWorkflowId),
      )?.defaultOperators,
    [queryClient, selectedWorkflowId],
  );

  const getNormalizedDefaultOperators = useCallback(
    () =>
      queryClient.getQueryData(
        operatorsQueryKeys.defaultOperators(selectedWorkflowId),
      )?.normalizedDefaultOperators,
    [queryClient, selectedWorkflowId],
  );

  const getDefaultOperator = useCallback(
    (operatorName) => getNormalizedDefaultOperators()?.[operatorName],
    [getNormalizedDefaultOperators],
  );

  const getOperator = useCallback(
    (operatorId) => {
      const operators = getSelectedWorkflowOperators();
      const operator = operators.find((operator) => operator.id === operatorId);

      return operator;
    },
    [getSelectedWorkflowOperators],
  );

  const getOperatorFromProvidedArg = useCallback(
    (operator) => {
      const isOperatorId = isString(operator);

      if (isOperatorId) {
        return getOperator(operator);
      }

      return operator;
    },
    [getOperator],
  );
  return {
    getDefaultOperators,
    getOperator,
    getOperatorFromProvidedArg,
    getNormalizedDefaultOperators,
    getDefaultOperator,
  };
}
