import React, { useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import usePrevious from '@hooks/usePrevious';
import {
  Wrapper,
  BackgroundLayer,
  Grid,
  LeftSideBar,
  CenterColumn,
  TopBar,
  BottomBar,
  RightSideBar,
  LayoutSettingBarLayer as LayoutSettingBarLayerStyled,
} from './EditorLayout.styled';
import { isUndefined } from 'lodash';

export const LayoutSettingBarLayer = ({
  children,
  fadeOut,
  slideOut,
  visible,
}) => {
  const layoutSettingBarRef = useRef(null);
  const initialVisibleRef = useRef(visible);
  const previousVisible = usePrevious(visible);
  const initalRendering = isUndefined(previousVisible);
  const animationStarted = !initalRendering && previousVisible !== visible;

  const handleAnimationEnd = useCallback(() => {
    layoutSettingBarRef.current.removeAttribute('data-animating');
  }, []);

  useEffect(() => {
    if (initalRendering || previousVisible === visible) return;

    layoutSettingBarRef.current.setAttribute('data-animating', true);
  }, [initalRendering, previousVisible, visible]);

  return (
    <LayoutSettingBarLayerStyled
      resetAnimation={initialVisibleRef.current && visible}
      animationStarted={animationStarted}
      fadeOut={fadeOut}
      onAnimationEnd={handleAnimationEnd}
      ref={layoutSettingBarRef}
      slideOut={slideOut}
      visible={visible}
    >
      {children}
    </LayoutSettingBarLayerStyled>
  );
};

LayoutSettingBarLayer.propTypes = {
  children: PropTypes.element,
  fadeOut: PropTypes.bool,
  slideOut: PropTypes.bool,
  visible: PropTypes.bool,
};

const EditorLayout = ({
  backgroundLayer,
  banner = null,
  footer = null,
  bottomBar,
  children,
  settingBarCollapsed,
  sidebarCollapsed,
  topBar,
}) => {
  return (
    <>
      {banner}

      <Wrapper>
        <BackgroundLayer
          sidebarCollapsed={sidebarCollapsed}
          settingBarCollapsed={settingBarCollapsed}
        >
          {backgroundLayer}
        </BackgroundLayer>

        <Grid
          sidebarCollapsed={sidebarCollapsed}
          settingBarCollapsed={settingBarCollapsed}
        >
          <LeftSideBar>{children?.[0] || null}</LeftSideBar>

          <CenterColumn>
            {topBar && <TopBar>{topBar}</TopBar>}

            {bottomBar && <BottomBar>{bottomBar}</BottomBar>}
          </CenterColumn>

          <RightSideBar>{children?.[1] || null}</RightSideBar>
        </Grid>
      </Wrapper>

      {footer}
    </>
  );
};

EditorLayout.propTypes = {
  backgroundLayer: PropTypes.element,
  banner: PropTypes.element,
  footer: PropTypes.element,
  bottomBar: PropTypes.element,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  settingBarCollapsed: PropTypes.bool,
  sidebarCollapsed: PropTypes.bool,
  topBar: PropTypes.element,
};

export default EditorLayout;
