import styled, { css } from 'styled-components';
import Text, { TEXT_VARIANT_BODY_MEDIUM } from '@components/1-atoms/Text';
import SpriteSVG from '@app/components/1-atoms/SpriteSVG';
import IconButton, {
  ICON_BUTTON_VARIANT_STANDARD,
} from '@components/1-atoms/IconButton';
import withTooltip from '@hoc/withTooltip';
import { flexCenterVertical } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  ${flexCenterVertical}
  overflow: hidden;
`;

export const Item = styled.div`
  ${flexCenterVertical}

  ${({ theme: { spacing } }) => css`
    gap: ${spacing.level3};
    padding: ${spacing.level2} ${spacing.level4};
  `}
`;

export const ItemIcon = styled(SpriteSVG)`
  width: 24px;
  height: 24px;
  flex-shrink: 0;

  ${({ theme: { colors } }) => css`
    fill: ${colors?.outline};
  `}
`;

export const ItemLabel = styled(Text).attrs({
  variant: TEXT_VARIANT_BODY_MEDIUM,
  color: 'onSurface',
})`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  user-select: none;
  flex-shrink: 1;
`;

export const InfoGrid = styled.div`
  ${({ theme: { spacing } }) => css`
    gap: ${spacing.level2};
    padding: ${spacing.level2};
  `}
`;

export const InfoRow = styled.div`
  ${({ theme: { spacing } }) => css`
    display: grid;
    gap: ${spacing.level2};
    grid-template-columns: 1fr 1fr 1fr;
  `}
`;

export const InfoLabel = styled(Text).attrs({
  variant: TEXT_VARIANT_BODY_MEDIUM,
  color: 'onSurface',
})`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  user-select: none;
`;

export const TooltipIconButton = styled(withTooltip(IconButton)).attrs({
  variant: ICON_BUTTON_VARIANT_STANDARD,
})`
  flex-shrink: 0;
`;
