import * as THREE from 'three';
import { Printer } from '../Printer';
import { printerConstants as constants } from '@constants/printers/printerConstants';
import { Vector3 } from 'three';
import PrinterComponent from '../PrinterComponent.js';
import LabeledAxesHelper from '../LabeledAxesHelper.js';
import { machineConstants } from '@constants/printers/machineConstants';
import { componentDiscriminators } from '../../constants/printers/componentDiscriminators.js';
import { DEFAULT_PRINTING_BED } from '@app/constants/machineConstants';

/**
 * THREE.Group object containing all settings and models relevant to visualising
 * the bed of the printer.
 *
 * This object contains a property 'base' which is a THREE.Group and
 * represents the base which the print object is defined against, i.e.
 * the XYZABC values of the print object are positioned relative to this group.
 */
export class Bed extends PrinterComponent {
  constructor(
    printerSettings,
    machineDefaults,
    printingBedDefinitions,
    plinthDefinitions,
  ) {
    super(printerSettings, machineDefaults, constants.printingBed);
    this.printingBedType = this.getBedType(printerSettings, machineDefaults);
    this.printingBedDefaults = printingBedDefinitions.find(
      (item) => item.displayName == this.printingBedType,
    );
    this.bedMovement = machineDefaults?.bedMovement;
    this.bedOffsets = machineDefaults?.bedOffsets;

    this.printingBedSettingsType = this.getPrintingBedSettingsType();
    this.plinthType = Printer.getPrinterSettingValueOrDefault(
      printerSettings,
      'plinthType',
      this.machineDefaults?.plinthType,
    );
    this.plinthSettings = plinthDefinitions.find(
      (item) => item.displayName == this.plinthType,
    );
    const [origin, yPoint, xPoint] = this.getCornerPoints(printerSettings);
    this.origin = origin;
    this.yPoint = yPoint;
    this.xPoint = xPoint;
    this.baseTransformationMatrix = this.getBaseTransformationMatrix(
      origin,
      yPoint,
      xPoint,
    );
    this.setBaseFrame(this.baseTransformationMatrix);

    const [yCornerRelativeToBase, xCornerRelativeToBase] =
      this.getCornerPointsRelativeToBase(
        yPoint,
        xPoint,
        this.baseTransformationMatrix,
      );
    this.yCornerRelativeToBase = yCornerRelativeToBase;
    this.xCornerRelativeToBase = xCornerRelativeToBase;
    this.addDebugPoints(xCornerRelativeToBase, yCornerRelativeToBase);
  }

  getPrintingBedSettingsType() {
    return this.printingBedDefaults?.[constants.printingBedSettingsType];
  }

  /**
   * Returns the bed type belonging to this printer, based on the printer
   * settings belonging to this printer. If the printer settings object is empty
   * or does not contain information about the bed type, then the default
   * bed type for this machine is returned.
   * @param {*} printerSettings object containing all printer settings for this printer
   * @param {*} machineDefaults
   * @returns type of bed for this printer
   */
  getBedType(printerSettings, machineDefaults) {
    const defaultBedName =
      machineDefaults?.defaultPrintingBedName || DEFAULT_PRINTING_BED;
    const selectedBed = printerSettings.find(
      (x) => x.settingName === constants.printingBedType,
    ) || { value: defaultBedName };
    return selectedBed.value;
  }

  /**
   * You cannot scale the models themselves or they move out of position, as meshes probably
   * are scaling around their centroid?
   *
   * Returns resolved promise once models are loadedd
   */
  initializeModels() {
    return new Promise((resolve) => {
      const fileKey = this.printingBedDefaults?.fileKey || '';
      if (fileKey === '') {
        resolve(new THREE.Group());
      } else if (fileKey === constants.ParameterizedGeometry) {
        resolve(this.getPrintingBedModelOld());
      } else {
        const model = Printer.getModel(`/models/Bed_${fileKey}.glb`);
        model.then((model) => {
          model.children.forEach((child) => {
            this.models.add(child.clone());
          });
          if (this.printingBedDefaults?.shouldRotateBasedOnLongerLength) {
            const xAxis = new THREE.Vector3().subVectors(
              this.xPoint,
              this.origin,
            );
            const yAxis = new THREE.Vector3().subVectors(
              this.yPoint,
              this.origin,
            );
            //by default, long length of bed models is aligned to y-axis
            if (xAxis.length() > yAxis.length()) {
              this.models.translateY(yAxis.length());
              this.models.rotateZ(-Math.PI / 2);
            }
          }
          resolve();
        });
      }
    });
  }

  /**
   * Returns an array containing the xyz coordinates of the corner points defining:
   * the origin;
   * the point defining defining the y-axis direction from the origin;
   * the point defining the x-axis direction from the origin;
   *
   * Uses the values supplied by the printer settings, or if no printer settings are
   * found (E.g. if the Bed constructor has been called with empty printer settings during
   * printer creation), uses the defualts
   * @param {*} printerSettings
   */
  getCornerPoints(printerSettings) {
    const origin = new THREE.Vector3(
      Printer.getPrinterSettingValueOrDefault(
        printerSettings,
        constants.baseRef1X,
        this.printingBedDefaults?.baseRef1X,
      ),
      Printer.getPrinterSettingValueOrDefault(
        printerSettings,
        constants.baseRef1Y,
        this.printingBedDefaults?.baseRef1Y,
      ),
      Printer.getPrinterSettingValueOrDefault(
        printerSettings,
        constants.baseRef1Z,
        this.printingBedDefaults?.baseRef1Z,
      ),
    );
    const yPoint = new THREE.Vector3(
      Printer.getPrinterSettingValueOrDefault(
        printerSettings,
        constants.baseRef2X,
        this.printingBedDefaults?.baseRef2X,
      ),
      Printer.getPrinterSettingValueOrDefault(
        printerSettings,
        constants.baseRef2Y,
        this.printingBedDefaults?.baseRef2Y,
      ),
      Printer.getPrinterSettingValueOrDefault(
        printerSettings,
        constants.baseRef2Z,
        this.printingBedDefaults?.baseRef2Z,
      ),
    );
    const xPoint = new THREE.Vector3(
      Printer.getPrinterSettingValueOrDefault(
        printerSettings,
        constants.baseRef3X,
        this.printingBedDefaults?.baseRef3X,
      ),
      Printer.getPrinterSettingValueOrDefault(
        printerSettings,
        constants.baseRef3Y,
        this.printingBedDefaults?.baseRef3Y,
      ),
      Printer.getPrinterSettingValueOrDefault(
        printerSettings,
        constants.baseRef3Z,
        this.printingBedDefaults?.baseRef3Z,
      ),
    );
    return [origin, yPoint, xPoint];
  }

  /**
   * Converts the corner points of the bed from being defined relative to the
   * global coordinate system, to be defined relative to the base coordinate
   * system.
   * @param {Vector3} yCorner corner of the bed defining the y-axis, defined
   * relative to the global coordinate system
   * @param {Vector3} xCorner corner of the bed defining the x-axis, defined
   * relative to the global coordinate system
   */
  getCornerPointsRelativeToBase(yCorner, xCorner, baseTransformationMatrix) {
    const inverse = new THREE.Matrix4().copy(baseTransformationMatrix).invert();
    const yCornerRelativeToGlobal = new THREE.Vector3().copy(yCorner);
    const xCornerRelativeToGlobal = new THREE.Vector3().copy(xCorner);
    return [
      yCornerRelativeToGlobal.applyMatrix4(inverse),
      xCornerRelativeToGlobal.applyMatrix4(inverse),
    ];
  }

  /**
   * Sets the position and rotation of the bed based on the origin, and x and
   * y points that have been calculated from the supplied printer settings
   */
  setBaseFrame(baseTransformationMatrix) {
    this.position.setFromMatrixPosition(baseTransformationMatrix);
    this.setRotationFromMatrix(baseTransformationMatrix);
    this.base = new THREE.Group();
    this.base.name = constants.BED;
    this.models = new THREE.Group();
    this.add(this.base);
    this.add(this.models);
  }

  /**
   * Adds debug points at the specified positions
   * @param {*} xCornerRelativeToBase
   * @param {*} yCornerRelativeToBase
   */
  addDebugPoints(xCornerRelativeToBase, yCornerRelativeToBase) {
    const debugPointOrigin = new LabeledAxesHelper({
      rotationMatrix: this.baseTransformationMatrix,
    });
    const debugPointXAxis = this.debugPoint(
      0x0000ff,
      xCornerRelativeToBase.x,
      xCornerRelativeToBase.y,
      xCornerRelativeToBase.z,
    );
    const debugPointYAxis = this.debugPoint(
      0x00ff00,
      yCornerRelativeToBase.x,
      yCornerRelativeToBase.y,
      yCornerRelativeToBase.z,
    );
    this.add(debugPointOrigin);
    this.add(debugPointYAxis);
    this.add(debugPointXAxis);
  }

  /**
   * Simulates the movement of the bed based on the provided simulation data. The bed will
   * only move in the axes that are defined within the configuration e.g. if the bed is attached
   * to a gantry system in which the bed movement controls the z-axis, it will move only in the z-axis.
   * @param {*} simulationData
   */
  simulate(simulationData, machine) {
    if (machine.robotType === 'Spark') {
      const bedMovement =
        simulationData.previousStep.movement['jointAngle3'] +
        (simulationData.currentStep.movement['jointAngle3'] -
          simulationData.previousStep.movement['jointAngle3']) *
          simulationData.stepRatio;
      this.position.set(0, -bedMovement + 175, 0);
      return;
    }
    if (this.bedMovement) {
      this.simulateBed(simulationData, machine);
    }
  }

  simulateBed(simulationData, machine) {
    if (
      machine.props[machineConstants.typeDiscriminator] !=
      machineConstants.gantryDiscriminator
    ) {
      //eslint-disable-next-line
      console.error('Non-gantry machines do not support moving beds');
      return;
    }
    let x =
      simulationData.previousStep.movement['x'] +
      (simulationData.currentStep.movement['x'] -
        simulationData.previousStep.movement['x']) *
        simulationData.stepRatio;
    let y =
      simulationData.previousStep.movement['y'] +
      (simulationData.currentStep.movement['y'] -
        simulationData.previousStep.movement['y']) *
        simulationData.stepRatio;
    let z =
      simulationData.previousStep.movement['z'] +
      (simulationData.currentStep.movement['z'] -
        simulationData.previousStep.movement['z']) *
        simulationData.stepRatio;
    if (this.bedOffsets) {
      x += this.bedOffsets?.x;
      y += this.bedOffsets?.y;
      z += this.bedOffsets?.z;
    }
    if (machine.tool) {
      const toolId = simulationData.previousStep.movement.toolId;
      const calibration = machine.tool.calibration[toolId];
      const calibrationInBedBasis = machine.getToolCalibrationInBedBasis(
        new THREE.Vector3(
          calibration.toolX,
          calibration.toolY,
          calibration.toolZ,
        ),
      );
      x -= calibrationInBedBasis.x;
      y -= calibrationInBedBasis.y;
      z -= calibrationInBedBasis.z;
    }
    if (this.bedMovement?.includes('x')) {
      this.position.set(-x, this.position.y, this.position.z);
    }
    if (this.bedMovement?.includes('y')) {
      this.position.set(this.position.x, -y, this.position.z);
    }
    if (this.bedMovement?.includes('z')) {
      this.position.set(this.position.x, this.position.y, -z);
    }
    if (this.bedMovement?.includes('-x')) {
      this.position.set(x, this.position.y, this.position.z);
    }
    if (this.bedMovement?.includes('-y')) {
      this.position.set(this.position.x, y, this.position.z);
    }
    if (this.bedMovement?.includes('-z')) {
      this.position.set(this.position.x, this.position.y, z);
    }
  }

  /**
   * Returns the same input plane rotated according to
   * @param {*} plane
   * @param {*} x
   * @param {*} y
   * @param {*} z
   * @returns
   */
  rotatePlane(plane, x, y, z) {
    const xy = new THREE.Vector3().subVectors(y, x);
    const xz = new THREE.Vector3().subVectors(z, x);
    const normal = new THREE.Vector3().crossVectors(xy, xz).normalize();

    // initial normal vector of the plane
    const Z = new THREE.Vector3(0, 0, 1);
    const axis = new THREE.Vector3().crossVectors(Z, normal).normalize();
    const angle = Math.acos(Z.dot(normal));
    const q = new THREE.Quaternion().setFromAxisAngle(axis, angle);
    plane.rotation.setFromQuaternion(q);

    const distanceToPlane = x.dot(normal);
    plane.position.copy(normal.clone().multiplyScalar(distanceToPlane));
    return plane;
  }

  /**
   * Creates a small spherical mesh of a target colour at the provided position.
   * @param {*} color colour of the sphere
   * @param {*} x x-coordinate of the sphere
   * @param {*} y y-coordinate of the sphere
   * @param {*} z z-coordinate of the sphere
   * @returns sphere mesh
   */
  debugPoint(color, x, y, z, size = 10) {
    const geometry = new THREE.SphereGeometry(size, 32, 32);
    const material = new THREE.MeshBasicMaterial({ color: color });
    const sphere = new THREE.Mesh(geometry, material);
    sphere.position.x = x;
    sphere.position.y = y;
    sphere.position.z = z;
    return sphere;
  }

  /**
   * Returns the rotation matrix representing the orientation of the bed, this.based on the
   * the axis definitions provided in the form of reference point directions.
   * @returns rotation matrix representing the orientation of the bed
   */
  getBaseTransformationMatrix(origin, yPoint, xPoint) {
    const yDir = new THREE.Vector3().subVectors(yPoint, origin);
    const xDir = new THREE.Vector3().subVectors(xPoint, origin);
    const xAxis = new THREE.Vector3();
    const yAxis = new THREE.Vector3();
    const zAxis = new THREE.Vector3();
    xAxis.copy(xDir).normalize();
    yAxis.copy(yDir).normalize();
    zAxis.crossVectors(xAxis, yAxis).normalize();
    const baseTransformationMatrix = new THREE.Matrix4();
    baseTransformationMatrix.makeBasis(xAxis, yAxis, zAxis);
    baseTransformationMatrix.setPosition(origin.x, origin.y, origin.z);
    return baseTransformationMatrix;
  }

  /**
   * Returns a parametric simple bed model consisting of a cuboid whose corners
   * reside at the target origin, x point, ypoint.
   * @returns
   */
  getPrintingBedModelOld() {
    return new Promise((resolve) => {
      if (this.printingBedType === '') resolve(new THREE.Group());
      else {
        const dirAB = new THREE.Vector3();
        const originCorner = new Vector3(0, 0, 0);
        dirAB.subVectors(this.yCornerRelativeToBase, originCorner);
        const bedPtD = new THREE.Vector3();
        bedPtD.addVectors(this.xCornerRelativeToBase, dirAB);

        let plinthHeight = 0;
        if (this.plinthType === 'CUSTOM') {
          const plinthDefinitionResponse = Printer.getPrinterSettingValue(
            this.printerSettings,
            componentDiscriminators.PLINTH_RESPONSE_PROPERTY,
          );
          plinthHeight = plinthDefinitionResponse?.height || 0;
        } else {
          plinthHeight = this.plinthType ? this.plinthSettings.height : 0;
        }
        const groundLevel = -plinthHeight;
        let minZ = groundLevel;
        if (
          originCorner.z < groundLevel ||
          this.xCornerRelativeToBase.z < groundLevel ||
          this.yCornerRelativeToBase.z < groundLevel ||
          bedPtD.z < groundLevel
        ) {
          minZ =
            Math.min(
              originCorner.z,
              this.xCornerRelativeToBase.z,
              this.yCornerRelativeToBase.z,
              bedPtD.z,
            ) - 1;
        }

        const vertices = new Float32Array([
          originCorner.x,
          originCorner.y,
          originCorner.z,
          this.xCornerRelativeToBase.x,
          this.xCornerRelativeToBase.y,
          this.xCornerRelativeToBase.z,
          this.yCornerRelativeToBase.x,
          this.yCornerRelativeToBase.y,
          this.yCornerRelativeToBase.z,

          this.xCornerRelativeToBase.x,
          this.xCornerRelativeToBase.y,
          this.xCornerRelativeToBase.z,
          bedPtD.x,
          bedPtD.y,
          bedPtD.z,
          this.yCornerRelativeToBase.x,
          this.yCornerRelativeToBase.y,
          this.yCornerRelativeToBase.z,

          originCorner.x,
          originCorner.y,
          minZ,
          this.yCornerRelativeToBase.x,
          this.yCornerRelativeToBase.y,
          minZ,
          this.xCornerRelativeToBase.x,
          this.xCornerRelativeToBase.y,
          minZ,

          this.xCornerRelativeToBase.x,
          this.xCornerRelativeToBase.y,
          minZ,
          this.yCornerRelativeToBase.x,
          this.yCornerRelativeToBase.y,
          minZ,
          bedPtD.x,
          bedPtD.y,
          minZ,

          originCorner.x,
          originCorner.y,
          originCorner.z,
          originCorner.x,
          originCorner.y,
          minZ,
          this.xCornerRelativeToBase.x,
          this.xCornerRelativeToBase.y,
          this.xCornerRelativeToBase.z,

          this.xCornerRelativeToBase.x,
          this.xCornerRelativeToBase.y,
          this.xCornerRelativeToBase.z,
          originCorner.x,
          originCorner.y,
          minZ,
          this.xCornerRelativeToBase.x,
          this.xCornerRelativeToBase.y,
          minZ,

          this.yCornerRelativeToBase.x,
          this.yCornerRelativeToBase.y,
          this.yCornerRelativeToBase.z,
          bedPtD.x,
          bedPtD.y,
          bedPtD.z,
          this.yCornerRelativeToBase.x,
          this.yCornerRelativeToBase.y,
          minZ,

          bedPtD.x,
          bedPtD.y,
          bedPtD.z,
          bedPtD.x,
          bedPtD.y,
          minZ,
          this.yCornerRelativeToBase.x,
          this.yCornerRelativeToBase.y,
          minZ,

          originCorner.x,
          originCorner.y,
          originCorner.z,
          this.yCornerRelativeToBase.x,
          this.yCornerRelativeToBase.y,
          this.yCornerRelativeToBase.z,
          originCorner.x,
          originCorner.y,
          minZ,

          this.yCornerRelativeToBase.x,
          this.yCornerRelativeToBase.y,
          this.yCornerRelativeToBase.z,
          this.yCornerRelativeToBase.x,
          this.yCornerRelativeToBase.y,
          minZ,
          originCorner.x,
          originCorner.y,
          minZ,

          this.xCornerRelativeToBase.x,
          this.xCornerRelativeToBase.y,
          this.xCornerRelativeToBase.z,
          this.xCornerRelativeToBase.x,
          this.xCornerRelativeToBase.y,
          minZ,
          bedPtD.x,
          bedPtD.y,
          bedPtD.z,

          bedPtD.x,
          bedPtD.y,
          bedPtD.z,
          this.xCornerRelativeToBase.x,
          this.xCornerRelativeToBase.y,
          minZ,
          bedPtD.x,
          bedPtD.y,
          minZ,
        ]);

        const normals = new Float32Array([
          0, 0, 1, 0, 0, 1, 0, 0, 1,

          0, 0, 1, 0, 0, 1, 0, 0, 1,

          0, 0, 1, 0, 0, 1, 0, 0, 1,

          0, 0, 1, 0, 0, 1, 0, 0, 1,

          -1, 0, 0, -1, 0, 0, -1, 0, 0,

          -1, 0, 0, -1, 0, 0, -1, 0, 0,

          -1, 0, 0, -1, 0, 0, -1, 0, 0,

          -1, 0, 0, -1, 0, 0, -1, 0, 0,

          0, 1, 1, 0, 1, 1, 0, 1, 1,

          0, 1, 1, 0, 1, 1, 0, 1, 1,

          0, 1, 1, 0, 1, 1, 0, 1, 1,

          0, 1, 1, 0, 1, 1, 0, 1, 1,
        ]);

        const colors = new Float32Array([
          0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1,
          0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1,
          0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1,
          0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1,
          0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1,
          0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1,
          0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1,
          0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1,
        ]);

        const geometry = new THREE.BufferGeometry();

        geometry.setAttribute(
          constants.position,
          new THREE.BufferAttribute(vertices, 3),
        );
        geometry.setAttribute(
          constants.normal,
          new THREE.BufferAttribute(normals, 3),
        );
        geometry.setAttribute(
          constants.color,
          new THREE.BufferAttribute(colors, 3),
        );

        const bedMaterial = new THREE.MeshPhongMaterial({
          vertexColors: true,
        });

        const printingBase = new THREE.Mesh(geometry, bedMaterial);
        printingBase.receiveShadow = false;
        printingBase.castShadow = false;

        this.models.scale.set(1, 1, 1);
        this.models.add(printingBase);
        resolve();
      }
    });
  }

  /**
   * Returns the vector from the flange to the TCP based on the input tool id
   * @param {*} toolId
   * @returns
   */
  getToolVector(toolId) {
    const toolFrame =
      toolId === 1
        ? {
            x: this.getSettingValueFromType(constants.SECONDARY_TOOL_X),
            y: this.getSettingValueFromType(constants.SECONDARY_TOOL_Y),
            z: this.getSettingValueFromType(constants.SECONDARY_TOOL_Z),
          }
        : {
            x: this.getPrinterSettingValue(
              this.printerSettings,
              constants.toolX,
            ),
            y: this.getPrinterSettingValue(
              this.printerSettings,
              constants.toolY,
            ),
            z: this.getPrinterSettingValue(
              this.printerSettings,
              constants.toolZ,
            ),
          };
    return new THREE.Vector3(toolFrame.x, toolFrame.y, toolFrame.z);
  }

  setVisibility = (isVisible) => {
    const maxLimit = isVisible ? Infinity : 0;
    this.traverse((child) => {
      if (
        child.geometry &&
        child.children.length === 0 &&
        !child.name?.includes('simulation') // in order to preserve printing object
      ) {
        child.geometry.setDrawRange(0, maxLimit);
      }
    });
  };

  highlight(_, color) {
    this.models.traverse((child) => {
      if (child.isMesh) {
        this.doHighlight(child, color);
      }
    });
  }
}
