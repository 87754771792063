import React, { Fragment, memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Controller, useFormContext } from 'react-hook-form';
import { concat, isEmpty, omit } from 'lodash';
import { useSelector } from 'react-redux';
import { getUserAccountPreference } from '@selectors/loginSelectors';
import useOperatorSettings from '@hooks/operatorSettings/useOperatorSettings';
import useOperatorMutations from '@hooks/operators/useOperatorMutations';
import useActiveItems from '@app/hooks/useActiveItems';
import EnglishTranslation from '@app/languages/en-GB.json';
import { OperatorInputTypes } from '@constants/operatorInputTypes';
import {
  decimalInputPattern,
  integerInputPattern,
  stringInputPattern,
} from '@utils/validation';
import {
  whenEnterButtonPressed,
  whenMatchPattern,
} from '@utils/interactionEvents';
import {
  CHECKBOX_FIELD_TYPES,
  DROP_DOWN_DATA_FIELD_TYPES,
  DROP_DOWN_FIELD_TYPES,
  DROP_DOWN_FILE_FIELD_TYPES,
  DROP_DOWN_INPUT_FIELD_TYPES,
  INPUT_FIELD_TYPES,
  OPERATORS_WITH_ADD_BUTTON,
} from '@constants/operatorFieldTypes';
import { findClosestElementById } from '@utils/dom';
import {
  DROP_DOWN_MENU_ID,
  DROP_DOWN_MENU_POSITION_BOTTOM_RIGHT,
} from '@components/2-molecules/DropDownMenu';
import SettingDropDownWithIconButton from '@components/2-molecules/SettingDropDownWithIconButton';
import SettingTextField from '@components/2-molecules/SettingTextField';
import SettingCheckbox from '@components/2-molecules/SettingCheckbox';
import SettingActionRow from '@components/2-molecules/SettingActionRow';
import SettingsCategory from '@components/2-molecules/SettingsCategory';
import { TOOLTIP_POSITION_BOTTOM_LEFT } from '@components/2-molecules/Tooltip';
import { Wrapper } from './WorkflowOperatorFields.styled';
import SettingsFileButton from '@components/File/SettingsFileButton/SettingsFileButton';
import CanvasSelectionChip from '@components/CanvasSelection';
import { br } from '@utils/render';
import useCanvasSelection from '@app/hooks/canvasselection/useCanvasSelection';
import useFeatureFlagValue from '@hooks/featureflags/useFeatureFlagValue';
import {
  OPERATOR_CATEGORIES,
  AUTO_COMPUTE_ON_OPERATOR_INPUT_SELECTION,
} from '@constants/featureFlagConstants';

const settingTooltipProps = {
  tooltipAppearDelay: 200,
  tooltipWidth: 350,
  tooltipPosition: TOOLTIP_POSITION_BOTTOM_LEFT,
  tooltipOffsetRight: 20,
};

const WorkflowOperatorFields = ({
  disabled,
  operator = {},
  computeWorkflow = () => {},
  computeOperator = () => {},
}) => {
  const intl = useIntl();
  const isOperatorCategoriesEnabled = useFeatureFlagValue(OPERATOR_CATEGORIES);
  const isAutoComputeOperatorFeatureEnabled = useFeatureFlagValue(
    AUTO_COMPUTE_ON_OPERATOR_INPUT_SELECTION,
  );
  const isAutoComputeOperatorPreferenceEnabled = useSelector(
    getUserAccountPreference('AUTO_COMPUTE_ON_OPERATOR_INPUT_SELECTION'),
  );
  const isAutoComputeOperatorEnabled =
    isAutoComputeOperatorFeatureEnabled &&
    isAutoComputeOperatorPreferenceEnabled;

  const { toggleActiveItems, isItemActive } = useActiveItems();
  const {
    getOperatorVisibleInputs,
    getOperatorFieldOptions,
    getOperatorInputDescription,
    getCategorizedVisibleOperatorInputs,
    getIsOperatorInputMultiType,
  } = useOperatorSettings();
  const {
    doesFieldSupportCanvasSelection,
    getReferencedCanvasSelectionField,
    getIsCanvasSelectionActiveForField,
    selectCanvasSelectionField,
  } = useCanvasSelection();
  const { addOperatorValueMutation, removeOperatorValueMutation } =
    useOperatorMutations();

  const workflowId = useMemo(() => operator?.conceptId, [operator?.conceptId]);
  const operatorId = useMemo(() => operator?.id, [operator?.id]);

  const form = useFormContext();

  const operatorValues = useMemo(
    () => getOperatorVisibleInputs(operator),
    [getOperatorVisibleInputs, operator],
  );

  const operatorInputCategories = getCategorizedVisibleOperatorInputs(operator);
  const operatorDefaultInputCategory = operatorInputCategories?.default || [];
  const operatorCategoryInputs = omit(operatorInputCategories, 'default');

  const showAddButton = OPERATORS_WITH_ADD_BUTTON.includes(operator?.name);
  const fieldNameBasePath = `${operatorId}`;

  const getDropDownPlaceholder = useCallback(
    (fieldInput = {}) => {
      const { type } = fieldInput;
      let placeholder = intl.formatMessage({
        id: 'editorpanel.selector.select_value',
        defaultMessage: 'Select value',
      });

      if (DROP_DOWN_INPUT_FIELD_TYPES.includes(type)) {
        placeholder = intl.formatMessage({
          id: 'editorpanel.selector.select_input',
          defaultMessage: 'Select input',
        });
      }

      if (DROP_DOWN_FILE_FIELD_TYPES.includes(type)) {
        placeholder = intl.formatMessage({
          id: 'editorpanel.selector.select_file',
          defaultMessage: 'Select file',
        });
      }

      if (DROP_DOWN_DATA_FIELD_TYPES.includes(type)) {
        placeholder = intl.formatMessage({
          id: 'editorpanel.selector.select_data',
          defaultMessage: 'Select data',
        });
      }

      return placeholder;
    },
    [intl],
  );

  // this function is used to reorder the data values in the combine operator
  // when add or remove a Data name field
  const reorderCombineOperatorDataValues = useCallback((operatorValues) => {
    const dataValues = operatorValues.filter((operatorValue) =>
      operatorValue.name.startsWith('Data'),
    );
    const orderedDataValues = dataValues.map((dataValue, i) => ({
      ...dataValue,
      name: i === 0 ? 'Data' : `Data${i + 1}`,
    }));

    const otherValues = operatorValues.filter(
      (operatorValue) => !operatorValue.name.startsWith('Data'),
    );

    return concat(orderedDataValues, otherValues);
  }, []);

  const numericSort = (a, b) => {
    const getNumber = (name) => {
      // extract the numeric part; default to 1 if not present.
      const num = parseInt(name.replace('Data', ''), 10);
      return isNaN(num) ? 1 : num;
    };

    return getNumber(a.name) - getNumber(b.name);
  };

  // this function is used to to sort the data values in the combine operator
  // when add or remove a Data name field
  const updateCombineOperatorDataValues = useCallback(
    (operators) =>
      operators.map((operator) => {
        if (operator.id === operatorId) {
          return {
            ...operator,
            values: reorderCombineOperatorDataValues(
              operator.values.sort(numericSort),
            ),
          };
        }

        return operator;
      }),
    [operatorId, reorderCombineOperatorDataValues],
  );

  const handleAddField = useCallback(() => {
    const dataFieldOrder = operator?.values?.reduce((acc, value) => {
      if (value.name.startsWith('Data')) {
        const order = parseInt(value.name.replace('Data', ''), 10);

        return order > acc ? order : acc;
      }

      return acc;
    }, 1);

    addOperatorValueMutation.mutate({
      workflowId,
      operatorId,
      valueName: `Data${dataFieldOrder + 1}`,
      valueType: OperatorInputTypes.LIST_GEOMETRY_CLASSIFIED_POLYLINES,
      updateOperatorValues: updateCombineOperatorDataValues,
    });
  }, [
    workflowId,
    operatorId,
    operator?.values,
    addOperatorValueMutation,
    updateCombineOperatorDataValues,
  ]);

  const handleFieldRemove = useCallback(
    (fieldInput) => () => {
      removeOperatorValueMutation.mutate({
        workflowId,
        operatorId,
        operatorValueId: fieldInput.id,
        updateOperatorValues: updateCombineOperatorDataValues,
      });
    },
    [
      removeOperatorValueMutation,
      workflowId,
      operatorId,
      updateCombineOperatorDataValues,
    ],
  );

  const handleSelectdBlur = useCallback(
    (cb) => (e) => {
      const relatedTarget = e?.relatedTarget;

      if (
        relatedTarget &&
        findClosestElementById(relatedTarget, DROP_DOWN_MENU_ID)
      ) {
        const dropDownMenuElement = document.getElementById(DROP_DOWN_MENU_ID);

        dropDownMenuElement.addEventListener('blur', () => {
          cb(e);
        });

        return;
      }

      cb(e);
    },
    [],
  );

  const getDropDownCanvasSelectionProps = useCallback(
    (option, onMouseSelectionClick) => {
      if (!option?.isCanvasSelectionOption) return {};

      const description = !option?.dependencyIsComputed
        ? intl.formatMessage({
            id: 'canvasselection.field.compute_to_select',
            defaultMessage: 'Compute to select',
          })
        : undefined;

      const optionalProps = {
        description,
      };

      return {
        label: option?.tag,
        leadingIconName: 'arrow_selector_tool_0',
        disabled: !option?.dependencyIsComputed,
        onClick: () => {
          onMouseSelectionClick?.(option?.operatorId);
        },
        ...optionalProps,
      };
    },
    [intl],
  );

  const _getTooltipDescription = useCallback(
    (option) => {
      if (option?.description) {
        return (
          <span dangerouslySetInnerHTML={{ __html: option.description }} />
        );
      }

      return EnglishTranslation[option?.descriptionKey]
        ? intl.formatMessage(
            {
              id: option?.descriptionKey,
              defaultMessage: option?.description,
            },
            { br },
          )
        : undefined;
    },
    [intl],
  );

  const getDropDownGeneralProps = useCallback(
    (field, option, selectedOption, isFieldInputType) => {
      const withTooltip = option?.assetImageUrl || option?.descriptionKey;
      const tooltip = withTooltip
        ? {
            tooltipAnimated: false,
            tooltipWidth: 300,
            tooltipPosition: TOOLTIP_POSITION_BOTTOM_LEFT,
            tooltipOffsetRight: 10,
            tooltipMediaSrc: option?.assetImageUrl,
            tooltipMediaHeight: 156,
            tooltipMediaPadding: '20px',
            tooltipInfoRow: {
              label: option?.value,
              description: _getTooltipDescription(option),
            },
          }
        : undefined;

      const optionalProps = {
        tooltip,
      };

      return {
        label: option?.label,
        leadingIconUrl: option?.assetIconUrl,
        selected: selectedOption?.value === option?.value,
        withDivider: option?.withDivider,
        onClick: () => {
          field.onChange({ target: { value: option?.value } });

          if (isFieldInputType && isAutoComputeOperatorEnabled) {
            computeOperator();
          }
        },
        ...optionalProps,
      };
    },
    [_getTooltipDescription, isAutoComputeOperatorEnabled, computeOperator],
  );

  const renderDropDownField = useCallback(
    (fieldInput = {}, options = [], onMouseSelectionClick = () => {}) => {
      const fieldId = fieldInput?.id;
      const formFieldName = `${fieldNameBasePath}.${fieldId}`;
      const fieldType = fieldInput?.type || '';
      const fieldName = fieldInput?.name || '';
      const isFieldInputType = [
        ...DROP_DOWN_INPUT_FIELD_TYPES,
        ...DROP_DOWN_FILE_FIELD_TYPES,
      ].includes(fieldType);
      const dropDownMenuScrolledToBottom = isFieldInputType;
      const withRemoveButton =
        !disabled &&
        fieldType === OperatorInputTypes.LIST_GEOMETRY_CLASSIFIED_POLYLINES;

      return (
        <Controller
          name={formFieldName}
          render={({ field, fieldState }) => {
            const error = fieldState.isTouched && fieldState.error;
            const fieldIsDirty = !error && fieldState.isDirty;

            const selectedOption = options?.find(
              (option) => option?.value === field.value,
            ) || { label: '', value: '' };
            const dropDownMenuItems = options?.map((option) => {
              return {
                ...getDropDownGeneralProps(
                  field,
                  option,
                  selectedOption,
                  isFieldInputType,
                ),
                ...getDropDownCanvasSelectionProps(
                  option,
                  onMouseSelectionClick,
                ),
              };
            });

            return (
              <SettingDropDownWithIconButton
                label={fieldName}
                labelTooltip={{
                  ...settingTooltipProps,
                  tooltipInfoRow: {
                    label: fieldName,
                    description: getOperatorInputDescription(operator, fieldId),
                  },
                }}
                dropDownField={{
                  disabled,
                  value: selectedOption?.label,
                  placeholder: getDropDownPlaceholder(fieldInput),
                  error: !!error,
                  supportingText: error?.message,
                  dirty: fieldIsDirty,
                  diffRemoved: fieldInput?.diffRemoved,
                  diffAdded: fieldInput?.diffAdded,
                  diffModified: fieldInput?.diffModified,
                  dropDownMenuItems: dropDownMenuItems,
                  dropDownMenuPosition: DROP_DOWN_MENU_POSITION_BOTTOM_RIGHT,
                  dropDownMenuScrolledToBottom,
                  fullWidthDropDownMenu: false,
                  onBlur: handleSelectdBlur(field.onBlur),
                }}
                {...(withRemoveButton
                  ? {
                      iconButton: {
                        iconName: 'delete_0',
                        onClick: handleFieldRemove(fieldInput),
                      },
                    }
                  : {})}
              />
            );
          }}
        />
      );
    },
    [
      disabled,
      fieldNameBasePath,
      getDropDownPlaceholder,
      getOperatorInputDescription,
      handleFieldRemove,
      operator,
      handleSelectdBlur,
      getDropDownCanvasSelectionProps,
      getDropDownGeneralProps,
    ],
  );

  const renderStandardDropDownField = useCallback(
    (fieldInput = {}) => {
      const options = getOperatorFieldOptions(operator, fieldInput, form);
      return renderDropDownField(fieldInput, options);
    },
    [renderDropDownField, operator, getOperatorFieldOptions, form],
  );

  const renderCanvasSelectionField = useCallback(
    (fieldInput = {}, referencedInputs = {}) => {
      const fieldId = fieldInput?.id;
      const formFieldName = `${fieldNameBasePath}.${fieldId}`;
      const referencedModelFieldName = `${fieldNameBasePath}.${referencedInputs?.modelInput?.id}`;
      const referencedShapeIdFieldName = `${fieldNameBasePath}.${referencedInputs?.idInput?.id}`;

      return (
        <CanvasSelectionChip
          formFieldName={formFieldName}
          fieldInput={fieldInput}
          referencedModelFieldName={referencedModelFieldName}
          referencedShapeIdFieldName={referencedShapeIdFieldName}
          renderDropDownField={renderDropDownField}
          disabled={disabled}
          operator={operator}
        />
      );
    },
    [fieldNameBasePath, renderDropDownField, disabled, operator],
  );

  const renderCanvasSelectionDropDownField = useCallback(
    (fieldInput = {}) => {
      const referencedInputs = getReferencedCanvasSelectionField(
        fieldInput,
        operator,
      );
      return renderCanvasSelectionField(fieldInput, referencedInputs);
    },
    [renderCanvasSelectionField, operator, getReferencedCanvasSelectionField],
  );

  const shouldRenderMultiTypeCanvasSelectionField = useCallback(
    (fieldInput) => {
      const canvasSelectionActive =
        getIsCanvasSelectionActiveForField(fieldInput);
      const referencedInputs = getReferencedCanvasSelectionField(
        fieldInput,
        operator,
      );
      const referencedModelFieldName = `${fieldNameBasePath}.${referencedInputs?.modelInput?.id}`;
      const referencedShapeIdFieldName = `${fieldNameBasePath}.${referencedInputs?.idInput?.id}`;
      const [watchReferencedModelInput, watchReferencedShapeIdField] =
        form?.getValues([
          referencedModelFieldName,
          referencedShapeIdFieldName,
        ]) || [];
      const canvasSelectionFieldFilled =
        watchReferencedModelInput && watchReferencedShapeIdField;

      return canvasSelectionActive || canvasSelectionFieldFilled;
    },
    [
      getIsCanvasSelectionActiveForField,
      getReferencedCanvasSelectionField,
      fieldNameBasePath,
      operator,
      form,
    ],
  );

  const renderMultiTypeDropDownField = useCallback(
    (fieldInput = {}) => {
      if (shouldRenderMultiTypeCanvasSelectionField(fieldInput)) {
        return renderCanvasSelectionDropDownField(fieldInput);
      }

      const options = getOperatorFieldOptions(operator, fieldInput, {
        getValues: form?.getValues,
        withNestedMenu: true,
      });
      const fieldId = fieldInput?.id;
      const formFieldName = `${fieldNameBasePath}.${fieldId}`;
      const fieldType = fieldInput?.type || '';
      const fieldName = fieldInput?.name || '';
      const isFieldInputType = [
        ...DROP_DOWN_INPUT_FIELD_TYPES,
        ...DROP_DOWN_FILE_FIELD_TYPES,
      ].includes(fieldType);

      const pupulateWithProps = (option, selectedOption, field) => ({
        ...getDropDownGeneralProps(
          field,
          option,
          selectedOption,
          isFieldInputType,
        ),
        selected:
          selectedOption?.value === option?.value &&
          !option?.isCanvasSelectionOption,
        ...getDropDownCanvasSelectionProps(option, () =>
          selectCanvasSelectionField(fieldInput, option?.operatorId),
        ),
      });

      const generateNestedOptionProps = (option, selectedOption, field) => {
        const hasNestedOptions = !isEmpty(option?.options);
        const nestedOptionsProps = hasNestedOptions
          ? {
              dropDownMenuItems: option?.options.map((option, i, options) => ({
                ...pupulateWithProps(option, selectedOption, field),
                withDivider:
                  options?.[i - 1]?.isCanvasSelectionOption &&
                  !option?.isCanvasSelectionOption,
              })),
            }
          : {};

        return nestedOptionsProps;
      };

      return (
        <Controller
          name={formFieldName}
          render={({ field, fieldState }) => {
            const error = fieldState.isTouched && fieldState.error;
            const fieldIsDirty = !error && fieldState.isDirty;

            const flattenOptions = options?.reduce((acc, option) => {
              if (isEmpty(option?.options)) {
                return [...acc, option];
              }

              return [...acc, option, ...(option?.options || {})];
            }, []);

            const selectedOption = flattenOptions?.find(
              (option) => option?.value === field.value,
            ) || { label: '', value: '' };

            const dropDownMenuItems = options?.map((option) => {
              const nestedOptionsProps = generateNestedOptionProps(
                option,
                selectedOption,
                field,
              );

              return {
                ...pupulateWithProps(option, selectedOption, field),
                ...nestedOptionsProps,
              };
            });

            return (
              <SettingDropDownWithIconButton
                label={fieldName}
                labelTooltip={{
                  ...settingTooltipProps,
                  tooltipInfoRow: {
                    label: fieldName,
                    description: getOperatorInputDescription(operator, fieldId),
                  },
                }}
                dropDownField={{
                  disabled,
                  value: selectedOption?.fullLabel || selectedOption?.label,
                  placeholder: getDropDownPlaceholder(fieldInput),
                  error: !!error,
                  supportingText: error?.message,
                  dirty: fieldIsDirty,
                  diffRemoved: fieldInput?.diffRemoved,
                  diffAdded: fieldInput?.diffAdded,
                  diffModified: fieldInput?.diffModified,
                  dropDownMenuItems: dropDownMenuItems,
                  dropDownMenuPosition: DROP_DOWN_MENU_POSITION_BOTTOM_RIGHT,
                  fullWidthDropDownMenu: false,
                  onBlur: handleSelectdBlur(field.onBlur),
                }}
              />
            );
          }}
        />
      );
    },
    [
      disabled,
      fieldNameBasePath,
      getDropDownPlaceholder,
      getOperatorFieldOptions,
      getOperatorInputDescription,
      handleSelectdBlur,
      operator,
      getDropDownGeneralProps,
      getDropDownCanvasSelectionProps,
      selectCanvasSelectionField,
      form,
      shouldRenderMultiTypeCanvasSelectionField,
      renderCanvasSelectionDropDownField,
    ],
  );

  const getMatchPattern = useCallback((fieldInputType) => {
    switch (fieldInputType) {
      case OperatorInputTypes.DECIMAL:
        return decimalInputPattern;
      case OperatorInputTypes.STRING:
        return stringInputPattern;
      case OperatorInputTypes.INTEGER:
        return integerInputPattern;
      default:
        return integerInputPattern;
    }
  }, []);

  const renderFileField = useCallback(
    (fieldInput = {}) => {
      const fieldId = fieldInput?.id;
      const formFieldName = `${fieldNameBasePath}.${fieldId}`;
      const fieldName = fieldInput?.name || '';

      return (
        <Controller
          name={formFieldName}
          render={({ field: { onChange, value }, fieldState }) => {
            const error = fieldState.error;
            const onSettingChange = (e) => {
              onChange(e);

              if (isAutoComputeOperatorEnabled) {
                computeOperator();
              }
            };

            return (
              <SettingsFileButton
                labelTooltip={{
                  ...settingTooltipProps,
                  tooltipInfoRow: {
                    label: fieldName,
                    description: getOperatorInputDescription(operator, fieldId),
                  },
                }}
                fieldInput={fieldInput}
                onChange={onSettingChange}
                value={value}
                disabled={disabled}
                error={Boolean(error)}
              />
            );
          }}
        />
      );
    },
    [
      fieldNameBasePath,
      getOperatorInputDescription,
      operator,
      disabled,
      computeOperator,
      isAutoComputeOperatorEnabled,
    ],
  );

  const renderInputField = useCallback(
    (fieldInput = {}) => {
      const fieldId = fieldInput?.id;
      const formFieldName = `${fieldNameBasePath}.${fieldId}`;
      const fieldName = fieldInput?.name || '';
      const fieldInputType = fieldInput?.type || '';
      const intlId = `operatorinput.placeholder.${fieldInputType?.toLowerCase()}`;

      return (
        <Controller
          name={formFieldName}
          rules={{ required: true }}
          render={({ field, fieldState }) => {
            const error = fieldState.isTouched && fieldState.error;
            const fieldIsDirty = !error && fieldState.isDirty;

            return (
              <SettingTextField
                label={fieldName}
                labelTooltip={{
                  ...settingTooltipProps,
                  tooltipInfoRow: {
                    label: fieldName,
                    description: getOperatorInputDescription(operator, fieldId),
                  },
                }}
                field1={{
                  disabled,
                  id: fieldId,
                  name: formFieldName,
                  placeholder: intl.formatMessage({
                    id: intlId,
                    defaultMessage: fieldInputType,
                  }),
                  dirty: fieldIsDirty,
                  error: !!error,
                  supportingText: error?.message,
                  diffRemoved: fieldInput?.diffRemoved,
                  diffAdded: fieldInput?.diffAdded,
                  diffModified: fieldInput?.diffModified,
                  onKeyPress: whenEnterButtonPressed(
                    whenMatchPattern(getMatchPattern(fieldInputType)),
                    computeWorkflow,
                  ),
                  ...field,
                  onChange: whenMatchPattern(
                    getMatchPattern(fieldInputType),
                    field.onChange,
                  ),
                }}
              />
            );
          }}
        />
      );
    },
    [
      computeWorkflow,
      disabled,
      fieldNameBasePath,
      getMatchPattern,
      getOperatorInputDescription,
      intl,
      operator,
    ],
  );

  const renderCheckboxField = useCallback(
    (fieldInput = {}) => {
      const fieldId = fieldInput?.id;
      const formFieldName = `${fieldNameBasePath}.${fieldId}`;
      const fieldName = fieldInput?.name || '';

      return (
        <Controller
          name={formFieldName}
          rules={{ required: true }}
          render={({ field, fieldState }) => {
            const error = fieldState.isTouched && fieldState.error;
            const fieldIsDirty = !error && fieldState.isDirty;
            const checked = field.value === 'true';

            return (
              <SettingCheckbox
                label={fieldName}
                labelTooltip={{
                  ...settingTooltipProps,
                  tooltipInfoRow: {
                    label: fieldName,
                    description: getOperatorInputDescription(operator, fieldId),
                  },
                }}
                checkbox={{
                  disabled,
                  id: fieldId,
                  name: formFieldName,
                  checked: checked,
                  onChange: (checked) =>
                    field.onChange({ target: { value: checked?.toString() } }),
                  dirty: fieldIsDirty,
                  diffRemoved: fieldInput?.diffRemoved,
                  diffAdded: fieldInput?.diffAdded,
                  diffModified: fieldInput?.diffModified,
                }}
              />
            );
          }}
        />
      );
    },
    [disabled, fieldNameBasePath, getOperatorInputDescription, operator],
  );

  const renderField = useCallback(
    (fieldInput = {}) => {
      const isDropDownFieldType = DROP_DOWN_FIELD_TYPES.includes(
        fieldInput?.type,
      );
      const isMultiTypeField =
        isDropDownFieldType &&
        getIsOperatorInputMultiType(operator, fieldInput);
      const isCanvasSelectionField =
        !isMultiTypeField &&
        isDropDownFieldType &&
        doesFieldSupportCanvasSelection(operator, fieldInput);
      const isRegularDropDownField =
        !isMultiTypeField && isDropDownFieldType && !isCanvasSelectionField;

      return (
        <Fragment key={fieldInput.id}>
          {isCanvasSelectionField &&
            renderCanvasSelectionDropDownField(fieldInput)}

          {isRegularDropDownField && renderStandardDropDownField(fieldInput)}

          {isMultiTypeField && renderMultiTypeDropDownField(fieldInput)}

          {DROP_DOWN_FILE_FIELD_TYPES.includes(fieldInput?.type) &&
            renderFileField(fieldInput)}

          {INPUT_FIELD_TYPES.includes(fieldInput?.type) &&
            renderInputField(fieldInput)}

          {CHECKBOX_FIELD_TYPES.includes(fieldInput?.type) &&
            renderCheckboxField(fieldInput)}
        </Fragment>
      );
    },
    [
      doesFieldSupportCanvasSelection,
      operator,
      renderCanvasSelectionDropDownField,
      renderStandardDropDownField,
      renderMultiTypeDropDownField,
      renderFileField,
      renderInputField,
      renderCheckboxField,
      getIsOperatorInputMultiType,
    ],
  );

  return (
    <Wrapper>
      {isOperatorCategoriesEnabled ? (
        <>
          {operatorDefaultInputCategory.map(renderField)}

          {Object.entries(operatorCategoryInputs).map(
            ([categoryName, inputs]) => (
              <SettingsCategory
                key={categoryName}
                dataTestId={`workflow-operator-${operator.id}-category-${categoryName}`}
                title={categoryName}
                withDividerTop
                subcategory
                expand={isItemActive(categoryName)}
                onHeaderClick={() => toggleActiveItems([categoryName])}
              >
                {inputs.map(renderField)}
              </SettingsCategory>
            ),
          )}
        </>
      ) : (
        operatorValues.map(renderField)
      )}

      {showAddButton && (
        <SettingActionRow
          button={{
            disabled,
            iconName: 'add_0',
            children: intl.formatMessage({
              id: 'operator.fields.add_field',
              defaultMessage: 'Add Field',
            }),
            onClick: handleAddField,
          }}
        />
      )}
    </Wrapper>
  );
};

WorkflowOperatorFields.propTypes = {
  disabled: PropTypes.bool,
  operator: PropTypes.object,
  computeWorkflow: PropTypes.func,
  computeOperator: PropTypes.func,
};

export default memo(WorkflowOperatorFields);
