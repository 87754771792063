import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useDeepLink from '@hooks/useDeepLink';
import useScreenSizeObserver from '@hooks/useScreenSizeObserver';
import useHostAvailabilityCheck from '@hooks/useHostAvailabilityCheck';
import useApplicationQueries from '@hooks/application/useApplicationQueries';
import {
  getIsLoggedIn,
  getCurrentUser,
  getUserPreferences,
  getUserSessionPreferences,
  getIsTokenAuthentificated,
} from '@selectors/loginSelectors';
import AuthenticatedRoutes from '@routes/AuthenticatedRoutes';
import UnauthenticatedRoutes from '@routes/UnauthenticatedRoutes';
import GaTracker from '@utils/GaTracker';
import { addToLocalStorage } from '@utils/localStorage';
import { addToSessionStorage } from '@utils/sessionStorage';
import TokenAuthenticator from '@containers/TokenAuthenticator';
import {
  USER_PREFERENCES_KEY,
  USER_SESSION_PREFERENCES_KEY,
} from '@constants/utilityConstants';
import { GlobalStyles, Wrapper, SVGSprite } from './Main.styled';
import useDatadog from '@app/hooks/observability/useDatadog';
import useAuth from '@app/hooks/auth/useAuth';

const Main = () => {
  const user = useSelector(getCurrentUser());
  const { themeQuery } = useApplicationQueries();
  const { getLicenseStatusData } = useAuth();
  const userPreferences = useSelector(getUserPreferences);
  const userSessionPreferences = useSelector(getUserSessionPreferences);
  const tokenAuthentificated = useSelector(getIsTokenAuthentificated);
  const isLoggedIn = useSelector(getIsLoggedIn());
  const licenseStatusData = getLicenseStatusData();
  const shouldCheckDesktopLicense =
    DESKTOP_APP && !licenseStatusData && !tokenAuthentificated;
  const shouldCheckCloudToken =
    !DESKTOP_APP && isLoggedIn && !tokenAuthentificated;
  const checkForTokenValidity =
    shouldCheckDesktopLicense || shouldCheckCloudToken;

  const refetchTheme = themeQuery.refetch;

  if (!DESKTOP_APP && PROD_OR_TEST_ENV) {
    GaTracker();
  }

  useDeepLink();
  useScreenSizeObserver();
  useHostAvailabilityCheck({ pause: checkForTokenValidity });
  useDatadog();

  useEffect(() => {
    if (DESKTOP_APP) {
      refetchTheme();
    }
  }, [refetchTheme]);

  useEffect(() => {
    if (isLoggedIn) {
      addToLocalStorage(`${USER_PREFERENCES_KEY}.${user.id}`, userPreferences);
      addToSessionStorage(
        `${USER_SESSION_PREFERENCES_KEY}.${user.id}`,
        userSessionPreferences,
      );
    }
  }, [isLoggedIn, userPreferences, userSessionPreferences, user.id]);

  let ContentComponent = UnauthenticatedRoutes;

  //TODO: this one is removed because seems the query is getting resetted
  /*if (DESKTOP_APP && !themeQuery?.isFetched) {
    return null;
  }*/

  if (checkForTokenValidity) {
    ContentComponent = TokenAuthenticator;
  } else if (isLoggedIn) {
    ContentComponent = AuthenticatedRoutes;
  }

  return (
    <>
      <GlobalStyles />

      <Wrapper>
        <ContentComponent />
      </Wrapper>

      <SVGSprite uniquifyIDs={false} />
    </>
  );
};

export default Main;
