import * as THREE from 'three';
import Gantry from './Gantry';
import { printerConstants as constants } from '../../../constants/printers/printerConstants';
import { degToRad } from 'three/src/math/MathUtils';
import { machineConstants } from '../../../constants/printers/machineConstants';

/**
 * Represents a gantry object, which is composed of a tree of THREE.js components that are
 * necessary for visualisation of the movement of the machine.
 */
class Gantry5Axis extends Gantry {
  constructor(robotType, machineDefinitions, printerSettings, bed) {
    super(robotType, machineDefinitions, printerSettings, bed);
    this.props[machineConstants.gantryType] = machineConstants.gantryType5Axis;
  }
  /**
   * Moves the gantry axes based on the simulation data that has been provided.
   * The simulation data is always relative to the base, so vectors need to be
   * converted from the base coordinate system to the gantry coordinate system
   * so that the axes know which positions they need to move to.
   * @param {*} simulationData
   */
  simulate(simulationData) {
    const jointAngle1 =
      simulationData.previousStep.movement[constants.jointAngle1] +
      (simulationData.currentStep.movement[constants.jointAngle1] -
        simulationData.previousStep.movement[constants.jointAngle1]) *
        simulationData.stepRatio;
    const jointAngle2 =
      simulationData.previousStep.movement[constants.jointAngle2] +
      (simulationData.currentStep.movement[constants.jointAngle2] -
        simulationData.previousStep.movement[constants.jointAngle2]) *
        simulationData.stepRatio;
    const jointAngle3 =
      simulationData.previousStep.movement[constants.jointAngle3] +
      (simulationData.currentStep.movement[constants.jointAngle3] -
        simulationData.previousStep.movement[constants.jointAngle3]) *
        simulationData.stepRatio;
    const jointAngle4 =
      simulationData.previousStep.movement[constants.jointAngle4] +
      (simulationData.currentStep.movement[constants.jointAngle4] -
        simulationData.previousStep.movement[constants.jointAngle4]) *
        simulationData.stepRatio;
    const jointAngle5 =
      simulationData.previousStep.movement[constants.jointAngle5] +
      (simulationData.currentStep.movement[constants.jointAngle5] -
        simulationData.previousStep.movement[constants.jointAngle5]) *
        simulationData.stepRatio;
    const gantryVector = this.convertBedToGantryCoordinateSystem(
      new THREE.Vector3(jointAngle1, jointAngle2, jointAngle3),
    );
    this.moveAxes(
      gantryVector,
      simulationData.previousStep.movement.toolId,
      jointAngle4,
      jointAngle5,
    );
  }

  rotateTwoAxisLink(jointAngle1, jointAngle2) {
    const jointAngle1Rad = degToRad(jointAngle1);
    const jointAngle2Rad = degToRad(jointAngle2);
    this.axis4.rotation.set(0, 0, jointAngle1Rad);
    this.axis5.rotation.set(0, 0, jointAngle2Rad);
    this.axis4.rotation.set(0, 0, 0);
    this.axis5.rotation.set(0, 0, 0);
  }

  /**
   * Creates the part groups for each axes and the flange to mount the extruder
   */
  createJointGroups() {
    this.components = this.machineDefaults[constants.components];
    if (!this.components) {
      //eslint-disable-next-line no-console
      console.error('This machine is missing axis configuration information');
      return;
    }

    for (const [key, value] of Object.entries(this.components)) {
      if (key != constants.flange) this[key] = new THREE.Group();
      this[key].movement = value.movement;
      this[key].offset = value.offset;
      this.add(this[key]);
      this[key].position.set(
        this[key].offset.x,
        this[key].offset.y,
        this[key].offset.z,
      );
      this.name = this[key];
    }
    this.toolEnd = new THREE.Group();
    this.add(this.toolEnd);
    this.axis4ZeroRotation = new THREE.Group();
    this.toolEnd.add(this.axis4ZeroRotation);
    this.axis4ZeroRotation.setRotationFromEuler(
      new THREE.Euler(
        this.components.axis4.rotation.x,
        this.components.axis4.rotation.y,
        this.components.axis4.rotation.z,
        this.components.axis4.rotationAxis,
      ),
    );
    this.axis5ZeroRotation = new THREE.Group();
    this.axis4ZeroRotation.add(this.axis4);
    this.axis4.add(this.axis5ZeroRotation);
    this.axis5ZeroRotation.add(this.axis5);
    this.axis5ZeroRotation.position.set(
      this.components.axis5.offset.x,
      this.components.axis5.offset.y,
      this.components.axis5.offset.z,
    );
    this.axis5.add(this.flange);
    this.axis5ZeroRotation.setRotationFromEuler(
      new THREE.Euler(
        this.components.axis5.rotation.x,
        this.components.axis5.rotation.y,
        this.components.axis5.rotation.z,
        this.components.axis5.rotationAxis,
      ),
    );
    this.axis5.add(this.flange);
    this.flange.position.set(
      this.components.flange.offset.x,
      this.components.flange.offset.y,
      this.components.flange.offset.z,
    );
    this.axis4.position.set(0, 0, 0);
    this.axis5.position.set(0, 0, 0);
    this.flange.setRotationFromEuler(
      new THREE.Euler(
        this.components.flange.rotation.x,
        this.components.flange.rotation.y,
        this.components.flange.rotation.z,
        this.components.flange.rotationAxis,
      ),
    );
  }

  moveAxes(target, toolId, jointAngle4, jointAngle5) {
    for (const componentName of Object.keys(this.components)) {
      const component = this[componentName];
      if (component.movement.includes(constants.componentMovementX)) {
        component.position.set(
          target.x,
          component.position.y,
          component.position.z,
        );
      }
      if (component.movement.includes(constants.componentMovementY)) {
        component.position.set(
          component.position.x,
          target.y,
          component.position.z,
        );
      }
      if (component.movement.includes(constants.componentMovementZ)) {
        component.position.set(
          component.position.x,
          component.position.y,
          target.z,
        );
      }
      this.toolEnd.position.set(target.x, target.y, target.z);
      //moveaxes is called from parent gantry class without jointangle4
      if (jointAngle4 == null) {
        jointAngle4 = 0;
        jointAngle5 = 0;
      }
      this.axis4.rotation.set(0, 0, degToRad(jointAngle4));
      this.axis5.rotation.set(0, 0, degToRad(jointAngle5));
    }
  }
}

export default Gantry5Axis;
