import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Login from '@pages/Login';
import { ROUTES } from '@constants/router';
import NotFound from '@pages/NotFound';
import { TopLayerElements } from '@routes/AuthenticatedRoutes/AuthenticatedRoutes.styled';
import { PAGE_LOADER_CONTAINER_ID } from '@components/2-molecules/PageLoaderPortal';
import { DIALOGS_COINTAINER_ID } from '@components/2-molecules/DialogPortal';
import { MODALS_COINTAINER_ID } from '@components/2-molecules/ModalPortal';
import PageLoader from '@components/1-atoms/PageLoader';
import Dialogs from '@containers/Dialogs';
import Modals from '@containers/Modals';
import useApplication from '@hooks/application/useApplication';
import SSOTokenCaptor from '@app/pages/SSOTokenCaptor';
import SnackbarPortal, {
  SNACKBAR_CONTAINER_ID,
} from '@components/2-molecules/SnackbarPortal';
import LicenseLogin from '@pages/LicenseLogin';

const Layers = () => {
  const { getIsApplicationLoading } = useApplication();
  const isApplicationLoading = getIsApplicationLoading();

  return (
    <>
      <Dialogs />
      <Modals />
      <SnackbarPortal />
      <TopLayerElements>
        <div id={SNACKBAR_CONTAINER_ID} />
        <div id={DIALOGS_COINTAINER_ID} />
        <div id={MODALS_COINTAINER_ID} />
        <div id={PAGE_LOADER_CONTAINER_ID}>
          <PageLoader show={isApplicationLoading} />
        </div>
      </TopLayerElements>
    </>
  );
};

export default function UnauthenticatedRoutes() {
  const loginComponent = DESKTOP_APP ? LicenseLogin : Login;
  const resetPasswordComponent = DESKTOP_APP ? NotFound : Login;
  return (
    <>
      <Switch>
        <Route path={ROUTES.NOT_FOUND} component={NotFound} />
        <Route path={ROUTES.LOGIN} component={loginComponent} />
        <Route
          path={ROUTES.RESET_PASSWORD}
          component={resetPasswordComponent}
        />
        <Route path={ROUTES.SSO_RESPONSE} component={SSOTokenCaptor} />
        <Redirect to={ROUTES.LOGIN} />
      </Switch>

      <Layers />
    </>
  );
}
