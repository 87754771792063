export const logInfo = (...args) => {
  window.electron?.send('log-info', `[FRONTEND]-Args: ${args}`);
};

export const logWarn = (...args) => {
  window.electron?.send('log-warn', `[FRONTEND]-Args: ${args}`);
};

export const logError = (error = {}) => {
  window.electron?.send(
    'log-error',
    `[FRONTEND]-Message: ${error?.message} \n Stack: ${
      error?.stack || error?.error?.stack
    }`,
  );
  logErrorObject(error);
};

export const logErrorObject = (error = {}) => {
  window.electron?.send(
    'log-error',
    `[FRONTEND]-Error: ${JSON.stringify(error, [
      'message',
      'arguments',
      'type',
      'name',
      'stack',
    ])}`,
  );
};
