import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import environments from '@utils/environment';
import { getCurrentUser } from '@app/selectors/loginSelectors';
import { useSelector } from 'react-redux';
import { getConfig } from '@actions/AuthenticatedFetch';
import { useState } from 'react';
import useFeatureFlagValue from '../featureflags/useFeatureFlagValue';
import {
  DATADOG_RUM_ENABLED,
  DATADOG_SESSION_REPLAY_ENABLED,
} from '@app/constants/featureFlagConstants';

const USERNAMES_TO_IGNORE_FOR_SESSION_REPLAYS = [
  'aibuild-qa+test2@aibuild.com',
  'aibuild-qa-superadmin@aibuild.com',
  'aibuild-qa-superadmin@aibuild-light.com',
  'aibuild-qa-superadmin@aibuild-standard.com',
  'aibuild-qa+test1@aibuild.com',
  'e2e-automatedtest@ai-build.com',
  'aibuild-qa+test4@aibuild.com',
];

const environmentMappings = {
  test: 'test',
  production: 'prod',
};

export default function useDatadog() {
  const config = getConfig();
  const currentUser = useSelector(getCurrentUser());
  const [isSessionReplayActive, setIsSessionReplayActive] = useState(false);
  const isRumEnabled = useFeatureFlagValue(DATADOG_RUM_ENABLED);
  const isSessionReplayEnabled = useFeatureFlagValue(
    DATADOG_SESSION_REPLAY_ENABLED,
  );

  if (DESKTOP_APP || DEVELOPMENT_ENV || !isRumEnabled) {
    return;
  }

  if (datadogRum.getInitConfiguration() === undefined) {
    datadogRum.init({
      applicationId: 'd141bbc8-6ada-4649-9d12-c54be43e57cf',
      clientToken: 'pubd323ab5bcfdfadf3d34400165c46edbb',
      site: 'datadoghq.eu',
      service: 'aibuild',
      env: environmentMappings[environments.current] || environments.current,
      version: process.env.APP_VERSION,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'allow',
      startSessionReplayRecordingManually: true,
      allowedTracingUrls: [(url) => url.startsWith(config.baseUrl)],
      enableExperimentalFeatures: ['feature_flags'],
    });
  }
  if (datadogLogs.getInitConfiguration() === undefined) {
    datadogLogs.init({
      clientToken: 'pubd323ab5bcfdfadf3d34400165c46edbb',
      site: 'datadoghq.eu',
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      forwardConsoleLogs: 'all',
    });
  }

  if (
    isSessionReplayEnabled &&
    !isSessionReplayActive &&
    currentUser?.username &&
    !USERNAMES_TO_IGNORE_FOR_SESSION_REPLAYS.includes(currentUser?.username)
  ) {
    datadogRum.setUser({
      id: currentUser.id,
      name: currentUser.name,
      email: currentUser.username,
      organizationId: currentUser.organizationId,
      organizationName: currentUser.organizationName,
    });
    datadogRum.startSessionReplayRecording();
    setIsSessionReplayActive(true);
  }
}
