import { useQueryClient } from '@tanstack/react-query';
import { authQueryKeys } from './useAuthQueries';
import { useCallback } from 'react';

export default function useAuth() {
  const queryClient = useQueryClient();

  const getLicenseStatusData = useCallback(() => {
    return queryClient.getQueryData(authQueryKeys.licenseStatus);
  }, [queryClient]);

  return {
    getLicenseStatusData,
  };
}
