import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { concat } from 'lodash';
import { operatorsQueryKeys } from '@hooks/operators/useOperatorQueries';
import { workflowQueryKeys } from '@hooks/workflows/useWorkflowQueries';
import client from '@api/client';
import endpoints from '@api/endpoints';
import { updateLastAddedOperator } from '@actions/conceptActions';
import { logError } from '@utils/logs';

export const operatorMutationKeys = {
  favouriteCustomOperator: ['favouriteCustomOperator'],
  addOperator: ['showLoader', 'addOperator'],
  cloneOperator: ['showLoader', 'cloneOperator'],
  deleteOperator: ['deleteOperator'],
  deleteCustomOperator: ['deleteCustomOperator'],
  upgradeOperators: ['showLoader', 'upgradeOperators'],
  reorderOperator: ['showLoader', 'reorderOperator'],
  explode: ['showLoader', 'explode'],
  editOperatorName: ['showLoader', 'explode'],
  addOperatorValue: ['showLoader', 'addOperatorValue'],
  removeOperatorValue: ['showLoader', 'removeOperatorValue'],
  streamToolpathOperatorToPrinter: [
    'showLoader',
    'streamToolpathOperatorToPrinter',
  ],
};

export default function usePrinterMutations() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const favouriteCustomOperatorMutation = useMutation({
    mutationKey: operatorMutationKeys.favouriteCustomOperator,
    mutationFn: ({ templateId, favourite } = {}) =>
      client
        .patch(endpoints.template.replace(':templateId', templateId), {
          favourite,
        })
        .then((res) => res.data),
    onSuccess: (_, { templateId, favourite } = {}) => {
      const updater = (data = []) =>
        data?.map((template) =>
          template.id === templateId ? { ...template, favourite } : template,
        );

      queryClient.setQueryData(operatorsQueryKeys.templates, updater);
    },
  });

  const addOperatorMutation = useMutation({
    mutationKey: operatorMutationKeys.addOperator,
    mutationFn: ({
      workflowId,
      operatorName,
      operatorTag,
      templateId,
      order,
    } = {}) =>
      client
        .post(endpoints.operators.replace(':conceptId', workflowId), {
          name: operatorName,
          tag: operatorTag || operatorName,
          templateId: templateId || undefined,
          order,
        })
        .then((res) => res.data),
    onSuccess: (addedOperator, { workflowId } = {}) => {
      const updater = (data) => {
        const filteredOperators = data?.operators?.filter(
          (operator) => !operator.adding,
        );

        return {
          ...data,
          operators: concat(filteredOperators, addedOperator),
        };
      };

      dispatch(updateLastAddedOperator(addedOperator?.id));

      queryClient.setQueryData(workflowQueryKeys.workflow(workflowId), updater);
    },
    onError: (_, { workflowId } = {}) => {
      const updater = (data) => {
        const filteredOperators = data?.operators?.filter(
          (operator) => !operator.adding,
        );

        return {
          ...data,
          operators: filteredOperators,
        };
      };

      queryClient.setQueryData(workflowQueryKeys.workflow(workflowId), updater);
    },
  });

  const cloneOperatorMutation = useMutation({
    mutationKey: operatorMutationKeys.cloneOperator,
    mutationFn: ({ workflowId, operatorId }) =>
      client
        .put(
          endpoints.duplicateOperator
            .replace(':workflowId', workflowId)
            .replace(':operatorId', operatorId),
        )
        .then((res) => res.data),
    onSuccess: async (clonedOperator, { workflowId }) => {
      try {
        const updatedWorkflow = await client
          .get(endpoints.concept.replace(':conceptId', workflowId))
          .then((res) => res.data);

        const updater = (workflow) => {
          return workflow
            ? {
                ...workflow,
                operatorDependencies: updatedWorkflow.operatorDependencies,
                operators: workflow.operators
                  .toSpliced(clonedOperator.order - 1, 0, clonedOperator)
                  .map((operator, index) => ({
                    ...operator,
                    order: index + 1,
                  })),
              }
            : undefined;
        };

        queryClient.setQueryData(
          workflowQueryKeys.workflow(workflowId),
          updater,
        );
      } catch (e) {
        logError(e);
      }
    },
  });

  const deleteOperatorMutation = useMutation({
    mutationKey: operatorMutationKeys.deleteOperator,
    mutationFn: ({ operatorId }) =>
      client
        .delete(endpoints.operator.replace(':operatorId', operatorId))
        .then((res) => res.data),
    onSuccess: (_, { workflowId, operatorId }) => {
      const updater = (workflow) => {
        return workflow
          ? {
              ...workflow,
              operators: workflow.operators
                ?.filter((operator) => operator.id !== operatorId)
                .map((operator, index) => ({
                  ...operator,
                  order: index + 1,
                })),
            }
          : undefined;
      };

      queryClient.setQueryData(workflowQueryKeys.workflow(workflowId), updater);
    },
  });

  const deleteCustomOperatorMutation = useMutation({
    mutationKey: operatorMutationKeys.deleteCustomOperator,
    mutationFn: (templateId) =>
      client
        .delete(endpoints.template.replace(':templateId', templateId))
        .then((res) => res.data),
    onSuccess: (_, mutationTemplateId) => {
      const updater = (data) => {
        return data
          ? data?.filter((template) => template.id !== mutationTemplateId)
          : undefined;
      };

      queryClient.setQueryData(operatorsQueryKeys.templates, updater);
    },
  });

  const upgradeOperatorsMutation = useMutation({
    mutationKey: operatorMutationKeys.upgradeOperators,
    mutationFn: async ({ workflowId, operatorsId } = {}) => {
      await client
        .put(endpoints.upgradeWorkflow.replace(':workflowId', workflowId), {
          operatorsId,
        })
        .then((res) => res.data);
      await queryClient.refetchQueries({
        queryKey: operatorsQueryKeys.defaultOperators(workflowId),
      });
      const workflow = await client
        .get(endpoints.concept.replace(':conceptId', workflowId))
        .then((res) => res.data);

      return workflow;
    },
    onSuccess: (updatedWorkflow, { workflowId } = {}) => {
      const updater = () => updatedWorkflow;

      queryClient.setQueryData(workflowQueryKeys.workflow(workflowId), updater);
    },
  });

  const reorderOperatorMutation = useMutation({
    mutationKey: operatorMutationKeys.reorderOperator,
    mutationFn: ({ operatorId, order }) =>
      client
        .put(
          endpoints.reorderOperator
            .replace(':operatorId', operatorId)
            .replace(':order', order),
        )
        .then((res) => res.data),
    onError: (_, { workflowId, originalOperatorsList }) => {
      const updater = (workflow) => {
        return workflow
          ? {
              ...workflow,
              operators: originalOperatorsList,
            }
          : undefined;
      };

      queryClient.setQueryData(workflowQueryKeys.workflow(workflowId), updater);
    },
  });

  const explodeOperatorMutation = useMutation({
    mutationKey: operatorMutationKeys.explode,
    mutationFn: ({ workflowId, operatorId }) =>
      client
        .post(
          endpoints.explodeTemplate
            .replace(':conceptId', workflowId)
            .replace(':operatorId', operatorId),
        )
        .then((res) => res.data),
    onSuccess: (explodedWorkflow, { workflowId }) => {
      const updater = (workflow) => {
        return workflow
          ? {
              ...workflow,
              operators: explodedWorkflow.operators,
            }
          : undefined;
      };

      queryClient.setQueryData(workflowQueryKeys.workflow(workflowId), updater);
    },
  });

  const editOperatorNameMutation = useMutation({
    mutationKey: operatorMutationKeys.editOperatorName,
    mutationFn: ({ workflowId, operatorId, operatorName }) =>
      client
        .post(
          endpoints.updateOperatorTag
            .replace(':workflowId', workflowId)
            .replace(':operatorId', operatorId),
          { requestedTag: operatorName },
        )
        .then((res) => res.data),
    onSuccess: (_, { workflowId, operatorId, operatorName }) => {
      const updater = (workflow) => {
        return workflow
          ? {
              ...workflow,
              operators: workflow.operators?.map((operator) =>
                operator.id === operatorId
                  ? { ...operator, tag: operatorName }
                  : operator,
              ),
            }
          : undefined;
      };

      queryClient.setQueryData(workflowQueryKeys.workflow(workflowId), updater);
    },
  });

  const addOperatorValueMutation = useMutation({
    mutationKey: operatorMutationKeys.addOperatorValue,
    mutationFn: ({ operatorId, valueName, valueType, value = '' }) =>
      client
        .post(endpoints.operatorValues, {
          operatorId,
          name: valueName,
          type: valueType,
          value,
        })
        .then((res) => res.data),
    onSuccess: (
      addedOperatorValue,
      { workflowId, operatorId, updateOperatorValues },
    ) => {
      const updater = (workflow) => {
        if (!workflow) {
          return undefined;
        }

        let nextOperators = workflow.operators?.map((operator) =>
          operator.id === operatorId
            ? {
                ...operator,
                values: concat(operator.values, addedOperatorValue),
              }
            : operator,
        );

        if (updateOperatorValues) {
          nextOperators = updateOperatorValues?.(nextOperators);
        }

        const nextWorkflow = {
          ...workflow,
          operators: nextOperators,
        };

        return nextWorkflow;
      };

      queryClient.setQueryData(workflowQueryKeys.workflow(workflowId), updater);
    },
  });

  const removeOperatorValueMutation = useMutation({
    mutationKey: operatorMutationKeys.removeOperatorValue,
    mutationFn: ({ operatorId, operatorValueId }) =>
      client
        .delete(
          endpoints.operatorValue
            .replace(':operatorId', operatorId)
            .replace(':operatorValueId', operatorValueId),
        )
        .then((res) => res.data),
    onSuccess: (
      _,
      { workflowId, operatorId, operatorValueId, updateOperatorValues },
    ) => {
      const updater = (workflow) => {
        if (!workflow) {
          return undefined;
        }

        let nextOperators = workflow.operators?.map((operator) =>
          operator.id === operatorId
            ? {
                ...operator,
                computed: false,
                values: operator.values?.filter(
                  (value) => value.id !== operatorValueId,
                ),
              }
            : operator,
        );

        if (updateOperatorValues) {
          nextOperators = updateOperatorValues?.(nextOperators);
        }

        const nextWorkflow = {
          ...workflow,
          operators: nextOperators,
        };

        return nextWorkflow;
      };

      queryClient.setQueryData(workflowQueryKeys.workflow(workflowId), updater);
    },
  });

  const streamToolpathOperatorToPrinterMutation = useMutation({
    mutationKey: operatorMutationKeys.streamToolpathOperatorToPrinter,
    mutationFn: ({ printerId, operatorId }) =>
      client
        .post(
          endpoints.printerStreamOperator
            .replace(':printerId', printerId)
            .replace(':operatorId', operatorId),
        )
        .then((res) => res.data),
  });

  return {
    favouriteCustomOperatorMutation,
    addOperatorMutation,
    cloneOperatorMutation,
    deleteOperatorMutation,
    deleteCustomOperatorMutation,
    upgradeOperatorsMutation,
    reorderOperatorMutation,
    explodeOperatorMutation,
    editOperatorNameMutation,
    addOperatorValueMutation,
    removeOperatorValueMutation,
    streamToolpathOperatorToPrinterMutation,
  };
}
