//MACHINE TYPES
export const MACHINE_GANTRY = 'MACHINE_GANTRY';
export const MACHINE_GANTRY_5_AXIS = 'MACHINE_GANTRY_5_AXIS';
export const MACHINE_SIX_AXIS_ROBOT = 'MACHINE_SIX_AXIS_ROBOT';
export const MACHINE_SIX_AXIS_ROBOT_COUPLED = 'MACHINE_SIX_AXIS_ROBOT_COUPLED';
export const MACHINE_DEMO = 'MACHINE_DEMO';

//BED KINEMATIC TYPES
export const STATIC_BED_KINEMATICS = 'STATIC';
export const TWO_AXIS_POSITIONER_BED_KINEMATICS = 'TWO_AXIS_POSITIONER';
export const ROTARY_BED_KINEMATICS = 'ROTARY';

//GANTRY Z MOVEMENTS
export const GANTRY_Z_MOVEMENT_BED = 'Z_MOVEMENT_BED';
export const GANTRY_Z_MOVEMENT_EXTRUDER = 'Z_MOVEMENT_EXTRUDER';
export const GANTRY_AXES = 'GANTRY_AXES';

//PRINT OBJECT SIMULATION
export const PRINT_OBJECT_ANCHOR_NAME = 'printObjectAnchor';

//EXTERNAL AXIS BED
export const EXTERNAL_AXIS_NAME = 'axis';
export const ZERO_ROTATION = 'ZeroRotation';

//SIMULATION DATA
export const EXTERNAL_JOINT_ANGLE = 'externalJointAngle';
export const LINEAR_RAIL_POSITION = 'linearRailPosition';

//LINEAR RAIL
export const PLINTH_KINEMATICS_LINEAR_RAIL = 'LINEAR_RAIL';
export const STATIC_PLINTH = 'STATIC_PLINTH';

//DEFAULT PRINTER COMPONENTS
export const DEFAULT_EXTRUDER = 'Aibuild AiMaker V5';
export const DEFAULT_PRINTING_BED = 'Parametric';
export const DEFAULT_PLINTH = 'NoPlinth';
export const DEFAULT_BASE_PLINTH = 'NoBasePlinth';
export const DEFAULT_ENCLOSURE = 'NoEnclosure';

export const DISPLAY_NAME_POWER_WASP_45_HDP = 'POWER WASP 45 HDP';
