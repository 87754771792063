import { s3Directories } from './s3Directories';

//values are used as compnent keys to generate S3 path directorys. Do not change.
export const toolTypeFiles = [
  {
    value: s3Directories.extruderModel,
    label: 'Model',
    allowedExtensions: ['glb', 'gltf', 'obj'],
  },
  {
    value: s3Directories.extruderCollisionModel,
    label: 'Collision Model',
    allowedExtensions: ['obj'],
    placeholderLabel:
      'printers.settings.tool.type.fileupload.button.collision.model.label',
    fileSize: 2,
  },
];

export const machineFiles = (numberOfAxes) =>
  Array.from({ length: numberOfAxes }, (_, i) => ({
    value: s3Directories.machineAxis + i,
    label: `Link ${i}`,
  }));

export const plinthTypeStaticFiles = [
  {
    value: s3Directories.plinthBase,
    label: 'Plinth',
  },
];

export const plinthTypeLinearFiles = [
  {
    value: s3Directories.plinthBase,
    label: 'Rail',
  },
  {
    value: s3Directories.plinthRailPlatform,
    label: 'Platform',
  },
];

export const enclosureTypeFiles = [
  {
    value: s3Directories.enclosureModel,
    label: 'Model',
  },
];

export const bedTypeStaticFiles = [
  {
    value: s3Directories.bedBase,
    label: 'Bed',
  },
  {
    value: s3Directories.bedBaseCollisionModel,
    label: 'Bed Collision Model',
  },
];

export const bedTypeRotaryFiles = [
  {
    value: s3Directories.bedBase,
    label: 'Link 0',
  },
  {
    value: s3Directories.bedAxis1,
    label: 'Link 1',
  },
  {
    value: s3Directories.bedBaseCollisionModel,
    label: 'Link 0 Collision Model',
  },
  {
    value: s3Directories.bedAxis1CollisionModel,
    label: 'Link 1 Collision Model',
  },
];

export const bedTypeTwoAxisFiles = [
  {
    value: s3Directories.bedBase,
    label: 'Link 0',
  },
  {
    value: s3Directories.bedAxis1,
    label: 'Link 1',
  },
  {
    value: s3Directories.bedAxis2,
    label: 'Link 2',
  },
  {
    value: s3Directories.bedBaseCollisionModel,
    label: 'Link 0 Collision Model',
  },
  {
    value: s3Directories.bedAxis1CollisionModel,
    label: 'Link 1 Collision Model',
  },
  {
    value: s3Directories.bedAxis2CollisionModel,
    label: 'Link 2 Collision Model',
  },
];
