import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { operatorsQueryKeys } from '@hooks/operators/useOperatorQueries';
import usePrinter from '@hooks/printers/usePrinter';
import { getWeight } from '@containers/WorkflowOperatorOutputs/WorkflowOperatorOutputs';
import { LineTypes } from '@constants/lineTypes';

export default function usePrint() {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const { getPrinterMaterials } = usePrinter();

  const getOperatorAnalysisQueryData = useCallback(
    (operatorId) =>
      queryClient.getQueryData(
        operatorsQueryKeys.operatorAnalysisData(operatorId),
      ),
    [queryClient],
  );

  const getPrintEvaluation = useCallback(
    (operatorId = '', { printerId, materialId } = {}) => {
      const operatorAnalysisQueryData =
        getOperatorAnalysisQueryData(operatorId);
      const durationOutput = operatorAnalysisQueryData?.totalDuration;
      const weightOutput = operatorAnalysisQueryData?.weight;
      const duration = moment.duration(durationOutput, 'seconds');
      const formattedDuration = moment
        .utc(duration.asMilliseconds())
        .format('HH:mm');

      let output = {
        formattedDuration: `${formattedDuration} H`,
      };

      if (!weightOutput || !printerId || !materialId) {
        return output;
      }

      const materials = getPrinterMaterials(printerId);

      const material = materials?.find(({ id }) => id === materialId);

      const materialCost = weightOutput * (material?.costPerKg || 0);

      output = {
        ...output,
        formattedWeight: getWeight(weightOutput),
        formattedPrice: `$${materialCost.toFixed(2)}`,
      };

      return output;
    },
    [getOperatorAnalysisQueryData, getPrinterMaterials],
  );

  const getPrintEvaluationInfo = useCallback(
    (operatorId) => {
      const operatorAnalysisQueryData =
        getOperatorAnalysisQueryData(operatorId);
      const durationMap = operatorAnalysisQueryData?.durationMap || {};
      const labelMap = {
        [LineTypes.OUTER_WALL]: intl.formatMessage({
          id: 'displaymodepanel.text.innerwall',
          defaultMessage: 'Inner Wall',
        }),
        [LineTypes.BRIM]: intl.formatMessage({
          id: 'displaymodepanel.text.brim',
          defaultMessage: 'Brim',
        }),
        [LineTypes.TRAVEL]: intl.formatMessage({
          id: 'displaymodepanel.text.travel',
          defaultMessage: 'Travel',
        }),
        [LineTypes.SUPPORT]: intl.formatMessage({
          id: 'displaymodepanel.text.support',
          defaultMessage: 'Support',
        }),
        [LineTypes.SKIN]: intl.formatMessage({
          id: 'displaymodepanel.text.skin',
          defaultMessage: 'Skin',
        }),
        [LineTypes.INFILL]: intl.formatMessage({
          id: 'displaymodepanel.text.infill',
          defaultMessage: 'Infill',
        }),
        [LineTypes.OUTER_WALL]: intl.formatMessage({
          id: 'displaymodepanel.text.outerwall',
          defaultMessage: 'Outer Wall',
        }),
        [LineTypes.INNER_WALL]: intl.formatMessage({
          id: 'displaymodepanel.text.innerwall',
          defaultMessage: 'Inner Wall',
        }),
        [LineTypes.SUPPORT_INTERFACE]: intl.formatMessage({
          id: 'displaymodepanel.text.supportinterface',
          defaultMessage: 'Support Interface',
        }),
      };

      const totalDurationTime = Object.values(durationMap).reduce(
        (acc, duration) => acc + duration,
        0,
      );

      const durationInfo = Object.keys(durationMap).map((key) => {
        const durationOutput = durationMap[key];
        const duration = moment.duration(durationOutput, 'seconds');
        const formattedDuration = moment
          .utc(duration.asMilliseconds())
          .format('HH:mm');
        const durationPercentage = (durationOutput / totalDurationTime) * 100;

        return {
          label: labelMap[key] || key,
          duration: durationOutput,
          formattedDuration,
          durationPercentage,
          formattedDurationPercentage: `${durationPercentage.toFixed(0)}%`,
        };
      });

      return durationInfo;
    },
    [intl, getOperatorAnalysisQueryData],
  );

  return {
    getOperatorAnalysisQueryData,
    getPrintEvaluation,
    getPrintEvaluationInfo,
  };
}
