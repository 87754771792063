import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  bedTypeRotaryFiles,
  bedTypeStaticFiles,
  bedTypeTwoAxisFiles,
} from '@constants/printers/componentFiles';
import {
  bedTypeDefinitions,
  defaultRotaryProperties,
  defaultStaticProperties,
  defaultTwoAxisProperties,
} from '@constants/printers/printingBedDefaultProperties';
import { RotarySetting } from '@components/Printers/GeneralPrinterSetting/BedTypeSetting/RotarySetting';
import { StaticSetting } from '@components/Printers/GeneralPrinterSetting/BedTypeSetting/StaticSetting';
import { TwoAxisSetting } from '@components/Printers/GeneralPrinterSetting/BedTypeSetting/TwoAxisSetting';
import * as Yup from 'yup';
import { DiagramRowComponent } from '@components/Printers/GeneralPrinterSetting/DiagramRowComponent';
import { Text } from '../Text';
import SettingsCategory from '@components/2-molecules/SettingsCategory';
import { Field as FormikField, useFormikContext } from 'formik';
import SettingDropDown from '@components/2-molecules/SettingDropDown';
import { getFormattedOption } from '@components/Printers/SettingsUtils';
import { checkIfFieldIsDirty } from '@utils/commonFunctions';
import { generateSingleFileUpload } from '@utils/filesUpload';

export const BedTypeSetting = () => {
  const intl = useIntl();

  const { values, initialValues, setFieldValue } = useFormikContext();
  const bedTypeSettingsValues = values['bedTypeSetting'];
  const setValue = useMemo(
    () => (field, value) => {
      setFieldValue(`bedTypeSetting.${field}`, value);
    },
    [setFieldValue],
  );

  const changeBedType = useCallback(
    (option) => {
      let filesData = [];
      let properties = {};
      switch (option.value) {
        case bedTypeDefinitions.ROTARY_BED.value: {
          filesData = bedTypeRotaryFiles;
          properties = defaultRotaryProperties;
          break;
        }
        case bedTypeDefinitions.TWO_AXIS_BED.value: {
          properties = defaultTwoAxisProperties;
          filesData = bedTypeTwoAxisFiles;
          break;
        }
        case bedTypeDefinitions.STATIC_BED.value: {
          filesData = bedTypeStaticFiles;
          properties = defaultStaticProperties;
          break;
        }
      }

      const filesObject = Object.fromEntries(
        filesData.map(({ value, label }) => [
          value,
          {
            label,
            file: null,
            fileName: null,
          },
        ]),
      );

      setValue('files', filesObject);
      setValue('bedProperties', properties);
    },
    [setValue],
  );

  const getFormattedOptions = useMemo(
    () => (definitions, changeBedType) =>
      Object.values(definitions).map((option) =>
        getFormattedOption(option, changeBedType),
      ),
    [],
  );

  const options = getFormattedOptions(bedTypeDefinitions, changeBedType);

  const renderUploadRows = () => {
    if (bedTypeSettingsValues.files) {
      return Object.keys(bedTypeSettingsValues.files).map((axisKey) => {
        const entry = bedTypeSettingsValues.files[axisKey];
        return generateSingleFileUpload(
          entry,
          'printer-components-bed-type__category-upload',
          axisKey,
          (file) => {
            setValue(`files.${axisKey}.file`, file);
            setValue(`files.${axisKey}.fileName`, file.name + new Date());
          },
          {
            dirty: checkIfFieldIsDirty(
              values,
              initialValues,
              `bedTypeSetting.files.${axisKey}.fileName`,
            ),
          },
        );
      });
    }
  };

  const renderBedParameters = (bedType) => {
    switch (bedType) {
      case bedTypeDefinitions.ROTARY_BED.value: {
        return <RotarySetting />;
      }
      case bedTypeDefinitions.TWO_AXIS_BED.value: {
        return <TwoAxisSetting />;
      }
      case bedTypeDefinitions.STATIC_BED.value: {
        return <StaticSetting />;
      }
    }
  };

  return (
    <>
      <SettingsCategory
        title={intl.formatMessage({
          id: 'printers.settings.information.label',
        })}
        dataTestId={`printer-components-bed-type__category-information`}
        expand
        withDividerBottom={!!bedTypeSettingsValues.bedType}
      >
        <FormikField
          component={SettingDropDown}
          label={intl.formatMessage({
            id: 'printers.settings.type.label',
          })}
          dataTestId={`printer-components-bed-type__category-information__setting-bed-type`}
          name={'bedTypeSetting.bedType'}
          dropDownField={{
            dropDownMenuItems: options,
            fullWidthDropDownMenu: false,
            placeholder: intl.formatMessage({
              id: 'printers.settings.bed.type.placeholder',
              defaultMessage: 'Bed Type',
            }),
            dirty: checkIfFieldIsDirty(
              values,
              initialValues,
              'bedTypeSetting.bedType',
            ),
          }}
        />
        {(bedTypeSettingsValues.bedType.value ===
          bedTypeDefinitions.ROTARY_BED.value ||
          bedTypeSettingsValues.bedType.value ===
            bedTypeDefinitions.TWO_AXIS_BED.value) && (
          <Text
            descriptionKey={'printers.settings.externalaxis.disclaimer.label'}
            fontSize={'12'}
          />
        )}
      </SettingsCategory>
      {bedTypeSettingsValues.bedType && (
        <>
          <SettingsCategory
            title={intl.formatMessage({
              id: 'printers.settings.upload.title',
            })}
            dataTestId={`printer-components-bed-type__category-upload`}
            expand
            withDividerBottom
          >
            <DiagramRowComponent
              src={`/img/digitalTwin/bed/diagram_${bedTypeSettingsValues.bedType.value}.svg`}
              description_key={`printers.settings.bed.diagram.${bedTypeSettingsValues.bedType.value}.description.label`}
              second_description_key={'printers.settings.upload.export.label'}
            />
            {renderUploadRows()}
          </SettingsCategory>
          {renderBedParameters(bedTypeSettingsValues.bedType.value)}
        </>
      )}
    </>
  );
};

export const BedTypeSettingValidationSchema = Yup.object().shape({
  bedType: Yup.object().shape({
    value: Yup.string().required('Bed Type is required'),
  }),
  files: Yup.object().test(
    'areFilesValid',
    'All files must be provided',
    (files) => {
      return Object.values(files).every((entry) => entry.file !== null);
    },
  ),
  bedProperties: Yup.object().when(['bedType'], {
    is: (bedType) => bedType?.value,
    then: Yup.object().test(
      'arePropertiesValid',
      'All properties must be provided',
      (bedProperties, { parent }) => {
        if (parent.bedType.value === bedTypeDefinitions.STATIC_BED.value) {
          return (
            bedProperties['length'] &&
            bedProperties['length'] > 0 &&
            bedProperties['width'] &&
            bedProperties['width'] > 0
          );
        }

        if (parent.bedType.value === bedTypeDefinitions.ROTARY_BED.value) {
          return (
            bedProperties['maxSpeed'] &&
            bedProperties['maxSpeed'] > 0 &&
            bedProperties['d1'] !== '' &&
            bedProperties['d2'] !== ''
          );
        }

        if (parent.bedType.value === bedTypeDefinitions.TWO_AXIS_BED.value) {
          return (
            bedProperties['d1'] !== '' &&
            bedProperties['d2'] !== '' &&
            bedProperties['d3'] !== '' &&
            bedProperties['a1'] !== '' &&
            bedProperties['a2'] !== '' &&
            bedProperties['joint1MaxSpeed'] &&
            bedProperties['joint1MaxSpeed'] > 0 &&
            bedProperties['joint2MaxSpeed'] &&
            bedProperties['joint2MaxSpeed'] > 0
          );
        }

        return false;
      },
    ),
  }),
});

export const initDataBedType = (bedDefinitionResponse, initialValues) => {
  const propertiesToExclude = ['modelUrls', 'name', 'id', 'bedType'];

  let selectedFiles;
  switch (bedDefinitionResponse.bedType) {
    case bedTypeDefinitions.ROTARY_BED.value: {
      selectedFiles = bedTypeRotaryFiles;
      break;
    }
    case bedTypeDefinitions.TWO_AXIS_BED.value: {
      selectedFiles = bedTypeTwoAxisFiles;
      break;
    }
    case bedTypeDefinitions.STATIC_BED.value: {
      selectedFiles = bedTypeStaticFiles;
      break;
    }
  }

  return {
    ...initialValues,
    bedType: bedTypeDefinitions[bedDefinitionResponse.bedType],
    bedProperties: Object.keys(bedDefinitionResponse)
      .filter((key) => !propertiesToExclude.includes(key))
      .reduce((obj, key) => {
        obj[key] = bedDefinitionResponse[key];
        return obj;
      }, {}),
    files: Object.fromEntries(
      selectedFiles.map(({ value, label }) => [
        value,
        {
          label,
          file: new File(
            [],
            bedDefinitionResponse.modelUrls[value]?.fileName || '',
          ),
          fileName: bedDefinitionResponse.modelUrls[value]?.fileName || '',
        },
      ]),
    ),
  };
};
