import { useCallback } from 'react';
import { isEmpty } from 'lodash';
import useWorkflow from '@hooks/workflows/useWorkflow';
import useOperatorUtils from '@hooks/operators/useOperatorUtils';

export default function useOperatorSettingUtils() {
  const { getSelectedWorkflowOperators } = useWorkflow();
  const { getOperatorFromProvidedArg } = useOperatorUtils();

  const getOperatorInput = useCallback(
    (operatorOrId, inputId) => {
      const operator = getOperatorFromProvidedArg(operatorOrId);

      return operator?.values?.find(({ id }) => id === inputId);
    },
    [getOperatorFromProvidedArg],
  );

  const getSelectedOperatorByValueId = useCallback(
    (valueId) => {
      const selectedWorkflowOperators = getSelectedWorkflowOperators();

      if (isEmpty(selectedWorkflowOperators)) {
        return;
      }

      const operator = selectedWorkflowOperators.find((operator) =>
        operator?.values?.some((value) => value?.id === valueId),
      );

      return operator;
    },
    [getSelectedWorkflowOperators],
  );

  return {
    getOperatorInput,
    getSelectedOperatorByValueId,
  };
}
