import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty, isEqual, isUndefined, pick } from 'lodash';
import { useFormContext } from 'react-hook-form';
import usePrevious from '@hooks/usePrevious';
import useFile from '@hooks/files/useFile';
import useWorkflow from '@hooks/workflows/useWorkflow';
import useOperator from '@hooks/operators/useOperator';
import useOperatorSettings from '@app/hooks/operatorSettings/useOperatorSettings';
import { compare } from 'compare-versions';
import { setHiddenInputNames } from '@reducers/workflowSlice';

export default function useWorkflowHiddenInputs() {
  const dispatch = useDispatch();
  const { watch, resetField, setError, clearErrors } = useFormContext();
  const { getProjectFiles } = useFile();
  const { getSelectedWorkflowOperators, getSelectedWorkflow } = useWorkflow();
  const { getOperator } = useOperator();
  const {
    getOperatorInputs,
    getDefaultOperatorSettings,
    getValuesVisibilityConditions,
    getWorkflowHiddenInputNames,
    calculateWorkflowHiddenValues,
  } = useOperatorSettings();
  const workflow = getSelectedWorkflow();
  const workflowVersion = workflow.version;
  const previousWorkflowVersion = usePrevious(workflowVersion);
  const projectId = workflow?.workspaceId;
  const workflowOperators = getSelectedWorkflowOperators();
  const workflowHiddenInputNames = getWorkflowHiddenInputNames();
  const previousWorflowHiddenInputNames = usePrevious(workflowHiddenInputNames);

  const workflowPrinterSettings = useMemo(
    () => pick(workflow, ['printerId', 'nozzleId', 'materialId']),
    [workflow],
  );
  const previousWorkflowPrinterSettings = usePrevious(workflowPrinterSettings);

  const workflowOperatorsIds = useMemo(
    () => workflowOperators?.map(({ id }) => id),
    [workflowOperators],
  );
  const previousWorkflowOperatorIds = usePrevious(workflowOperatorsIds);

  const workflowPrinterSettingsChanged =
    !isUndefined(previousWorkflowPrinterSettings) &&
    !isEqual(workflowPrinterSettings, previousWorkflowPrinterSettings);

  const operatorsChanged =
    !isUndefined(previousWorkflowOperatorIds) &&
    !isEqual(workflowOperatorsIds, previousWorkflowOperatorIds);

  const workflowHasBeenUpgraded =
    !isUndefined(previousWorkflowVersion) &&
    compare(workflowVersion, previousWorkflowVersion, '>');

  const visibilityConditions = useMemo(
    () => getValuesVisibilityConditions(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getValuesVisibilityConditions, workflowVersion],
  );

  const formFieldNamesToWatch = useMemo(() => {
    const fields = workflowOperators?.reduce((acc, operator) => {
      const operatorInputs = getOperatorInputs(operator);
      const operatorConditions = visibilityConditions?.[operator?.name];

      if (isEmpty(operatorConditions)) {
        return acc;
      }

      const stringifiedOperatorConditions = JSON.stringify(operatorConditions);

      //this is not parsing the conditions, it is just checking if the input name is present in the condition
      operatorInputs.forEach((input) => {
        const matchCondition =
          stringifiedOperatorConditions?.includes(`"${input.name}:`) ||
          stringifiedOperatorConditions?.includes(`|${input.name}:`) ||
          stringifiedOperatorConditions?.includes(`&${input.name}:`) ||
          stringifiedOperatorConditions?.includes(`"${input.name}.TYPE:`) ||
          stringifiedOperatorConditions?.includes(`|${input.name}.TYPE:`) ||
          stringifiedOperatorConditions?.includes(`&${input.name}.TYPE:`);

        if (matchCondition) {
          const fieldName = `${operator.id}.${input.id}`;

          acc.push(fieldName);
        }
      }, []);

      return acc;
    }, []);

    return fields;
  }, [getOperatorInputs, visibilityConditions, workflowOperators]);

  const watchFields = watch(formFieldNamesToWatch);
  const previousWatchFields = usePrevious(watchFields);

  const mergedWatchValues = useMemo(
    () =>
      formFieldNamesToWatch.reduce((acc, fieldName, i) => {
        const inputId = fieldName?.split('.')?.[1];

        if (!inputId) {
          return acc;
        }

        return {
          ...acc,
          [inputId]: watchFields[i],
        };
      }, {}),
    [formFieldNamesToWatch, watchFields],
  );

  const findInputByName = useCallback((inputs, name) => {
    return inputs.find((i) => i.name === name);
  }, []);

  const resetHiddenInputFormValues = useCallback(
    (calculatedHiddenInputNames) => {
      const skip = isEmpty(previousWorflowHiddenInputNames);

      if (skip) return;

      const formFieldNamesToReset = Object.entries(
        calculatedHiddenInputNames,
      )?.reduce((acc, [operatorId, operatorHiddenInputNames]) => {
        const operatorInputs = getOperatorInputs(operatorId);
        const operator = getOperator(operatorId);
        const defaultSettings = getDefaultOperatorSettings(operator?.name);

        operatorInputs.forEach((input) => {
          const inputName = input?.name;
          const fieldName = `${operatorId}.${input.id}`;

          const inputIsAddedToHiddenInputs =
            operatorHiddenInputNames?.includes(inputName) &&
            !previousWorflowHiddenInputNames?.[operatorId]?.includes(inputName);

          if (!inputIsAddedToHiddenInputs) return;

          acc.push(fieldName);

          const defaultSetting = defaultSettings[inputName];
          // if we are resetting a canvas selection input, also reset its hidden model and shape ID inputs
          if (defaultSetting?.selectionConfig) {
            const modelInput = findInputByName(
              operatorInputs,
              defaultSetting?.selectionConfig?.modelInput,
            );
            const shapeIdInput = findInputByName(
              operatorInputs,
              defaultSetting?.selectionConfig?.shapeIdInput,
            );
            acc.push(`${operatorId}.${modelInput?.id}`);
            acc.push(`${operatorId}.${shapeIdInput?.id}`);
          }
        });

        return acc;
      }, []);

      formFieldNamesToReset.forEach(resetField);
    },
    [
      previousWorflowHiddenInputNames,
      resetField,
      getOperatorInputs,
      getOperator,
      getDefaultOperatorSettings,
      findInputByName,
    ],
  );

  useEffect(() => {
    const watchFieldsModified = !isEqual(watchFields, previousWatchFields);
    const skip =
      !workflowHasBeenUpgraded &&
      !workflowPrinterSettingsChanged &&
      !operatorsChanged &&
      !watchFieldsModified;

    if (skip) return;

    setError('root.blockComputation');

    const calculatedHiddenValues = calculateWorkflowHiddenValues({
      formValues: mergedWatchValues,
    });

    // Format hidden inputs as operator ID -> array of input names
    const formattedHiddenInputs = calculatedHiddenValues.reduce(
      (acc, input) => {
        if (!acc[input.operatorId]) {
          acc[input.operatorId] = [];
        }
        acc[input.operatorId].push(input.name);
        return acc;
      },
      {},
    );

    resetHiddenInputFormValues(formattedHiddenInputs);
    dispatch(setHiddenInputNames(formattedHiddenInputs));
    clearErrors('root.blockComputation');
  }, [
    workflowPrinterSettingsChanged,
    workflowOperators,
    visibilityConditions,
    mergedWatchValues,
    operatorsChanged,
    watchFields,
    previousWatchFields,
    setError,
    workflow,
    projectId,
    getProjectFiles,
    workflowHasBeenUpgraded,
    calculateWorkflowHiddenValues,
    dispatch,
    resetHiddenInputFormValues,
    clearErrors,
  ]);
}
