export const SAFETY_CHECK_MODES = {
  COLLISION_DETECTION: 'collisionDetection',
  WRIST_SINGULARITY: 'wristSingularity',
  REACH_LIMITS: 'reachLimits',
  WORKSPACE_LIMITS: 'workspaceLimits',
  AXIS_LIMITS: 'axisLimits',
};

export const SAFETY_CHECK_FAILURES = {
  COLLISION_DETECTION_FAILURE: 'CollisionDetectionFailure',
  REACH_LIMIT_FAILURE: 'ReachLimitFailure',
};

export const SAFETY_CHECK_COMPONENTS = {
  WORK_OBJECT: 'WORK_OBJECT',
};
