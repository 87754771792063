import React, { createContext, useCallback, useEffect, useMemo } from 'react';
import { StatsigProvider, useClientAsyncInit } from '@statsig/react-bindings';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '@selectors/loginSelectors';
import usePrevious from '@app/hooks/usePrevious';
import { getStatsigKey } from '@app/selectors/configSelectors';
import environments from '@utils/environment';
import { datadogRum } from '@datadog/browser-rum';

export const FeatureFlagContext = createContext(null);

const environmentMappings = {
  development: 'development',
  ephemeral: 'development',
  test: 'staging',
  production: 'production',
};

const CloudFeatureFlagProvider = ({ children }) => {
  const currentUser = useSelector(getCurrentUser());
  const statsigKey = useSelector(getStatsigKey());

  const statsigUser = useMemo(() => {
    return {
      userID: currentUser.id,
      custom: {
        organisationId: currentUser.organizationId,
      },
      customIDs: {
        organisationId: currentUser.organizationId,
      },
    };
  }, [currentUser.id, currentUser.organizationId]);

  const { client } = useClientAsyncInit(statsigKey, statsigUser, {
    disableLogging: false,
    environment: { tier: environmentMappings[environments.current] },
  });

  const previousUser = usePrevious(statsigUser);

  const onGateEvaluation = useCallback(({ gate }) => {
    if (datadogRum.getInitConfiguration() !== undefined) {
      datadogRum.addFeatureFlagEvaluation(gate.name, gate.value);
    }
  }, []);

  useEffect(() => {
    if (!statsigKey || DESKTOP_APP) return;

    if (statsigUser !== previousUser) {
      if (!client) {
        client.on('gate_evaluation', onGateEvaluation);
        client.initializeAsync();
      } else {
        client.updateUserAsync(statsigUser);
      }
    }
  }, [client, onGateEvaluation, previousUser, statsigKey, statsigUser]);

  useEffect(() => {
    const id = setInterval(() => {
      client?.updateUserAsync(statsigUser);
    }, 60000);
    return () => clearInterval(id);
  }, [client, statsigUser]);

  return (
    <FeatureFlagContext.Provider value={client}>
      <StatsigProvider client={client}>{children}</StatsigProvider>
    </FeatureFlagContext.Provider>
  );
};

CloudFeatureFlagProvider.propTypes = {
  children: PropTypes.object,
};

const DesktopFeatureFlagProvider = ({ children }) => children;

export default DESKTOP_APP
  ? DesktopFeatureFlagProvider
  : CloudFeatureFlagProvider;
