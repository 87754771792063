/* eslint-disable */
import React, { useRef, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import useDropDownMenu from '@hooks/useDropDownMenu';
import { DROP_DOWN_MENU_ID } from '@components/2-molecules/DropDownMenu';
import DropDownMenuPortal from '@components/2-molecules/DropDownMenuPortal';

export const dropDownMenuHOCProps = {
  dropDownMenuId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  getDropDownMenuItems: PropTypes.func,
  dropDownMenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      formFieldValue: PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
      color: PropTypes.string,
      description: PropTypes.string,
      endingIconName: PropTypes.string,
      id: PropTypes.string,
      label: PropTypes.string,
      leadingIconColor: PropTypes.string,
      leadingIconName: PropTypes.string,
      leadingIconUrl: PropTypes.string,
      endingButton: PropTypes.shape({
        type: PropTypes.oneOf(['switch', 'radio', 'checkbox', 'iconButton']),
        checked: PropTypes.bool,
        onChange: PropTypes.func,
      }),
      onClick: PropTypes.func,
      onEndingIconButtonClick: PropTypes.func,
      selected: PropTypes.bool,
      disabled: PropTypes.bool,
      navigationPath: PropTypes.string,
      navigationItem: PropTypes.bool,
      withBadge: PropTypes.bool,
      withDivider: PropTypes.bool,
      field: PropTypes.object,
    }),
  ),
  dropDownMenuPosition: PropTypes.oneOf([
    'top left',
    'top center',
    'top right',
    'bottom left',
    'bottom center',
    'bottom right',
  ]),
  dropDownMenuOffsetX: PropTypes.number,
  dropDownMenuOverlay: PropTypes.bool,
  showDropDownMenuOnHover: PropTypes.bool,
  dropDownMenuScrolledToBottom: PropTypes.bool,
  emptyDropDownStateIconName: PropTypes.string,
  emptyDropDownStateLinkButtonLabel: PropTypes.string,
  emptyDropDownStateLinkButtonPath: PropTypes.string,
  emptyDropDownStateOnLinkButtonClick: PropTypes.func,
  emptyDropDownStateTitle: PropTypes.string,
  emptyDropDownStateDescription: PropTypes.string,
};

export default function withDropDownMenu(Component) {
  /* eslint-disable react-hooks/rules-of-hooks */
  const wrapped = ({
    dropDownMenuId,
    dropDownMenuItems,
    dropDownMenuPosition = 'bottom right',
    dropDownMenuScrolledToBottom,
    dropDownMenuOffsetX,
    dropDownMenuOverlay,
    showDropDownMenuOnHover,
    emptyDropDownStateDescription,
    emptyDropDownStateIconName,
    emptyDropDownStateLinkButtonLabel,
    emptyDropDownStateLinkButtonPath,
    emptyDropDownStateOnLinkButtonClick,
    emptyDropDownStateTitle,
    onDropDownMenuOpen,
    onDropDownMenuClose,
    getDropDownMenuItems,
    ...props
  }) => {
    const [loadedDropDownMenuItems, setLoadedDropDownMenuItems] = useState([
      { skeleton: true },
    ]);
    const hasDropDownMenuItems = !!dropDownMenuItems || !!getDropDownMenuItems;
    const componentRef = useRef();
    const generateDropDownMenuId = useRef(
      dropDownMenuId || uniqueId('drop-down-menu-'),
    );
    const {
      showDropDownMenu,
      hideDropDownMenu,
      dropDownMenuIsOpen,
      dropDownMenuId: activeDropDownMenuId,
    } = useDropDownMenu();

    const handleClick = useCallback(
      async (e) => {
        if (showDropDownMenuOnHover) return;

        props?.onClick?.(e);

        if (hasDropDownMenuItems) {
          showDropDownMenu?.(generateDropDownMenuId.current, e.currentTarget, {
            position: dropDownMenuPosition,
          });

          if (getDropDownMenuItems) {
            const loadedItems = await getDropDownMenuItems();

            setLoadedDropDownMenuItems(loadedItems);
          }

          onDropDownMenuOpen?.();
        }
      },
      [
        showDropDownMenuOnHover,
        hasDropDownMenuItems,
        getDropDownMenuItems,
        dropDownMenuPosition,
        showDropDownMenu,
        onDropDownMenuOpen,
        props,
      ],
    );

    const onMouseEnter = useCallback(
      async (e) => {
        props?.onMouseEnter?.(e);

        const isMenuItemOfCurrentDropDownMenu =
          activeDropDownMenuId === generateDropDownMenuId.current;

        if (
          dropDownMenuIsOpen &&
          !isMenuItemOfCurrentDropDownMenu &&
          !hasDropDownMenuItems
        ) {
          hideDropDownMenu?.();
          onDropDownMenuClose?.();

          return;
        }

        if (isMenuItemOfCurrentDropDownMenu || !showDropDownMenuOnHover) return;

        if (hasDropDownMenuItems) {
          showDropDownMenu?.(generateDropDownMenuId.current, e.currentTarget, {
            id: generateDropDownMenuId.current,
            position: dropDownMenuPosition,
          });

          if (getDropDownMenuItems) {
            const loadedItems = await getDropDownMenuItems();

            setLoadedDropDownMenuItems(loadedItems);
          }

          onDropDownMenuOpen?.();
        }
      },
      [
        dropDownMenuIsOpen,
        showDropDownMenuOnHover,
        hasDropDownMenuItems,
        getDropDownMenuItems,
        dropDownMenuPosition,
        showDropDownMenu,
        onDropDownMenuOpen,
        props,
        hideDropDownMenu,
        onDropDownMenuClose,
      ],
    );

    const onMouseLeave = useCallback(
      (e) => {
        props?.onMouseLeave?.(e);

        if (!dropDownMenuIsOpen || !showDropDownMenuOnHover) return;

        const leftComponent = !e.relatedTarget?.closest?.(
          `[data-menu-id="${generateDropDownMenuId.current}"]`,
        );

        if (!leftComponent) return;

        hideDropDownMenu?.();
        onDropDownMenuClose?.();
      },
      [
        dropDownMenuIsOpen,
        showDropDownMenuOnHover,
        hideDropDownMenu,
        onDropDownMenuClose,
        props,
      ],
    );

    const onMouseOut = useCallback(
      (e) => {
        if (!dropDownMenuIsOpen || !showDropDownMenuOnHover) return;

        const onDropDownMenuElement =
          e.relatedTarget?.closest?.(`[data-menu-id]`);

        if (onDropDownMenuElement) return;

        hideDropDownMenu?.();
        onDropDownMenuClose?.();
      },
      [
        dropDownMenuIsOpen,
        showDropDownMenuOnHover,
        hideDropDownMenu,
        onDropDownMenuClose,
      ],
    );

    return (
      <>
        <Component
          ref={componentRef}
          {...props}
          onClick={handleClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />

        {hasDropDownMenuItems && (
          <DropDownMenuPortal
            menuId={generateDropDownMenuId.current}
            menuItems={
              getDropDownMenuItems ? loadedDropDownMenuItems : dropDownMenuItems
            }
            withOverlay={dropDownMenuOverlay}
            offsetX={dropDownMenuOffsetX}
            menuScrollToBottom={dropDownMenuScrolledToBottom}
            emptyStateIconName={emptyDropDownStateIconName}
            emptyStateLinkButtonLabel={emptyDropDownStateLinkButtonLabel}
            emptyStateLinkButtonPath={emptyDropDownStateLinkButtonPath}
            emptyStateOnLinkButtonClick={emptyDropDownStateOnLinkButtonClick}
            emptyStateTitle={emptyDropDownStateTitle}
            emptyStateDescription={emptyDropDownStateDescription}
            onClose={onDropDownMenuClose}
            onMouseOut={onMouseOut}
          />
        )}
      </>
    );
  };

  wrapped.propTypes = {
    onClick: PropTypes.func,
  };

  return wrapped;
}
