import React, { useCallback, useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isObject, uniqueId } from 'lodash';
import { NavLink } from 'react-router-dom';
import withTooltip from '@hoc/withTooltip';
import Skeleton, {
  SKELETON_VARIANT_ONE_LINE,
} from '@components/1-atoms/Skeleton';
import Switch from '@components/1-atoms/Switch';
import RadioButton from '@components/1-atoms/RadioButton';
import Checkbox from '@components/1-atoms/Checkbox';
import IconButton from '@components/1-atoms/IconButton/IconButton';
import Field, { FIELD_SIZE_COMPACT } from '@components/1-atoms/Field';
import Badge, { BADGE_VARIANT_DOT } from '@components/1-atoms/Badge';
import {
  whenEnterButtonPressed,
  stopPropagation,
  preventDefault,
} from '@utils/interactionEvents';
import {
  SkeletonWrapper,
  Wrapper,
  Divider,
  Icon,
  ExternalIcon,
  Content,
  Label,
  Description,
  BadgeWrapper,
} from './MenuListItem.styled';

export const MENU_LIST_ITEM_ENDING_BUTTON_TYPE_SWITCH = 'switch';
export const MENU_LIST_ITEM_ENDING_BUTTON_TYPE_RADIO = 'radio';
export const MENU_LIST_ITEM_ENDING_BUTTON_TYPE_CHECKBOX = 'checkbox';
export const MENU_LIST_ITEM_ENDING_BUTTON_TYPE_ICON_BUTTON = 'iconButton';

const BUTTONS_COMPONENTS_SCHEMA = {
  [MENU_LIST_ITEM_ENDING_BUTTON_TYPE_SWITCH]: Switch,
  [MENU_LIST_ITEM_ENDING_BUTTON_TYPE_RADIO]: RadioButton,
  [MENU_LIST_ITEM_ENDING_BUTTON_TYPE_CHECKBOX]: Checkbox,
  [MENU_LIST_ITEM_ENDING_BUTTON_TYPE_ICON_BUTTON]: (props = {}) => (
    <IconButton {...props} />
  ),
};

const WrapperWithTooltip = withTooltip(Wrapper);

const MenuListItem = forwardRef(
  (
    {
      className,
      closeDropDownMenu,
      color,
      dataTestId = 'menu-list-item',
      description,
      disabled,
      endingButton,
      endingIconName,
      field,
      label,
      leadingIconColor,
      leadingIconName,
      leadingIconUrl,
      navigationItem,
      navigationPath = '',
      onClick,
      onMouseEnter,
      onMouseLeave,
      selected,
      skeleton,
      tabIndex,
      tooltip = {},
      withBadge,
      withDivider,
    },
    ref,
  ) => {
    const withField = isObject(field) && !isEmpty(field);
    const withEndingButton = !!endingButton;
    const isHoverable = !withField;
    const isEndingIconButtonType =
      endingButton?.type === MENU_LIST_ITEM_ENDING_BUTTON_TYPE_ICON_BUTTON;
    const shouldSetItemClickHandler =
      !withField && !(withEndingButton && !isEndingIconButtonType);
    const isClickable = !withField;
    const noPadding =
      withEndingButton &&
      [
        MENU_LIST_ITEM_ENDING_BUTTON_TYPE_RADIO,
        MENU_LIST_ITEM_ENDING_BUTTON_TYPE_CHECKBOX,
        MENU_LIST_ITEM_ENDING_BUTTON_TYPE_ICON_BUTTON,
      ].includes(endingButton?.type);
    const ButtonComponent =
      BUTTONS_COMPONENTS_SCHEMA?.[endingButton?.type] || null;

    const endingButtonId = useMemo(() => uniqueId('ending-button__'), []);

    const WrapperComponent = useMemo(
      () => (isEmpty(tooltip) ? Wrapper : WrapperWithTooltip),
      [tooltip],
    );

    const wrapperElementName = useMemo(() => {
      if (endingButton && !isEndingIconButtonType) {
        return 'label';
      }

      if (navigationPath) {
        return NavLink;
      }

      return 'div';
    }, [endingButton, isEndingIconButtonType, navigationPath]);

    const handleEndingIconButtonClick = useCallback(
      (iconButtonOnClick) => (e) => {
        iconButtonOnClick?.(e);
        closeDropDownMenu?.();
      },
      [closeDropDownMenu],
    );

    if (skeleton) {
      return (
        <>
          {withDivider && <Divider />}

          <SkeletonWrapper>
            <Skeleton variant={SKELETON_VARIANT_ONE_LINE} height={24} />
          </SkeletonWrapper>
        </>
      );
    }

    return (
      <>
        {withDivider && <Divider />}

        <WrapperComponent
          {...tooltip}
          ref={ref}
          as={wrapperElementName}
          autoHeight={withField}
          to={navigationPath}
          className={className}
          data-testid={dataTestId}
          disabled={disabled}
          htmlFor={endingButtonId}
          $noPadding={noPadding}
          $clickable={isClickable}
          hoverable={isHoverable}
          onClick={
            shouldSetItemClickHandler
              ? stopPropagation(onClick)
              : stopPropagation()
          }
          onMouseEnter={preventDefault(stopPropagation(onMouseEnter))}
          onMouseLeave={preventDefault(stopPropagation(onMouseLeave))}
          onKeyDown={
            shouldSetItemClickHandler
              ? whenEnterButtonPressed(stopPropagation(onClick))
              : stopPropagation()
          }
          selected={selected}
          tabIndex={disabled ? -1 : tabIndex || 0}
        >
          {leadingIconName && (
            <Icon
              disabled={disabled}
              navigationItem={!!navigationPath || navigationItem}
              name={leadingIconName}
              color={color || leadingIconColor}
            />
          )}

          {(!leadingIconName || leadingIconUrl) && (
            <ExternalIcon
              disabled={disabled}
              src={leadingIconUrl}
              color={color || leadingIconColor}
            />
          )}

          {withField && (
            <Field
              dataTestId={`${dataTestId}__field`}
              disabled={disabled}
              {...field}
              size={FIELD_SIZE_COMPACT}
            />
          )}

          {!withField && (
            <Content>
              <Label
                dataTestId={`${dataTestId}__label`}
                disabled={disabled}
                navigationItem={!!navigationPath || navigationItem}
                color={color}
              >
                {label}
              </Label>

              {description && (
                <Description
                  dataTestId={`${dataTestId}__description`}
                  disabled={disabled}
                  color={color}
                >
                  {description}
                </Description>
              )}
            </Content>
          )}

          {endingIconName && (
            <Icon disabled={disabled} name={endingIconName} color={color} />
          )}

          {withEndingButton && !isEndingIconButtonType && (
            <ButtonComponent
              id={endingButtonId}
              dataTestId={`${dataTestId}__ending-button`}
              name={endingButton?.name}
              value={endingButton?.value}
              checked={endingButton?.checked}
              enabled={endingButton?.enabled}
              onChange={endingButton?.onChange}
            />
          )}

          {withEndingButton && isEndingIconButtonType && (
            <ButtonComponent
              id={endingButtonId}
              dataTestId={`${dataTestId}__ending-button`}
              disabled={endingButton?.disabled}
              iconName={endingButton?.iconName}
              loading={endingButton?.loading}
              onClick={stopPropagation(
                handleEndingIconButtonClick(endingButton?.onClick),
              )}
              selected={endingButton?.selected}
              title={endingButton?.title}
              variant={endingButton?.variant}
            />
          )}

          {withBadge && (
            <BadgeWrapper>
              <Badge variant={BADGE_VARIANT_DOT} />
            </BadgeWrapper>
          )}
        </WrapperComponent>
      </>
    );
  },
);

MenuListItem.displayName = 'MenuListItem';

MenuListItem.propTypes = {
  className: PropTypes.string,
  closeDropDownMenu: PropTypes.func,
  color: PropTypes.string,
  dataTestId: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  endingIconName: PropTypes.string,
  field: PropTypes.shape(Field.propTypes),
  label: PropTypes.string,
  leadingIconName: PropTypes.string,
  leadingIconUrl: PropTypes.string,
  leadingIconColor: PropTypes.string,
  navigationPath: PropTypes.string,
  navigationItem: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  selected: PropTypes.bool,
  skeleton: PropTypes.bool,
  tabIndex: PropTypes.number,
  tooltip: PropTypes.object,
  withDivider: PropTypes.bool,
  withBadge: PropTypes.bool,
  endingButton: PropTypes.shape({
    // Switch, Radio, Checkbox props
    name: PropTypes.string,
    type: PropTypes.oneOf([
      MENU_LIST_ITEM_ENDING_BUTTON_TYPE_SWITCH,
      MENU_LIST_ITEM_ENDING_BUTTON_TYPE_RADIO,
      MENU_LIST_ITEM_ENDING_BUTTON_TYPE_CHECKBOX,
      MENU_LIST_ITEM_ENDING_BUTTON_TYPE_ICON_BUTTON,
    ]).isRequired,
    onChange: PropTypes.func,
    value: PropTypes.string,
    checked: PropTypes.bool,
    enabled: PropTypes.bool,

    // Icon Button props
    disabled: PropTypes.bool,
    iconName: PropTypes.string,
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    selected: PropTypes.bool,
    title: PropTypes.string,
    variant: PropTypes.string,
  }),
};

export default MenuListItem;
