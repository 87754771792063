import * as THREE from 'three';
import {
  EXTERNAL_AXIS_NAME,
  ZERO_ROTATION,
  EXTERNAL_JOINT_ANGLE,
} from '../constants/machineConstants';
import { printerConstants as constants } from '../constants/printers/printerConstants';
import { degToRad } from 'three/src/math/MathUtils';
import { Bed } from './beds/Bed';
import { Printer } from './Printer';

/**
 * Represents an individual external axis bed object. This bed contains at least one
 * moving axis.
 */
export class ExternalAxis extends Bed {
  constructor(
    printerSettings,
    machineDefaults,
    printingBedDefinitions,
    plinthDefinitions,
  ) {
    super(
      printerSettings,
      machineDefaults,
      printingBedDefinitions,
      plinthDefinitions,
    );
    this.plinthDefinitions = plinthDefinitions;
  }

  /**
   * Asynchronously loads the models required for visualization of the external
   * axis and adds them to the individual axes groups.
   * @returns resolved promise on completion of loading
   */
  initializeModels() {
    return new Promise((resolve) => {
      const { fileKey } = this.printingBedDefaults;

      if (fileKey === '') resolve(new THREE.Group());
      else if (fileKey === constants.ParameterizedGeometry)
        resolve(this.getPrintingBedModelOld(this.plinthDefinitions));
      const model = Printer.getModel(`/models/Bed_${fileKey}.glb`);
      model.then((model) => {
        model.children.forEach((child) => {
          const axisName = child.name.split(constants._)[0];
          this[axisName].add(child.clone());
        });
        resolve();
      });
    });
  }

  getConfiguration() {
    return this.printingBedDefaults.configuration;
  }

  getOffset() {
    return this.printingBedDefaults.offset;
  }

  getNumberOfJoints() {
    return 1;
  }

  /**
   * Creates the individual groups for each axis and sets the base property
   * to the end group representing the base frame that the print object should be
   * defined relative to.
   * @param {*} baseTransformationMatrix
   */
  setBaseFrame(baseTransformationMatrix) {
    const numberOfJoints = this.getNumberOfJoints();
    this.numberOfJoints = numberOfJoints;

    //axis0 needs to be offset, as the input base calibration values define hte plate itself, rather than the position of the axis0 model
    const offset = this.getOffset();
    const axis0 = new THREE.Group();
    this.add(axis0);
    this[EXTERNAL_AXIS_NAME + 0] = axis0;
    axis0.add(new THREE.AxesHelper(10));
    this.position.setFromMatrixPosition(baseTransformationMatrix);
    this.setRotationFromMatrix(baseTransformationMatrix);
    axis0.translateX(offset.x);
    axis0.translateY(offset.y);
    axis0.translateZ(offset.z);
    if (offset.rotationParts) {
      offset.rotation = new THREE.Euler(
        offset.rotationParts.x,
        offset.rotationParts.y,
        offset.rotationParts.z,
        offset.rotationParts.type,
      );
    } else {
      offset.rotation = new THREE.Euler(0, 0, 0, 'XYZ');
    }
    const rotationOffset = offset.rotation;
    if (rotationOffset instanceof THREE.Euler) {
      axis0.setRotationFromEuler(
        new THREE.Euler(rotationOffset.x, rotationOffset.y, rotationOffset.z),
        'XYZ',
      );
    }
    const additionalOffsetRotation = this.getOffsetParameter();
    axis0.rotateZ(degToRad(additionalOffsetRotation));
    //get axis positions from configuration
    const configuration = this.getConfiguration();
    let previousAxis = axis0;
    for (let axisNumber = 1; axisNumber <= numberOfJoints; axisNumber++) {
      const axis = new THREE.Group();
      axis.name = EXTERNAL_AXIS_NAME + axisNumber;
      this[axis.name] = axis;
      let axisZeroRotation = new THREE.Group();
      axisZeroRotation.name = axis.name + ZERO_ROTATION;
      this[axisZeroRotation.name] = axisZeroRotation;
      axisZeroRotation.position.set(
        configuration[axisNumber - 1].x,
        configuration[axisNumber - 1].y,
        configuration[axisNumber - 1].z,
      );
      configuration[axisNumber - 1].rotation = new THREE.Euler(
        configuration[axisNumber - 1].rotationParts.x,
        configuration[axisNumber - 1].rotationParts.y,
        configuration[axisNumber - 1].rotationParts.z,
        configuration[axisNumber - 1].rotationParts.type,
      );

      axisZeroRotation = this.setRotation(
        axisZeroRotation,
        configuration[axisNumber - 1].rotation,
      );
      previousAxis.add(axisZeroRotation);
      axisZeroRotation.add(axis);
      axisZeroRotation.add(new THREE.AxesHelper(10));
      axis.add(new THREE.AxesHelper(10));
      previousAxis = axis;
    }
    this.base = new THREE.Group();
    previousAxis.add(this.base);
    this.base.position.set(
      configuration[numberOfJoints].x,
      configuration[numberOfJoints].y,
      configuration[numberOfJoints].z,
    );
    this.base.setRotationFromEuler(
      new THREE.Euler(
        configuration[numberOfJoints].rotationParts.x,
        configuration[numberOfJoints].rotationParts.y,
        configuration[numberOfJoints].rotationParts.z,
        configuration[numberOfJoints].rotationParts.type,
      ),
    );
    this.base.rotateZ(degToRad(-additionalOffsetRotation));
    this.base.add(new THREE.AxesHelper(100));
  }

  /**
   * Returns the value of the additional rotation offset to apply for this instance of 
   * the external axis. Allows the same external axis to have a base calibration at a
   * different angle to its model.

   * @returns 
   */
  getOffsetParameter() {
    return 0;
  }

  /**
   * Sets the rotation of the target axis group to the input rotation object
   * provided.
   * @param {*} axis
   * @param {*} rotation
   * @returns
   */
  setRotation(axis, rotation) {
    if (rotation instanceof THREE.Euler) {
      axis.setRotationFromEuler(rotation);
    } else if (rotation instanceof THREE.Quaternion) {
      axis.setRotationFromQuaternion(rotation);
    }
    return axis;
  }

  /**
   * Adds debug points to the external axis origin
   */
  addDebugPoints() {
    const debugPointOrigin = this.debugPoint(0xff0000, 0, 0, 0, 2);
    this.base.add(debugPointOrigin);
  }

  /**
   * Rotates the THREE.Group containing the individual joints of the external axes
   * according to the simulation data provided.
   * @param {*} simulationData
   */
  simulate(simulationData) {
    for (
      let jointNumber = 1;
      jointNumber <= this.numberOfJoints;
      jointNumber++
    ) {
      const jointName = EXTERNAL_JOINT_ANGLE + jointNumber;
      const previousMovement = simulationData.previousStep.movement;
      const currentMovement = simulationData.currentStep.movement;
      const jointValue =
        previousMovement[jointName] +
        (currentMovement[jointName] - previousMovement[jointName]) *
          simulationData.stepRatio;
      this.setJointPosition(jointNumber, jointValue);
    }
  }

  setJointPosition(jointNumber, jointValue) {
    this[EXTERNAL_AXIS_NAME + jointNumber].rotation.set(0, 0, jointValue);
  }

  getRotatedBaseTransformationMatrix() {
    return this.baseTransformationMatrix;
  }

  highlight(componentId, color) {
    const highlightChildrenMeshes = (axisIndex) => {
      const axisName = EXTERNAL_AXIS_NAME + axisIndex;
      if (this.models) {
        this.models.traverse.doHighlight();
      }
      for (const child of this[axisName].children) {
        if (child.isMesh) {
          this.doHighlight(child, color);
        } else if (child.name === axisName) {
          child.traverse((nestedChild) => {
            if (nestedChild.isMesh) {
              this.doHighlight(nestedChild, color);
            }
          });
        }
      }
    };

    if (componentId === 'BASE') {
      highlightChildrenMeshes(0);
    } else if (componentId === 'AXIS_1') {
      highlightChildrenMeshes(1);
    } else if (componentId === 'AXIS_2') {
      highlightChildrenMeshes(2);
    } else {
      super.highlight();
    }
  }
}
