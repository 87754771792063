import { flexColumn } from '@app/stylesheets/helpers';
import styled from 'styled-components';

export const Wrapper = styled.div`
  ${flexColumn}
  flex: 1;
  width: min(100%, 880px);
  border-radius: 10px;
  margin-inline: auto;
  overflow: hidden;
`;
