import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import usePrinter from '@hooks/printers/usePrinter';
import { getPrinterWithModels } from '../../utils/model';
import { Visualization } from './Visualization.js.jsx';

const SpaceComponent = ({ children }) => {
  const [gridSize, setGridSize] = useState(100);
  const [printer, setPrinter] = useState(null);

  const { getAllPrinterDefinitions, getSelectedPrinter } = usePrinter();
  const selectedPrinter = getSelectedPrinter();

  const {
    machineDefinitions,
    extruderDefinitions,
    printingBedDefinitions,
    plinthDefinitions,
    enclosureDefinitions,
  } = getAllPrinterDefinitions();

  useEffect(() => {
    if (
      machineDefinitions?.length > 0 &&
      extruderDefinitions?.length > 0 &&
      plinthDefinitions?.length > 0 &&
      enclosureDefinitions?.length > 0 &&
      printingBedDefinitions?.length > 0
    ) {
      getPrinterWithModels(
        selectedPrinter,
        machineDefinitions,
        extruderDefinitions,
        printingBedDefinitions,
        plinthDefinitions,
        enclosureDefinitions,
      ).then((printer) => {
        setPrinter(printer);
      });
    }
    setGridSize(100);
  }, [
    selectedPrinter,
    machineDefinitions,
    extruderDefinitions,
    printingBedDefinitions,
    plinthDefinitions,
    enclosureDefinitions,
  ]);

  return (
    <Visualization
      showGrid
      gridSizeX={gridSize}
      cameraX={0}
      cameraY={-300}
      cameraZ={300}
      onlyRenderOnChange={false}
      printer={printer}
    >
      {children}
    </Visualization>
  );
};

SpaceComponent.propTypes = {
  children: PropTypes.node,
};

export default SpaceComponent;
