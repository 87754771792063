import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { useSelector, useDispatch } from 'react-redux';
import useWorkflow from '@hooks/workflows/useWorkflow';
import useOperatorList from '@hooks/operators/useOperatorList';
import {
  selectFullScreenOperatorVisibility,
  setFullScreenOperatorVisibility,
  selectActiveCanvasSelectionInput,
} from '@reducers/workflowSlice';
import Operator from '@components/2-molecules/Operator';
import SettingsCategory from '@components/2-molecules/SettingsCategory';
import WorkflowOperatorFields from '@containers/WorkflowOperatorFields';
import WorkflowOperatorOutputs from '@containers/WorkflowOperatorOutputs';
import { WORKFLOW_SETTING_BAR_ID } from '@containers/WorkflowSetting';
import {
  Wrapper,
  OperatorContent,
  SLIDE_IN_COMPLETED_CLASS_NAME,
} from './WorkflowFullScreenOperator.styled';

export const OPERATOR_SLIDE_IN_ANIMATION_SECONDS_DURATION = 0.5;
export const OPERATOR_SLIDE_OUT_ANIMATION_SECONDS_DURATION = 0.25;
export const OPERATOR_BODY_VISIBILITY_DELAY = 600;
export const PARENT_SCROLLABLE_ELEMENT_SCROLL_TO_DELAY = 200;

const WorkflowFullScreenOperator = ({
  animated = true,
  computeOperator,
  dataTestId = 'workflow-full-screen-operator',
  name,
  onApply,
  onCancel,
  operator = {},
  shouldDelayContentVisibility = true,
  status,
  isValid,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const showFullScreenOperator = useSelector(
    selectFullScreenOperatorVisibility,
  );
  const activeCanvasSelectionInput = useSelector(
    selectActiveCanvasSelectionInput,
  );
  const { getSelectedWorkflow, getIsWorkflowEditable } = useWorkflow();
  const { getDefaultOperator } = useOperatorList();
  const wrapperRef = useRef(null);

  const operatorId = operator.id;
  const operatorName = name || operator?.tag || operator.name;

  const workflow = getSelectedWorkflow();
  const workflowIsDisabled = !getIsWorkflowEditable(workflow);
  const operatorIcon = getDefaultOperator(operator.name)?.iconName;
  const isOperatorDisabled = workflowIsDisabled || !!activeCanvasSelectionInput;

  const footerProps = useMemo(
    () =>
      showFullScreenOperator
        ? {
            primaryButtonLabel:
              onApply &&
              intl.formatMessage({
                id: 'general.apply',
                defaultMessage: 'Apply',
              }),
            primaryButtonDisabled: isOperatorDisabled || !isValid,
            onPrimaryButtonClick: onApply,
            secondaryButtonLabel:
              onCancel &&
              intl.formatMessage({
                id: 'general.cancel',
                defaultMessage: 'Cancel',
              }),
            secondaryButtonDisabled: workflowIsDisabled,
            onSecondaryButtonClick: () => onCancel(operator),
          }
        : undefined,
    [
      isOperatorDisabled,
      workflowIsDisabled,
      isValid,
      operator,
      showFullScreenOperator,
      onApply,
      onCancel,
      intl,
    ],
  );

  const getWrapperParentElementDimensions = useCallback(() => {
    const wrapperElement = wrapperRef.current;

    if (!wrapperElement) {
      return;
    }

    const parentElement = wrapperElement.parentElement;
    const parentElementDimensions = parentElement.getBoundingClientRect();

    return parentElementDimensions;
  }, []);

  const calculateInitialPosition = useCallback(() => {
    const wrapperElement = wrapperRef.current;

    if (!wrapperElement) {
      return;
    }

    const parentElementDimensions = getWrapperParentElementDimensions();
    const paddingTop = 4;

    return {
      position: 'fixed',
      width: parentElementDimensions.width,
      top: parentElementDimensions.top + paddingTop,
      left: parentElementDimensions.left,
    };
  }, [getWrapperParentElementDimensions]);

  const calculateWrapperFinalPosition = useCallback(() => {
    const settingBarTarget = document.getElementById(WORKFLOW_SETTING_BAR_ID);

    if (!settingBarTarget) {
      return;
    }

    const settingBarElement = settingBarTarget.getBoundingClientRect();

    return {
      width: settingBarElement.width,
      height: settingBarElement.height,
      top: settingBarElement.top,
      left: settingBarElement.left,
    };
  }, []);

  const setWrapperStyles = useCallback((styles = {}) => {
    if (!wrapperRef.current) {
      return;
    }

    gsap.set(wrapperRef.current, styles);
  }, []);

  const handleWindowResize = useCallback(() => {
    const wrapperFinalPosition = calculateWrapperFinalPosition();

    if (!wrapperFinalPosition) {
      return;
    }

    setWrapperStyles(wrapperFinalPosition);
  }, [calculateWrapperFinalPosition, setWrapperStyles]);

  useGSAP(
    () => {
      if (!animated) return;

      const initialPosition = calculateInitialPosition();

      if (!initialPosition) {
        return;
      }

      setWrapperStyles(initialPosition);
    },
    {
      scope: wrapperRef,
      dependencies: [animated, calculateInitialPosition, setWrapperStyles],
    },
  );

  useGSAP(
    () => {
      const wrapperElement = wrapperRef.current;

      if (!animated || !wrapperElement) return;

      if (showFullScreenOperator) {
        const wrapperFinalPosition = calculateWrapperFinalPosition();

        if (!wrapperFinalPosition) {
          return;
        }

        gsap.to(wrapperElement, OPERATOR_SLIDE_IN_ANIMATION_SECONDS_DURATION, {
          ...wrapperFinalPosition,
          opacity: 1,
          onComplete: () => {
            wrapperElement.classList.add(SLIDE_IN_COMPLETED_CLASS_NAME);
          },
        });

        return;
      }

      const initialPosition = calculateInitialPosition();

      if (!initialPosition) {
        return;
      }

      const parentElementDimensions = getWrapperParentElementDimensions();

      wrapperElement.classList.remove(SLIDE_IN_COMPLETED_CLASS_NAME);

      gsap.to(wrapperElement, {
        ...initialPosition,
        height: parentElementDimensions.height,
        position: 'fixed',
        overflow: 'hidden',
        duration: OPERATOR_SLIDE_OUT_ANIMATION_SECONDS_DURATION,
      });

      gsap.to(wrapperElement, {
        opacity: 0,
        duration: 0.3,
        delay: 0.2,
      });
    },
    {
      scope: wrapperRef,
      dependencies: [
        animated,
        showFullScreenOperator,
        calculateWrapperFinalPosition,
        getWrapperParentElementDimensions,
      ],
    },
  );

  useEffect(() => {
    if (!animated) return;

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [animated, handleWindowResize]);

  useEffect(() => {
    const wrapperElement = wrapperRef.current;

    if (!animated || !wrapperElement) return;

    setTimeout(() => {
      const parentElement = wrapperElement.parentElement;

      parentElement?.scrollIntoView?.();

      setTimeout(() => {
        dispatch(setFullScreenOperatorVisibility(true));
      }, 50);
    }, PARENT_SCROLLABLE_ELEMENT_SCROLL_TO_DELAY);
  }, [dispatch, animated, getWrapperParentElementDimensions]);

  return (
    <Wrapper ref={wrapperRef} animated={animated} show={showFullScreenOperator}>
      <Operator
        dataTestId={dataTestId || `workflow-full-screen-operator-${operatorId}`}
        disableActions={isOperatorDisabled}
        operatorIcon={operatorIcon}
        operatorName={operatorName || operator?.tag}
        bodySkeleton={shouldDelayContentVisibility}
        contentVisibilityDelay={
          shouldDelayContentVisibility ? OPERATOR_BODY_VISIBILITY_DELAY : 0
        }
        footer={footerProps}
        onComputeButtonClick={computeOperator}
        stickyHeader={false}
        status={status}
        statusOnlyHeader
        expanded
      >
        <OperatorContent>
          <SettingsCategory
            title={intl.formatMessage({
              id: 'general.settings',
              defaultMessage: 'Settings',
            })}
            expand
          >
            <WorkflowOperatorFields
              disabled={isOperatorDisabled}
              operator={operator}
              computeOperator={computeOperator}
            />
          </SettingsCategory>

          <WorkflowOperatorOutputs
            disabled={isOperatorDisabled}
            operator={operator}
          />
        </OperatorContent>
      </Operator>
    </Wrapper>
  );
};

WorkflowFullScreenOperator.propTypes = {
  animated: PropTypes.bool,
  computeOperator: PropTypes.func,
  dataTestId: PropTypes.string,
  operator: PropTypes.shape({
    id: PropTypes.string,
    tag: PropTypes.string,
  }),
  name: PropTypes.string,
  onApply: PropTypes.func,
  onCancel: PropTypes.func,
  shouldDelayContentVisibility: PropTypes.bool,
  status: PropTypes.string,
  isValid: PropTypes.bool,
};

export default WorkflowFullScreenOperator;
