import { isString } from 'lodash';
import getIntlProvider from '@app/utils/getIntlProvider';

const intl = getIntlProvider();

export const generateErrorTitle = (error) => {
  const isErrorTitle = isString(error);

  if (isErrorTitle) {
    return error;
  }

  return intl.formatMessage({
    id: 'error.message.default_title',
    defaultMessage: 'Something went wrong',
  });
};

export const generateErrorMessage = (error) => {
  const isErrorMessage = isString(error);
  const isRepsonseError = error?.response?.data?.error;
  const isRepsonseErrors = error?.response?.data?.errors;

  if (isErrorMessage) {
    return error;
  }

  if (isRepsonseError) {
    return error?.response?.data?.error?.message;
  }

  if (isRepsonseErrors) {
    return error?.response?.data?.errors.join('\n');
  }

  return intl.formatMessage({
    id: 'error.message.default_text',
    defaultMessage:
      "We're sorry, but it looks like something didn't go as planned. You can: refresh the page, return to the home page, or contact our support team if the issue persists.",
  });
};
