import React, { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isEqual, pick } from 'lodash';
import usePrinter from '@hooks/printers/usePrinter';
import useWorkflow from '@hooks/workflows/useWorkflow';
import useDialog from '@hooks/useDialog';
import { operatorsQueryKeys } from '@hooks/operators/useOperatorQueries';
import useOperatorSettingUtils from '@hooks/operatorSettings/useOperatorSettingUtils';
import useOperatorMutations, {
  operatorMutationKeys,
} from '@hooks/operators/useOperatorMutations';
import {
  getFrozenOutputIds,
  getSelectedOperatorOutputId,
} from '@selectors/conceptSelectors';
import {
  exitToolpathSimulation,
  selectOperatorOutput as selectOperatorOutputAction,
} from '@actions/conceptActions';
import { getComputationProgressHandler } from '@selectors/computationProgressSelectors';
import { selectActiveCanvasSelectionInput } from '@reducers/workflowSlice';
import { OperatorInputTypes } from '@constants/operatorInputTypes';
import { ModalDataTypes } from '@constants/modalDataTypes';
import {
  MELTIO_TOOLPATH_GENERATOR_OPERATOR_NAME,
  TOOLPATH_GENERATOR_OPERATOR_NAME,
} from '@constants/operatorsConstants';
import {
  OPERATOR_HEADER_STATUS_ALERT,
  OPERATOR_HEADER_STATUS_ALERT_MISS,
  OPERATOR_HEADER_STATUS_HIGHLIGHT,
} from '@utils/operatorStatus';
import { buildGraph, getDependentNodes } from '@utils/visualTool';
import useCanvasSelection from '@hooks/canvasselection/useCanvasSelection';
import useOperatorUtils from '@hooks/operators/useOperatorUtils';
import useFeatureFlagValue from '@hooks/featureflags/useFeatureFlagValue';
import { QUICK_START } from '@constants/featureFlagConstants';

export default function useOperator() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { showDialog } = useDialog();
  const selectedOperatorOutputId = useSelector(getSelectedOperatorOutputId());
  const activeCanvasSelectionInput = useSelector(
    selectActiveCanvasSelectionInput,
  );
  const frozenOutputIds = useSelector(getFrozenOutputIds());
  const currentComputationProgress = useSelector(getComputationProgressHandler);
  const quickStartFeatureAvailable = useFeatureFlagValue(QUICK_START);
  const { getPrinters } = usePrinter();
  const { getOperatorFromProvidedArg, getDefaultOperators, getOperator } =
    useOperatorUtils();
  const {
    getSelectedWorkflow,
    getSelectedWorkflowOperators,
    getIsWorkflowComputing,
  } = useWorkflow();
  const { getOperatorInput, getSelectedOperatorByValueId } =
    useOperatorSettingUtils();
  const { doesFieldSupportCanvasSelection } = useCanvasSelection();
  const { explodeOperatorMutation } = useOperatorMutations();
  const selectedWorkflow = getSelectedWorkflow();
  const selectedWorkflowId = selectedWorkflow?.id;

  const asyncExplodeOperatorMutation = explodeOperatorMutation.mutateAsync;

  const getIsAddingOperator = useCallback(
    () =>
      queryClient.isMutating({ mutationKey: operatorMutationKeys.addOperator }),
    [queryClient],
  );

  const getIsDeletingOperator = useCallback(
    () =>
      queryClient.isMutating({
        mutationKey: operatorMutationKeys.deleteOperator,
      }),
    [queryClient],
  );

  const getIsReorderingOperator = useCallback(
    () =>
      queryClient.isMutating({
        mutationKey: operatorMutationKeys.reorderOperator,
      }),
    [queryClient],
  );

  const getNormalizedDefaultOperators = useCallback(
    () =>
      queryClient.getQueryData(
        operatorsQueryKeys.defaultOperators(selectedWorkflowId),
      )?.normalizedDefaultOperators,
    [queryClient, selectedWorkflowId],
  );

  const getFeaturedOperators = useCallback(
    () =>
      queryClient.getQueryData(
        operatorsQueryKeys.featuredOperators(selectedWorkflowId),
      ),
    [queryClient, selectedWorkflowId],
  );

  const getDefaultOperator = useCallback(
    (operatorName) => getNormalizedDefaultOperators()?.[operatorName],
    [getNormalizedDefaultOperators],
  );

  const getComputedOperators = useCallback(() => {
    const workflowOperators = getSelectedWorkflowOperators();
    const computedOperators = workflowOperators.filter(
      (operator) => operator.computed,
    );

    return computedOperators;
  }, [getSelectedWorkflowOperators]);

  const getTemplates = useCallback(
    () => queryClient.getQueryData(operatorsQueryKeys.templates),
    [queryClient],
  );

  const getWorkflowRecommendedOperators = useCallback(
    (workflowId) =>
      queryClient.getQueryData(
        operatorsQueryKeys.recommendedOperators(workflowId),
      ),
    [queryClient],
  );

  const getIsTemplateOperator = useCallback(
    (operator = {}) => !!operator?.templateId,
    [],
  );

  const getIsOperatorComputed = useCallback(
    (operatorOrId) => {
      const operator = getOperatorFromProvidedArg(operatorOrId);
      const isComputed = operator.computed;

      return isComputed;
    },
    [getOperatorFromProvidedArg],
  );

  const getIsOperatorComputing = useCallback(
    (operatorOrId) => {
      const operator = getOperatorFromProvidedArg(operatorOrId);
      const isComputing =
        getIsWorkflowComputing() &&
        currentComputationProgress?.id === operator?.id;

      return isComputing;
    },
    [
      currentComputationProgress,
      getIsWorkflowComputing,
      getOperatorFromProvidedArg,
    ],
  );

  const getIsOperatorExplodable = useCallback(
    (operatorOrId) => {
      const printers = getPrinters();
      const operator = getOperatorFromProvidedArg(operatorOrId);
      const quickStartAvailable =
        printers?.length && quickStartFeatureAvailable;
      const explodable = quickStartAvailable && !!operator?.templateId;

      return explodable;
    },
    [getPrinters, getOperatorFromProvidedArg, quickStartFeatureAvailable],
  );

  const getIsOperatorInvalid = useCallback((operator = {}, form = {}) => {
    const formOperatorErrors = form?.formState?.errors?.[operator.id];
    const formOperatorValid = isEmpty(formOperatorErrors);
    const operatorIsInvalid = !formOperatorValid;

    return operatorIsInvalid;
  }, []);

  const getIsOperatorModified = useCallback((operator = {}, form = {}) => {
    const formOperatorValues = form?.getValues(`${operator.id}`) || {};
    const formOperatorInitialValues =
      form?.formState?.defaultValues?.[operator.id] || {};
    const formOperatorVisibleInitialValues = pick(
      formOperatorInitialValues,
      Object.keys(formOperatorValues),
    );

    const formOperatorIsModified = !isEqual(
      formOperatorVisibleInitialValues,
      formOperatorValues,
    );

    return !operator.computed || formOperatorIsModified;
  }, []);

  const getIsOperatorValid = useCallback(
    (operator = {}, form = {}) => {
      operator?.computed &&
        !getIsOperatorInvalid(operator, form) &&
        getIsOperatorModified(operator, form);
    },
    [getIsOperatorInvalid, getIsOperatorModified],
  );

  const getOperatorStatus = useCallback(
    (operator, form) => {
      if (getIsOperatorInvalid(operator, form)) {
        return OPERATOR_HEADER_STATUS_ALERT_MISS;
      }

      if (getIsOperatorModified(operator, form)) {
        return OPERATOR_HEADER_STATUS_ALERT;
      }

      return OPERATOR_HEADER_STATUS_HIGHLIGHT;
    },
    [getIsOperatorInvalid, getIsOperatorModified],
  );

  const getIsModifiedOperatorStatus = useCallback(
    (status) => [OPERATOR_HEADER_STATUS_ALERT].includes(status),
    [],
  );

  const getIsValidOperatorStatus = useCallback(
    (status) =>
      [OPERATOR_HEADER_STATUS_HIGHLIGHT, OPERATOR_HEADER_STATUS_ALERT].includes(
        status,
      ),
    [],
  );

  const getFrozenOperators = useCallback(
    () =>
      getSelectedWorkflowOperators().filter((operator) =>
        operator.values.some((value) => frozenOutputIds?.includes(value.id)),
      ),
    [getSelectedWorkflowOperators, frozenOutputIds],
  );

  const getIsOperatorFrozen = useCallback(
    (operatorOrId) => {
      const operator = getOperatorFromProvidedArg(operatorOrId);

      return operator.values.some((value) =>
        frozenOutputIds?.includes(value.id),
      );
    },
    [getOperatorFromProvidedArg, frozenOutputIds],
  );

  const getSelectedOperator = useCallback(() => {
    return getSelectedOperatorByValueId(selectedOperatorOutputId);
  }, [selectedOperatorOutputId, getSelectedOperatorByValueId]);

  const getIsToolpathOperatorSelected = useCallback(() => {
    const selectedOperator = getSelectedOperator();
    const selectedOperatorOutput = getOperatorInput(
      selectedOperator,
      selectedOperatorOutputId,
    );
    const toolpathOperatorNames = [
      TOOLPATH_GENERATOR_OPERATOR_NAME,
      MELTIO_TOOLPATH_GENERATOR_OPERATOR_NAME,
    ];
    const isToolpathOperator =
      selectedOperatorOutput?.type ===
        OperatorInputTypes.TOOLPATH_INSTRUCTIONS ||
      toolpathOperatorNames.some((allowedOperatorName) =>
        selectedOperator?.name?.startsWith(allowedOperatorName),
      );

    return isToolpathOperator;
  }, [getSelectedOperator, selectedOperatorOutputId, getOperatorInput]);

  const getOperatorPreviousDepended = useCallback(
    (operatorOrId) => {
      const operator = getOperatorFromProvidedArg(operatorOrId);
      return getSelectedWorkflowOperators()?.reduce((acc, previousOperator) => {
        const isDependentOperator = previousOperator?.order < operator?.order;

        if (!isDependentOperator) {
          return acc;
        }

        return [...acc, previousOperator];
      }, []);
    },
    [getSelectedWorkflowOperators, getOperatorFromProvidedArg],
  );

  const getOperatorGraphDependencies = useCallback(() => {
    const workflow = getSelectedWorkflow();
    const workflowOperators = getSelectedWorkflowOperators();
    if (!workflowOperators?.length) return;

    return buildGraph(workflow, workflowOperators);
  }, [getSelectedWorkflowOperators, getSelectedWorkflow]);

  const getIsComputationAvailable = useCallback(
    (form = {}) => {
      if (activeCanvasSelectionInput) return false;
      if (getIsWorkflowComputing()) return false;

      const {
        formState: { isValid, errors },
      } = form;

      const operators = getSelectedWorkflowOperators();
      const isCalculatingHiddenInputs = !!errors?.root?.blockComputation;

      if (isCalculatingHiddenInputs || !isValid || isEmpty(operators))
        return false;

      const hasInvalidOperators = operators?.some((operator) =>
        getIsOperatorInvalid(operator, form),
      );

      if (hasInvalidOperators) return false;

      const hasModifiedOperators = operators?.some((operator) =>
        getIsOperatorModified(operator, form),
      );

      if (hasModifiedOperators) return true;

      const computedOperators = getComputedOperators();
      const allOperetorsComputed =
        operators?.length === computedOperators?.length;

      return !allOperetorsComputed;
    },
    [
      getIsWorkflowComputing,
      getSelectedWorkflowOperators,
      getComputedOperators,
      getIsOperatorInvalid,
      getIsOperatorModified,
      activeCanvasSelectionInput,
    ],
  );

  const getIsDownloadStreamButtonAvailable = useCallback(
    (form = {}) => {
      if (activeCanvasSelectionInput) return false;
      if (getIsWorkflowComputing()) return false;

      const selectedOperator = getSelectedOperator();
      const dependedGraph = getOperatorGraphDependencies();
      const dependentNodes = getDependentNodes(
        dependedGraph,
        selectedOperator.id,
      );
      const dependentOperators = dependentNodes.map((op) => op.getContent());
      const selectedOperatorAndDependencies = [
        ...dependentOperators,
        selectedOperator,
      ];

      if (isEmpty(selectedOperatorAndDependencies)) return false;

      const hasInvalidOperators = selectedOperatorAndDependencies?.some(
        (operator) => getIsOperatorInvalid(operator, form),
      );

      return !hasInvalidOperators;
    },
    [
      getIsOperatorInvalid,
      getOperatorGraphDependencies,
      getSelectedOperator,
      getIsWorkflowComputing,
      activeCanvasSelectionInput,
    ],
  );

  const explodeOperator = useCallback(
    (operator) => {
      showDialog(ModalDataTypes.PROMPT, {
        dataTestId: 'explode-operator-dialog',
        title: intl.formatMessage({
          id: 'workflow.operator.modal.explode_title',
          defaultMessage: 'Explode template',
        }),
        subtitle: (
          <>
            <FormattedMessage
              id="workflow.operator.modal.eplode_warning_1"
              defaultMessage="Are you sure that you want to explode the template?"
            />

            <br />

            <FormattedMessage
              id="workflow.operator.modal.eplode_warning_2"
              defaultMessage="It will be exploded into individual operators."
            />

            <br />

            <FormattedMessage
              id="workflow.operator.modal.eplode_warning_3"
              defaultMessage="Your changes will be lost if you don't recompute your workflow first."
            />
          </>
        ),
        onPrimaryButtonClick: () =>
          asyncExplodeOperatorMutation({
            workflowId: operator.conceptId,
            operatorId: operator.id,
          }),
      });
    },
    [intl, showDialog, asyncExplodeOperatorMutation],
  );

  const invalidateDefaultOperatorsQuery = useCallback(
    (workflowId) =>
      queryClient.invalidateQueries({
        queryKey: operatorsQueryKeys.defaultOperators(workflowId),
        refetchType: 'all',
      }),
    [queryClient],
  );

  const getMutationCache = useCallback(
    () => queryClient.getMutationCache(),
    [queryClient],
  );

  const getMutationCacheByKey = useCallback(
    (mutationKey, props = {}) =>
      queryClient.getMutationCache()?.find({ ...props, mutationKey }),
    [queryClient],
  );

  const unselectSelectedOperator = useCallback(() => {
    dispatch(exitToolpathSimulation());
    dispatch(selectOperatorOutputAction(null));
  }, [dispatch]);

  return {
    doesFieldSupportCanvasSelection,
    explodeOperator,
    getComputedOperators,
    getFeaturedOperators,
    getDefaultOperator,
    getDefaultOperators,
    getFrozenOperators,
    getIsAddingOperator,
    getIsComputationAvailable,
    getIsDeletingOperator,
    getIsOperatorComputed,
    getIsOperatorComputing,
    getIsOperatorExplodable,
    getIsOperatorFrozen,
    getIsOperatorInvalid,
    getIsOperatorModified,
    getIsOperatorValid,
    getIsReorderingOperator,
    getIsTemplateOperator,
    getIsToolpathOperatorSelected,
    getIsModifiedOperatorStatus,
    getIsValidOperatorStatus,
    getMutationCache,
    getMutationCacheByKey,
    getNormalizedDefaultOperators,
    getOperator,
    getOperatorGraphDependencies,
    getOperatorPreviousDepended,
    getOperatorStatus,
    getSelectedOperator,
    getTemplates,
    getWorkflowRecommendedOperators,
    getIsDownloadStreamButtonAvailable,
    invalidateDefaultOperatorsQuery,
    unselectSelectedOperator,
  };
}
