import React, { useEffect, useCallback, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import { generatePath, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import usePrevious from '@hooks/usePrevious';
import useSteps from '@hooks/useSteps';
import usePrinterQueries from '@hooks/printers/usePrinterQueries';
import useApplication from '@hooks/application/useApplication';
import NavigationBar from '@components/NavigationBar';
import { getNavigationBarHistory } from '@selectors/applicationSelectors';
import { setNavigationBarHistory } from '@actions/applicationActions';
import PrintersPageContent from '@components/PrintersPageContent';
import { ROUTES } from '@constants/router';
import {
  Wrapper,
  Header,
  RouterLink,
  Logo,
  ImgLogo,
  Content,
} from './PrinterDynamicNavigationBar.styled';

const NAVIGATION_BAR_STEP = 'navigationBar';
const PRINTERS_STEP = 'printers';

const NAVIGATION_STEPS = [NAVIGATION_BAR_STEP, PRINTERS_STEP];

const dataTestId = 'printer-dynamic-navigation-bar';

const PrinterDynamicNavigationBar = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const navigationHistory = useSelector(getNavigationBarHistory);
  const wrapperRef = useRef(null);
  const { getThemeLogoUrl } = useApplication();
  const logoUrl = getThemeLogoUrl();

  const { printersQuery } = usePrinterQueries();

  const isLoadingPrinters = !printersQuery.isFetched || printersQuery.isLoading;

  const refetchPrintersQuery = printersQuery.refetch;

  const initialStepNumber = useMemo(() => {
    if (navigationHistory?.isPrintersStep) {
      return NAVIGATION_STEPS.indexOf(PRINTERS_STEP);
    }

    return NAVIGATION_STEPS.indexOf(NAVIGATION_BAR_STEP);
  }, [navigationHistory]);

  const { currentStepName, isStepActive, getStepIndex, goNext, goPrev } =
    useSteps({
      steps: NAVIGATION_STEPS,
      initialStepNumber: initialStepNumber,
    });
  const previousCurrentStepName = usePrevious(currentStepName);
  const currentStepIndex = getStepIndex(currentStepName);
  const navigationBarStepIndex = getStepIndex(NAVIGATION_BAR_STEP);
  const isNavigationBarStep = isStepActive(NAVIGATION_BAR_STEP);
  const isPrintersStep = isStepActive(PRINTERS_STEP);

  const updateNavigationHistory = useCallback(
    (nextHistory) =>
      dispatch(
        setNavigationBarHistory({
          ...navigationHistory,
          ...nextHistory,
        }),
      ),
    [dispatch, navigationHistory],
  );

  const printersListItems = useMemo(
    () =>
      printersQuery.data?.map((printer) => ({
        id: printer.id,
        label: printer.name,
        description: intl.formatMessage(
          {
            id: 'printerspage.printers_list.list_item.description',
            defaultMessage: 'Created: {date}',
          },
          { date: moment(printer?.createdAt || moment()).format('DD/MM/YYYY') },
        ),
        leadingIconName: 'precision_manufacturing_0',
        createdAt: printer?.createdAt,
        onClick: () =>
          history.push(
            generatePath(ROUTES.PRINTER, {
              printerId: printer?.id,
            }),
          ),
      })),
    [intl, history, printersQuery.data],
  );

  useGSAP(
    () => {
      const isMovingBackwards =
        getStepIndex(currentStepName) < getStepIndex(previousCurrentStepName);

      const startFrom = isMovingBackwards ? '-100%' : '100%';

      gsap.from('.animated-step', 0.35, { x: startFrom });
    },
    {
      scope: wrapperRef,
      dependencies: [currentStepName, getStepIndex, currentStepName],
    },
  );

  useEffect(() => {
    if (currentStepIndex > navigationBarStepIndex) {
      if (!navigationHistory?.isPrintersStep) {
        updateNavigationHistory({ isPrintersStep: true });
      }

      return;
    }

    if (navigationHistory?.isPrintersStep) {
      updateNavigationHistory({ isPrintersStep: false });
    }
  }, [
    currentStepIndex,
    navigationBarStepIndex,
    navigationHistory,
    updateNavigationHistory,
  ]);

  useEffect(() => {
    if (!printersQuery.isFetched) {
      refetchPrintersQuery();
    }
  }, [refetchPrintersQuery, printersQuery.isFetched]);

  return (
    <Wrapper ref={wrapperRef} data-testid={dataTestId}>
      <Header data-testid={`${dataTestId}__header`}>
        <RouterLink data-testid={`${dataTestId}__logo-link`} to="/">
          {logoUrl ? <ImgLogo src={logoUrl} /> : <Logo />}
        </RouterLink>
      </Header>

      {isNavigationBarStep && (
        <div className="animated-step">
          <NavigationBar onPrintersItemClick={goNext} withLogo={false} />
        </div>
      )}

      {!isNavigationBarStep && (
        <Content className="animated-step">
          {isPrintersStep && (
            <PrintersPageContent
              headerLeadingIconButtonIconName="arrow_back_0"
              loading={isLoadingPrinters}
              onHeaderLeadingIconButtonClick={goPrev}
              printersListItems={printersListItems}
            />
          )}
        </Content>
      )}
    </Wrapper>
  );
};

PrinterDynamicNavigationBar.propTypes = {};

export default PrinterDynamicNavigationBar;
