const syncActions = [
  // Login
  'RESET_LOG_IN_FAILURE',

  // App
  'SET_SCREEN_SIZE',
  'ADD_BANNER',
  'REMOVE_BANNER',
  'SET_NAVIGATION_BAR_HISTORY',
  'RESET_NAVIGATION_BAR_HISTORY',

  //USERs
  'SET_CURRENT_USER',
  'SET_CURRENT_USER_PREFERENCE',

  // NOZZLE
  'SHOW_NOZZLE_CONFIG',
  'HIDE_NOZZLE_CONFIG',

  // POST PROCESSOR CONFIG
  'SHOW_POST_PROCESSOR_CONFIG',
  'HIDE_POST_PROCESSOR_CONFIG',

  // Toolbar
  'TOGGLE_CLIP_TOOL',
  'RESET_CLIP_TOOL',
  'SELECT_CLIP_TOOL_OPTION',
  'SET_CLIP_TOOL_RANGE',
  'SET_CLIP_RANGE_APPLIED',
  'RESET_CLIP_RANGE_APPLIED',
  'UPDATE_CLIP_TOOL_MIN_MAX_RANGES',
  'SET_CLIP_TOOL_MODE',
  'SET_CLIP_TOOL_AVAILABILITY',
  'SET_SAFETY_CHECK_RESULTS',
  'RESET_SAFETY_CHECK_RESULTS',
  'GET_SAFETY_CHECK_RESULTS',

  // Printers
  'MACHINE_SETTING_MODIFIED',
  'CHANGE_MACHINE_MODEL',
  'SHOW_GENERAL_PRINTER_SETTING',
  'HIDE_GENERAL_PRINTER_SETTING',
  'PRINTER_SETTINGS_MODIFIED',
  'PRINTER_SETTINGS_DELETED',

  // Nozzle library
  'RESET_NOZZLE_CHANGES',

  // Concepts
  'CONCEPT_NOZZLE_MODIFIED',
  'SHOW_UPDATE_NOZZLE_DIALOG',
  'DISMISS_UPDATE_NOZZLE_DIALOG',
  'CONCEPT_MATERIAL_MODIFIED',
  'SHOW_UPDATE_MATERIAL_DIALOG',
  'DISMISS_UPDATE_MATERIAL_DIALOG',

  // Safety Checks
  'SET_SAFETY_CHECKS_MODE',

  // Loading
  'LOADING_STARTED',
  'LOADING_FINISHED',
];

export default syncActions;
