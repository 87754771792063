import { FileTypes } from '../constants/fileTypes';
import { Printer } from '../lib/Printer';
import VisualizationUtils from '../lib/VisualizationUtils';
import * as THREE from 'three';

/**
 * It represents a .glb file
 */
export default class GlbModel {
  constructor(
    originalDesign,
    displayMode = 'SOLID',
    showDimension = false,
    bedAlignmentData = null,
  ) {
    if (
      !originalDesign.fileGlbTwinURL ||
      !(
        (originalDesign.fileGlbTwinURL instanceof String ||
          typeof originalDesign.fileGlbTwinURL === 'string') &&
        originalDesign.fileGlbTwinURL.startsWith('http')
      )
    ) {
      throw 'Invaid Glb desing received!';
    }
    this.design = originalDesign;
    this.displayMode = displayMode;
    this.showDimension = showDimension;
    this.bedAlignmentData = bedAlignmentData;

    this.loadedModelPromise = this.loadGltfModel(this);
  }

  equals(other) {
    if (!(other instanceof GlbModel)) {
      return false;
    }
    if (other.getUrl() !== this.getUrl()) {
      return false;
    }
    return true;
  }

  getUrl() {
    return this.design.fileGlbTwinURL;
  }

  getDisplayMode() {
    return this.displayMode;
  }

  isShowingDimensions() {
    return this.showDimension;
  }

  getHashCode() {
    return (
      Math.abs(GlbModel.hashCode(this.design.fileGlbTwinURL)) +
      Math.abs(GlbModel.hashCode(this.displayMode)) * (this.showDimension + 1)
    );
  }

  getLoadedModelPromise() {
    return this.loadedModelPromise;
  }

  /**
   * Returns a hash code for a string.
   * (Compatible to Java's String.hashCode())
   *
   * The hash code for a string object is computed as
   *     s[0]*31^(n-1) + s[1]*31^(n-2) + ... + s[n-1]
   * using number arithmetic, where s[i] is the i th character
   * of the given string, n is the length of the string,
   * and ^ indicates exponentiation.
   * (The hash value of the empty string is zero.)
   *
   * @param {string} s a string
   * @return {number} a hash code value for the given string.
   */
  static hashCode(s) {
    let h = 0;
    const l = s.length;
    let i = 0;
    if (l > 0) while (i < l) h = ((h << 5) - h + s.charCodeAt(i++)) | 0;
    return h;
  }

  loadGltfModel = () => {
    const gltfUrl = this.getUrl();
    return new Promise((resolve) => {
      const isGltf = this.design?.filetype?.toLowerCase() === FileTypes.gltf;
      Printer.gltfLoader.load(gltfUrl, (gltf) => {
        if (isGltf) {
          gltf.scene.position.set(0, 0, 0);
          gltf.scene.scale.set(0.02, -0.02, 0.02);
          gltf.scene.rotation.z = -Math.PI;
        } else {
          gltf.scene.children[0] = VisualizationUtils.convertGlbToObject3D(
            gltf,
            this.design?.filetype?.toLowerCase() !== FileTypes.stl,
          );
          gltf.scene.children[0].updateMatrix();
        }

        if (this.bedAlignmentData && !isGltf) {
          //for now the aligment is needed only if it is not gltf
          const bedMatrix = this.buildBedAlignmentMatrix();
          const object = gltf.scene.children[0];
          object.matrix.premultiply(bedMatrix);
          object.matrixAutoUpdate = false;
        }

        resolve(gltf);
      });
    });
  };

  buildBedAlignmentMatrix() {
    const {
      origin, // THREE.Vector3
      dirX, // THREE.Vector3
      dirY, // THREE.Vector3
      dirZ, // THREE.Vector3
    } = this.bedAlignmentData;

    // (A) M1: unscale by 1 / scaleFactor
    // TODO: this is working but it doesn't look right as it is using the obj scale factor. I think it is because of the threejs stuff we have in the comment in the getObjScaleFactor
    const scaleFactor = VisualizationUtils.getObjScaleFactor();
    const M1 = new THREE.Matrix4().makeScale(
      1 / scaleFactor,
      1 / scaleFactor,
      1 / scaleFactor,
    );

    // (B) M2: bed alignment
    // We want: T0 = origin + x*dirX + y*dirY + z*dirZ (for the "unscaled" coords)
    // In matrix form (row-major):
    // [
    //   dirX.x, dirY.x, dirZ.x, origin.x,
    //   dirX.y, dirY.y, dirZ.y, origin.y,
    //   dirX.z, dirY.z, dirZ.z, origin.z,
    //   0,      0,      0,      1
    // ]
    const M2 = new THREE.Matrix4().set(
      dirX.x,
      dirY.x,
      dirZ.x,
      origin.x,
      dirX.y,
      dirY.y,
      dirZ.y,
      origin.y,
      dirX.z,
      dirY.z,
      dirZ.z,
      origin.z,
      0,
      0,
      0,
      1,
    );

    // (C) M3: rescale by scaleFactor
    const M3 = new THREE.Matrix4().makeScale(
      scaleFactor,
      scaleFactor,
      scaleFactor,
    );

    // Combine into a single matrix: M = M3 * M2 * M1
    // (order is important: first M1, then M2, then M3)
    const M = new THREE.Matrix4();
    M.multiply(M3); // M = M3
    M.multiply(M2); // M = M3*M2
    M.multiply(M1); // M = M3*M2*M1

    return M;
  }
}
