import * as THREE from 'three';
import {
  EXTERNAL_AXIS_NAME,
  LINEAR_RAIL_POSITION,
} from '../../constants/machineConstants';
import { Printer } from '../Printer';
import { Bed } from '../beds/Bed';
import Plinth from './Plinth';
import { degToRad } from 'three/src/math/MathUtils';

class LinearRail extends Plinth {
  constructor(
    printerSettings,
    machineDefaults,
    plinthType,
    mountingType,
    plinthDefinitions,
  ) {
    super(printerSettings, machineDefaults, plinthType, plinthDefinitions);
    const rotationX = degToRad(
      parseFloat(
        Printer.getSettingValueFromTypeOrDefault(
          printerSettings,
          'LINEAR_RAIL_ROTATION_RX',
          0,
        ),
      ),
    );
    const rotationY = degToRad(
      parseFloat(
        Printer.getSettingValueFromTypeOrDefault(
          printerSettings,
          'LINEAR_RAIL_ROTATION_RY',
          0,
        ),
      ),
    );
    const rotationZ = degToRad(
      parseFloat(
        Printer.getSettingValueFromTypeOrDefault(
          printerSettings,
          'LINEAR_RAIL_ROTATION_RZ',
          0,
        ),
      ),
    );
    this.setRotationFromEuler(
      new THREE.Euler(rotationX, rotationY, rotationZ, 'XYZ'),
    );
    this.homePosition = parseFloat(
      Printer.getSettingValueFromTypeOrDefault(
        printerSettings,
        'LINEAR_RAIL_HOME_POSITION',
        0,
      ),
    );
    this.createGroups();

    this.railOrigin = new THREE.Vector3(0, 0, 0);
    this.railDirection = new THREE.Vector3(1, 0, 0);
    this.railDirection.applyEuler(this.rotation);
    this.mountingType = mountingType;
    this.originValue = this.plinthSettings?.originValue
      ? this.plinthSettings.originValue
      : 0;
    this.isNegative = this.plinthSettings?.negative
      ? this.plinthSettings.negative
      : false;
  }

  initializeModels() {
    return new Promise((resolve) => {
      if (this.plinthType === '' || !this.plinthType)
        resolve(new THREE.Group());
      else {
        if (this.plinthSettings.empty || this.plinthSettings.fileKey == '')
          return resolve();
        const url = `/models/Plinth_${this.plinthSettings.fileKey}.glb`;
        const model = Printer.getModel(url);
        model.then((model) => {
          model.children.forEach((child) => {
            if (child.name.includes(EXTERNAL_AXIS_NAME + 0)) {
              this.add(child.clone());
            } else if (child.name.includes(EXTERNAL_AXIS_NAME + 1)) {
              this.rail.add(child.clone());
            } else {
              //eslint-disable-next-line no-console
              console.error('Unknown axis in linear rail');
            }
          });
          resolve();
        });
      }
    });
  }

  /**
   * Attaches the target component to the rail. The target component position is set as the position of the rail.
   * The component is NOT set as a child of the rail, all motion is simulated by adjusted its global position.
   * TODO: make child of rail
   * @param {*} attachment
   */
  addAttachment(attachment) {
    this.attachment = attachment;

    if (this.attachment instanceof Bed) {
      //if attaching a bed, then the linear rail will be underneath the bed.
      this.railOrigin = new THREE.Vector3(
        attachment.position.x,
        attachment.position.y,
        attachment.position.z,
      );
      //additional offset is the additional z offset between the base calibration frame and the
      //bed positional frame
      const additionalOffset = this.attachment.printingBedDefaults.offset.z;
      this.position.set(
        this.railOrigin.x,
        this.railOrigin.y,
        this.railOrigin.z - this.height + additionalOffset,
      );
    }
  }

  /**
   * Simulates the rail motion based on the linear rail position data supplied
   * in the toolpath. NOTE. the attached machine is not a child of the rail,
   * but is instead moves the machine to the rail origin + the rail movement.
   * @param {*} simulationData
   */
  simulate(simulationData) {
    const railPosition =
      simulationData.previousStep.movement[LINEAR_RAIL_POSITION] +
      (simulationData.currentStep.movement[LINEAR_RAIL_POSITION] -
        simulationData.previousStep.movement[LINEAR_RAIL_POSITION]) *
        simulationData.stepRatio;
    this.moveRailToPosition(railPosition);
  }

  /**
   * Moves the motion component of the rail to the target position, based on the min value
   * of the rail and the desired position along the rail.
   */
  moveRailToPosition(targetPosition) {
    let adjustedPosition = isNaN(targetPosition)
      ? 0
      : targetPosition - this.originValue;
    if (this.isNegative) adjustedPosition *= -1;
    this.rail.position.set(adjustedPosition, 0, 0);

    //attachment should really be a child of the plinth to avoid having to do this
    this.attachment?.position.set(
      this.railOrigin.x + this.railDirection.x * adjustedPosition,
      this.railOrigin.y + this.railDirection.y * adjustedPosition,
      this.railOrigin.z + this.railDirection.z * adjustedPosition,
    );
  }

  createGroups() {
    this.rail = new THREE.Group();
    this.add(this.rail);
  }

  moveToHome() {
    this.moveRailToPosition(this.homePosition);
  }
}

export default LinearRail;
