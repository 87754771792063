import { Printer } from '../lib/Printer';
import VisualizationUtils from '../lib/VisualizationUtils';
import GlbModel from './GlbModel';
import * as THREE from 'three';
import { FileTypes } from '../constants/fileTypes';
import { LineData } from '../constants/lineData';
import { ViewModes } from '../constants/viewModes';
import { convertThreePointsToQuaternion } from '@components/Printers/Editor';
import { isNumber } from 'lodash';

/**
 * Retrieves the glb file containing the print object from the target url
 * and returns it as a promise.
 * @param {*} toolpathSimulationUrl
 * @returns
 */
export const loadPrintingObject = (toolpathSimulationUrl) => {
  return new Promise((resolve) => {
    Printer.gltfLoader.load(toolpathSimulationUrl, (glb) => {
      resolve(glb);
    });
  });
};

export const updateModel = (design, designGeometry, settings, printer) => {
  if (!design || !designGeometry || !settings) {
    return new THREE.Object3D();
  }
  const basis = getBasisOfPrintBed(printer);
  const rotationCorrections = getRotationCorrections(printer);
  const correctionMatrix = new THREE.Matrix4().makeRotationFromEuler(
    rotationCorrections,
  );
  const bedAlignmentData = getBedAlignmentData(printer);

  const {
    isShowingOuterWall = true,
    isShowingInnerWall = true,
    isShowingSupport = true,
    isShowingSupportInterface = true,
    isShowingBrim = true,
    isShowingSkin = true,
    isShowingInfill = true,
    isShowingSeams = false,
    isShowingSolid = false,
    isShowingRotations = false,
    isShowingVertices = false,
    lineData = LineData.LINETYPE,
    customLineData = {},
    selectedOutputOperatorId = '',
    customLineDataSelection = new Map(),
    isShowingDimensions = false,
    displayMode = ViewModes.SOLID,
    isShowingMilling = true,
  } = settings;

  const customLineDataEntry =
    customLineDataSelection.get(selectedOutputOperatorId) ?? {};
  const customLineDataKey = customLineData?.name ?? '';

  const currentLineDataSelection = (
    customLineDataEntry[customLineDataKey] ?? []
  ).map((value) => {
    if (value === 'true') return true;
    if (value === 'false') return false;
    return value;
  });

  const lineTypeVisibilitySettings = {
    OUTER_WALL: isShowingOuterWall,
    INNER_WALL: isShowingInnerWall,
    SUPPORT: isShowingSupport,
    SUPPORT_INTERFACE: isShowingSupportInterface,
    BRIM: isShowingBrim,
    SKIN: isShowingSkin,
    INFILL: isShowingInfill,
    MILLING: isShowingMilling,
  };

  if (allowedExtensions['OBJ'].includes(design?.filetype.toLowerCase())) {
    return VisualizationUtils.convertObjToObject3D(designGeometry, displayMode);
  } else if (
    allowedExtensions['STEP'].includes(design?.filetype.toLowerCase()) ||
    allowedExtensions['STL'].includes(design?.filetype.toLowerCase()) ||
    design?.filetype.toLowerCase() === FileTypes.gltf
  ) {
    return new GlbModel(
      design,
      displayMode,
      isShowingDimensions,
      bedAlignmentData,
    );
  } else if (design?.filetype === FileTypes.glb) {
    //TODO: it isn't a clean way to manage
    return new THREE.Object3D();
  }
  return VisualizationUtils.convertAibToObject3D(
    designGeometry,
    lineData,
    basis,
    correctionMatrix,
    bedAlignmentData,
    lineTypeVisibilitySettings,
    customLineData,
    currentLineDataSelection,
    isShowingSeams,
    isShowingSolid,
    isShowingRotations,
    isShowingVertices,
  );
};

const getBedAlignmentData = (printer) => {
  if (!printer) {
    return {
      origin: new THREE.Vector3(0, 0, 0),
      dirX: new THREE.Vector3(1, 0, 0),
      dirY: new THREE.Vector3(0, 1, 0),
      dirZ: new THREE.Vector3(0, 0, 1),
    };
  }

  const refA = new THREE.Vector3(
    printer.baseRef1X,
    printer.baseRef1Y,
    printer.baseRef1Z,
  );
  const refB = new THREE.Vector3(
    printer.baseRef2X,
    printer.baseRef2Y,
    printer.baseRef2Z,
  );
  const refC = new THREE.Vector3(
    printer.baseRef3X,
    printer.baseRef3Y,
    printer.baseRef3Z,
  );

  // Compute directional vectors
  const dirX = new THREE.Vector3().subVectors(refC, refA).normalize();
  const normal = new THREE.Vector3()
    .crossVectors(refC.clone().sub(refA), refB.clone().sub(refA))
    .normalize();
  const dirY = new THREE.Vector3().crossVectors(normal, dirX).normalize();
  const dirZ = normal;

  return {
    origin: refA,
    dirX,
    dirY,
    dirZ,
  };
};

/**
 * Returns the basis (i.e. the vectors defining the coordinate system) of the printing bed's
 * coordinate system, so that axis frame visualisations can be generated using this basis
 * rather than the global one. If no basis is provided, i.e. printer or base refs are null, then
 * the default global basis is returned.
 * @param {Printer} printer
 */
const getBasisOfPrintBed = (printer) => {
  const defaultBase = [
    new THREE.Vector3(1, 0, 0),
    new THREE.Vector3(0, 1, 0),
    new THREE.Vector3(0, 0, 1),
  ];
  if (printer) {
    const quaternion = convertThreePointsToQuaternion(
      new THREE.Vector3(
        printer.baseRef1X,
        printer.baseRef1Y,
        printer.baseRef1Z,
      ),
      new THREE.Vector3(
        printer.baseRef3X,
        printer.baseRef3Y,
        printer.baseRef3Z,
      ),
      new THREE.Vector3(
        printer.baseRef2X,
        printer.baseRef2Y,
        printer.baseRef2Z,
      ),
    );
    const rotationMatrix = new THREE.Matrix4().makeRotationFromQuaternion(
      quaternion,
    );
    const xAxis = new THREE.Vector3();
    const yAxis = new THREE.Vector3();
    const zAxis = new THREE.Vector3();
    rotationMatrix.extractBasis(xAxis, yAxis, zAxis);
    const basis = [xAxis, yAxis, zAxis];
    if (basis === undefined) {
      return defaultBase;
    } else {
      return basis;
    }
  } else {
    return defaultBase;
  }
};

/**
 * Returns an euler angle representing the rotation corrections that should be applied
 * to the rotation frames at every point in the file. Used by printers which have a different
 * default frame for zero-rotation, e.g. some machines have the z-axis down at zero rotation.
 * @param {*} printer
 * @returns
 */
const getRotationCorrections = (printer) => {
  if (!printer) {
    return new THREE.Euler(0, 0, 0, 'XYZ');
  } else {
    return new THREE.Euler(
      VisualizationUtils.toRadians(printer.rotationCorrectionRx),
      VisualizationUtils.toRadians(printer.rotationCorrectionRy),
      VisualizationUtils.toRadians(printer.rotationCorrectionRz),
      'XYZ',
    );
  }
};

export const fileTypesByInputType = {
  FILE_GENERAL: [
    FileTypes.obj,
    FileTypes.stl,
    FileTypes.step,
    FileTypes.stp,
    FileTypes.dxf,
    FileTypes.aib,
  ],
  FILE_TRIANGLE_MESH: [
    FileTypes.obj,
    FileTypes.stl,
    FileTypes.step,
    FileTypes.stp,
  ],
  FILE_CLASSIFIED_POLYLINES: [FileTypes.aib, FileTypes.dxf],
  FILE_GCODE: [FileTypes.gcode],
};

const allowedExtensions = {
  OBJ: [FileTypes.obj],
  STEP: [FileTypes.step, FileTypes.stp],
  STL: [FileTypes.stl],
  AIB: [FileTypes.aib],
  DXF: [FileTypes.dxf],
};

export const getExtensionFromString = function (string) {
  if (!string) return null;
  return allowedExtensions[string.toUpperCase()];
};

export const getAllAllowedExtensions = function () {
  return Object.keys(allowedExtensions)
    .map((key) => allowedExtensions[key])
    .flat(1);
};

export const getViewIconsAllowedExtensions = function () {
  return allowedExtensions.OBJ;
};

export const getLegendRange = (
  design,
  designGeometry,
  lineData,
  customLineData,
) => {
  if (!design || !designGeometry) {
    return;
  }
  const { min, max } = customLineData?.range ?? {};

  if (isNumber(min) && isNumber(max)) {
    return [min, max];
  }
  if (design?.filetype === FileTypes.aib) {
    return VisualizationUtils.getLegendRangeFromAib(designGeometry, lineData);
  } else {
    return;
  }
};

export const displayGrid = (gridSize, plinthHeight) => {
  const grid = new THREE.GridHelper(
    gridSize,
    gridSize / 2,
    new THREE.Color('#222222'),
    new THREE.Color('#111111'),
  );
  //TODO: scale ratio should be global, included in the threejs scene
  const scaleRatio = 0.02;
  if (plinthHeight) grid.translateZ(-plinthHeight * scaleRatio);
  grid.rotation.x = -Math.PI / 2;

  const group = new THREE.Group();
  group.add(grid);
  group.name = 'grid';

  return group;
};

/**
 * Creates a new Printer object from the supplied properties, and
 * initialises the models of the parts of the printer. A promise is returned
 * which resolves once the printer models have completed loading.
 *
 * Resolves null if there are no printer props passed, i.e. the printer
 * has been deselected.
 * @param {} printerProps
 * @return promise which resolves once the printer models loading
 */
export const getPrinterWithModels = (
  printerProps,
  machineDefinitions,
  extruderDefinitions,
  printingBedDefinitions,
  plinthDefinitions,
  enclosureDefinitions,
) => {
  return new Promise((resolve) => {
    if (!printerProps) return resolve(null);
    const printerSettings = Object.entries(printerProps).map(
      ([key, value]) => ({
        settingName: key,
        value: value,
      }),
    );
    const printer = new Printer(
      printerProps.machine.modelName,
      printerSettings,
      machineDefinitions,
      extruderDefinitions,
      printingBedDefinitions,
      plinthDefinitions,
      enclosureDefinitions,
    );
    printer.initializeMachineGeometry().then(() => {
      return resolve(printer);
    });
  });
};

export const fitCameraToCenteredObject = function (
  camera,
  object,
  offset,
  orbitControls,
  x = 0,
  y = 0,
  repositionToCenter = false,
  useInverseZ = false,
) {
  //TODO: manage the line for the gltf
  if (object instanceof GlbModel) {
    return object.getLoadedModelPromise().then((gltf) => {
      return fitCameraToCenteredObject(
        camera,
        gltf.scene.children[0],
        offset,
        orbitControls,
        x,
        y,
        repositionToCenter,
      );
    });
  }
  const boundingBox = new THREE.Box3().setFromObject(object);
  const size = new THREE.Vector3();
  boundingBox.getSize(size);
  const center = new THREE.Vector3();
  boundingBox.getCenter(center);

  // figure out how to fit the box in the view:
  // 1. figure out horizontal FOV (on non-1.0 aspects)
  // 2. figure out distance from the object in X and Y planes
  // 3. select the max distance (to fit both sides in)
  //
  // The reason is as follows:
  //
  // Imagine a bounding box (BB) is centered at (0,0,0).
  // Camera has vertical FOV (camera.fov) and horizontal FOV
  // (camera.fov scaled by aspect, see fovh below)
  //
  // Therefore if you want to put the entire object into the field of view,
  // you have to compute the distance as: z/2 (half of Z size of the BB
  // protruding towards us) plus for both X and Y size of BB you have to
  // figure out the distance created by the appropriate FOV.
  //
  // The FOV is always a triangle:
  //
  //  (size/2)
  // +--------+
  // |       /
  // |      /
  // |     /
  // | F° /
  // |   /
  // |  /
  // | /
  // |/
  //
  // F° is half of respective FOV, so to compute the distance (the length
  // of the straight line) one has to: `size/2 / Math.tan(F)`.
  //
  // FTR, from https://threejs.org/docs/#api/en/cameras/PerspectiveCamera
  // the camera.fov is the vertical FOV.

  const fov = camera.fov * (Math.PI / 180);
  const fovh = 2 * Math.atan(Math.tan(fov / 2) * camera.aspect);
  const dx = size.z / 2 + Math.abs(size.x / 2 / Math.tan(fovh / 2));
  const dy = size.z / 2 + Math.abs(size.y / 2 / Math.tan(fov / 2));
  let cameraZ = Math.max(Math.max(dx, dy), center.z);
  if (useInverseZ) cameraZ *= -1;

  let finalx, finaly, finalz;

  // offset the camera, if desired (to avoid filling the whole canvas)
  if (offset !== undefined && offset !== 0) cameraZ *= offset;
  if (repositionToCenter) {
    camera.position.set(center.x, center.y, cameraZ);
    finalx = center.x;
    finaly = center.y;
    finalz = cameraZ;
  } else {
    camera.position.set(x, y, cameraZ);
    finalx = x;
    finaly = y;
    finalz = cameraZ;
  }

  camera.updateProjectionMatrix();

  if (orbitControls !== undefined) {
    //orbitControls.target = new THREE.Vector3(0, 0, 0);
    orbitControls.target = center;
    orbitControls.update();
  }

  // this returns a promise to make consistent return (see above return)
  return Promise.resolve([finalx, finaly, finalz]);
};
