import { isUndefined } from 'lodash';

const parseCondition = (condition = '') => {
  const parsedCondition = condition.split(':');
  const conditionInputFirstPart = parsedCondition?.[0];
  let conditionValue = parsedCondition?.[1];

  let conditionInputName;
  let conditionInputType;

  if (conditionInputFirstPart.includes('.TYPE')) {
    conditionInputName = conditionInputFirstPart.replace('.TYPE', '');
    conditionInputType = conditionValue;
  } else {
    conditionInputName = conditionInputFirstPart;
  }
  let compareValueExtension = false;

  if (conditionValue?.includes('*.')) {
    compareValueExtension = true;
    conditionValue = conditionValue.replace('*.', '');
  }

  return {
    conditionInputName,
    conditionInputType,
    conditionValue,
    compareValueExtension,
  };
};

const matchCondition = (condition, inputNameConditions, files) => {
  const {
    conditionInputName,
    conditionInputType,
    conditionValue,
    compareValueExtension,
  } = parseCondition(condition);
  const field = inputNameConditions?.[conditionInputName];

  if (!field) {
    return false;
  }

  if (compareValueExtension) {
    const file = files.find((file) => file.id === field.value);

    return file?.filetype === conditionValue.toLowerCase();
  }

  if (conditionInputType) {
    return field.type === conditionInputType;
  }

  return !isUndefined(conditionValue) && field.value === conditionValue;
};

const checkSomeCondition = (condition, inputNameConditions, files) => {
  const parsedConditions = condition.split('|').filter((c) => !!c);

  return parsedConditions.some((part) =>
    matchCondition(part, inputNameConditions, files),
  );
};

const checkMultiCondition = (condition, inputNameConditions, files) => {
  const multiCondition = condition.split('&').filter((c) => !!c);

  return multiCondition.every((condition) => {
    if (condition.includes('|')) {
      return checkSomeCondition(condition, inputNameConditions, files);
    }

    return matchCondition(condition, inputNameConditions, files);
  });
};

export const doesMeetTheConditions = (
  conditions,
  inputNameConditions,
  files,
) => {
  return conditions.every((condition) => {
    const isMultiCondition = condition.includes('&');

    if (isMultiCondition) {
      const parsedMultiCondition = condition.split('|').filter((c) => !!c);

      return parsedMultiCondition.some((c) =>
        checkMultiCondition(c, inputNameConditions, files),
      );
    }

    return checkSomeCondition(condition, inputNameConditions, files);
  });
};
