import {
  ContentWrapper,
  Frame,
  Wrapper,
} from '@pages/Preferences/Preferences.styled';
import React, { useCallback, useMemo } from 'react';
import SettingSwitch from '@components/2-molecules/SettingSwitch';
import { useSelector, useDispatch } from 'react-redux';
import { useStatsigClient } from '@statsig/react-bindings';
import { setCurrentUserPreference } from '@actions/loginActions';
import { getCurrentUser } from '@selectors/loginSelectors';
import { useIntl } from 'react-intl';
import useOrganizationMutations from '@hooks/organization/useOrganizationMutations';
import useSnackbar from '@hooks/useSnackbar';
import useFeatureFlagValue from '@hooks/featureflags/useFeatureFlagValue';
import { Formik } from 'formik';
import { logError } from '@utils/logs';
import {
  FULL_SCREEN_OPERATOR_FEATURE,
  AUTO_COMPUTE_ON_OPERATOR_INPUT_SELECTION,
} from '@app/constants/featureFlagConstants';

const Preferences = () => {
  const { client: statsigClient } = useStatsigClient();
  const currentUser = useSelector(getCurrentUser());
  const { updateUserPreferenceMutation } = useOrganizationMutations();
  const { showSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const intl = useIntl();
  const isFullScreenOperatorEnabled = useFeatureFlagValue(
    FULL_SCREEN_OPERATOR_FEATURE,
  );
  const isAutoComputeOperatorEnabled = useFeatureFlagValue(
    AUTO_COMPUTE_ON_OPERATOR_INPUT_SELECTION,
  );

  const formInitialValues = useMemo(() => {
    return currentUser?.userPreferences || {};
  }, [currentUser]);

  const preferences = useMemo(
    () => [
      {
        available: isFullScreenOperatorEnabled,
        key: 'USE_FULL_SCREEN_OPERATORS',
        value: formInitialValues['USE_FULL_SCREEN_OPERATORS'],
        label: intl.formatMessage({
          id: 'settings.preferences.operator.view.label',
          defaultMessage: 'Full-screen operator view',
        }),
        description: intl.formatMessage({
          id: 'settings.preferences.operator.view.description',
          defaultMessage:
            'Automatically Display Expanded Operator Settings on Addition',
        }),
        successMessage: intl.formatMessage({
          id: 'settings.preferences.operator.view.snackbar.label',
          defaultMessage: 'Full-Screen operator view was successfully updated',
        }),
      },
      {
        available: isAutoComputeOperatorEnabled,
        key: 'AUTO_COMPUTE_ON_OPERATOR_INPUT_SELECTION',
        value: formInitialValues['AUTO_COMPUTE_ON_OPERATOR_INPUT_SELECTION'],
        label: intl.formatMessage({
          id: 'settings.preferences.auto_compute_on_operator_input_selection.view.label',
          defaultMessage: 'Auto Compute',
        }),
        description: intl.formatMessage({
          id: 'settings.preferences.auto_compute_on_operator_input_selection.view.description',
          defaultMessage:
            'Automatically compute operator’s output and dependencies upon selecting a valid input',
        }),
        successMessage: intl.formatMessage({
          id: 'settings.preferences.auto_compute_on_operator_input_selection.snackbar.label',
          defaultMessage:
            'Auto Compute on Operator Input Selection was successfully updated',
        }),
      },
    ],
    [
      intl,
      formInitialValues,
      isFullScreenOperatorEnabled,
      isAutoComputeOperatorEnabled,
    ],
  );

  const availablePreferences = preferences.filter(
    (preference) => preference.available,
  );

  const onFormSubmit = useCallback(
    async (values, { resetForm }) => {
      const valuesToUpdate = availablePreferences.filter(
        (preference) => values[preference.key] !== preference.value,
      );

      try {
        await Promise.all(
          valuesToUpdate.map(async (preference) => {
            const preferenceValue = values[preference.key];

            await updateUserPreferenceMutation.mutateAsync({
              userPreference: {
                preferenceKey: preference.key,
                preferenceValue,
              },
            });

            statsigClient?.logEvent?.(preference.key, preferenceValue);

            dispatch(setCurrentUserPreference(preference.key, preferenceValue));

            showSnackbar({
              text: preference.successMessage,
            });
          }),
        );
      } catch (e) {
        resetForm();
        logError(e);
      }
    },
    [
      dispatch,
      showSnackbar,
      updateUserPreferenceMutation,
      availablePreferences,
      statsigClient,
    ],
  );

  return (
    <Wrapper>
      <ContentWrapper>
        <Formik
          enableReinitialize
          initialValues={formInitialValues}
          onSubmit={onFormSubmit}
        >
          {({ handleSubmit, values, setFieldValue }) => (
            <Frame>
              {availablePreferences.map((preference) => (
                <SettingSwitch
                  key={preference.key}
                  dataTestId={`settings-page__preferences__${preference.key}`}
                  label={preference.label}
                  supportingText={preference.description}
                  switchProps={{
                    enabled: preference.value,
                    onChange: () => {
                      const fieldValue = values[preference.key];

                      setFieldValue(preference.key, !fieldValue);
                      handleSubmit();
                    },
                  }}
                />
              ))}
            </Frame>
          )}
        </Formik>
      </ContentWrapper>
    </Wrapper>
  );
};

Preferences.propTypes = {};

export default Preferences;
