import { isFetchInProgess } from '@selectors/fetchSelector';

export const getIsFetchesInProgress = () => (state) => {
  const { designs, concepts, printers, workspaces } = state;

  return !!(
    designs.fetchesInProgress ||
    concepts.ui.fetchesInProgress ||
    printers.ui.fetchesInProgress ||
    workspaces.ui.fetchesInProgress ||
    isFetchInProgess('loading')(state) ||
    isFetchInProgess('logout')(state) ||
    isFetchInProgess('explodeTemplate')(state) ||
    isFetchInProgess('showSpinner')(state) ||
    isFetchInProgess('uploadPrinterComponent')(state) ||
    isFetchInProgess('fetchSimulationDataSafetyCheck')(state) ||
    isFetchInProgess('updateVisibility')(state) ||
    isFetchInProgess('generateMFAConnectorKey')(state) ||
    isFetchInProgess('validateMFACode')(state) ||
    isFetchInProgess('deactivateMFA')(state)
  );
};
