import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import { TOOLTIP_POSITION_BOTTOM_CENTER } from '@components/2-molecules/Tooltip';
import withTooltip from '@hoc/withTooltip';
import {
  Wrapper,
  Item,
  ItemIcon,
  ItemLabel,
  InfoGrid,
  InfoRow,
  InfoLabel,
  TooltipIconButton,
} from './PrintEvaluator.styled';

const TooltipItem = withTooltip(Item);

const PrintEvaluator = ({ duration, weight, price, info = {} }) => {
  const intl = useIntl();
  const evaluationItems = useMemo(
    () => [
      {
        icon: 'schedule_0',
        label: duration,
        title: intl.formatMessage({
          id: 'print_evaluator.duration_tooltip.label',
          defaultMessage: 'Toolpath Duration Estimation',
        }),
      },
      {
        icon: 'spool_0',
        label: weight,
        title: intl.formatMessage({
          id: 'print_evaluator.weight_tooltip.label',
          defaultMessage: 'Material Weight Estimation',
        }),
      },
      {
        icon: 'paid_0',
        label: price,
        title: intl.formatMessage({
          id: 'print_evaluator.material_tooltip.label',
          defaultMessage: 'Material Cost Estimation',
        }),
      },
    ],
    [intl, duration, weight, price],
  );

  const availableItems = evaluationItems.filter((item) => item.label);

  const renderItems = useCallback(
    (items) =>
      items.map((item) => (
        <TooltipItem
          key={item.label}
          tooltipPosition={TOOLTIP_POSITION_BOTTOM_CENTER}
          tooltipInfoRow={{
            label: item.title,
          }}
        >
          <ItemIcon name={item.icon} />
          <ItemLabel>{item.label}</ItemLabel>
        </TooltipItem>
      )),
    [],
  );

  return (
    <Wrapper>
      {renderItems(availableItems)}

      {!isEmpty(info) && (
        <TooltipIconButton
          iconName="info_0"
          tooltipWidth={310}
          tooltipPosition={TOOLTIP_POSITION_BOTTOM_CENTER}
          tooltipInfoRow={{
            label: intl.formatMessage({
              id: 'print_evaluator.info_tooltip.label',
              defaultMessage: 'Toolpath Time Estimation',
            }),
            description: (
              <InfoGrid>
                {info.map((item) => (
                  <InfoRow key={item.label}>
                    <InfoLabel>{item.label}</InfoLabel>
                    <InfoLabel>{item.formattedDuration}</InfoLabel>
                    <InfoLabel>{item.formattedDurationPercentage}</InfoLabel>
                  </InfoRow>
                ))}
              </InfoGrid>
            ),
          }}
        />
      )}
    </Wrapper>
  );
};

PrintEvaluator.propTypes = {
  duration: PropTypes.string,
  weight: PropTypes.string,
  price: PropTypes.string,
  info: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      formattedDuration: PropTypes.string,
      formattedDurationPercentage: PropTypes.string,
    }),
  ),
};

export default PrintEvaluator;
