import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import usePrevious from '@hooks/usePrevious';
import { VisualizationContext } from '@contexts/VisualizationContext';
import GlbModel from '@utils/GlbModel';
import { printerConstants } from '@constants/printers/printerConstants';
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { getShowSafetyCheckResults } from '@selectors/toolBarSelector';
import {
  applyVertexColors,
  applyVertexColorAtIndex,
  useHexToRGBA,
} from '@utils/threeJsUtils';
import { getSafetyChecksMode } from '../../../selectors/conceptSelectors';
import { SAFETY_CHECK_MODES } from '../../../constants/safetyChecks';
import useSafetyCheck from '@hooks/operators/useSafetyCheck';

export default function Scene() {
  const {
    threeState,
    printer,
    simulation,
    objects,
    highlightPrinterComponent,
    restoreOriginalPrinterComponent,
  } = useContext(VisualizationContext);

  const theme = useTheme();
  const highlightColor = useHexToRGBA(theme.colors.fixedError);
  const normalColor = useHexToRGBA(theme.colors.secondary);
  const safetyCheckResults = useSelector(getShowSafetyCheckResults);
  const safetyChecksMode = useSelector(getSafetyChecksMode);
  const { getSafetyCheckPassed } = useSafetyCheck();

  const [lastUpdatedPoint, setLastUpdatedPoint] = useState({
    layer: 0,
    index: 0,
  });
  const [hasEncounteredErrors, setHasEncounteredErrors] = useState(false);

  const previousState = usePrevious({
    objects,
    simulation,
    printer,
    safetyCheckResults,
    safetyChecksMode,
  });

  /**
   * 1D array containing the equivalent simulation step index for the start
   * of each polyline of the printing object. i.e. if print object is:
   * [
   *    line1: [x1, y1, z1, x2, y2, z2, ..., x20, y20, z20],
   *    line2: [x1, y1, z1, x2, y2, z2, ..., x25, y25, z25],
   *    line3: [x1, y1, z1, x2, y2, z2, ..., x10, y10, z10],
   * ]
   * this array would be:
   * [0, 20, 45, 55]
   */
  const cumulativeCount = useMemo(() => {
    if (!simulation.printingObject) return [];
    const cumulative = [0];
    let acc = 0;

    for (const child of simulation.printingObject.scene.children) {
      const { count } = child.geometry.attributes.position;
      acc += count;
      cumulative.push(acc);
    }

    return cumulative;
  }, [simulation.printingObject]);

  /**
   * Maps the 1D array index, printerStepIndex to the equivalent 2D array index of
   * the print object (lines + vertex) using the cached value for the cumulative count.
   * @param {*} printerStepIndex
   * @param {*} cumulativeCount
   * @returns
   */
  function mapPrinterStepIndexToPolylineIndex(
    printerStepIndex,
    cumulativeCount,
  ) {
    let left = 0;
    let right = cumulativeCount.length - 1;

    while (left <= right) {
      const mid = Math.floor((left + right) / 2);

      if (
        cumulativeCount[mid] <= printerStepIndex &&
        printerStepIndex < cumulativeCount[mid + 1]
      ) {
        return {
          polylineIndex: mid,
          vertexIndex: printerStepIndex - cumulativeCount[mid],
        };
      } else if (printerStepIndex < cumulativeCount[mid]) {
        right = mid - 1;
      } else {
        left = mid + 1;
      }
    }

    return null;
  }

  /**
   * Returns an object containing the relevant simulation data, i.e. the current
   * step, the time of the simulation that has passed, etc.
   * @param {*} simulation
   * @returns
   */
  const getSimulationDataObject = useCallback((simulation) => {
    if (simulation.printerSteps.length == 0) return;
    const simulationCurrentStepIndex = simulation.step;
    const simulationPreviousStepIndex =
      simulationCurrentStepIndex > 0 ? simulationCurrentStepIndex - 1 : 0;
    const simulationPreviousStepTime =
      simulationPreviousStepIndex == 0
        ? 0
        : simulation.printerSteps[simulationPreviousStepIndex].movement.time;
    const simulationCurrentStepPassedTime =
      simulation.time - simulationPreviousStepTime;
    const simulationStepRatio =
      simulationCurrentStepPassedTime != 0
        ? simulationCurrentStepPassedTime /
          (simulation.printerSteps[simulationCurrentStepIndex].movement.time -
            simulationPreviousStepTime)
        : 0;

    const simulationCurrentStep =
      simulation.printerSteps[simulationCurrentStepIndex];
    const simulationPreviousStep =
      simulation.printerSteps[simulationPreviousStepIndex];

    return {
      currentStepIndex: simulationCurrentStepIndex,
      previousStepIndex: simulationPreviousStepIndex,
      currentStepPassedTime: simulationCurrentStepPassedTime,
      stepRatio: simulationStepRatio,
      currentStep: simulationCurrentStep,
      previousStep: simulationPreviousStep,
    };
  }, []);

  const calculateLastPointPositionBasedOnRatio = useCallback(
    (
      inLayerPointXIdx,
      printingObjectCoordinates,
      layerIdx,
      simulationStepRatio,
    ) => {
      // 'coordinates' is one big array which is containing xyz values sequentially.
      // that's why we need to multiply by 3 and append 0 (for X), 1 (for Y) and 2 (for Z)
      // to finding corresponding coordinate position for that line
      const indexCur = inLayerPointXIdx;
      const indexCurX = indexCur * 3;
      const indexCurY = indexCur * 3 + 1;
      const indexCurZ = indexCur * 3 + 2;
      const valueCurX = printingObjectCoordinates[layerIdx][indexCurX];
      const valueCurY = printingObjectCoordinates[layerIdx][indexCurY];
      const valueCurZ = printingObjectCoordinates[layerIdx][indexCurZ];

      const indexPrev = indexCur - 1;
      const indexPrevX = indexPrev * 3;
      const indexPrevY = indexPrev * 3 + 1;
      const indexPrevZ = indexPrev * 3 + 2;
      const previousPointLayerIndex =
        indexCur < indexPrev ? layerIdx - 1 : layerIdx;
      let valuePrevX =
        printingObjectCoordinates[previousPointLayerIndex][indexPrevX];
      let valuePrevY =
        printingObjectCoordinates[previousPointLayerIndex][indexPrevY];
      let valuePrevZ =
        printingObjectCoordinates[previousPointLayerIndex][indexPrevZ];

      // if this is the first line
      if (valuePrevX === undefined) {
        valuePrevX = valueCurX;
        valuePrevY = valueCurY;
        valuePrevZ = valueCurZ;
      }

      // calculate how much line should we append to previous line to visualize
      // printing object partially
      const deltaX = (valueCurX - valuePrevX) * simulationStepRatio;
      const deltaY = (valueCurY - valuePrevY) * simulationStepRatio;
      const deltaZ = (valueCurZ - valuePrevZ) * simulationStepRatio;

      const valueNewX = valuePrevX + deltaX;
      const valueNewY = valuePrevY + deltaY;
      const valueNewZ = valuePrevZ + deltaZ;

      return {
        valueNewX: valueNewX,
        valueNewY: valueNewY,
        valueNewZ: valueNewZ,
        indexCurX: indexCurX,
        indexCurY: indexCurY,
        indexCurZ: indexCurZ,
      };
    },
    [],
  );

  const shouldHighlightPrintObject = useCallback(
    (safetyCheckPassed, simulationData) => {
      if (
        safetyCheckResults &&
        !safetyCheckPassed &&
        simulationData?.currentStep?.errors?.length > 0
      ) {
        for (const error of simulationData.currentStep.errors) {
          if (
            error.type === 'CollisionDetectionFailure' &&
            safetyChecksMode === SAFETY_CHECK_MODES.COLLISION_DETECTION
          ) {
            if (
              error.type1 === 'WORK_OBJECT' ||
              error.type2 === 'WORK_OBJECT'
            ) {
              return true;
            }
          }
        }
      }
      return false;
    },
    [safetyCheckResults, safetyChecksMode],
  );

  /**
   * It renders the printObject until the point with index simulationCurrentStepIndex
   * @param {*} printObject
   * @param {*} simulationData
   * @returns an object containing these infos:
   *  {
   *    layerMeshContainingSimulationIdx= the buffergeometry containing the specified point
   *    inLayerPointXIdx= the point idx relative to the container layer
   *    layerIdx= idx of the layerMeshContainingSimulationIdx
   *  }
   */
  const renderObjectUntilStep = useCallback(
    (printObject, simulationData) => {
      const safetyCheckPassed = getSafetyCheckPassed();
      const simulationCurrentStepIndex = simulationData.currentStepIndex;
      let currentPointIdx = 0;
      let layerMeshContainingSimulationIdx = null;
      let inLayerPointXIdx = 0;
      let layerIdx = 0;
      const highlight = shouldHighlightPrintObject(
        safetyCheckPassed,
        simulationData,
      );

      for (const child of printObject.children) {
        if (
          !safetyCheckPassed &&
          safetyChecksMode === SAFETY_CHECK_MODES.COLLISION_DETECTION
        ) {
          applyVertexColors(
            child.geometry,
            highlightColor,
            normalColor,
            highlight,
            !safetyCheckResults,
          );
        }

        const currentLayerPoints =
          child.geometry.getAttribute('position').count;
        if (
          currentPointIdx + currentLayerPoints <=
          simulationCurrentStepIndex - 1
        ) {
          layerIdx++;
          currentPointIdx += currentLayerPoints;
          child.geometry.setDrawRange(0, Infinity);
        } else {
          if (layerMeshContainingSimulationIdx != null) {
            child.geometry.setDrawRange(0, 0);
          } else {
            inLayerPointXIdx = simulationCurrentStepIndex - currentPointIdx;
            child.geometry.setDrawRange(0, inLayerPointXIdx + 1);
            layerMeshContainingSimulationIdx = child;
            currentPointIdx += simulationCurrentStepIndex;
          }
        }

        if (child.userData.name.startsWith('simulation-travel-line')) {
          child.visible = simulation.showTravelLine;
        }
      }

      return {
        layerMeshContainingSimulationIdx: layerMeshContainingSimulationIdx,
        inLayerPointXIdx: inLayerPointXIdx,
        layerIdx: layerIdx,
      };
    },
    [
      shouldHighlightPrintObject,
      getSafetyCheckPassed,
      safetyChecksMode,
      highlightColor,
      normalColor,
      safetyCheckResults,
      simulation.showTravelLine,
    ],
  );
  const restorePointOriginalPosition = useCallback(
    (printObject, printingObjectCoordinates) => {
      if (!printingObjectCoordinates) {
        // eslint-disable-next-line
        console.error('printingObjectCoordinates is undefined');
        return;
      }

      const { layer, index } = lastUpdatedPoint;

      const layerData = printingObjectCoordinates[layer];
      if (!layerData) {
        // eslint-disable-next-line
        console.error(
          'layerData is undefined for layer',
          layer,
          ' printingObjectCoordinates:',
          printingObjectCoordinates,
        );
        return;
      }

      const previousMesh = printObject.children[layer];

      const indexX = index;
      const indexY = indexX + 1;
      const indexZ = indexX + 2;
      const valueX = printingObjectCoordinates[layer][indexX];
      const valueY = printingObjectCoordinates[layer][indexY];
      const valueZ = printingObjectCoordinates[layer][indexZ];

      previousMesh.geometry.attributes.position.array[indexX] = valueX;
      previousMesh.geometry.attributes.position.array[indexY] = valueY;
      previousMesh.geometry.attributes.position.array[indexZ] = valueZ;

      previousMesh.geometry.attributes.position.needsUpdate = true;
      previousMesh.geometry.computeBoundingBox();
      previousMesh.geometry.computeBoundingSphere();
    },
    [lastUpdatedPoint],
  );

  /**
   * Handles errors encountered during the simulation.
   *
   * - If the current simulation step contains errors:
   *   - Sets `hasEncounteredErrors` to `true`
   *   - Highlights the printer components involved in a collision error
   * - If there are no errors but `hasEncounteredErrors` was previously `true`:
   *   - Restores the original appearance of printer components
   *   - Resets `hasEncounteredErrors` to `false`
   *
   * This ensures that errors are properly visualized and removed when they are no longer present.
   *
   * @param {Object} simulationData - The data object representing the current state of the simulation.
   */
  const handleSimulationErrors = useCallback(
    (simulationData) => {
      if (
        safetyCheckResults &&
        safetyChecksMode === SAFETY_CHECK_MODES.COLLISION_DETECTION &&
        simulationData?.currentStep?.errors?.length > 0
      ) {
        setHasEncounteredErrors(true);
        for (const error of simulationData.currentStep.errors) {
          if (error.type === 'CollisionDetectionFailure') {
            highlightPrinterComponent(error.type1, error.id1);
            highlightPrinterComponent(error.type2, error.id2);
          }
        }
      } else if (hasEncounteredErrors || !safetyCheckResults) {
        restoreOriginalPrinterComponent();
        setHasEncounteredErrors(false);
      }
    },
    [
      safetyCheckResults,
      safetyChecksMode,
      highlightPrinterComponent,
      restoreOriginalPrinterComponent,
      hasEncounteredErrors,
    ],
  );

  /*
   * It renders the printObject based on the simulationCurrentStepIndex and the simulationStepRatio.
   * The number of simulation.steps must always match the number of points in printingObjectCoordinates.
   * The printingObjectCoordinates array is precisely the number of points * 3 because each point is composed by
   * x, y and z stored as sequential numbers in the array.
   * The printingObjectCoordinates array is divided into layer, each layer is a separate buffergeometry.
   *
   * @param {THREE.Object3D} printObject print object to be simulated printing
   * @param {integer} simulationCurrentStepIndex
   * @param {float} simulationStepRatio the percentage (from 0 to 1) of the last line to be rendered.
   *  The last line is defined as between the simulationCurrentStepIndex and simulationCurrentStepIndex-1
   */
  const renderPrintObject = useCallback(
    (printObject, simulationData) => {
      if (!simulationData.currentStep) return;
      handleSimulationErrors(simulationData);

      const printingObjectCoordinates = simulation.printingObjectCoordinates;

      const { layerMeshContainingSimulationIdx, inLayerPointXIdx, layerIdx } =
        renderObjectUntilStep(printObject, simulationData);

      const {
        valueNewX,
        valueNewY,
        valueNewZ,
        indexCurX,
        indexCurY,
        indexCurZ,
      } = calculateLastPointPositionBasedOnRatio(
        inLayerPointXIdx,
        printingObjectCoordinates,
        layerIdx,
        simulationData.stepRatio,
      );

      // If users choose another simulation, they don't have to restore points.
      // It will cause crash in case previous simulation's last played time is
      //    longer than current simulation's duration.
      if (previousState?.simulation.printingObject != null) {
        restorePointOriginalPosition(printObject, printingObjectCoordinates);
      }

      setLastUpdatedPoint({ layer: layerIdx, index: indexCurX });

      //Update last point position
      layerMeshContainingSimulationIdx.geometry.attributes.position.array[
        indexCurX
      ] = valueNewX;
      layerMeshContainingSimulationIdx.geometry.attributes.position.array[
        indexCurY
      ] = valueNewY;
      layerMeshContainingSimulationIdx.geometry.attributes.position.array[
        indexCurZ
      ] = valueNewZ;

      layerMeshContainingSimulationIdx.geometry.attributes.position.needsUpdate = true;
      layerMeshContainingSimulationIdx.geometry.computeBoundingBox();
      layerMeshContainingSimulationIdx.geometry.computeBoundingSphere();
    },
    [
      handleSimulationErrors,
      calculateLastPointPositionBasedOnRatio,
      previousState?.simulation?.printingObject,
      simulation?.printingObjectCoordinates,
      renderObjectUntilStep,
      restorePointOriginalPosition,
      setLastUpdatedPoint,
    ],
  );

  const shouldUpdateObjects = useCallback(
    (currentObjects, nextObjects) => {
      let currentDigest = 1;
      let nextDigest = 1;

      if (printer?.uuid != previousState?.printer?.uuid) return true;

      Object.values(currentObjects.objects).forEach((value) => {
        if (value?.id) currentDigest *= value.id;
        if (value instanceof GlbModel) {
          currentDigest *= value.getHashCode();
        }
      });

      if (currentObjects.simulation) {
        currentDigest *= currentObjects.simulation.time + 1;
        currentDigest *= currentObjects.simulation.step + 1;
        currentDigest *= currentObjects.simulation.isActive + 1;
        currentDigest *= currentObjects.simulation.showTravelLine + 1;
      }

      Object.values(nextObjects.objects).forEach((value) => {
        if (value?.id) nextDigest *= value.id;
        if (value instanceof GlbModel) {
          nextDigest *= value.getHashCode();
        }
      });

      if (nextObjects.simulation) {
        nextDigest *= nextObjects.simulation.time + 1;
        nextDigest *= nextObjects.simulation.step + 1;
        nextDigest *= nextObjects.simulation.isActive + 1;
        nextDigest *= nextObjects.simulation.showTravelLine + 1;
      }

      return currentDigest !== nextDigest;
    },
    [previousState?.printer?.uuid, printer?.uuid],
  );

  useEffect(() => {
    if (!simulation.printingObject) {
      return;
    }

    if (!(safetyChecksMode === SAFETY_CHECK_MODES.REACH_LIMITS)) {
      return;
    }

    const printObject = simulation.printingObject.scene;

    simulation.printerSteps.forEach((step, i) => {
      const { polylineIndex, vertexIndex } = mapPrinterStepIndexToPolylineIndex(
        i,
        cumulativeCount,
      );
      const reachLimitFailures = step?.errors?.filter(
        (error) => error.type === 'ReachLimitFailure',
      );
      const color = reachLimitFailures?.length ? highlightColor : normalColor;
      applyVertexColorAtIndex(
        printObject.children[polylineIndex].geometry,
        color,
        !safetyCheckResults,
        vertexIndex,
      );
    });
  }, [
    safetyChecksMode,
    safetyCheckResults,
    cumulativeCount,
    getSimulationDataObject,
    highlightColor,
    simulation,
    normalColor,
  ]);

  const observeSimulationUpdates = useCallback(() => {
    if (!threeState) return;

    const objectsNeedsToBeUpdated =
      printer &&
      simulation?.isActive &&
      (shouldUpdateObjects({ objects, simulation }, previousState) ||
        safetyCheckResults !== previousState?.safetyCheckResults ||
        safetyChecksMode !== previousState?.safetyChecksMode);

    if (!objectsNeedsToBeUpdated) return;

    const simulationData = getSimulationDataObject(simulation);

    const printingObject = simulation?.printingObject?.scene;

    if (!printingObject) return;

    //-1 reflection in x is needed for some reason
    printingObject.scale.set(-1, 1, 1);
    printingObject.name = printerConstants.printingObject;

    const isPrintingObjectChanged =
      previousState?.simulation?.printingObject?.scene?.uuid !=
      printingObject?.uuid;

    if (isPrintingObjectChanged) {
      //it avoids to reuse the old-model indexes with a new 3d model
      setLastUpdatedPoint({ layer: 0, index: 0 });
    }

    const { bed } = printer;

    //Demo machines do not have a bed
    const base = bed ? bed.base : null;
    const currentPrintingObject = threeState.scene.getObjectByName(
      printerConstants.printingObject,
    );
    const isPrintingObjectExist = currentPrintingObject != null;

    if (base) {
      if (!isPrintingObjectExist) {
        base.add(printingObject);
        printer.printingObject = printingObject;
      } else if (printingObject.uuid !== currentPrintingObject.uuid) {
        //is there a memory leak if dispose is not used?
        base.remove(currentPrintingObject);
        base.add(printingObject);
        printer.printingObject = printingObject;
      }
    }

    printer.simulate(simulationData, safetyCheckResults && safetyChecksMode);

    renderPrintObject(printingObject, simulationData);
  }, [
    safetyCheckResults,
    safetyChecksMode,
    threeState,
    objects,
    getSimulationDataObject,
    previousState,
    printer,
    renderPrintObject,
    shouldUpdateObjects,
    simulation,
  ]);

  useEffect(() => {
    observeSimulationUpdates();
  }, [observeSimulationUpdates]);

  return null;
}

Scene.propTypes = {};
