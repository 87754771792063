import React, { useEffect, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isBoolean } from 'lodash';
import { getEventsWebSocket } from '@selectors/websocketSelectors';
import useAuthQueries from '@hooks/auth/useAuthQueries';
import useApplication from '@hooks/application/useApplication';
import PageLoader from '@components/1-atoms/PageLoader';
import {
  tokenAuthenticationSuccess,
  logOut as importedLogout,
  expireToken,
  logInSuccess,
  tokenAuthentication,
} from '@actions/loginActions';
import { addToLocalStorage, removeFromLocalStorage } from '@utils/localStorage';
import { removeFromSessionStorage } from '@utils/sessionStorage';
import { ROUTES } from '@constants/router';
import {
  CURRENT_USER_KEY_LOCAL_STORAGE,
  USER_SESSION_PREFERENCES_KEY,
} from '@constants/utilityConstants';
import { Wrapper } from './TokenAuthenticator.styled';

export default function AuthChecker() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { cleanQueryClient } = useApplication();
  const WS = useSelector(getEventsWebSocket());
  const { licenseStatusQuery } = useAuthQueries();

  const { data: user } = licenseStatusQuery;
  const refetchLicenseStatusQuery = licenseStatusQuery.refetch;

  const isLicenseChecked = !!user;
  const isLicenseValid = isBoolean(user?.licenseValid) && user?.licenseValid;
  const isUserLicenseExpired =
    DESKTOP_APP && isLicenseChecked && !isLicenseValid;

  const isTokenValid = !user?.tokenExpired && !isUserLicenseExpired;

  const proceedWithNavigation = useCallback(() => {
    history?.replace(location?.pathname + history.location.search);
  }, [history, location?.pathname]);

  const logOut = useCallback(() => {
    dispatch(importedLogout());
    dispatch(expireToken());
    removeFromLocalStorage(CURRENT_USER_KEY_LOCAL_STORAGE);
    removeFromSessionStorage(`${USER_SESSION_PREFERENCES_KEY}.${user?.id}`);

    if (!DESKTOP_APP) {
      cleanQueryClient();
    }

    WS?.close();

    history.push(ROUTES.LOGIN, {
      tokenExpired: true,
    });
  }, [dispatch, user?.id, WS, cleanQueryClient, history]);

  useEffect(() => {
    if (DESKTOP_APP) {
      refetchLicenseStatusQuery();
    }
  }, [refetchLicenseStatusQuery]);

  useEffect(() => {
    if (DESKTOP_APP && !isLicenseChecked) {
      return;
    }
    if (!isTokenValid) {
      logOut();

      return;
    }

    if (isTokenValid) {
      if (DESKTOP_APP && user) {
        addToLocalStorage(CURRENT_USER_KEY_LOCAL_STORAGE, user);
        dispatch(tokenAuthenticationSuccess());
        dispatch(logInSuccess(user));
        proceedWithNavigation?.();
      } else {
        dispatch(tokenAuthentication(proceedWithNavigation));
      }
    }
  }, [
    dispatch,
    isUserLicenseExpired,
    isTokenValid,
    user,
    logOut,
    proceedWithNavigation,
    isLicenseChecked,
  ]);

  return (
    <Wrapper>
      <PageLoader show />
    </Wrapper>
  );
}
