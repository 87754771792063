import { showErrorDialog, manageError } from '../actions/errorActions.js';

import Config_dev from '../../config/dev';
import Config_test from '../../config/test';
import Config_prod from '../../config/production';
import Config_onpremise from '../../config/onpremise';
import Config_ephemeral from '../../config/ephemeral';
import desktopConfig from '../../config/desktop';

import { expireToken } from '../actions/loginActions.js';
import {
  TOKEN_EXPIRED_REASON_CODE,
  CURRENT_USER_KEY_LOCAL_STORAGE,
} from '../constants/utilityConstants.js';
import { isStringBooleanIsTrue } from '@utils/dom';
import { DELETE, GET, PATCH, POST, PUT } from '../constants/fetchMethods.js';
import { getFromLocalStorage } from '@utils/localStorage';
import { generateErrorTitle, generateErrorMessage } from '@utils/error';
/* options object
 * {
 *   url:
 *   method:
 *   body:
 *   prefetchAction:
 *   successAction:
 *   failureAction:
 *   disableErrorDialog: (optional)
 *   throwExceptionOnFailure: (optional)
 * }
 */

export const AuthenticatedFetch = (options) => (dispatch) => {
  const currentUser = getFromLocalStorage(CURRENT_USER_KEY_LOCAL_STORAGE);
  if (!currentUser) {
    if (DESKTOP_APP) {
      return dispatch(
        showErrorDialog(
          'Error',
          'Something went wrong, please restart the application',
        ),
      );
    }
    throw new Error({
      code: 500,
      message: 'something went wrong...',
    });
  }
  const token = currentUser.token;

  if (!isOptionsValid(options)) {
    return dispatch(
      showErrorDialog(
        'Error',
        'Invalid HTTP request options, please contact the administrator',
      ),
    );
  }
  const Config = getConfig();
  const apiServerUrl = DESKTOP_APP ? window.apiServerUrl : Config.baseUrl;
  const baseUrl = apiServerUrl || 'http://localhost/api/';

  const headers = new Headers();
  headers.append('Authorization', `Bearer ${token}`);
  headers.append('content-type', 'application/json');

  const fetchOptions = {
    method: options.method,
    headers: headers,
    body: options.body,
    credentials: 'include',
  };
  let url = options.queryParams
    ? `${options.url}?${queryParamString(options.queryParams)}`
    : options.url;

  url = baseUrl + url;

  if (options.prefetchAction) {
    dispatch(options.prefetchAction);
  }
  return fetch(url, fetchOptions)
    .catch(() => {
      if (options.failureAction) dispatch(options.failureAction(null));
      if (options.failureCallback) options.failureCallback(null);

      dispatch(showErrorDialog(generateErrorTitle(), generateErrorMessage()));
      throw new Error({
        code: 500,
        message: 'something went wrong...',
      });
    })
    .then((response) =>
      response.text().then((text) => {
        const json = jsonIfPresent(text);
        if (isExpectedResponseCode(response.status, options.method)) {
          if (options.successAction) dispatch(options.successAction(json));
          if (options.successCallback) {
            options.successCallback(json, options.successCallBackParams);
          }
          return json;
        }

        if (
          response.status === 401 &&
          json.error.internalCode === TOKEN_EXPIRED_REASON_CODE
        ) {
          return dispatch(expireToken());
        }

        if (options.failureAction) dispatch(options.failureAction(json));

        if (options.failureCallback) options.failureCallback(json);

        if (json && (json.error || json.errors)) {
          if (options.disableErrorDialog) {
            throw json.error;
          } else {
            if (json.error) {
              dispatch(manageError(json.error));
            } else {
              const errorsConcat = json.errors.join('\n');
              dispatch(showErrorDialog('Error', errorsConcat));
            }
          }
        }
        return json || response;
      }),
    );
};
export function getConfig() {
  if (DESKTOP_APP) {
    return desktopConfig;
  }
  if (TEST_ENV) {
    return Config_test;
  } else if (ONPREMISE_ENV) {
    return Config_onpremise;
  } else if (PROD_ENV) {
    return Config_prod;
  } else if (EPHEMERAL_ENV) {
    return Config_ephemeral;
  } else {
    const devConfig = Config_dev;

    try {
      if (isStringBooleanIsTrue(process?.env?.REACT_APP_USE_TEST_ENV_SERVER)) {
        devConfig.baseUrl = Config_test.baseUrl;
        devConfig.baseUrlws = Config_test.baseUrlws;
      }
    } catch (_) {
      // Error handling
    }

    return Config_dev;
  }
}

const queryParamString = (queryParams) =>
  Object.keys(queryParams)
    .map(
      (k) =>
        `${encodeURIComponent(k)}=${encodeURIComponent(queryParams[k] || '')}`,
    )
    .join('&');

const jsonIfPresent = (str) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return null;
  }
};

const isExpectedResponseCode = (code, method) => {
  switch (method) {
    case GET:
    case DELETE:
    case PATCH:
      return code === 200;
    case POST:
    case PUT:
      return code === 200 || code === 201;
    default:
      return false;
  }
};

const isOptionsValid = (options) => {
  let isValid = true;
  if (!options.url) {
    // eslint-disable-next-line no-console
    console.error("required option 'url' missing");
    isValid = false;
  }
  if (!options.method) {
    // eslint-disable-next-line no-console
    console.error("required option 'method' missing");
    isValid = false;
  }
  return isValid;
};
