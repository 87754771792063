export const FULL_SCREEN_OPERATOR_FEATURE = {
  flagName: 'full_screen_operator',
  defaultValue: false,
};

export const AUTO_COMPUTE_ON_OPERATOR_INPUT_SELECTION = {
  flagName: 'auto_compute_on_operator_input_selection',
  defaultValue: false,
};

export const LIBRARY_EXAMPLES = {
  flagName: 'library_examples',
  defaultValue: false,
};

export const COMPUTATION_WAITING_TIME = {
  flagName: 'computation_waiting_time',
  defaultValue: false,
};

export const CLIPPING_TOOL = {
  flagName: 'clipping_tool',
  defaultValue: true,
};

export const QUICK_START = {
  flagName: 'quick_start',
  defaultValue: false,
};

export const TALK_TO_AI = {
  flagName: 'talk_to_ai',
  defaultValue: false,
};

export const OPERATOR_CATEGORIES = {
  flagName: 'operator_categories',
  defaultValue: true,
};

export const KNOWLEDGE_CENTRE = {
  flagName: 'knowledge_centre',
  defaultValue: false,
};

export const TOOL_LIBRARY_FEATURE = {
  flagName: 'tool_library',
  defaultValue: false,
};

export const SEND_WORKFLOW_SENDER_FLOW_FEATURE = {
  flagName: 'send_workflow_sender_flow',
  defaultValue: false,
};

export const DOWNLOAD_STREAM_TOOLPATH_SAFETY_CHECK_FEATURE = {
  flagName: 'toolpath-download-safety-check-toggle',
  defaultValue: false,
};

export const SEND_WORKFLOW_RECEIVER_FLOW_FEATURE = {
  flagName: 'send_workflow_receiver_flow',
  defaultValue: false,
};

export const PRINTER_MONITOR_FEATURE = {
  flagName: 'printer_monitor',
  defaultValue: false,
};

export const DATADOG_RUM_ENABLED = {
  flagName: 'datadog_rum_enabled',
  defaultValue: false,
};

export const DATADOG_SESSION_REPLAY_ENABLED = {
  flagName: 'datadog_session_replay_enabled',
  defaultValue: false,
};

export const CUSTOM_AIB_DATA = {
  flagName: 'custom_aib_data',
  defaultValue: false,
};

export const PRINT_EVALUATOR = {
  flagName: 'print_evaluator',
  defaultValue: true,
};
