import * as THREE from 'three';

/**
 * Represents a visualisable element of the printer system, containing the printer
 * settings of the printer, along with default settings for that type of printer.
 */
export default class PrinterComponent extends THREE.Group {
  constructor(printerSettings, machineDefaults, name) {
    super();
    this.printerSettings = printerSettings;
    this.machineDefaults = machineDefaults;
    this.name = name;
    //currently, the machine type is what determines the defaults
    this.machineType = this.machineDefaults?.machineType;
  }

  /**
   * Sets the color of all mesh materials within this group.
   * @param componentId - The id of the component to color
   * @param color - color
   */
  highlight(componentId, color) {
    this.traverse((child) => {
      if (child.isMesh) {
        if (!componentId || child.name.includes(componentId.toLowerCase())) {
          this.doHighlight(child, color);
        }
      }
    });
  }

  doHighlight(component, color) {
    // Store original color if not already stored
    if (!component.userData.originalColor) {
      component.userData.originalColor = component.material.color.clone();
    }

    // Clone material and set color
    if (component.material) {
      component.material = component.material.clone();
      component.material.color.set(color);
      component.material.needsUpdate = true;
    }
  }

  /**
   * Restores the original color of all mesh materials within this group.
   */
  restoreOriginalColor() {
    this.traverse((child) => {
      if (child.isMesh && child.userData.originalColor) {
        child.material.color.copy(child.userData.originalColor);
        child.material.needsUpdate = true;
      }
    });
  }
}
