import { useMemo } from 'react';

/**
 * Ensures geometry has a stored copy of its original colors. Returns that copy.
 */
function getOriginalColors(geometry) {
  if (!geometry.userData.originalColors) {
    const colorAttr = geometry.getAttribute('color');
    geometry.userData.originalColors = colorAttr.array.slice();
  }
  return geometry.userData.originalColors;
}

/**
 * Sets the color in `colors` at `offset` using `newColor`. Handles RGB or RGBA.
 */
function setColor(colors, offset, newColor, itemSize) {
  // R, G, B
  colors[offset] = newColor[0];
  colors[offset + 1] = newColor[1];
  colors[offset + 2] = newColor[2];

  // A (if RGBA)
  if (itemSize === 4 && newColor.length === 4) {
    colors[offset + 3] = newColor[3];
  }
}

/**
 * Applies or restores the highlight color at a specific vertex index.
 */
export function applyVertexColorAtIndex(
  geometry,
  highlightColor,
  restoreOriginalColors,
  vertexIndex,
) {
  const colorAttr = geometry.getAttribute('color');
  if (!colorAttr) return;

  const colors = colorAttr.array;
  const itemSize = colorAttr.itemSize;
  const originalColors = getOriginalColors(geometry);

  const offset = vertexIndex * itemSize;

  if (restoreOriginalColors) {
    // Restore original
    const originalColorSegment = originalColors.slice(
      offset,
      offset + itemSize,
    );
    setColor(colors, offset, originalColorSegment, itemSize);
  } else {
    // Highlight
    setColor(colors, offset, highlightColor, itemSize);
  }

  colorAttr.needsUpdate = true;
}

/**
 * Applies either the highlight or normal/original color to all (or selected) vertices.
 */
export function applyVertexColors(
  geometry,
  highlightColor,
  normalColor,
  highlight,
  useOriginalColor,
  verticesToHighlight = null,
) {
  const colorAttr = geometry.getAttribute('color');
  if (!colorAttr) return;

  const colors = colorAttr.array;
  const itemSize = colorAttr.itemSize;
  const originalColors = getOriginalColors(geometry);

  const numVertices = colors.length / itemSize;

  for (let i = 0; i < numVertices; i++) {
    const offset = i * itemSize;
    const shouldHighlight =
      highlight && (!verticesToHighlight || verticesToHighlight.includes(i));

    if (shouldHighlight) {
      setColor(colors, offset, highlightColor, itemSize);
    } else if (useOriginalColor) {
      const originalColorSegment = originalColors.slice(
        offset,
        offset + itemSize,
      );
      setColor(colors, offset, originalColorSegment, itemSize);
    } else {
      setColor(colors, offset, normalColor, itemSize);
    }
  }

  colorAttr.needsUpdate = true;
}

/**
 * Converts a hex color (e.g. "#ff0000" or "#f00") to RGBA array: [r, g, b, alpha].
 * Defaults `alpha` to 255 if not provided.
 */
export function useHexToRGBA(hex, alpha = 255) {
  return useMemo(() => {
    // Fallback to [0, 0, 0, alpha] if invalid hex
    if (!hex.startsWith('#') || (hex.length !== 7 && hex.length !== 4)) {
      return [0, 0, 0, alpha];
    }

    let r, g, b;
    if (hex.length === 7) {
      r = parseInt(hex.slice(1, 3), 16);
      g = parseInt(hex.slice(3, 5), 16);
      b = parseInt(hex.slice(5, 7), 16);
    } else {
      // short form #rgb
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
    }

    return [r, g, b, alpha];
  }, [hex, alpha]);
}
