import * as THREE from 'three';
import { Printer } from '../../Printer';
import { printerConstants as constants } from '@constants/printers/printerConstants';
import Machine from '../Machine';
import NumberHelper from '../../NumberHelper';
import { degToRad } from 'three/src/math/MathUtils';
import { machineConstants } from '@constants/printers/machineConstants';
import { s3Directories } from '@constants/printers/s3Directories';
import queryClient from '@app/api/queryClient';
import { getMachineBrand } from '../MachineUtils';
import { printerQueryKeys } from '@app/hooks/printers/usePrinterQueries';

/**
 * Represents a six axis robot object, which is composed of a tree of THREE.js components that are
 * necessary for visualisation of the movement of the machine.
 */
class SixAxisRobot extends Machine {
  constructor(robotType, machineDefinitions, printerSettings) {
    super(
      robotType,
      machineDefinitions,
      printerSettings,
      constants.SixAxisRobot,
    );

    const mountingAngle = (
      this.printerSettings.find(
        (setting) => setting.settingName === constants.toolMountingAngle,
      ) || 0
    ).value;
    this.mountingAngle = degToRad(mountingAngle);

    this.type = machineConstants.sixAxisRobotDiscriminator;
    this.props[machineConstants.typeDiscriminator] =
      machineConstants.sixAxisRobotDiscriminator;
    const brand = getMachineBrand(
      machineDefinitions,
      this.robotType,
      printerSettings,
    );
    this.setPropertiesHomePosition(printerSettings, brand);
    this.setPropertiesAxesLimits(printerSettings);
    this.setPropertiesKinematicValues(printerSettings);
    this.createJointGroups();
  }

  /**
   * Creates THREE.Group objects to contain each individual rotating axis and adds
   * additional groups for rotation (separate groups for rotation and models allows
   * the models to have rotation offsets).
   *
   * Distal axes are added as children in a tree-structure to the proximal axes.
   */
  createJointGroups() {
    for (let partNumber = 0; partNumber <= 6; partNumber++) {
      const partGroup = new THREE.Group();
      partGroup.name = constants.axis + partNumber;
      const pivot = this.generatePivotForPart(partNumber);
      pivot.name = partGroup.name + constants.pivot;
      partGroup.add(pivot);
      this.rotationPoints.push(pivot);
      if (partNumber > 0) {
        const parentPartNumber = partNumber - 1;
        const parentPivot = this.rotationPoints[parentPartNumber];
        parentPivot.add(partGroup);
      } else {
        this.add(partGroup);
      }
      if (partNumber === 6) {
        this.setupFlange(pivot, partGroup);
      }
      this[constants.part + partNumber] = partGroup;
    }
  }

  setupFlange(pivot, partGroup) {
    if (this.props.modelName === constants.CUSTOM.value) {
      this.flange = pivot;
      this.flange.add(this.flangeMounting);
      this.flangeMounting.rotateY(-Math.PI / 2);
      this.tcp.add(new THREE.AxesHelper(10000));
    } else {
      this.flange = partGroup;
      this.flange.add(this.flangeMounting);
      this.flangeMounting.rotation.set(this.mountingAngle, 0, 0);
      //Yaskawa robots have flange coordinate system defined differently to other brands, x is up.
      if (
        this.machineDefaults.brand === 'Yaskawa' ||
        this.machineDefaults.brand === 'Fanuc'
      ) {
        this.tcp.rotation.set(0, Math.PI / 2, Math.PI);
      } else {
        this.tcp.rotation.set(0, Math.PI / 2, 0);
      }
    }
    this.flange.add(this.tcp);
  }

  /**
   * Updates the 'home' properties of this object based on the values
   * provided in the 'printerSettings' object. If these values are not available,
   * default values are used.
   *
   * @param {Object} printerSettings - The settings for the printer, which contains
   *                                   a 'machine' property which should define a Robot.
   */
  setPropertiesHomePosition(printerSettings, brand) {
    this.props.homeA = Machine.getMachineSettingValueOrDefault(
      printerSettings,
      constants.homeA,
      this.getDefaultHomeValue(brand, 0),
    );

    this.props.homeB = Machine.getMachineSettingValueOrDefault(
      printerSettings,
      constants.homeB,
      this.getDefaultHomeValue(brand, 1),
    );

    this.props.homeC = Machine.getMachineSettingValueOrDefault(
      printerSettings,
      constants.homeC,
      this.getDefaultHomeValue(brand, 2),
    );

    this.props.homeD = Machine.getMachineSettingValueOrDefault(
      printerSettings,
      constants.homeD,
      this.getDefaultHomeValue(brand, 3),
    );

    this.props.homeE = Machine.getMachineSettingValueOrDefault(
      printerSettings,
      constants.homeE,
      this.getDefaultHomeValue(brand, 4),
    );

    this.props.homeF = Machine.getMachineSettingValueOrDefault(
      printerSettings,
      constants.homeF,
      this.getDefaultHomeValue(brand, 5),
    );
  }

  /**
   * Returns the default value for the robot's specified axis through a combination of
   * default home position and home position corrections, which are both defined per machine.
   * @param {Num} axis axis number between 0 and 5 inclusive, representing axes 1-6
   * @returns
   */
  getDefaultHomeValue = (brand, axis) => {
    const robotBrandDefinitions = queryClient.getQueryData(
      printerQueryKeys.robotBrandDefinitions,
    );
    const brandDefintion = robotBrandDefinitions[brand];
    if (!brandDefintion) {
      throw new Error(
        `No robot brand definition found for ${brand} and robot type ${this.robotType}`,
      );
    }
    const { defaultHomePosition } = brandDefintion;
    return defaultHomePosition[axis];
  };

  /**
   * Initializes the axes properties based on the supplied printerSettings object that
   * was created from the backend. If printerSettings is empty then defaults are used.
   * @param {} printerSettings
   */
  setPropertiesAxesLimits(printerSettings) {
    this.props.axisMin1 = Machine.getMachineSettingValueOrDefault(
      printerSettings,
      constants.axisMin1,
      -360,
    );
    this.props.axisMin2 = Machine.getMachineSettingValueOrDefault(
      printerSettings,
      constants.axisMin2,
      -360,
    );
    this.props.axisMin3 = Machine.getMachineSettingValueOrDefault(
      printerSettings,
      constants.axisMin3,
      -360,
    );
    this.props.axisMin4 = Machine.getMachineSettingValueOrDefault(
      printerSettings,
      constants.axisMin4,
      -360,
    );
    this.props.axisMin5 = Machine.getMachineSettingValueOrDefault(
      printerSettings,
      constants.axisMin5,
      -360,
    );
    this.props.axisMin6 = Machine.getMachineSettingValueOrDefault(
      printerSettings,
      constants.axisMin6,
      -360,
    );
    this.definition = {
      ...this.definition,
      axisMin6: this.props.axisMin6,
    };
    this.props.axisMax1 = Machine.getMachineSettingValueOrDefault(
      printerSettings,
      constants.axisMax1,
      360,
    );
    this.props.axisMax2 = Machine.getMachineSettingValueOrDefault(
      printerSettings,
      constants.axisMax2,
      360,
    );
    this.props.axisMax3 = Machine.getMachineSettingValueOrDefault(
      printerSettings,
      constants.axisMax3,
      360,
    );
    this.props.axisMax4 = Machine.getMachineSettingValueOrDefault(
      printerSettings,
      constants.axisMax4,
      360,
    );
    this.props.axisMax5 = Machine.getMachineSettingValueOrDefault(
      printerSettings,
      constants.axisMax5,
      360,
    );
    this.props.axisMax6 = Machine.getMachineSettingValueOrDefault(
      printerSettings,
      constants.axisMax6,
      360,
    );
  }

  /**
   * Initializes the kinematic properties of the robot based on the definitions file.
   */
  setPropertiesKinematicValues(printerSettings) {
    this.props.axisA1 = Machine.getMachineSettingValueOrDefault(
      printerSettings,
      //we need to override this with defaults
      constants.axisA1,
      this.machineDefaults.pivots.a[0],
    );
    this.props.axisA2 = Machine.getMachineSettingValueOrDefault(
      printerSettings,
      constants.axisA2,
      this.machineDefaults.pivots.a[1],
    );
    this.props.axisA3 = Machine.getMachineSettingValueOrDefault(
      printerSettings,
      constants.axisA3,
      this.machineDefaults.pivots.a[2],
    );
    this.props.axisA4 = Machine.getMachineSettingValueOrDefault(
      printerSettings,
      constants.axisA4,
      this.machineDefaults.pivots.a[3],
    );
    this.props.axisA5 = Machine.getMachineSettingValueOrDefault(
      printerSettings,
      constants.axisA5,
      this.machineDefaults.pivots.a[4],
    );
    this.props.axisA6 = Machine.getMachineSettingValueOrDefault(
      printerSettings,
      constants.axisA6,
      this.machineDefaults.pivots.a[5],
    );

    this.props.axisD1 = Machine.getMachineSettingValueOrDefault(
      printerSettings,
      constants.axisD1,
      this.machineDefaults.pivots.d[0],
    );
    this.props.axisD2 = Machine.getMachineSettingValueOrDefault(
      printerSettings,
      constants.axisD2,
      this.machineDefaults.pivots.d[1],
    );
    this.props.axisD3 = Machine.getMachineSettingValueOrDefault(
      printerSettings,
      constants.axisD3,
      this.machineDefaults.pivots.d[2],
    );
    this.props.axisD4 = Machine.getMachineSettingValueOrDefault(
      printerSettings,
      constants.axisD4,
      this.machineDefaults.pivots.d[3],
    );
    this.props.axisD5 = Machine.getMachineSettingValueOrDefault(
      printerSettings,
      constants.axisD5,
      this.machineDefaults.pivots.d[4],
    );
    this.props.axisD6 = Machine.getMachineSettingValueOrDefault(
      printerSettings,
      constants.axisD6,
      this.machineDefaults.pivots.d[5],
    );
  }

  /**
   * Moves the individual groups that the robot link models are attached to based
   * on the simulation data that is provided
   * @param {} simulationData object containing the simulation data
   * @returns
   */
  simulate(simulationData) {
    if (!this.visible || this.isOutOfReach(simulationData)) return;
    for (let j = 1; j <= 6; j++) {
      this.simulateAxis(simulationData, j);
    }
  }

  /**
   * If the kinematics solver failed due to reach, it will have resulted in not-a-number
   * for joint 2.
   * @param {*} simulationData
   * @returns
   */
  isOutOfReach(simulationData) {
    const jointName = 'jointAngle2';
    return simulationData.currentStep.movement[jointName] === 'NaN';
  }

  /**
   * Simulates the single target axis of the robot by moving it to the position
   * defined within the simulation data object.
   * @param {Object} simulationData object containing the simulation data
   * @param {Integer} axisNumber target axis number to move
   */
  simulateAxis(simulationData, axisNumber) {
    const jointName = `jointAngle${axisNumber}`;
    if (NumberHelper.isFloat(simulationData.currentStep.movement[jointName])) {
      const currentAngle = parseFloat(
        simulationData.currentStep.movement[jointName],
      );
      let prevAngle = parseFloat(
        simulationData.previousStep.movement[jointName],
      );
      if (simulationData.currentStepIndex == 0) {
        prevAngle = this.getHomeAngle(axisNumber);
      }
      // calculate how much angle should we apply to each axis to move partially
      const angle =
        prevAngle + (currentAngle - prevAngle) * simulationData.stepRatio;
      if (angle.toString() != 'NaN') {
        this.setAngleOfAxis(axisNumber, angle, true);
      }
    }
  }

  /**
   * Returns the angle of the target joint number when that joint is in its
   * home position, in degrees.
   * @param {Integer} jointNumber number of joint 1-6
   * @return angle of target joint in home position [deg]
   */
  getHomeAngle(jointNumber) {
    if (jointNumber === 1) {
      return this.props.homeA;
    } else if (jointNumber === 2) {
      return this.props.homeB;
    } else if (jointNumber === 3) {
      return this.props.homeC;
    } else if (jointNumber === 4) {
      return this.props.homeD;
    } else if (jointNumber === 5) {
      return this.props.homeE;
    } else if (jointNumber === 6) {
      return this.props.homeF;
    } else {
      //eslint-disable-next-line no-console
      console.error('No stored home position for joint number ' + jointNumber);
    }
  }

  /**
   * Loads the complete model of the specified robot and then iterates through each axis,
   * to add the models of each axis to the correct groups.
   * @returns a promise that is resolved when the complete model is loaded
   */
  initializeMachineGeometry() {
    return new Promise((resolve) => {
      if (this.robotType === constants.CUSTOM.value) {
        this.initializeCustomModels(resolve);
      } else {
        const url = `/models/Robot_${this.fileKey}.glb`;
        const robotModel = Printer.getModel(url);
        robotModel.then((robotModel) => {
          for (let axisNumber = 0; axisNumber <= 6; axisNumber++) {
            this.addAxis(robotModel, axisNumber);
          }
          resolve();
        });
      }
    });
  }
  initializeCustomModels(resolve) {
    const machineDefinition = Printer.getPrinterSettingValue(
      this.printerSettings,
      machineConstants.machineDefinitionResponse,
    );
    const { modelUrls } = machineDefinition;
    const modelPromises = []; // Array to hold all the promises for the robot models

    for (let axisNumber = 0; axisNumber <= 6; axisNumber++) {
      const robotModelPromise = Printer.getModel(
        modelUrls[s3Directories.machineAxis + axisNumber],
      );
      modelPromises.push(robotModelPromise); // Add promise to the array
    }

    // Wait for all models to load
    Promise.all(modelPromises).then((loadedRobotModels) => {
      for (let axisNumber = 0; axisNumber <= 6; axisNumber++) {
        const model = loadedRobotModels[axisNumber];
        this.addCustomAxis(model, axisNumber);
      }
      resolve();
    });
  }

  /**
   * This shifts the mesh of each axis from the global origin to its respective pivot point.
   * @param {*} robotModel
   * @param {*} axisNumber
   */
  addCustomAxis(robotModel, axisNumber) {
    if (axisNumber == 1) {
      robotModel.rotateX(Math.PI);
    }
    if (axisNumber == 2) {
      robotModel.rotateX(Math.PI / 2);
      robotModel.children.forEach((child) => {
        child.position.x = -this.props.axisA1;
        child.position.z = -this.props.axisD1;
      });
    }
    if (axisNumber == 3) {
      robotModel.rotateX(Math.PI / 2);
      robotModel.children.forEach((child) => {
        child.position.x = -this.props.axisA1 - this.props.axisA2;
        child.position.z = -this.props.axisD1;
      });
    }
    if (axisNumber == 4) {
      robotModel.rotateY(Math.PI / 2);
      robotModel.children.forEach((child) => {
        child.position.x = -this.props.axisA1 - this.props.axisA2;
        child.position.z = -this.props.axisD1 - this.props.axisA3;
      });
    }
    if (axisNumber == 5) {
      robotModel.rotateZ(Math.PI / 2);
      robotModel.rotateX(Math.PI / 2);
      robotModel.children.forEach((child) => {
        child.position.x =
          -this.props.axisA1 - this.props.axisA2 - this.props.axisD4;
        child.position.z = -this.props.axisD1 - this.props.axisA3;
      });
    }
    if (axisNumber == 6) {
      robotModel.rotateY(Math.PI / 2);
      robotModel.children.forEach((child) => {
        child.position.x =
          -this.props.axisA1 - this.props.axisA2 - this.props.axisD4;
        child.position.z = -this.props.axisD1 - this.props.axisA3;
      });
    }
    this[constants.part + axisNumber].add(robotModel);
  }

  /**
   * Searches the provided robot model file for the models associated with the
   * target axis number and adds them as children to the correct axis group.
   * @param {gltf} robotModel complete robot model gltf file
   * @param {Integer} axisNumber target axis number
   */
  addAxis(robotModel, axisNumber) {
    const groupedMesh = new THREE.Group();
    const axisModels = this.getAxisModels(robotModel, axisNumber);
    axisModels.forEach((axisModel) => {
      if (axisModel instanceof THREE.Mesh) {
        const mesh = this.getAxisMesh(axisModel);
        groupedMesh.add(mesh);
      }
    });
    this[constants.part + axisNumber].add(groupedMesh);
  }

  /**
   * Filters the provided robot model (gltf scene) for the models that
   * are associated with the target axis number and returns them as an
   * array.
   * @param {*} robotModel
   * @param {*} axisNumber
   * @returns array of models that are associated with the input axis number
   */
  getAxisModels(robotModel, axisNumber) {
    return robotModel.children.filter((child) =>
      child.name
        ?.toLowerCase()
        .includes(constants.axis + axisNumber + constants._),
    );
  }

  /**
   * Sets the shadows and position of the target mesh and returns it
   * @param {THREE.Mesh} mesh three js mesh to set the parameters of
   * @returns the same mesh with the defined parameters
   */
  getAxisMesh(mesh) {
    mesh.position.set(0, 0, 0);
    mesh.receiveShadow = true;
    mesh.castShadow = false;
    return mesh;
  }

  /**
   * Sets the angle of every axis of the robot to the defined home positions
   */
  moveHomePosition() {
    this.setAngleOfAxis(1, this.props.homeA, true);
    this.setAngleOfAxis(2, this.props.homeB, true);
    this.setAngleOfAxis(3, this.props.homeC, true);
    this.setAngleOfAxis(4, this.props.homeD, true);
    this.setAngleOfAxis(5, this.props.homeE, true);
    this.setAngleOfAxis(6, this.props.homeF, true);
  }

  generatePivotForPartCustom(partNumber) {
    const pivot = new THREE.Group();
    pivot.add(new THREE.AxesHelper(10000));

    const kukaAlpha = [
      Math.PI,
      Math.PI / 2,
      0,
      Math.PI / 2,
      -Math.PI / 2,
      Math.PI / 2,
      Math.PI,
    ];
    const kukaTheta = [0, 0, 0, -Math.PI / 2, 0, 0, Math.PI];

    //THESE PIVOTS ARE FOR KUKAS ONLY
    pivot.rotateX(kukaAlpha[partNumber]);
    pivot.rotateY(kukaTheta[partNumber]);
    switch (partNumber) {
      case 0: //axis 1 attaches
        break;
      case 1: //axis 2 attaches
        pivot.position.x = this.props.axisA1;
        pivot.position.z = -this.props.axisD1;
        break;
      case 2: //axis 3 attaches
        pivot.position.x = this.props.axisA2;
        break;
      case 3: //axis 4 attaches
        pivot.position.y = -this.props.axisA3;
        pivot.rotateY(-Math.PI / 2);
        break;
      case 4:
        pivot.position.z = -this.props.axisD4;
        break;
      case 5:
        break;
      case 6:
        pivot.position.z = -this.props.axisD6;
        pivot.rotateY(Math.PI);
        pivot.add(new THREE.AxesHelper(1000));
        break;
      default:
    }
    return pivot;
  }

  /**
   * Creates THREE.Groups at the correct positions for rotating the child parts, i.e.
   * the links which attach at that position, i.e. the point of rotation of all joints.
   * Successive THREE.Groups are created as children of the parent group, with their position
   * offset relative to the parent dependent on the values defined in the machine geometry.
   * @param {*} partNumber
   * @returns
   */
  generatePivotForPart(partNumber) {
    const pivot = new THREE.Group();
    const a = this.machineDefaults.pivots.a;
    const d = this.machineDefaults.pivots.d;
    if (this.robotType === constants.CUSTOM.value) {
      return this.generatePivotForPartCustom(partNumber, a, d);
    }

    switch (partNumber) {
      case 0:
        break;
      case 1:
      case 2:
        pivot.position.x = a[partNumber - 1];
        pivot.position.z = d[partNumber - 1];
        break;
      case 3:
      case 4:
      case 5:
        pivot.position.z = a[partNumber - 1];
        pivot.position.x = d[partNumber - 1];
        break;
      case 6:
      case 7:
        break;
      default:
    }
    return pivot;
  }

  //the rotations should not be pivot.rotation.z, pivot.rotation.y *-1 etc., these
  //differences should be captured within the THREE groups themselves.
  /**
   * Sets the angle of a specific joint of the 6DOF robot, and rotates
   * @param {*} axisNumber
   * @param {*} angle
   * @param {*} useCorrection
   * @returns
   */
  setAngleOfAxis(axisNumber, angle) {
    const pivot = this.rotationPoints[axisNumber - 1];
    if (!pivot) return;
    //THIS IS NEEDED To convert our kinematic model into robot specific model.
    // bizarre thouhg, dont we already do a conversion to kuka specifci values? why is this needed, was it just
    // to convert to three js, our weird representatoin? but not only the first is wrong? why is it still wrong? Fuck... i did the first one upside down.. thats why!
    const motorDirection = this.machineDefaults.motorDirection
      ? this.machineDefaults.motorDirection
      : [-1, 1, 1, -1, 1, -1];
    //NOTE FOR MORNING ALEX - TO GET THE CUSTOM KUKA TO WORK, USE THESE VALUES
    /*
      Note - motor direction is a correct which converts robot-specific motor directions into our
      threejs model setup. As the original model setup had no concern for specific motor directions
      a correction was applied. Now the model will be set up according to the brands themselves, so
      no motor correction is needed.
      */
    //FOR SOME REASON 4 and 6 ARE REVERSED RELATIVE TO OUR TRADITIONAL SETUP.
    // : [-1, 1, 1, 1, 1, 1];
    if (this.props.modelName === constants.CUSTOM.value) {
      //for custom robot models we are "CORRECTLY" assuming the z-axis is the robot axis
      angle = (angle / 360.0) * (Math.PI * 2.0);
      pivot.rotation.z = angle;
      return;
    }

    angle *= motorDirection[axisNumber - 1];
    //If not defined, use standard KUKA rotations
    angle += this.machineDefaults.homePositionCorrections[axisNumber - 1];
    angle = (angle / 360.0) * (Math.PI * 2.0);
    // convert from / to radians

    switch (axisNumber) {
      case 0:
        break;
      case 1:
        pivot.rotation.z = angle;
        break;
      case 2:
        pivot.rotation.y = angle;
        break;
      case 3:
        pivot.rotation.y = angle;
        break;
      case 4:
        pivot.rotation.x = angle;
        break;
      case 5:
        pivot.rotation.y = angle;
        break;
      case 6:
        pivot.rotation.x = angle;
        break;
      default:
        // eslint-disable-next-line no-console
        console.error(
          `setAngleOfAxis received an invalid axisNumber (${axisNumber})`,
        );
        break;
    }
  }
}

export default SixAxisRobot;
