import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, uniqueId } from 'lodash';
import useTooltip from '@hooks/useTooltip';
import { TOOLTIP_POSITION_TOP_LEFT } from '@components/2-molecules/Tooltip';
import TooltipPortal from '@components/2-molecules/TooltipPortal';

export const tooltipHOCProps = {
  tooltipAnimated: PropTypes.bool,
  tooltipAppearDelay: PropTypes.number,
  tooltipBridge: PropTypes.bool,
  tooltipId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tooltipPosition: PropTypes.oneOf([
    'top left',
    'top center',
    'top right',
    'bottom left',
    'bottom center',
    'bottom right',
  ]),
  tooltipDisabled: PropTypes.bool,
  tooltipInfoRow: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    color: PropTypes.string,
    withDivider: PropTypes.bool,
  }),
  tooltipMediaAlt: PropTypes.string,
  tooltipMediaSrc: PropTypes.string,
  tooltipWidth: PropTypes.number,
  tooltipMaxWidth: PropTypes.number,
  tooltipHeight: PropTypes.number,
  tooltipMaxHeight: PropTypes.number,
  tooltipOffsetTop: PropTypes.number,
  tooltipOffsetRight: PropTypes.number,
  tooltipOffsetBottom: PropTypes.number,
  tooltipOffsetLeft: PropTypes.number,
};

export default function withTooltip(Component) {
  /* eslint-disable react-hooks/rules-of-hooks */
  const wrapped = ({
    className,
    tooltipAnimated,
    tooltipAppearDelay = 0,
    tooltipBridge = true,
    tooltipDisabled,
    tooltipHeight,
    tooltipId,
    tooltipInfoRow = {},
    tooltipMaxHeight,
    tooltipMaxWidth,
    tooltipMediaAlt = '',
    tooltipMediaHeight,
    tooltipMediaPadding,
    tooltipMediaSrc,
    tooltipOffsetBottom,
    tooltipOffsetLeft,
    tooltipOffsetRight,
    tooltipOffsetTop,
    tooltipPosition = TOOLTIP_POSITION_TOP_LEFT,
    tooltipWidth,
    ...props
  }) => {
    const generateTooltipId = useRef(tooltipId || uniqueId('drop-down-menu-'));
    const { showTooltip, hideTooltip, tooltipIsOpen } = useTooltip();
    const hasTooltipContent = !isEmpty(tooltipInfoRow) || tooltipMediaSrc;
    const tooltipDelayTimeOutRef = useRef();
    const targetRef = useRef();

    const onMouseEnter = useCallback(() => {
      if (tooltipAppearDelay) {
        tooltipDelayTimeOutRef.current = setTimeout(() => {
          showTooltip?.(generateTooltipId.current, targetRef.current, {
            position: tooltipPosition,
          });
        }, tooltipAppearDelay);

        return;
      }

      showTooltip?.(generateTooltipId.current, targetRef.current, {
        position: tooltipPosition,
      });
    }, [tooltipAppearDelay, tooltipPosition, showTooltip]);

    const onMouseLeave = useCallback(() => {
      clearTimeout(tooltipDelayTimeOutRef.current);
      hideTooltip();
    }, [hideTooltip]);

    if (!hasTooltipContent) {
      return <Component {...props} />;
    }

    return (
      <div
        ref={targetRef}
        className={className}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Component {...props} />

        {tooltipIsOpen && (
          <TooltipPortal
            animated={tooltipAnimated}
            bridge={tooltipBridge}
            disabled={tooltipDisabled}
            height={tooltipHeight}
            id={generateTooltipId.current}
            infoRow={tooltipInfoRow}
            maxHeight={tooltipMaxHeight}
            maxWidth={tooltipMaxWidth}
            mediaAlt={tooltipMediaAlt}
            mediaHeight={tooltipMediaHeight}
            mediaPadding={tooltipMediaPadding}
            mediaSrc={tooltipMediaSrc}
            offsetBottom={tooltipOffsetBottom}
            offsetLeft={tooltipOffsetLeft}
            offsetRight={tooltipOffsetRight}
            offsetTop={tooltipOffsetTop}
            width={tooltipWidth}
          />
        )}
      </div>
    );
  };

  wrapped.propTypes = {
    onClick: PropTypes.func,
  };

  return wrapped;
}
