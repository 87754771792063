export const OperatorInputTypes = {
  GEOMETRY_TRIANGLE_MESH: 'GEOMETRY_TRIANGLE_MESH',
  GEOMETRY_CLASSIFIED_POLYLINES: 'GEOMETRY_CLASSIFIED_POLYLINES',
  LIST_GEOMETRY_CLASSIFIED_POLYLINES: 'LIST_GEOMETRY_CLASSIFIED_POLYLINES',
  GEOMETRY_PLANES: 'GEOMETRY_PLANES',
  GEOMETRY_POINTS: 'GEOMETRY_POINTS',
  GLTF_BYTE_ARRAY: 'GLTF_BYTE_ARRAY',
  ANALYSIS_DATA_CLASSIFIED_POLYLINES: 'ANALYSIS_DATA_CLASSIFIED_POLYLINES',
  TOOLPATH_ANALYSIS_DATA: 'TOOLPATH_ANALYSIS_DATA',
  TOOLPATH_SIMULATION_STEPS: 'TOOLPATH_SIMULATION_STEPS',
  TOOLPATH_SIMULATION: 'TOOLPATH_SIMULATION',
  TOOLPATH_INSTRUCTIONS: 'TOOLPATH_INSTRUCTIONS',
  FILE_TRIANGLE_MESH: 'FILE_TRIANGLE_MESH',
  FILE_GENERAL: 'FILE_GENERAL',
  FILE_CLASSIFIED_POLYLINES: 'FILE_CLASSIFIED_POLYLINES',
  FILE_GCODE: 'FILE_GCODE',
  SELECTION: 'SELECTION',
  DECIMAL: 'DECIMAL',
  INTEGER: 'INTEGER',
  PROGRAM_TYPE: 'PROGRAM_TYPE',
  EXTRUDER_SETTINGS_TYPE: 'EXTRUDER_SETTINGS_TYPE',
  EXTRUSION_TYPE: 'EXTRUSION_TYPE',
  BOOLEAN: 'BOOLEAN',
  STRING: 'STRING',
  PRINTER: 'PRINTER',
  GEOMETRY_BREP: 'GEOMETRY_BREP',
};
