import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import usePrintingStatus from '@hooks/printers/usePrintingStatus';
import usePrinterSocket from '@hooks/websocket/usePrinterSocket';
import useWorkflow from '@hooks/workflows/useWorkflow';
import useOperator from '@hooks/operators/useOperator';
import useOperatorSettings from '@hooks/operatorSettings/useOperatorSettings';
import useOperatorQueries from '@hooks/operators/useOperatorQueries';
import usePrint from '@hooks/prints/usePrint';
import { getProjectInstructionsURL } from '@selectors/conceptSelectors';
import Paper from '@components/1-atoms/Paper';
import Button, {
  BUTTON_VARIANT_PRIMARY,
  BUTTON_VARIANT_PLAIN,
} from '@components/1-atoms/Button';
import IconButton, {
  ICON_BUTTON_VARIANT_PLAIN,
  ICON_BUTTON_VARIANT_ERROR,
  ICON_BUTTON_VARIANT_STANDARD,
} from '@components/1-atoms/IconButton';
import Loader, {
  LOADER_ICON_ANIMATION_VARIANT_PULSE,
} from '@components/2-molecules/Loader';
import {
  ColorIcon,
  ColorInfoRow,
  Content,
  LoaderWrapper,
} from './WorkflowToolpathBar.styled';
import { setShowSafetyCheckResults } from '@app/actions/toolBarActions';
import { getShowSafetyCheckResults } from '@app/selectors/toolBarSelector';
import useDialog from '@app/hooks/useDialog';
import { ModalDataTypes } from '@app/constants/modalDataTypes';
import useFeatureFlags from '@app/hooks/featureflags/useFeatureFlags';
import {
  DOWNLOAD_STREAM_TOOLPATH_SAFETY_CHECK_FEATURE,
  PRINT_EVALUATOR,
} from '@app/constants/featureFlagConstants';
import PrintEvaluator from '@app/components/PrintEvaluator';
import { useFormContext } from 'react-hook-form';
import useDownloadStreamToolpath from '@hooks/operators/useDownloadStreamToolpath';
import useSnackbar from '@hooks/useSnackbar';
import {
  setDownloadStreamAction,
  setSafetyCheckAction,
} from '@reducers/workflowSlice';
import { getDependentNodes } from '@utils/visualTool';
import useSafetyCheck from '@hooks/operators/useSafetyCheck';
import { br } from '@utils/render';
import VerticalDivider from '@components/1-atoms/VerticalDivider';
import ButtonDropDown from '@components/1-atoms/ButtonDropDown';
import { fetchToolpathSimulationSafetyCheck } from '@actions/conceptActions';
import useOrganization from '@hooks/organization/useOrganization';

// this will be refactored once Toolpath categories are implemented, we can then find all Safety Check inputs from their category
const SAFETY_CHECK_VALUES = [
  'Check Axis Limits',
  'Check Workspace Limits',
  'Check Reach Limits',
  'Check Wrist Singularities',
  'Enable Collision Detection',
];
const WorkflowToolpathBar = () => {
  const [showPrintingProgress, setShowPrintingProgress] = useState(false);
  const projectInstructionsURL = useSelector(getProjectInstructionsURL);
  const safetyCheckResults = useSelector(getShowSafetyCheckResults);
  const {
    getSelectedWorkflow,
    getWorkflowPrinter,
    getIsWorkflowEditable,
    updateValuesAndComputeWorkflow,
  } = useWorkflow();
  const {
    getSelectedOperator,
    unselectSelectedOperator,
    getIsDownloadStreamButtonAvailable,
    getOperatorGraphDependencies,
    getIsOperatorModified,
  } = useOperator();
  const { getOperatorVisibleInputs } = useOperatorSettings();
  const {
    getSafetyCheckIconText,
    getSafetyCheckIconColor,
    getSafetyCheckPassed,
    getFailedSafetyCheckLabels,
    getSafetyCheckDropDownValues,
  } = useSafetyCheck();
  const { showDialog } = useDialog();
  const { isFeatureFlagEnabled } = useFeatureFlags();
  const { setValue, getValues, handleSubmit, formState } = useFormContext();
  const { showSnackbar } = useSnackbar();
  const { getOperatorAnalysisDataQueryOptions } = useOperatorQueries();
  const { getPrintEvaluation, getPrintEvaluationInfo } = usePrint();
  const intl = useIntl();
  const dispatch = useDispatch();

  const workflow = getSelectedWorkflow();
  const printer = getWorkflowPrinter(workflow);
  const printerId = printer?.id;
  const workflowMaterialId = workflow?.materialId;
  const selectedOperator = getSelectedOperator();
  const safetyCheckIconColor = useSelector(getSafetyCheckIconColor);
  const safetyCheckIconText = useSelector(getSafetyCheckIconText);
  const safetyCheckDropDownValues = useSelector(getSafetyCheckDropDownValues);
  const { getIsOrganizationIdOverride } = useOrganization();
  const organizationIdOverride = getIsOrganizationIdOverride();
  const selectedOperatorId = selectedOperator?.id;
  const isWorkflowEditable = getIsWorkflowEditable();
  const {
    isPrinting,
    printerStatus,
    formattedPrinterStatusName,
    printingProgress,
    stopPrint,
  } = usePrintingStatus(printerId);
  const operatorInputs = getOperatorVisibleInputs(selectedOperator);
  const { downloadToolpath, streamToolpath } = useDownloadStreamToolpath();

  const operatorAnalysisQuery = useQuery(
    getOperatorAnalysisDataQueryOptions(selectedOperatorId),
  );
  const isPrintEvaluatorEnabled =
    operatorAnalysisQuery?.isFetched &&
    isFeatureFlagEnabled(PRINT_EVALUATOR) &&
    !safetyCheckResults;

  usePrinterSocket();

  const printEvaluation = getPrintEvaluation(selectedOperatorId, {
    printerId,
    materialId: workflowMaterialId,
  });
  const printEvaluationInfo = getPrintEvaluationInfo(selectedOperatorId);

  const enableSafetyChecksAndCompute = useCallback(
    (intentStreaming) => {
      const formFields = operatorInputs
        .filter((i) => SAFETY_CHECK_VALUES.includes(i.name))
        .map((i) => `${i.operatorId}.${i.id}`);
      formFields.forEach((f) =>
        setValue(f, 'true', { shouldTouch: true, shouldDirty: true }),
      );
      handleSubmit(async (formValues) => {
        await updateValuesAndComputeWorkflow(
          formValues,
          { safetyCheck: true, intentStreaming },
          selectedOperatorId,
        );
        const action = intentStreaming ? 'stream' : 'download';
        dispatch(setSafetyCheckAction(action));
        showSnackbar({
          text: intl.formatMessage(
            {
              id: 'safetycheck.snackbar.in_progress',
              defaultMessage:
                'Safety check in progress.\nYou will receive a confirmation email shortly with a link to {action} the toolpath.',
            },
            { action },
          ),
        });
      })();
    },
    [
      operatorInputs,
      handleSubmit,
      setValue,
      updateValuesAndComputeWorkflow,
      selectedOperatorId,
      showSnackbar,
      intl,
      dispatch,
    ],
  );

  const computeAndDownloadOrStream = useCallback(
    (download) => {
      const selectedOperator = getSelectedOperator();
      const dependedGraph = getOperatorGraphDependencies();
      const dependentNodes = getDependentNodes(
        dependedGraph,
        selectedOperator.id,
      );
      const dependentOperators = dependentNodes.map((op) => op.getContent());
      const selectedOperatorAndDependencies = [
        ...dependentOperators,
        selectedOperator,
      ];

      const hasModifiedOperators = selectedOperatorAndDependencies?.some(
        (operator) =>
          getIsOperatorModified(operator, {
            formState: formState,
            getValues: getValues,
          }),
      );

      if (hasModifiedOperators) {
        handleSubmit(async (formValues) => {
          await updateValuesAndComputeWorkflow(
            formValues,
            {},
            selectedOperatorId,
          );
          const action = download ? 'download' : 'stream';
          dispatch(setDownloadStreamAction(action));
        })();
      } else {
        const safetyCheckPassed = getSafetyCheckPassed();
        if (safetyCheckPassed) {
          if (download) {
            downloadToolpath(selectedOperatorId);
          } else {
            streamToolpath(selectedOperatorId);
          }
        } else {
          const issues = getFailedSafetyCheckLabels();
          const issuesList = issues.map((label) => (
            <span key={label}>
              • {intl.formatMessage({ id: label })}
              <br></br>
            </span>
          ));
          showDialog(ModalDataTypes.PROMPT, {
            dataTestId: 'download-stream-dialog',
            title: intl.formatMessage({
              id: 'general.warning',
              defaultMessage: 'Warning',
            }),
            subtitle: intl.formatMessage(
              {
                id: 'downloadstreamdialog.safetycheck.attention',
                defaultMessage:
                  'The safety check has identified the following issues <br></br><br></br> {issues} <br></br> Proceeding with the current Toolpath may result in collisions.<br></br> Do you still wish to continue?',
              },
              {
                issues: issuesList,
                br,
              },
            ),
            secondaryButtonLabel: intl.formatMessage({
              id: 'general.cancel',
              defaultMessage: 'Cancel',
            }),
            primaryButtonLabel: intl.formatMessage({
              id: 'downloadstreamdialog.safetycheck.download.button.label',
              defaultMessage: 'Ignore warning and download',
            }),
            onPrimaryButtonClick: () => {
              if (download) {
                downloadToolpath(selectedOperatorId);
              } else {
                streamToolpath(selectedOperatorId);
              }
            },
          });
        }
      }
    },
    [
      getFailedSafetyCheckLabels,
      intl,
      getSafetyCheckPassed,
      showDialog,
      dispatch,
      formState,
      getValues,
      getIsOperatorModified,
      getOperatorGraphDependencies,
      getSelectedOperator,
      handleSubmit,
      updateValuesAndComputeWorkflow,
      downloadToolpath,
      streamToolpath,
      selectedOperatorId,
    ],
  );

  const downloadStreamButtonAvailable = useMemo(
    () =>
      getIsDownloadStreamButtonAvailable({
        formState: formState,
        getValues: getValues,
      }),
    [getIsDownloadStreamButtonAvailable, formState, getValues],
  );

  const onDownloadClick = useCallback(() => {
    const safetyCheckValues = operatorInputs.filter((i) =>
      SAFETY_CHECK_VALUES.includes(i.name),
    );
    const completeSafetyCheckAlreadyExecuted = safetyCheckValues.every(
      (i) => i.value === true || i.value === 'true',
    );
    if (
      !completeSafetyCheckAlreadyExecuted &&
      isFeatureFlagEnabled(DOWNLOAD_STREAM_TOOLPATH_SAFETY_CHECK_FEATURE)
    ) {
      showDialog(ModalDataTypes.DOWNLOAD_STREAM_TOOLPATH, {
        isStream: false,
        toolpathId: selectedOperatorId,
        onConfirm: (runSafetyChecks) =>
          runSafetyChecks
            ? enableSafetyChecksAndCompute(false)
            : computeAndDownloadOrStream(true),
      });
    } else {
      computeAndDownloadOrStream(true);
    }
  }, [
    selectedOperatorId,
    computeAndDownloadOrStream,
    operatorInputs,
    showDialog,
    isFeatureFlagEnabled,
    enableSafetyChecksAndCompute,
  ]);

  const openSafetyCheckResults = useCallback(() => {
    dispatch(setShowSafetyCheckResults(true));
    const safetyCheckPassed = getSafetyCheckPassed();
    if (!safetyCheckPassed) {
      dispatch(
        fetchToolpathSimulationSafetyCheck(
          selectedOperatorId,
          organizationIdOverride,
          safetyCheckDropDownValues[0]?.mode,
        ),
      );
    }
  }, [
    organizationIdOverride,
    getSafetyCheckPassed,
    safetyCheckDropDownValues,
    selectedOperatorId,
    dispatch,
  ]);

  const isComputationStreamable = useCallback(() => {
    if (!printerStatus) return false;

    const isIdle = printerStatus.status === 'IDLE';
    const currentDate = new Date();
    // TODO store the cutoff interval somewhere else
    const cutoffDate = new Date(currentDate.getTime() - 5000);
    const isStreamable = isIdle && new Date(printerStatus.time) > cutoffDate;

    return isStreamable;
  }, [printerStatus]);

  const onStreamClick = useCallback(async () => {
    const safetyCheckInputs = operatorInputs.filter((i) =>
      SAFETY_CHECK_VALUES.includes(i.name),
    );
    const completeSafetyCheckAlreadyExecuted = safetyCheckInputs.every(
      (i) => i.value === true || i.value === 'true',
    );
    if (
      !completeSafetyCheckAlreadyExecuted &&
      isFeatureFlagEnabled(DOWNLOAD_STREAM_TOOLPATH_SAFETY_CHECK_FEATURE)
    ) {
      showDialog(ModalDataTypes.DOWNLOAD_STREAM_TOOLPATH, {
        isStream: true,
        toolpathId: selectedOperatorId,
        onConfirm: (runSafetyChecks) =>
          runSafetyChecks
            ? enableSafetyChecksAndCompute(true)
            : computeAndDownloadOrStream(false),
      });
    } else {
      computeAndDownloadOrStream(false);
    }
  }, [
    selectedOperatorId,
    computeAndDownloadOrStream,
    operatorInputs,
    isFeatureFlagEnabled,
    showDialog,
    enableSafetyChecksAndCompute,
  ]);

  const togglePrintingProgress = useCallback(() => {
    setShowPrintingProgress((prev) => !prev);
  }, []);

  const stopPrinting = useCallback(() => {
    stopPrint();
    togglePrintingProgress();
  }, [stopPrint, togglePrintingProgress]);

  const moreIconButtonDropDownMenuItems = useMemo(
    () => [
      {
        id: 'stream',
        label: intl.formatMessage({
          id: 'general.stream',
          defaultMessage: 'Stream',
        }),
        disabled: !downloadStreamButtonAvailable,
        onClick: onStreamClick,
      },
    ],
    [intl, downloadStreamButtonAvailable, onStreamClick],
  );

  if (showPrintingProgress) {
    return (
      <LoaderWrapper>
        <Loader
          endingIconButtonIconName="stop_player_1"
          endingIconButtonTitle="Stop Printing"
          iconColor="success"
          iconName="Dot_1"
          iconAnimationVariant={LOADER_ICON_ANIMATION_VARIANT_PULSE}
          leadingIconButtonIconName="chevron_left_0"
          onEndingIconButtonClick={stopPrinting}
          onLeadingIconButtonClick={togglePrintingProgress}
          progress={printingProgress}
          title={formattedPrinterStatusName}
        />
      </LoaderWrapper>
    );
  }

  return (
    <Paper dataTestId="workflow-toolpath-bar">
      <Content
        withContent={!!projectInstructionsURL || isPrintEvaluatorEnabled}
      >
        {isPrintEvaluatorEnabled && (
          <>
            <PrintEvaluator
              duration={printEvaluation.formattedDuration}
              weight={printEvaluation.formattedWeight}
              price={printEvaluation.formattedPrice}
              info={printEvaluationInfo}
            />

            <VerticalDivider middleInset inlineMiddleInset />
          </>
        )}

        {safetyCheckResults && (
          <>
            <ColorInfoRow
              noPadding
              color={safetyCheckIconColor}
              label={safetyCheckIconText}
              leadingIconName={
                safetyCheckIconColor === 'success' ? 'done_0' : 'error_0'
              }
            />
            {safetyCheckDropDownValues && safetyCheckDropDownValues.length > 0 && (
              <>
                <VerticalDivider middleInset inlineMiddleInset />
                <ButtonDropDown
                  leadingElement={
                    <ColorIcon name={'Dot_1'} errorColor={'fixedError'} />
                  }
                  dataTestId={`workflow-safety-check-bar__button-drop-down`}
                  title={intl.formatMessage({
                    id: 'workflows.safety_check_results.select_an_option.title',
                    defaultMessage: 'Select an_option',
                  })}
                  dropDownMenuItems={safetyCheckDropDownValues}
                >
                  {
                    safetyCheckDropDownValues.find((item) => item.selected)
                      ?.label
                  }
                </ButtonDropDown>
              </>
            )}
          </>
        )}
        {!safetyCheckResults && projectInstructionsURL && (
          <>
            <IconButton
              variant={
                safetyCheckIconColor === 'error'
                  ? ICON_BUTTON_VARIANT_ERROR
                  : ICON_BUTTON_VARIANT_STANDARD
              }
              iconColor={
                safetyCheckIconColor === 'error'
                  ? undefined
                  : safetyCheckIconColor
              }
              iconName="health_and_safety_0"
              onClick={
                safetyCheckIconColor === 'outlineVariant'
                  ? undefined
                  : openSafetyCheckResults
              }
            />
            <Button
              disabled={!isWorkflowEditable || !downloadStreamButtonAvailable}
              variant={BUTTON_VARIANT_PRIMARY}
              stretch={false}
              onClick={onDownloadClick}
            >
              <FormattedMessage
                id="operator.progress.download_toolpath"
                defaultMessage="Download"
              />
            </Button>

            {isComputationStreamable() && (
              <IconButton
                dataTestId="stream-button"
                title={intl.formatMessage({
                  id: 'general.more',
                  defaultMessage: 'More',
                })}
                iconName="more_vert_0"
                dropDownMenuItems={moreIconButtonDropDownMenuItems}
              />
            )}

            {isPrinting && (
              <Button
                variant={BUTTON_VARIANT_PLAIN}
                iconName="Dot_1"
                iconColor="success"
                stretch={false}
                onClick={togglePrintingProgress}
              >
                {formattedPrinterStatusName}
              </Button>
            )}
          </>
        )}

        {!safetyCheckResults && (
          <IconButton
            variant={ICON_BUTTON_VARIANT_PLAIN}
            iconName="close_0"
            onClick={unselectSelectedOperator}
          />
        )}
      </Content>
    </Paper>
  );
};

WorkflowToolpathBar.propTypes = {};

export default WorkflowToolpathBar;
