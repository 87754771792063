import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import useWorkflow from '@hooks/workflows/useWorkflow';
import useOperatorSettings from '@app/hooks/operatorSettings/useOperatorSettings';
import useDialog from '@hooks/useDialog';
import { ModalDataTypes } from '@constants/modalDataTypes';
import DialogPortal from '@components/2-molecules/DialogPortal';
import { DIALOG_SIZE_VARIANT_MEDIUM } from '@components/2-molecules/Dialog';
import PageHeader, {
  PAGE_HEADER_VARIANT_LARGE,
} from '@components/2-molecules/PageHeader';
import TalkToAiComparison from '@containers/TalkToAiComparison';
import TalkToAiChatBox from '@containers/TalkToAiChatBox';
import { AiIcon, Wrapper } from './TalkToAiDialog.styled';
import useGptMutations from '@hooks/talkToAi/useGptMutations';
import { showErrorDialog } from '@actions/errorActions';
import moment from 'moment';
import { setIsSubmitted } from '@reducers/workflowSlice';

const MODAL_ID = ModalDataTypes.TALK_TO_AI;

const CONVERSATION_TYPE = {
  PROMPT: 'prompt',
  ANSWER: 'answer',
};

const TalkToAiDialog = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { getDialogData, hideDialog } = useDialog();
  const {
    getIsWorkflowEmpty,
    getIsWorkflowComputed,
    refetchWorkflow,
    computeWorkflow,
  } = useWorkflow();
  const { calculateWorkflowHiddenValues } = useOperatorSettings();

  const dialogData = getDialogData(MODAL_ID);

  const { sendMessageMutation, applyChangesMutation } = useGptMutations();

  const [conversation, setConversation] = useState([]);

  const [workflowChanges, setWorkflowChanges] = useState({});
  const loading =
    sendMessageMutation.isPending || applyChangesMutation.isPending;
  const isConversationStarted = !!conversation?.length;
  const { proposedWorkflowDiff, originalWorkflowDiff } = workflowChanges;
  const proposedWorkflowIsModified = proposedWorkflowDiff?.diffModified;

  const refetchAndComputeCurrentWorkflow = useCallback(async () => {
    await refetchWorkflow();
    // resets form values by triggering a custom submit
    dispatch(setIsSubmitted(true));
    computeWorkflow(calculateWorkflowHiddenValues());
  }, [
    refetchWorkflow,
    computeWorkflow,
    calculateWorkflowHiddenValues,
    dispatch,
  ]);

  const closeGpt = useCallback(() => {
    hideDialog(MODAL_ID);
  }, [hideDialog]);

  const addToConversation = useCallback(
    (message, type, error = false) => {
      setConversation((prevConversation) => [
        ...prevConversation,
        { type, data: message, time: moment(), error },
      ]);
    },
    [setConversation],
  );

  const applyChanges = useCallback(async () => {
    const message = intl.formatMessage({
      id: 'gpt.diff.answer.applying_change',
      defaultMessage: 'Applying changes. Please wait',
    });
    addToConversation(message, CONVERSATION_TYPE.ANSWER);

    await applyChangesMutation.mutateAsync({
      proposedWorkflow: workflowChanges?.proposedWorkflow,
    });

    refetchAndComputeCurrentWorkflow();
    closeGpt();
  }, [
    applyChangesMutation,
    workflowChanges,
    closeGpt,
    refetchAndComputeCurrentWorkflow,
    intl,
    addToConversation,
  ]);

  const handleGptSend = useCallback(
    async (message) => {
      const isWorkflowComputed =
        getIsWorkflowComputed() && dialogData?.isWorkflowFormNotDirtyAndValid;

      if (!getIsWorkflowEmpty() && !isWorkflowComputed) {
        closeGpt();
        dispatch(
          showErrorDialog(
            'Error',
            intl.formatMessage({
              id: 'gpt.diff.answer.compute_before_proceed',
              defaultMessage:
                'Please compute the workflow before we can proceed with your request',
            }),
          ),
        );
        return;
      }

      addToConversation(message, CONVERSATION_TYPE.PROMPT);
      await sendMessageMutation
        .mutateAsync({
          message,
        })
        .then((response) => {
          setWorkflowChanges(response);
          addToConversation(
            response.answerMessage,
            CONVERSATION_TYPE.ANSWER,
            response.error,
          );
        });
    },
    [
      getIsWorkflowEmpty,
      getIsWorkflowComputed,
      dialogData?.isWorkflowFormNotDirtyAndValid,
      sendMessageMutation,
      dispatch,
      closeGpt,
      intl,
      addToConversation,
    ],
  );

  return (
    <DialogPortal
      dataTestId="talk-to-ai-dialog"
      dialogId={MODAL_ID}
      primaryButtonLabel={null}
      size={isConversationStarted ? DIALOG_SIZE_VARIANT_MEDIUM : undefined}
    >
      <Wrapper>
        {!isConversationStarted && (
          <>
            <AiIcon />

            <PageHeader
              variant={PAGE_HEADER_VARIANT_LARGE}
              title={intl.formatMessage({
                id: 'gpt.chatbox.title',
                defaultMessage: 'Talk to AI',
              })}
              subtitle={intl.formatMessage({
                id: 'gpt.chatbox.description',
                defaultMessage:
                  "Type instructions e.g. 'print box.obj with 5mm layer height', 'add infill', 'remove all operators' etc.",
              })}
            />
          </>
        )}

        {isConversationStarted && (
          <TalkToAiComparison
            currentOperators={originalWorkflowDiff?.operators}
            recommendedOperators={proposedWorkflowDiff?.operators}
            onApplyButtonClick={applyChanges}
            onCancelButtonClick={closeGpt}
            disableApplyButton={!proposedWorkflowIsModified}
            loading={loading}
          />
        )}

        <TalkToAiChatBox
          chatAutoFocus
          disabled={loading}
          loading={loading}
          conversation={conversation}
          handleSend={handleGptSend}
          hidePrompt={isConversationStarted}
        />
      </Wrapper>
    </DialogPortal>
  );
};

export default TalkToAiDialog;
