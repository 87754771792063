import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { DropDownMenuProvider } from '@contexts/DropDownMenuContext';
import withDropDownMenu from '@hoc/withDropDownMenu';
import getIntlProvider from '@utils/getIntlProvider';
import { stopPropagation } from '@utils/interactionEvents';
import { setApplicationScrollable } from '@stylesheets/helpers';
import EmptyStateBox, {
  EMPTY_STATE_BOX_VARIANT_COMPACT,
} from '@components/2-molecules/EmptyStateBox';
import MenuListItem, {
  MENU_LIST_ITEM_ENDING_BUTTON_TYPE_RADIO,
  MENU_LIST_ITEM_ENDING_BUTTON_TYPE_CHECKBOX,
  MENU_LIST_ITEM_ENDING_BUTTON_TYPE_SWITCH,
  MENU_LIST_ITEM_ENDING_BUTTON_TYPE_ICON_BUTTON,
} from '@components/1-atoms/MenuListItem';
import {
  OverlayLayer,
  OuterWrapper,
  Wrapper,
  DROP_DOWN_MENU_POSITION_TOP_LEFT,
  DROP_DOWN_MENU_POSITION_TOP_CENTER,
  DROP_DOWN_MENU_POSITION_TOP_RIGHT,
  DROP_DOWN_MENU_POSITION_BOTTOM_LEFT,
  DROP_DOWN_MENU_POSITION_BOTTOM_CENTER,
  DROP_DOWN_MENU_POSITION_BOTTOM_RIGHT,
  DROP_DOWN_MENU_POSITION_BOTTOM_LEFT_EDGE,
  DROP_DOWN_MENU_POSITION_BOTTOM_RIGHT_EDGE,
} from './DropDownMenu.styled';

export const DROP_DOWN_MENU_OVERLAY_ID = 'drop-down-menu-overlay';
export const DROP_DOWN_MENU_ID = 'drop-down-menu';

const intl = getIntlProvider();

const MenuListItemWithDropDownMenu = withDropDownMenu(MenuListItem);

const DropDownMenu = forwardRef(
  (
    {
      menuId,
      className,
      closeDropDownMenu,
      dataTestId = 'drop-down-menu',
      emptyStateDescription,
      emptyStateIconName,
      emptyStateLinkButtonLabel,
      emptyStateLinkButtonPath,
      emptyStateOnLinkButtonClick,
      emptyStateTitle = intl.formatMessage({
        id: 'dropdownmenu.empty_state.title',
        defaultMessage: 'No items available',
      }),
      menuItems = [],
      menuScrollToBottom,
      onFormSelect,
      position = DROP_DOWN_MENU_POSITION_BOTTOM_RIGHT,
      show,
      style,
      withOverlay = true,
      offsetX = 0,
      onMouseOut,
    },
    ref,
  ) => {
    const wrapperRef = useRef(null);
    const [activeMenuItemIndex, setActiveMenuItemIndex] = useState();
    const showEmptyMenuItem = !menuItems.length;

    const combineRefs = useCallback(
      (element) => {
        wrapperRef.current = element;

        if (ref) {
          ref.current = element;
        }
      },
      [ref],
    );

    const clickOnOverlay = useCallback(() => {
      document.getElementById(DROP_DOWN_MENU_OVERLAY_ID)?.click();
    }, []);

    const handleClick = useCallback(
      (formFieldValue, onClick, shouldCloseOnClick = true) =>
        (e) => {
          onClick?.(e);
          onFormSelect?.(formFieldValue);

          if (shouldCloseOnClick) {
            closeDropDownMenu?.();
            clickOnOverlay();
          }
        },
      [onFormSelect, closeDropDownMenu, clickOnOverlay],
    );

    const onDropDownMenuOpen = useCallback(
      (menuIndex) => () => {
        setActiveMenuItemIndex(menuIndex);
      },
      [],
    );

    useEffect(() => {
      if (menuScrollToBottom) {
        wrapperRef.current?.scrollTo(0, wrapperRef.current?.scrollHeight);
      }
    }, [menuScrollToBottom]);

    useEffect(() => {
      if (show) {
        wrapperRef.current?.focus();
      }
    }, [show]);

    useEffect(() => {
      if (show) {
        setApplicationScrollable(false);
      } else {
        setApplicationScrollable(true);
      }

      return () => {
        setApplicationScrollable(true);
      };
    }, [show]);

    const ListItem = useMemo(
      () =>
        menuItems.some((item) => item.dropDownMenuItems)
          ? MenuListItemWithDropDownMenu
          : MenuListItem,
      [menuItems],
    );

    return (
      <DropDownMenuProvider>
        {withOverlay && show && (
          <OverlayLayer
            id={DROP_DOWN_MENU_OVERLAY_ID}
            onClick={stopPropagation(closeDropDownMenu)}
          />
        )}

        <OuterWrapper
          show={show}
          offsetX={offsetX}
          position={position}
          data-menu-id={menuId}
          fixedPosition={style?.position === 'fixed'}
          style={style}
          onMouseOut={onMouseOut}
        >
          <Wrapper
            className={className}
            dataTestId={dataTestId}
            id={DROP_DOWN_MENU_ID}
            ref={combineRefs}
            tabIndex={0}
          >
            {showEmptyMenuItem && (
              <EmptyStateBox
                variant={EMPTY_STATE_BOX_VARIANT_COMPACT}
                iconName={emptyStateIconName}
                title={emptyStateTitle}
                description={emptyStateDescription}
                linkButtonLabel={emptyStateLinkButtonLabel}
                linkButtonPath={emptyStateLinkButtonPath}
                openLinkInNewTab
                onLinkButtonClick={emptyStateOnLinkButtonClick}
              />
            )}

            {menuItems.map(
              (
                {
                  color,
                  description,
                  disabled,
                  endingButton,
                  endingIconName,
                  field,
                  formFieldValue,
                  id,
                  label,
                  leadingIconColor,
                  leadingIconName,
                  leadingIconUrl,
                  navigationItem,
                  navigationPath,
                  onClick,
                  onMouseLeave,
                  onMouseEnter,
                  onEndingIconButtonClick,
                  selected,
                  skeleton,
                  tooltip,
                  withBadge,
                  withDivider,
                  dropDownMenuOffsetX = 10,
                  dropDownMenuItems,
                  dropDownMenuPosition = DROP_DOWN_MENU_POSITION_BOTTOM_LEFT_EDGE,
                  dropDownMenuScrolledToBottom,
                  showDropDownMenuOnHover = true,
                },
                index,
              ) => {
                const hasNestedDropDownMenu = !!dropDownMenuItems;
                const showNestedDropDownMenuOnClick =
                  hasNestedDropDownMenu && !showDropDownMenuOnHover;

                return (
                  <ListItem
                    key={id || index}
                    color={color}
                    closeDropDownMenu={
                      hasNestedDropDownMenu ? undefined : closeDropDownMenu
                    }
                    description={description}
                    disabled={disabled}
                    endingButton={endingButton}
                    endingIconName={endingIconName}
                    field={field}
                    label={label}
                    leadingIconName={leadingIconName}
                    leadingIconUrl={leadingIconUrl}
                    leadingIconColor={leadingIconColor}
                    navigationPath={navigationPath}
                    navigationItem={navigationItem}
                    onClick={stopPropagation(
                      handleClick(
                        formFieldValue,
                        onClick,
                        !hasNestedDropDownMenu,
                      ),
                    )}
                    onEndingIconButtonClick={stopPropagation(
                      onEndingIconButtonClick,
                    )}
                    onMouseEnter={onMouseLeave}
                    onMouseLeave={onMouseEnter}
                    selected={selected || activeMenuItemIndex === index}
                    skeleton={skeleton}
                    tooltip={tooltip}
                    withBadge={withBadge}
                    withDivider={withDivider}
                    dropDownMenuOverlay={!hasNestedDropDownMenu}
                    dropDownMenuOffsetX={dropDownMenuOffsetX}
                    dropDownMenuItems={dropDownMenuItems}
                    dropDownMenuPosition={dropDownMenuPosition}
                    dropDownMenuScrolledToBottom={dropDownMenuScrolledToBottom}
                    showDropDownMenuOnHover={
                      hasNestedDropDownMenu && showDropDownMenuOnHover
                    }
                    onDropDownMenuOpen={
                      showNestedDropDownMenuOnClick
                        ? onDropDownMenuOpen(index)
                        : undefined
                    }
                    onDropDownMenuClose={
                      showNestedDropDownMenuOnClick
                        ? () => {
                            setActiveMenuItemIndex();
                          }
                        : undefined
                    }
                  />
                );
              },
            )}
          </Wrapper>
        </OuterWrapper>
      </DropDownMenuProvider>
    );
  },
);

DropDownMenu.propTypes = {
  className: PropTypes.string,
  closeDropDownMenu: PropTypes.func,
  dataTestId: PropTypes.string,
  emptyStateDescription: PropTypes.string,
  emptyStateIconName: PropTypes.string,
  emptyStateLinkButtonLabel: PropTypes.string,
  emptyStateLinkButtonPath: PropTypes.string,
  emptyStateOnLinkButtonClick: PropTypes.func,
  emptyStateTitle: PropTypes.string,
  show: PropTypes.bool,
  position: PropTypes.oneOf([
    DROP_DOWN_MENU_POSITION_TOP_LEFT,
    DROP_DOWN_MENU_POSITION_TOP_CENTER,
    DROP_DOWN_MENU_POSITION_TOP_RIGHT,
    DROP_DOWN_MENU_POSITION_BOTTOM_LEFT,
    DROP_DOWN_MENU_POSITION_BOTTOM_CENTER,
    DROP_DOWN_MENU_POSITION_BOTTOM_RIGHT,
    DROP_DOWN_MENU_POSITION_BOTTOM_LEFT_EDGE,
    DROP_DOWN_MENU_POSITION_BOTTOM_RIGHT_EDGE,
  ]),
  menuId: PropTypes.string,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      formFieldValue: PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
      color: PropTypes.string,
      description: PropTypes.string,
      endingIconName: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
      leadingIconName: PropTypes.string,
      leadingIconColor: PropTypes.string,
      endingButton: PropTypes.shape({
        // Switch, Radio, Checkbox props
        name: PropTypes.string,
        type: PropTypes.oneOf([
          MENU_LIST_ITEM_ENDING_BUTTON_TYPE_SWITCH,
          MENU_LIST_ITEM_ENDING_BUTTON_TYPE_RADIO,
          MENU_LIST_ITEM_ENDING_BUTTON_TYPE_CHECKBOX,
          MENU_LIST_ITEM_ENDING_BUTTON_TYPE_ICON_BUTTON,
        ]).isRequired,
        onChange: PropTypes.func,
        value: PropTypes.string,
        checked: PropTypes.bool,
        enabled: PropTypes.bool,

        // Icon Button props
        disabled: PropTypes.bool,
        iconName: PropTypes.string,
        loading: PropTypes.bool,
        onClick: PropTypes.func,
        selected: PropTypes.bool,
        title: PropTypes.string,
        variant: PropTypes.string,
      }),
      onClick: PropTypes.func,
      onEndingIconButtonClick: PropTypes.func,
      selected: PropTypes.bool,
      skeleton: PropTypes.bool,
      tooltip: PropTypes.object,
      disabled: PropTypes.bool,
      navigationPath: PropTypes.string,
      withBadge: PropTypes.bool,
      withDivider: PropTypes.bool,
    }),
  ),
  menuScrollToBottom: PropTypes.bool,
  onFormSelect: PropTypes.func,
  style: PropTypes.shape({
    position: PropTypes.string,
    top: PropTypes.number,
    left: PropTypes.number,
  }),
  withOverlay: PropTypes.bool,
  offsetX: PropTypes.number,
  onMouseOut: PropTypes.func,
};

DropDownMenu.displayName = 'DropDownMenu';

export default DropDownMenu;
