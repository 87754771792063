import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { isAnyFetchInProgess } from '@selectors/fetchSelector';
import { fetchPostProcessorConfigsForPrinter } from '@actions/printerActions';
import { resetNavigationBarHistory } from '@actions/applicationActions';
import usePrinterQueries from '@hooks/printers/usePrinterQueries';
import LoadingEditorLayout from '@containers/LoadingEditorLayout';

const PrinterLoader = ({ children }) => {
  const { printerId } = useParams();
  const dispatch = useDispatch();
  const isFetching = useSelector(isAnyFetchInProgess);
  const {
    printerQuery,
    nozzlesQuery,
    printersQuery,
    extruderSettingsTypesQuery,
    printingBedSettingsTypesQuery,
  } = usePrinterQueries({
    nozzlePrinterId: printerId,
    printerId: printerId,
  });
  const refetchPrintersQuery = printersQuery.refetch;
  const refetchExtruderSettingsTypesQuery = extruderSettingsTypesQuery.refetch;
  const refetchPrintingBedSettingsTypesQuery =
    printingBedSettingsTypesQuery.refetch;

  useEffect(() => {
    if (!printersQuery.isFetched) {
      refetchPrintersQuery();
    }
    if (!extruderSettingsTypesQuery.isFetched) {
      refetchExtruderSettingsTypesQuery();
    }
    if (!printingBedSettingsTypesQuery.isFetched) {
      refetchPrintingBedSettingsTypesQuery();
    }
  }, [
    printingBedSettingsTypesQuery.isFetched,
    extruderSettingsTypesQuery.isFetched,
    printersQuery.isFetched,
    refetchExtruderSettingsTypesQuery,
    refetchPrintingBedSettingsTypesQuery,
    refetchPrintersQuery,
  ]);

  const pageDataIsFetched =
    !isFetching &&
    printerQuery.isFetched &&
    nozzlesQuery.isFetched &&
    printersQuery.isFetched &&
    extruderSettingsTypesQuery.isFetched &&
    printingBedSettingsTypesQuery.isFetched;

  useEffect(() => {
    dispatch(fetchPostProcessorConfigsForPrinter(printerId));
  }, [dispatch, printerId]);

  useEffect(() => {
    return () => {
      dispatch(resetNavigationBarHistory());
    };
  }, [dispatch]);

  if (!pageDataIsFetched) {
    return <LoadingEditorLayout />;
  }

  return children;
};

PrinterLoader.propTypes = {
  children: PropTypes.node,
};

export default PrinterLoader;
