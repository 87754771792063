import { useCallback, useEffect, useContext, useRef, useMemo } from 'react';
import DropDownMenuContext from '@contexts/DropDownMenuContext';
import {
  DROP_DOWN_MENU_ID,
  DROP_DOWN_MENU_POSITION_TOP_LEFT,
  DROP_DOWN_MENU_POSITION_TOP_CENTER,
  DROP_DOWN_MENU_POSITION_TOP_RIGHT,
  DROP_DOWN_MENU_POSITION_BOTTOM_LEFT,
  DROP_DOWN_MENU_POSITION_BOTTOM_CENTER,
  DROP_DOWN_MENU_POSITION_BOTTOM_RIGHT,
  DROP_DOWN_MENU_POSITION_BOTTOM_LEFT_EDGE,
  DROP_DOWN_MENU_POSITION_BOTTOM_RIGHT_EDGE,
} from '@components/2-molecules/DropDownMenu';

export default function useDropDownMenu({
  offset = 5,
  offsetX = 0,
  appearFromDistance = 0,
} = {}) {
  const {
    anchorElement,
    dropDownMenuData,
    dropDownMenuId,
    dropDownMenuIsOpen,
    dropDownMenuStyle,
    resetDropDown,
    setAnchorElement,
    setDropDownMenuData,
    setDropDownMenuId,
    setDropDownMenuIsOpen,
    setDropDownMenuStyle,
    updateDropDownMenuData,
    dropDownMenuPosition,
    setDropDownMenuPosition,
  } = useContext(DropDownMenuContext);
  const anchorElementRef = useRef(anchorElement);
  const dropDownMenuIsOpenRef = useRef(dropDownMenuIsOpen);

  const closedStateTransformStyle = useMemo(() => {
    if (
      [
        DROP_DOWN_MENU_POSITION_TOP_LEFT,
        DROP_DOWN_MENU_POSITION_TOP_CENTER,
        DROP_DOWN_MENU_POSITION_TOP_RIGHT,
      ].includes(dropDownMenuPosition)
    ) {
      return `translateY(${appearFromDistance}px)`;
    }

    return `translateY(-${appearFromDistance}px)`;
  }, [dropDownMenuPosition, appearFromDistance]);

  const hideDropDownMenu = useCallback(() => {
    if (!dropDownMenuIsOpen) return;

    resetDropDown();
  }, [dropDownMenuIsOpen, resetDropDown]);

  const showDropDownMenu = useCallback(
    (id, anchorElementTarget, data = {}) => {
      if (!id || !anchorElementTarget) return;

      const isSameDropDownMenu = dropDownMenuId === id;

      if (dropDownMenuIsOpen && isSameDropDownMenu) {
        hideDropDownMenu();
        return;
      }

      setDropDownMenuId(id);
      setAnchorElement(anchorElementTarget);
      setDropDownMenuData(data);

      if (data?.position) {
        setDropDownMenuPosition(data.position);
      }

      setDropDownMenuIsOpen(true);
    },
    [
      dropDownMenuIsOpen,
      dropDownMenuId,
      hideDropDownMenu,
      setAnchorElement,
      setDropDownMenuData,
      setDropDownMenuId,
      setDropDownMenuIsOpen,
      setDropDownMenuPosition,
    ],
  );

  const handleDropDownKeyDown = useCallback(
    (e) => {
      const isEscButton = ['Esc', 'Escape'].includes(e.key);

      const closeDropDown = dropDownMenuIsOpen && isEscButton;

      if (closeDropDown) hideDropDownMenu();
    },
    [dropDownMenuIsOpen, hideDropDownMenu],
  );

  const calculateDropDownMenuPosition = useCallback(() => {
    const dropDownMenuElement = document.getElementById(DROP_DOWN_MENU_ID);

    if (!anchorElement || !dropDownMenuElement) {
      return;
    }

    const fullWidthMenuMode = dropDownMenuData?.fullWidth;
    const anchorElementPosition = anchorElement.getBoundingClientRect();
    const menuWidth = dropDownMenuElement.offsetWidth;
    const menuHeight = dropDownMenuElement.offsetHeight;

    const topOrientation = [
      DROP_DOWN_MENU_POSITION_TOP_LEFT,
      DROP_DOWN_MENU_POSITION_TOP_CENTER,
      DROP_DOWN_MENU_POSITION_TOP_RIGHT,
    ].includes(dropDownMenuPosition);

    const bottomOrientation =
      fullWidthMenuMode ||
      [
        DROP_DOWN_MENU_POSITION_BOTTOM_LEFT,
        DROP_DOWN_MENU_POSITION_BOTTOM_CENTER,
        DROP_DOWN_MENU_POSITION_BOTTOM_RIGHT,
      ].includes(dropDownMenuPosition);

    let width;
    let maxHeight;
    let topPosition;
    let leftPosition;

    switch (dropDownMenuPosition) {
      case DROP_DOWN_MENU_POSITION_TOP_LEFT:
      case DROP_DOWN_MENU_POSITION_TOP_CENTER:
      case DROP_DOWN_MENU_POSITION_TOP_RIGHT:
        topPosition =
          anchorElementPosition.top - menuHeight - offset - appearFromDistance;
        break;

      case DROP_DOWN_MENU_POSITION_BOTTOM_LEFT:
      case DROP_DOWN_MENU_POSITION_BOTTOM_CENTER:
      case DROP_DOWN_MENU_POSITION_BOTTOM_RIGHT:
      default:
        topPosition =
          anchorElementPosition.top +
          anchorElementPosition.height +
          offset +
          appearFromDistance;
        break;

      case DROP_DOWN_MENU_POSITION_BOTTOM_LEFT_EDGE:
      case DROP_DOWN_MENU_POSITION_BOTTOM_RIGHT_EDGE:
        topPosition = anchorElementPosition.top + offset - appearFromDistance;
        break;
    }

    if (fullWidthMenuMode) {
      width = anchorElementPosition.width;
      leftPosition = anchorElementPosition.left;
    } else {
      switch (dropDownMenuPosition) {
        case DROP_DOWN_MENU_POSITION_BOTTOM_LEFT_EDGE:
          leftPosition =
            anchorElementPosition.left -
            anchorElement.parentElement.offsetWidth;
          break;

        case DROP_DOWN_MENU_POSITION_BOTTOM_RIGHT_EDGE:
          leftPosition =
            anchorElementPosition.left +
            anchorElement.parentElement.offsetWidth;
          break;

        case DROP_DOWN_MENU_POSITION_TOP_LEFT:
        case DROP_DOWN_MENU_POSITION_BOTTOM_LEFT:
          leftPosition = anchorElementPosition.left + offsetX;
          break;

        case DROP_DOWN_MENU_POSITION_TOP_RIGHT:
        case DROP_DOWN_MENU_POSITION_BOTTOM_RIGHT:
          leftPosition =
            anchorElementPosition.left +
            anchorElementPosition.width -
            menuWidth -
            offsetX;
          break;

        case DROP_DOWN_MENU_POSITION_TOP_CENTER:
        case DROP_DOWN_MENU_POSITION_BOTTOM_CENTER:
        default:
          leftPosition =
            anchorElementPosition.left +
            anchorElementPosition.width / 2 -
            menuWidth / 2 +
            offsetX;
          break;
      }
    }

    const screenVerticalOffset = 10;
    const minDropDownItemHeight = 56;
    const dropDownMenuVerticalPadding = 16;
    const menuMinHeight =
      minDropDownItemHeight * 3 + dropDownMenuVerticalPadding;

    if (topOrientation) {
      const isMenuBottomEdgeOutOfScreen = topPosition < screenVerticalOffset;

      if (isMenuBottomEdgeOutOfScreen) {
        maxHeight = Math.max(
          anchorElementPosition.top - screenVerticalOffset,
          menuMinHeight,
        );
        topPosition =
          anchorElementPosition.top - maxHeight - offset - appearFromDistance;

        if (maxHeight === menuMinHeight) {
          topPosition = 0;
        }
      }
    }

    if (bottomOrientation) {
      const menuBottomEdgePosition = topPosition + menuHeight;
      const isMenuBottomEdgeOutOfScreen =
        menuBottomEdgePosition + screenVerticalOffset > window.innerHeight;

      if (isMenuBottomEdgeOutOfScreen) {
        maxHeight = Math.max(
          window.innerHeight - topPosition - screenVerticalOffset,
          menuMinHeight,
        );

        if (maxHeight === menuMinHeight) {
          topPosition = window.innerHeight - maxHeight - screenVerticalOffset;
        }
      }
    }

    setDropDownMenuStyle({
      opacity: 1,
      position: 'fixed',
      width,
      maxHeight,
      top: topPosition,
      left: leftPosition,
      transform: closedStateTransformStyle,
    });
  }, [
    anchorElement,
    dropDownMenuData,
    dropDownMenuPosition,
    offset,
    offsetX,
    setDropDownMenuStyle,
    closedStateTransformStyle,
    appearFromDistance,
  ]);

  const handleClickOutsideDropDownMenu = useCallback(
    (e) => {
      const anchorElement = anchorElementRef.current;
      const dropDownMenuIsOpen = dropDownMenuIsOpenRef.current;
      const dropDownMenuElement = document.getElementById(DROP_DOWN_MENU_ID);
      const clickedOnTheAnchorElement =
        e.target === anchorElement || anchorElement?.control === e.target;

      if (
        !dropDownMenuIsOpen ||
        !anchorElement ||
        !dropDownMenuElement ||
        clickedOnTheAnchorElement
      )
        return;

      const isClickInsideAnchorElement = anchorElement.contains(e.target);
      const isClickInsideDropDownMenu = dropDownMenuElement.contains(e.target);

      if (isClickInsideAnchorElement || isClickInsideDropDownMenu) return;

      hideDropDownMenu();
    },
    [hideDropDownMenu],
  );

  const handleDropDownMenuResize = useCallback(() => {
    if (!dropDownMenuIsOpen) return;

    const anchorElementStillInDOM = document.body.contains(
      anchorElementRef.current,
    );

    if (!anchorElementStillInDOM) {
      hideDropDownMenu();

      return;
    }

    calculateDropDownMenuPosition();
  }, [dropDownMenuIsOpen, calculateDropDownMenuPosition, hideDropDownMenu]);

  useEffect(() => {
    dropDownMenuIsOpenRef.current = dropDownMenuIsOpen;
  }, [dropDownMenuIsOpen]);

  useEffect(() => {
    anchorElementRef.current = anchorElement;
  }, [anchorElement]);

  return {
    calculateDropDownMenuPosition,
    dropDownMenuData,
    dropDownMenuId,
    dropDownMenuIsOpen,
    dropDownMenuStyle,
    handleClickOutsideDropDownMenu,
    handleDropDownKeyDown,
    handleDropDownMenuResize,
    hideDropDownMenu,
    showDropDownMenu,
    updateDropDownMenuData,
  };
}
