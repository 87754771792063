import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Document, Page, pdfjs } from 'react-pdf';
import { Wrapper } from './PdfViewer.styled';

import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

const PdfViewer = ({
  file,
  pageScale,
  onDocumentLoadError,
  onDocumentLoadSuccess,
}) => {
  const [numPages, setNumPages] = useState();

  const handleDocumentLoadSuccess = useCallback(
    ({ numPages }) => {
      setNumPages(numPages);
      onDocumentLoadSuccess?.();
    },
    [onDocumentLoadSuccess],
  );

  return (
    <Wrapper>
      <Document
        file={file}
        onLoadSuccess={handleDocumentLoadSuccess}
        onLoadError={onDocumentLoadError}
      >
        {Array.from(new Array(numPages), (_, index) => (
          <Page key={index} pageNumber={index + 1} scale={pageScale} />
        ))}
      </Document>
    </Wrapper>
  );
};

PdfViewer.propTypes = {
  file: PropTypes.string.isRequired,
  pageScale: PropTypes.number,
  onDocumentLoadError: PropTypes.func,
  onDocumentLoadSuccess: PropTypes.func,
};

export default PdfViewer;
