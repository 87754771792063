import React, {
  useEffect,
  useCallback,
  useMemo,
  useContext,
  useRef,
  useState,
} from 'react';
import cadex from '@cadexchanger/web-toolkit';
import useCanvasSelectionFetches from '@app/hooks/canvasselection/useCanvasSelectionFetches';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectActiveCanvasSelectionInput,
  selectActiveCanvasSelectionOperator,
  selectCanvasSelectionConfig,
  selectCanvasSelectionMode,
  selectConfirmCanvasSelection,
  selectKeepCurrentSelections,
  setCanvasSelectionMode,
} from '@app/reducers/workflowSlice';
import useOperator from '@app/hooks/operators/useOperator';
import useOperatorSettings from '@app/hooks/operatorSettings/useOperatorSettings';
import PropTypes from 'prop-types';
import { VisualizationContext } from '@app/contexts/VisualizationContext';
import * as THREE from 'three';
import { BrepShapeFinder, BrepShapeIdFinder } from '@app/utils/canvasSelection';
import { useIntl } from 'react-intl';
import { ModalDataTypes } from '@app/constants/modalDataTypes';
import useDialog from '@app/hooks/useDialog';
import {
  ORTHOGRAPHIC_CAMERA_DEFAULT_POSITION,
  ORTHOGRAPHIC_CAMERA_MAX_ZOOM,
  orthographicCameraDirections,
} from '@constants/camera';
import { hexToRgbNormalised } from '@app/stylesheets/helpers';
import {
  BASE_CDXWEB_FILE_NAME,
  BREP_OUTPUT_NAME,
  SELECTION_MODES,
  SHAPE_TYPE_SELECTION_MODE_MAP,
} from '@app/constants/canvasSelection';
import useCanvasSelection from '@app/hooks/canvasselection/useCanvasSelection';

const entityColour = '#FF00FF';
const boundaryColour = '#000000';

const CanvasSelection = ({ form: { setValue, watch } }) => {
  const scene = useRef(null);
  const viewport = useRef(null);
  const model = useRef({});
  const intl = useIntl();
  const dispatch = useDispatch();
  const [activeShapeIdSelections, setActiveShapeIdSelections] = useState([]);
  const [activeModelSelection, setActiveModelSelection] = useState(null);

  const { canvasSelectionObjectFetch } = useCanvasSelectionFetches();
  const activeInputId = useSelector(selectActiveCanvasSelectionInput);
  const targetOperator = useSelector(selectActiveCanvasSelectionOperator);
  const selectionMode = useSelector(selectCanvasSelectionMode);
  const keepCurrentSelections = useSelector(selectKeepCurrentSelections);
  const confirmSelection = useSelector(selectConfirmCanvasSelection);
  const selectionConfig = useSelector(selectCanvasSelectionConfig);
  const {
    maxSelections,
    modelInput: modelInputName,
    shapeIdInput,
  } = selectionConfig;
  const { getDefaultOperators } = useOperator();
  const {
    getSelectedOperatorByValueId,
    getOperatorInput,
    getOperatorInputs,
    getOperatorOutputs,
  } = useOperatorSettings();
  const { deselectCanvasSelectionField } = useCanvasSelection();
  const {
    controls,
    setCanvasSelectionCamera,
    camera,
    orthographicCameraMode,
    cameraConfig,
    printer,
  } = useContext(VisualizationContext);
  const { showDialog } = useDialog();

  const cameraDirection = cameraConfig?.position;
  const selectedOperator = getSelectedOperatorByValueId(activeInputId);
  const selectedOperatorId = selectedOperator?.id;
  const activeInput = getOperatorInput(selectedOperator, activeInputId);
  const allInputs = getOperatorInputs(selectedOperator);

  const defaultOperators = useMemo(
    () => getDefaultOperators(),
    [getDefaultOperators],
  );
  const defaultOperator = useMemo(
    () => defaultOperators.find(({ name }) => selectedOperator?.name === name),
    [defaultOperators, selectedOperator],
  );
  const defaultOperatorTargetSetting = useMemo(
    () =>
      defaultOperator?.settings.find(({ name }) => name === activeInput?.name),
    [defaultOperator, activeInput],
  );
  const modelInput = allInputs?.find((i) => i.name === modelInputName);
  const idInput = allInputs?.find((i) => i.name === shapeIdInput);
  const originalInput = allInputs?.find(
    (i) => i.name === defaultOperatorTargetSetting?.name,
  );

  const modelPath = `${selectedOperatorId}.${modelInput?.id}`;
  const idPath = `${selectedOperatorId}.${idInput?.id}`;
  const targetPath = `${selectedOperatorId}.${originalInput?.id}`;
  const watchReferencedModelInput = watch(modelPath);
  const watchReferencedShapeIdInput = watch(idPath);

  const setInputFieldValue = useCallback(() => {
    if (activeModelSelection) {
      setValue(targetPath, activeModelSelection, {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue(modelPath, activeModelSelection, {
        shouldTouch: true,
        shouldDirty: true,
      });
    }
    if (activeShapeIdSelections.length > 0) {
      setValue(idPath, activeShapeIdSelections.join(), {
        shouldTouch: true,
        shouldDirty: true,
      });
    }
  }, [
    setValue,
    idPath,
    modelPath,
    targetPath,
    activeModelSelection,
    activeShapeIdSelections,
  ]);

  const showErrorDialog = useCallback(
    (subtitleId, subtitleDefault) => {
      showDialog(ModalDataTypes.PROMPT, {
        dataTestId: 'canvas-selection-design-id-error',
        title: intl.formatMessage({
          id: 'dialogs.title.attention',
          defaultMessage: 'Attention',
        }),
        subtitle: intl.formatMessage({
          id: subtitleId,
          defaultMessage: subtitleDefault,
        }),
        secondaryButtonLabel: '',
      });
    },
    [showDialog, intl],
  );

  const findShapeId = useCallback(
    async (chosenItem) => {
      // traverse up the nodes to find the design ID we have picked
      let currentNode = chosenItem.node;
      let pickedOperatorId;
      let brepId;
      while (currentNode) {
        if (currentNode.operatorId) {
          pickedOperatorId = currentNode.operatorId;
          brepId = currentNode.brepId;
          break;
        }
        currentNode = currentNode.parent;
      }

      if (!pickedOperatorId || !brepId) {
        showErrorDialog(
          'canvasselection.error.cannot_find_surface',
          'Something went wrong trying to find the surface you selected. Please try again.',
        );
        return;
      }

      let pickedShapeId = -1;
      let duplicateShapeIdFound = false;
      const pickedModel = model.current[pickedOperatorId];

      for (const entity of chosenItem.entities()) {
        await pickedModel.accept(
          new BrepShapeIdFinder(entity.shape, (foundShapeId) => {
            if (pickedShapeId !== -1) {
              duplicateShapeIdFound = true;
            }
            pickedShapeId = foundShapeId;
          }),
        );
      }

      if (duplicateShapeIdFound) {
        showErrorDialog(
          'canvasselection.error.duplicate_shape_ids',
          'The supplied STEP file contains duplicate shapes and this surface cannot be selected. Please try again with a different STEP file.',
        );
        return;
      }

      if (pickedShapeId !== -1) {
        return {
          shapeId: pickedShapeId,
          modelId: brepId,
        };
      } else {
        showErrorDialog(
          'canvasselection.error.cannot_find_surface',
          'Something went wrong trying to find the surface you selected. Please try again.',
        );
      }
      return {};
    },
    [showErrorDialog],
  );

  const onSelectionChanged = useCallback(
    async (e) => {
      const added = e.added;
      const removed = e.removed;
      let newShapeIdSelections = [
        ...(maxSelections === 1 ? [] : activeShapeIdSelections),
      ];

      for (const chosenItem of added) {
        if (newShapeIdSelections.length >= maxSelections) {
          scene.current.selectionManager.deselect(chosenItem, false);
          setActiveShapeIdSelections(newShapeIdSelections);
          return;
        }
        const { shapeId, modelId } = await findShapeId(chosenItem);
        if (shapeId) {
          newShapeIdSelections.push(shapeId);
        }
        if (modelId && !activeModelSelection) {
          setActiveModelSelection(modelId);
        }
      }

      for (const chosenItem of removed) {
        const { shapeId, modelId } = await findShapeId(chosenItem);
        if (shapeId) {
          newShapeIdSelections = newShapeIdSelections.filter(
            (id) => id !== shapeId,
          );
        }
        if (modelId && newShapeIdSelections.length === 0) {
          setActiveModelSelection(null);
        }
      }
      setActiveShapeIdSelections(newShapeIdSelections);
    },
    [findShapeId, activeModelSelection, activeShapeIdSelections, maxSelections],
  );

  const fetchFile = useCallback(
    (designId) => async (fileName) => {
      return await canvasSelectionObjectFetch(designId, fileName);
    },
    [canvasSelectionObjectFetch],
  );

  const matchExistingScene = useCallback(
    async (sceneNode) => {
      // position the object at bed origin
      const { bed } = printer;
      const { baseTransformationMatrix } = bed;

      const matrix = baseTransformationMatrix.clone();
      const basePosition = new THREE.Vector3();
      basePosition.setFromMatrixPosition(matrix);
      basePosition.multiplyScalar(0.02);
      matrix.setPosition(basePosition);
      const scaleMatrix = new THREE.Matrix4().makeScale(0.02, 0.02, 0.02);
      matrix.multiply(scaleMatrix);
      sceneNode.transformation = new cadex.ModelData_Transformation(
        matrix.elements,
      );
      // actually draw it on the screen
      scene.current.addRoot(sceneNode);
      await scene.current.update();
    },
    [printer],
  );

  /**
   * Sets the gravity point of camera rotation according to the provided target
   */
  const setGravityPoint = useCallback((target) => {
    for (const inputHandler of viewport.current.inputManager.inputHandlers()) {
      if (inputHandler instanceof cadex.ModelPrs_CameraManipulationHandler) {
        inputHandler.rotateHandler.gravityPoint = new cadex.ModelData_Point(
          target.x,
          target.y,
          target.z,
        );
      }
    }
  }, []);

  /**
   * Align the camera position and rotation between Three.js and the WTK.
   */
  const alignCameraPositions = useCallback(() => {
    if (!controls.current || !camera.current) return;
    const wtkCamera = viewport.current.camera;

    wtkCamera.projectionType = cadex.ModelPrs_CameraProjectionType.Perspective;
    wtkCamera.fov = 15;

    const threeJsCamera = camera.current;
    let threeJsCameraPosition = threeJsCamera.position;
    const orbitControlsTarget = controls.current.target;
    if (orthographicCameraMode) {
      const [dx, dy, dz] =
        orthographicCameraDirections?.[cameraDirection] ||
        ORTHOGRAPHIC_CAMERA_DEFAULT_POSITION;
      threeJsCameraPosition = {
        x:
          threeJsCameraPosition.x +
          dx * (ORTHOGRAPHIC_CAMERA_MAX_ZOOM / threeJsCamera.zoom),
        y:
          threeJsCameraPosition.y +
          dy * (ORTHOGRAPHIC_CAMERA_MAX_ZOOM / threeJsCamera.zoom),
        z:
          threeJsCameraPosition.z +
          dz * (ORTHOGRAPHIC_CAMERA_MAX_ZOOM / threeJsCamera.zoom),
      };
    }
    wtkCamera.set(
      new cadex.ModelData_Point(
        threeJsCameraPosition.x,
        threeJsCameraPosition.y,
        threeJsCameraPosition.z,
      ),
      new cadex.ModelData_Point(
        orbitControlsTarget.x,
        orbitControlsTarget.y,
        orbitControlsTarget.z,
      ),
      new cadex.ModelData_Point(0, 0, 1),
      1,
      10000,
    );

    // get orbit style camera by setting the gravity point of the camera to
    // the orbit target rather than the centre of the bounding box (the default)
    setGravityPoint(controls.current.target);

    // ensure orbit is maintained when the camera is panned
    viewport.current.camera.addEventListener('changed', (e) =>
      setGravityPoint(e.target.target),
    );

    viewport.current.update();
    setCanvasSelectionCamera(viewport.current.camera);
  }, [
    controls,
    camera,
    setGravityPoint,
    orthographicCameraMode,
    cameraDirection,
    setCanvasSelectionCamera,
  ]);

  /**
   * Select a shape recursively in a node. Recurses into each node's children
   * to try and select a shape. Will do nothing if the shape cannot be selected.
   */
  const selectItem = useCallback((nodes, selectedShape) => {
    if (!selectedShape || nodes.length === 0) return;

    let selectionItem;
    let selected;
    for (const node of nodes) {
      selectionItem = new cadex.ModelPrs_SelectionItem(node, selectedShape);
      selected = scene.current.selectionManager.select(
        selectionItem,
        false,
        false,
      );

      if (selected) {
        return;
      } else {
        selectItem(node.childNodes(), selectedShape);
      }
    }
  }, []);

  const preselectFaceIfNecessary = useCallback(
    async (node, model, brepId) => {
      if (
        watchReferencedModelInput &&
        watchReferencedModelInput === brepId &&
        watchReferencedShapeIdInput
      ) {
        setActiveModelSelection(watchReferencedModelInput);
        const allEntities = watchReferencedShapeIdInput.split(',');
        const allShapesNum = allEntities.map((id) => Number(id));
        setActiveShapeIdSelections(allShapesNum);
        let newSelectionMode = selectionMode;
        for (const shapeId of allEntities) {
          let selectedShape;
          await model.accept(
            new BrepShapeFinder(shapeId, async (foundShape) => {
              if (foundShape) {
                newSelectionMode =
                  SHAPE_TYPE_SELECTION_MODE_MAP[foundShape.type];
                selectedShape = new cadex.ModelPrs_SelectedShapeEntity(
                  foundShape,
                );
              }
            }),
          );
          selectItem([node], selectedShape);
        }
        if (newSelectionMode != selectionMode) {
          dispatch(
            setCanvasSelectionMode({
              selectionMode: newSelectionMode,
              keepCurrentSelections: true,
            }),
          );
        }
      }
    },
    [
      watchReferencedModelInput,
      watchReferencedShapeIdInput,
      selectItem,
      dispatch,
      selectionMode,
    ],
  );

  const renderModel = useCallback(
    async (baseFileName, operatorId) => {
      // avoid expensive re-computation of already rendered designs
      if (model.current[operatorId]) {
        return;
      }
      const modelReader = new cadex.ModelData_ModelReader();
      model.current[operatorId] = new cadex.ModelData_Model();
      const allOutputs = getOperatorOutputs(operatorId);
      const brepOutput = allOutputs.find(
        (obj) => obj.name === BREP_OUTPUT_NAME,
      );
      if (!brepOutput) {
        showErrorDialog(
          'canvasselection.error.generic',
          'Something went wrong preparing canvas selection',
        );
        return;
      }
      await modelReader.loadModel(
        baseFileName,
        model.current[operatorId],
        fetchFile(brepOutput.value),
      );
      const aSceneNodeFactory = new cadex.ModelPrs_SceneNodeFactory();
      const sceneNode = await aSceneNodeFactory.createGraphFromModel(
        model.current[operatorId],
        cadex.ModelData_RepresentationMask.ModelData_RM_BRep,
      );
      if (!sceneNode) {
        showErrorDialog(
          'canvasselection.error.generic',
          'Something went wrong preparing canvas selection',
        );
        return;
      }
      sceneNode.operatorId = operatorId;
      sceneNode.brepId = brepOutput.id;
      const parentSceneNode = new cadex.ModelPrs_SceneNode();
      parentSceneNode.addChildNode(sceneNode);
      parentSceneNode.selectionMode = SELECTION_MODES[selectionMode].cadexMode;
      parentSceneNode.style = new cadex.ModelPrs_Style();
      const rgb = hexToRgbNormalised(entityColour);
      const highlightAppearance = new cadex.ModelData_Appearance(
        new cadex.ModelData_ColorObject(rgb.r, rgb.g, rgb.b, 0.5),
      );
      const selectedAppearance = new cadex.ModelData_Appearance(
        new cadex.ModelData_ColorObject(rgb.r, rgb.g, rgb.b, 1),
      );
      // increase line size on hover/selection (this does not work due to a bug in cadex)
      highlightAppearance.lineProperties = new cadex.ModelData_LineProperties(
        cadex.ModelData_LineType.Solid,
        100,
      );
      selectedAppearance.lineProperties = new cadex.ModelData_LineProperties(
        cadex.ModelData_LineType.Solid,
        100,
      );
      // increase vertex size on hover/selection to make it clearer
      highlightAppearance.pointProperties = new cadex.ModelData_PointProperties(
        20,
      );
      selectedAppearance.pointProperties = new cadex.ModelData_PointProperties(
        20,
      );
      parentSceneNode.style.highlightAppearance = highlightAppearance;
      parentSceneNode.style.selectionAppearance = selectedAppearance;
      const boundaryRgb = hexToRgbNormalised(boundaryColour);
      parentSceneNode.style.boundariesDefaultAppearance =
        new cadex.ModelData_Appearance(
          new cadex.ModelData_ColorObject(
            boundaryRgb.r,
            boundaryRgb.g,
            boundaryRgb.b,
            0.5,
          ),
        );
      parentSceneNode.displayMode =
        cadex.ModelPrs_DisplayMode.ShadedWithBoundaries;

      await matchExistingScene(parentSceneNode);
      await preselectFaceIfNecessary(
        parentSceneNode,
        model.current[operatorId],
        brepOutput.id,
      );
    },
    [
      fetchFile,
      matchExistingScene,
      showErrorDialog,
      getOperatorOutputs,
      preselectFaceIfNecessary,
      selectionMode,
    ],
  );

  const hideLogo = useCallback(() => {
    // the "magic formula" for hiding the logo, other approaches causes them to render
    // their logo again on the canvas via threejs
    document.querySelectorAll('.cadex-logo').forEach((el) => {
      el.style.width = 0;
      el.style.height = 0;
      el.style.border = 'none';
      el.style.padding = 1;
      el.style.background = 'transparent';
    });
  }, []);

  const clearAllSelections = useCallback(() => {
    scene.current.selectionManager.deselectAll(false);
    setActiveModelSelection(null);
    setActiveShapeIdSelections([]);
  }, []);

  useEffect(() => {
    if (!scene.current) return;
    if (!keepCurrentSelections) {
      clearAllSelections();
    }
    for (const root of scene.current.roots()) {
      root.selectionMode = SELECTION_MODES[selectionMode].cadexMode;
    }
    scene.current.update();
  }, [selectionMode, clearAllSelections, keepCurrentSelections]);

  useEffect(() => {
    scene.current = new cadex.ModelPrs_Scene();
    scene.current.selectionManager.pickTolerance = 200;
    viewport.current = new cadex.ModelPrs_ViewPort(
      { addDefaultViewCube: false },
      document.getElementById('interactive-canvas'),
    );
    viewport.current.attachToScene(scene.current);
    viewport.current.inputManager.isHoverEnabled = true;
    viewport.current.inputManager.pushInputHandler(
      new cadex.ModelPrs_HighlightingHandler(viewport.current),
    );
    hideLogo();
    return () => {
      viewport.current?.dispose();
      scene.current?.dispose();
    };
  }, [hideLogo]);

  useEffect(() => {
    for (const inputHandler of viewport.current.inputManager.inputHandlers()) {
      if (inputHandler instanceof cadex.ModelPrs_SelectionHandler) {
        if (maxSelections > 1) {
          // enable multiple selection by default, so no modifier is needed to select multiple entities
          inputHandler.multipleSelectionModifier =
            cadex.ModelPrs_KeyboardModifier.NoModifier;
        } else {
          // disable multiple selection if max selections is 1
          inputHandler.multipleSelectionModifier = undefined;
        }
      }
    }
  }, [maxSelections]);

  useEffect(() => {
    clearAllSelections();
    scene.current.clear();
    model.current = {};
  }, [targetOperator, clearAllSelections]);

  useEffect(() => {
    alignCameraPositions();
    renderModel(BASE_CDXWEB_FILE_NAME, targetOperator);
  }, [alignCameraPositions, renderModel, targetOperator]);

  useEffect(() => {
    if (confirmSelection) {
      setInputFieldValue();
      deselectCanvasSelectionField();
    }
  }, [confirmSelection, setInputFieldValue, deselectCanvasSelectionField]);

  useEffect(() => {
    scene.current.selectionManager.addEventListener(
      'selectionChanged',
      onSelectionChanged,
    );
    return () =>
      scene.current?.selectionManager?.removeEventListener(
        'selectionChanged',
        onSelectionChanged,
      );
  }, [onSelectionChanged]);

  return <div id="interactive-canvas" className="threejs" />;
};

CanvasSelection.propTypes = {
  form: PropTypes.object,
};

export default CanvasSelection;
