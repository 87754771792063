import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  confirmCanvasSelectionChanges,
  resetActiveCanvasSelectionInput,
  selectActiveCanvasSelectionInput,
  setActiveCanvasSelectionInput,
} from '@app/reducers/workflowSlice';
import { FileTypes } from '@app/constants/fileTypes';
import { OperatorInputTypes } from '@constants/operatorInputTypes';
import { BREP_OUTPUT_NAME } from '@app/constants/canvasSelection';
import { DROP_DOWN_FILE_FIELD_TYPES } from '@app/constants/operatorFieldTypes';
import useWorkflow from '@hooks/workflows/useWorkflow';
import useFile from '@hooks/files/useFile';
import useOperatorUtils from '../operators/useOperatorUtils';

export default function useCanvasSelection() {
  const activeCanvasSelectionInput = useSelector(
    selectActiveCanvasSelectionInput,
  );
  const { getSelectedWorkflowProjectId } = useWorkflow();
  const { getOperatorFromProvidedArg, getDefaultOperators, getOperator } =
    useOperatorUtils();
  const { getProjectFiles } = useFile();
  const dispatch = useDispatch();

  const getIsCanvasSelectionActive = useCallback(() => {
    return !!activeCanvasSelectionInput;
  }, [activeCanvasSelectionInput]);

  const getIsCanvasSelectionActiveForOperator = useCallback(
    (operatorOrId) => {
      const operator = getOperatorFromProvidedArg(operatorOrId);
      return operator.values.some((v) => activeCanvasSelectionInput === v.id);
    },
    [activeCanvasSelectionInput, getOperatorFromProvidedArg],
  );

  const getIsCanvasSelectionActiveForField = useCallback(
    (fieldInput) => activeCanvasSelectionInput === fieldInput?.id,
    [activeCanvasSelectionInput],
  );

  const getReferencedCanvasSelectionField = useCallback(
    (fieldInput, operatorOrId) => {
      const operator = getOperatorFromProvidedArg(operatorOrId);
      const defaultOperator = getDefaultOperators().find(
        ({ name }) => name === operator?.name,
      );

      const defaultOperatorTargetSetting = defaultOperator?.settings.find(
        ({ name }) => name === fieldInput?.name,
      );

      const referencedModelInputName =
        defaultOperatorTargetSetting?.selectionConfig?.modelInput;
      const referencedIdInputName =
        defaultOperatorTargetSetting?.selectionConfig?.shapeIdInput;
      const modelInput = operator?.values?.find(
        ({ name }) => name === referencedModelInputName,
      );
      const idInput = operator?.values?.find(
        ({ name }) => name === referencedIdInputName,
      );

      return {
        modelInput,
        idInput,
      };
    },
    [getDefaultOperators, getOperatorFromProvidedArg],
  );

  const doesFieldSupportCanvasSelection = useCallback(
    (operator = {}, fieldInput = {}) => {
      const defaultOperator = getDefaultOperators().find(
        ({ name }) => name === operator?.name,
      );

      const defaultOperatorTargetSetting = defaultOperator?.settings.find(
        ({ name }) => name === fieldInput?.name,
      );

      if (defaultOperatorTargetSetting?.selectionConfig) {
        return true;
      }

      return defaultOperator.settings.find(
        ({ selectionConfig }) =>
          selectionConfig?.shapeIdInput === fieldInput?.name ||
          selectionConfig?.modelInput === fieldInput?.name,
      );
    },
    [getDefaultOperators],
  );

  const doesOutputSupportCanvasSelection = useCallback(
    (operatorOrId, brepOut, getValues) => {
      const operator = getOperatorFromProvidedArg(operatorOrId);
      const isBrepOutput =
        brepOut?.name === BREP_OUTPUT_NAME ||
        brepOut?.type === OperatorInputTypes.GEOMETRY_BREP;

      // no brep output = no canvas selection
      if (!isBrepOutput) {
        return false;
      }

      // simplest case. operator has a brep output and it is filled with a brep. selection already available
      if (brepOut?.value) {
        return true;
      }

      // computed but no brep output. not selectable
      if (operator.computed && !brepOut?.value) {
        return false;
      }

      // next case. has Step file input which will produce a brep output when it is computed
      const fileInputs = operator.values.filter((v) =>
        DROP_DOWN_FILE_FIELD_TYPES.includes(v.type),
      );
      for (const fileInput of fileInputs) {
        const inputValue = getValues?.(`${operator.id}.${fileInput.id}`);
        if (inputValue) {
          const files = getProjectFiles(getSelectedWorkflowProjectId());
          const matchingFile = files.find((f) => f.id === inputValue);
          if (
            matchingFile?.filetype === FileTypes.step ||
            matchingFile?.filetype === FileTypes.stp
          ) {
            return true;
          }
        }
      }
      return false;
    },
    [getOperatorFromProvidedArg, getProjectFiles, getSelectedWorkflowProjectId],
  );

  const selectCanvasSelectionField = useCallback(
    (input, targetOperatorId) => {
      const targetOperator = getOperator(input?.operatorId);
      const defaultOperator = getDefaultOperators().find(
        ({ name }) => targetOperator?.name === name,
      );
      const defaultOperatorTargetSetting = defaultOperator?.settings.find(
        ({ name }) => name === input?.name,
      );
      dispatch(
        setActiveCanvasSelectionInput({
          activeInput: input?.id,
          targetOperator: targetOperatorId,
          selectionConfig: defaultOperatorTargetSetting?.selectionConfig,
        }),
      );
    },
    [dispatch, getDefaultOperators, getOperator],
  );

  const deselectCanvasSelectionField = useCallback(() => {
    dispatch(resetActiveCanvasSelectionInput());
  }, [dispatch]);

  const confirmCanvasSelectionField = useCallback(() => {
    dispatch(confirmCanvasSelectionChanges());
  }, [dispatch]);

  return {
    getIsCanvasSelectionActive,
    getIsCanvasSelectionActiveForOperator,
    getIsCanvasSelectionActiveForField,
    getReferencedCanvasSelectionField,
    doesFieldSupportCanvasSelection,
    doesOutputSupportCanvasSelection,
    selectCanvasSelectionField,
    deselectCanvasSelectionField,
    confirmCanvasSelectionField,
  };
}
