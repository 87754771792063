import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import useOperator from '@hooks/operators/useOperator';
import useOperatorSettings from '@app/hooks/operatorSettings/useOperatorSettings';
import {
  Content,
  ActionWrapper,
} from './WorkflowCanvasSelectionInputBar.styled';
import Paper from '@app/components/1-atoms/Paper';
import IconButton, {
  ICON_BUTTON_VARIANT_PLAIN,
  ICON_BUTTON_VARIANT_PRIMARY,
} from '@components/1-atoms/IconButton';
import ButtonDropDown from '../1-atoms/ButtonDropDown';
import { SELECTION_MODES } from '@app/constants/canvasSelection';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectActiveCanvasSelectionInput,
  selectActiveCanvasSelectionOperator,
  selectCanvasSelectionConfig,
  selectCanvasSelectionMode,
  setActiveCanvasSelectionTargetOperator,
  setCanvasSelectionMode,
} from '@app/reducers/workflowSlice';
import { MENU_LIST_ITEM_ENDING_BUTTON_TYPE_RADIO } from '../1-atoms/MenuListItem';
import useCanvasSelection from '@app/hooks/canvasselection/useCanvasSelection';

const WorkflowCanvasSelectionInputBar = () => {
  const { getOperator } = useOperator();
  const { getSelectedOperatorByValueId, getOperatorFieldOptions, getInput } =
    useOperatorSettings();
  const { deselectCanvasSelectionField, confirmCanvasSelectionField } =
    useCanvasSelection();
  const intl = useIntl();
  const dispatch = useDispatch();
  const activeInput = useSelector(selectActiveCanvasSelectionInput);
  const activeOperatorId = useSelector(selectActiveCanvasSelectionOperator);
  const selectionMode = useSelector(selectCanvasSelectionMode);
  const selectionConfig = useSelector(selectCanvasSelectionConfig);
  const activeOperator = getOperator(activeOperatorId);

  const getSelectionTypeDropDownOptions = useCallback(() => {
    return selectionConfig?.entityTypes?.map((type) => {
      const defn = SELECTION_MODES[type];
      return {
        label: intl.formatMessage({
          id: defn.translationKey,
          defaultMessage: type,
        }),
        selected: selectionMode === type,
        onClick: () =>
          dispatch(setCanvasSelectionMode({ selectionMode: type })),
        formFieldValue: {
          label: intl.formatMessage({
            id: defn.translationKey,
            defaultMessage: type,
          }),
          value: defn.cadexMode.toString(),
        },
      };
    });
  }, [dispatch, selectionMode, intl, selectionConfig?.entityTypes]);

  const getModelSelectionDropDownOptions = useCallback(() => {
    const targetOperator = getSelectedOperatorByValueId(activeInput);
    const input = getInput(activeInput);
    let options = getOperatorFieldOptions(targetOperator, input, false).filter(
      (o) => o.isCanvasSelectionOption && o.dependencyIsComputed,
    );

    options = options.filter(
      (o, i, arr) =>
        arr.findIndex((o2) => o2.operatorId === o.operatorId) === i,
    );

    return options.map((o) => {
      return {
        label: o.tag,
        selected: activeOperatorId === o.operatorId,
        onClick: () =>
          dispatch(setActiveCanvasSelectionTargetOperator(o.operatorId)),
        leadingIconName: 'arrow_selector_tool_0',
        formFieldValue: {
          label: o.tag,
          value: o.operatorId,
        },
        endingButton: {
          type: MENU_LIST_ITEM_ENDING_BUTTON_TYPE_RADIO,
          checked: activeOperatorId === o.operatorId,
          value: o.operatorId,
          onChange: () =>
            dispatch(setActiveCanvasSelectionTargetOperator(o.operatorId)),
        },
      };
    });
  }, [
    activeInput,
    getSelectedOperatorByValueId,
    getInput,
    getOperatorFieldOptions,
    dispatch,
    activeOperatorId,
  ]);

  return (
    <Paper>
      <Content>
        <ActionWrapper>
          <ButtonDropDown
            dataTestId="canvas-selection-model-selection"
            leadingIconName="visibility_on_0"
            dropDownMenuItems={getModelSelectionDropDownOptions()}
          >
            {activeOperator?.tag}
          </ButtonDropDown>
        </ActionWrapper>
        <ActionWrapper>
          <ButtonDropDown
            dataTestId="canvas-selection-selection-type"
            dropDownMenuItems={getSelectionTypeDropDownOptions()}
          >
            {intl.formatMessage({
              id: SELECTION_MODES[selectionMode]?.translationKey,
              defaultMessage: selectionMode,
            })}
          </ButtonDropDown>
        </ActionWrapper>
        <ActionWrapper>
          <IconButton
            variant={ICON_BUTTON_VARIANT_PLAIN}
            iconName="close_0"
            onClick={deselectCanvasSelectionField}
          />
        </ActionWrapper>
        <ActionWrapper>
          <IconButton
            variant={ICON_BUTTON_VARIANT_PRIMARY}
            iconName="done_0"
            onClick={confirmCanvasSelectionField}
          />
        </ActionWrapper>
      </Content>
    </Paper>
  );
};

WorkflowCanvasSelectionInputBar.propTypes = {};

export default WorkflowCanvasSelectionInputBar;
