import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { printerQueryKeys } from '@hooks/printers/usePrinterQueries';
import { extrusionTypeToMaterialTypes } from '@utils/material';
import { materialConstants } from '@constants/materials';
import { isEmpty } from 'lodash';
import { printerConstants } from '@constants/printers/printerConstants';

export default function usePrinter() {
  const { printerId: selectedPrinterId } = useParams();
  const queryClient = useQueryClient();

  const getPrinters = useCallback(
    () =>
      queryClient
        .getQueryData(printerQueryKeys.printers)
        ?.sort((a, b) => a.name.localeCompare(b.name)),
    [queryClient],
  );

  const getPrinter = useCallback(
    (printerId) =>
      queryClient.getQueryData(printerQueryKeys.printer(printerId)),
    [queryClient],
  );

  const getPrinterFromPrinters = useCallback(
    (printerId) => getPrinters()?.find((printer) => printer.id === printerId),
    [getPrinters],
  );

  const getSelectedPrinter = useCallback(
    () => getPrinter(selectedPrinterId),
    [selectedPrinterId, getPrinter],
  );

  const getAllPrinterDefinitions = useCallback(
    () => queryClient.getQueryData(printerQueryKeys.allPrinterDefinitions),
    [queryClient],
  );

  const getMachineDefinitions = useCallback(
    () =>
      queryClient.getQueryData(printerQueryKeys.machineDefinitions) ||
      getAllPrinterDefinitions()?.machineDefinitions,
    [queryClient, getAllPrinterDefinitions],
  );

  const getProgramTypeDefinitions = useCallback(
    () =>
      queryClient.getQueryData(printerQueryKeys.programTypeDefinitions) ||
      getAllPrinterDefinitions()?.programTypeDefinitions,
    [queryClient, getAllPrinterDefinitions],
  );

  const getPrintingBedDefinitions = useCallback(
    () =>
      queryClient.getQueryData(printerQueryKeys.printingBedDefinitions) ||
      getAllPrinterDefinitions()?.printingBedDefinitions,
    [queryClient, getAllPrinterDefinitions],
  );

  const getExtruderDefinitions = useCallback(
    () =>
      queryClient.getQueryData(printerQueryKeys.extruderDefinitions) ||
      getAllPrinterDefinitions()?.extruderDefinitions,
    [queryClient, getAllPrinterDefinitions],
  );

  const getNozzles = useCallback(
    () =>
      queryClient
        .getQueryData(printerQueryKeys.allNozzles)
        ?.sort((a, b) => a.name.localeCompare(b.name)),
    [queryClient],
  );

  const getExtruderSettingsTypes = useCallback(
    () => queryClient.getQueryData(printerQueryKeys.extruderSettingsTypes),
    [queryClient],
  );

  const getPrintingBedSettingsTypes = useCallback(
    () => queryClient.getQueryData(printerQueryKeys.printingBedSettingsTypes),
    [queryClient],
  );

  const getRobotBrandDefinitions = useCallback(
    () => queryClient.getQueryData(printerQueryKeys.robotBrandDefinitions),
    [queryClient],
  );

  const getExtruderDefinition = useCallback(
    (printer) => {
      const extruderType = printer?.extruderType;

      if (extruderType == null) {
        return null;
      }

      if (extruderType !== printerConstants.CUSTOM.value) {
        return getExtruderDefinitions().find(
          (definition) => definition.displayName === extruderType,
        );
      }

      return { extrusionType: printer?.customExtruderDefinitionExtrusionType };
    },
    [getExtruderDefinitions],
  );

  const invalidatePrinterNozzleQuery = useCallback(
    (printerId) =>
      queryClient.invalidateQueries({
        queryKey: printerQueryKeys.nozzles(printerId),
        refetchType: 'all',
      }),
    [queryClient],
  );

  const invalidatePrinterQuery = useCallback(
    (printerId) =>
      queryClient.invalidateQueries({
        queryKey: printerQueryKeys.printer(printerId),
        refetchType: 'all',
      }),
    [queryClient],
  );

  const getPrinterNozzles = useCallback(
    (printerId) => {
      let nozzles = queryClient.getQueryData(
        printerQueryKeys.nozzles(printerId),
      );

      if (isEmpty(nozzles)) {
        nozzles = queryClient
          .getQueryData(printerQueryKeys.allNozzles)
          ?.filter((nozzle) => nozzle.printerId === printerId);
      }

      return nozzles?.sort((a, b) => a.name.localeCompare(b.name));
    },
    [queryClient],
  );

  const getMaterials = useCallback(
    () =>
      queryClient
        .getQueryData(printerQueryKeys.materials)
        ?.sort((a, b) => a.materialName.localeCompare(b.materialName)),
    [queryClient],
  );

  const getMaterialTypes = useCallback(() => {
    const CUSTOM_EXTRUDER_TYPE = 'CUSTOM';
    const printers = getPrinters();
    const extruderDefinitions = getExtruderDefinitions();
    const extruderDefinitionsByPrinters = printers.reduce((acc, printer) => {
      const { extruderType } = printer;

      if (extruderType == null) {
        acc[printer?.id] = null;
        return acc;
      }

      let extruderDef = {
        extrusionType: printer?.customExtruderDefinitionExtrusionType,
      };

      if (extruderType !== CUSTOM_EXTRUDER_TYPE) {
        extruderDef = extruderDefinitions.find(
          (definition) => definition.displayName === extruderType,
        );
      }

      acc[printer?.id] = extruderDef;

      return acc;
    }, {});

    const printerMaterialTypes = Object.fromEntries(
      Object.entries(extruderDefinitionsByPrinters).map(
        ([key, extruderDef]) => [
          key,
          extrusionTypeToMaterialTypes[extruderDef?.extrusionType],
        ],
      ),
    );

    return printerMaterialTypes;
  }, [getPrinters, getExtruderDefinitions]);

  const getPrinterMaterialTypes = useCallback(
    (printerId) => {
      const materialTypes = getMaterialTypes();
      return materialTypes[printerId];
    },
    [getMaterialTypes],
  );

  const getIsPrinterSupportsNozzle = useCallback(
    (printerId) => {
      const printerMaterialTypes = getPrinterMaterialTypes(printerId);
      const enableNozzleSelection =
        !printerMaterialTypes?.includes(
          materialConstants.MATERIAL_METAL_WIRE,
        ) &&
        !printerMaterialTypes?.includes(
          materialConstants.MATERIAL_METAL_POWDER,
        );

      return enableNozzleSelection;
    },
    [getPrinterMaterialTypes],
  );

  const getPrinterMaterials = useCallback(
    (printerId) => {
      const materials = getMaterials();
      const printerMaterialTypes = getPrinterMaterialTypes(printerId);
      const printerMaterials = materials.filter((material) =>
        printerMaterialTypes?.includes(material.type),
      );

      return printerMaterials;
    },
    [getMaterials, getPrinterMaterialTypes],
  );

  const getIsPrinterNozzlesQueryFetching = useCallback(
    (printerId) =>
      queryClient.isFetching({ queryKey: printerQueryKeys.nozzles(printerId) }),
    [queryClient],
  );

  const getProgramFileType = useCallback(
    (programType) =>
      queryClient.getQueryData(printerQueryKeys.programFileType(programType)),
    [queryClient],
  );

  const getPrintersRecording = useCallback(
    () => queryClient.getQueryData(printerQueryKeys.printersRecording),
    [queryClient],
  );

  const updatePrinterMonitorStatusData = useCallback(
    (printerId = '', status = {}) => {
      const updateMonitor = (printersMonitorData = []) => {
        const updatedPrintersMonitor = printersMonitorData.map((printer) => {
          if (printer.id === printerId) {
            return {
              ...printer,
              printerStatus: status,
            };
          }

          return printer;
        });

        return updatedPrintersMonitor;
      };

      queryClient.setQueryData(printerQueryKeys.printersMonitor, updateMonitor);
    },
    [queryClient],
  );

  const updatePrintersCache = useCallback(
    (printerResponse) => {
      const updatePrinters = (printers = []) => {
        return printers.map((printer) => {
          if (printer.id === printerResponse?.id) {
            return printerResponse;
          }
          return printer;
        });
      };

      queryClient.setQueryData(printerQueryKeys.printers, updatePrinters);
      queryClient.setQueryData(printerQueryKeys.printer(printerResponse?.id), {
        ...printerResponse,
      });
    },
    [queryClient],
  );

  return {
    getAllPrinterDefinitions,
    getExtruderDefinitions,
    getExtruderDefinition,
    getIsPrinterNozzlesQueryFetching,
    getIsPrinterSupportsNozzle,
    getMachineDefinitions,
    getMaterials,
    getMaterialTypes,
    getPrinter,
    getPrinterFromPrinters,
    invalidatePrinterNozzleQuery,
    invalidatePrinterQuery,
    getPrinterMaterials,
    getPrinterMaterialTypes,
    getNozzles,
    getPrinterNozzles,
    getPrinters,
    getPrintingBedDefinitions,
    getProgramTypeDefinitions,
    getSelectedPrinter,
    getExtruderSettingsTypes,
    getProgramFileType,
    getPrintingBedSettingsTypes,
    getRobotBrandDefinitions,
    getPrintersRecording,
    updatePrinterMonitorStatusData,
    updatePrintersCache,
  };
}
