import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { difference, isEmpty, isEqual, isUndefined } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import usePrevious from '@hooks/usePrevious';
import useActiveItems from '@hooks/useActiveItems';
import useWorkflow from '@hooks/workflows/useWorkflow';
import useWorkflowList from '@hooks/workflows/useWorkflowList';
import useWorkflowHiddenInputs from '@hooks/workflows/useWorkflowHiddenInputs';
import useOperator from '@hooks/operators/useOperator';
import { operatorMutationKeys } from '@hooks/operators/useOperatorMutations';
import { getUserAccountPreference } from '@selectors/loginSelectors';
import { getQuickStartRedirectingToWorkflow } from '@selectors/quickStartSelectors';
import { setFullScreenOperatorId } from '@reducers/workflowSlice';
import { quickStartRedirectionCompleted } from '@actions/quickStartActions';
import { DROP_DOWN_MENU_POSITION_BOTTOM_CENTER } from '@components/2-molecules/DropDownMenu';
import SettingBar from '@components/2-molecules/SettingBar';
import MessageBox from '@components/2-molecules/MessageBox';
import WorkflowOperators from '@containers/WorkflowOperators';
import { ContentWrapper } from './WorkflowSetting.styled';
import useVersion from '@app/hooks/version/useVersion';
import {
  HIDE_UPDATE_PRINTER_BANNER_KEY,
  HIDE_UPGRADE_BANNER_KEY,
  LAST_UPDATE_PRINTER_TIMESTAMP_DISMISSED_KEY,
  LAST_UPGRADE_VERSION_DISMISSED_KEY,
} from '@app/constants/utilityConstants';
import {
  addToLocalStorage,
  getFromLocalStorage,
} from '@app/utils/localStorage';
import { compare } from 'compare-versions';
import useFeatureFlags from '@app/hooks/featureflags/useFeatureFlags';
import { FULL_SCREEN_OPERATOR_FEATURE } from '@app/constants/featureFlagConstants';

export const WORKFLOW_SETTING_BAR_ID = 'workflow-setting-bar';

const WorkflowSetting = ({
  drag = {},
  handleSettingBarCollapse,
  isWorkflowSettingBarVisible,
  resetPreviousSettingBarCollapseState,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const form = useFormContext();
  const contentWrapperRef = useRef(null);
  const { isFeatureFlagEnabled } = useFeatureFlags();
  const isFullScreenOperatorEnabled = isFeatureFlagEnabled(
    FULL_SCREEN_OPERATOR_FEATURE,
  );
  const fullScreenOperatorPreference = useSelector(
    getUserAccountPreference('USE_FULL_SCREEN_OPERATORS'),
  );
  const quickStartRedirectingToWorkflow = useSelector(
    getQuickStartRedirectingToWorkflow,
  );
  const [skipDeprecatedMessage, setSkipDeprecatedMessage] = useState(
    !!getFromLocalStorage(HIDE_UPGRADE_BANNER_KEY),
  );
  const [skipDeprecatedPrinterMessage, setSkipDeprecatedPrinterMessage] =
    useState(!!getFromLocalStorage(HIDE_UPDATE_PRINTER_BANNER_KEY));

  const isFullScreenOperatorPreference =
    isFullScreenOperatorEnabled && fullScreenOperatorPreference;

  const {
    activeItems,
    addActiveItems,
    removeActiveItems,
    resetActiveItems,
    isItemActive,
  } = useActiveItems();
  const {
    getSelectedWorkflow,
    getWorkflowOperators,
    getIsWorkflowPublic,
    getIsWorkflowComputing,
    getWorkflowPrinter,
    getIsWorkflowEditable,
    getIsWorkflowBackwardsCompatible,
  } = useWorkflow();
  const { getIsComputationAvailable, getMutationCacheByKey } = useOperator();
  const { getWorkflowsListDropDownMenu, getSelectedWorkflowMoreDropDownMenu } =
    useWorkflowList();

  const { getComputationVersion } = useVersion();
  const version = getComputationVersion();

  useWorkflowHiddenInputs();

  const workflow = getSelectedWorkflow();
  const operators = getWorkflowOperators(workflow);

  const isWorkflowBackwardsCompatible =
    getIsWorkflowBackwardsCompatible(workflow);
  const isWorkflowEditable = getIsWorkflowEditable(workflow);
  const isWorkflowComputing = getIsWorkflowComputing(workflow);
  const isWorkflowPublic = getIsWorkflowPublic(workflow);

  const workflowPrinter = getWorkflowPrinter(workflow.id);

  const previousOperators = usePrevious(operators);

  const computationIsAvailable = useMemo(
    () => getIsComputationAvailable(form),
    [getIsComputationAvailable, form],
  );
  const isWorkflowEmpty = !operators?.length;

  const optionalSettingBarProps = useMemo(() => {
    let optionalProps = {};

    if (computationIsAvailable) {
      optionalProps = {
        loading: false,
        footerPrimaryButtonDisabled: false,
      };
    }

    if (
      !isWorkflowEmpty &&
      isWorkflowEditable &&
      (isWorkflowBackwardsCompatible || DESKTOP_APP)
    ) {
      optionalProps = {
        ...optionalProps,
        footerPrimaryButtonIconName: 'sync_0',
        footerPrimaryButtonLabel: intl.formatMessage({
          id: 'general.compute_all',
          defaultMessage: 'Compute all',
        }),
        withFooterDivider: true,
      };
    }

    return optionalProps;
  }, [
    isWorkflowEditable,
    computationIsAvailable,
    isWorkflowEmpty,
    isWorkflowBackwardsCompatible,
    intl,
  ]);

  const operatorIds = useMemo(
    () => operators?.map((operator) => operator?.id),
    [operators],
  );

  const expandAll = useCallback(() => {
    addActiveItems(operatorIds);
  }, [addActiveItems, operatorIds]);

  const collapseAll = useCallback(() => {
    resetActiveItems();
  }, [resetActiveItems]);

  const toggleOperatorExpansion = useCallback(
    (operatorId) => () => {
      if (isItemActive(operatorId)) {
        removeActiveItems([operatorId]);

        return;
      }

      addActiveItems([operatorId]);
    },
    [isItemActive, removeActiveItems, addActiveItems],
  );

  const getIsTemplateExploaded = useCallback(() => {
    if (
      isUndefined(previousOperators) ||
      isEqual(previousOperators, operators)
    ) {
      return;
    }

    const filterTemplateOperators = ({ templateId }) => !!templateId;
    const previousTemplateOperators =
      previousOperators?.filter?.(filterTemplateOperators) || [];
    const currentTemplateOperators =
      operators?.filter?.(filterTemplateOperators) || [];

    const templateExploaded =
      currentTemplateOperators.length < previousTemplateOperators.length &&
      operators?.length >= previousOperators?.length;

    return templateExploaded;
  }, [previousOperators, operators]);

  const expandedOperatorObservable = useCallback(() => {
    const currentWorkflowId = workflow?.id;
    const operatorsWorkflowId = previousOperators?.[0]?.conceptId;
    const templateExploaded = getIsTemplateExploaded();

    if (templateExploaded) {
      resetActiveItems();

      return;
    }

    let skip =
      !quickStartRedirectingToWorkflow &&
      (isUndefined(previousOperators) ||
        isEqual(previousOperators, operators) ||
        (!isEmpty(previousOperators) &&
          operatorsWorkflowId !== currentWorkflowId));

    if (skip) return;

    const previousWorkflowOperatorIds = previousOperators?.map(({ id }) => id);
    const currentWorkflowOperatorIds = operators?.map(({ id }) => id);

    skip = isEqual(previousWorkflowOperatorIds, currentWorkflowOperatorIds);

    if (skip) return;

    const removedOperatorsIds = difference(
      previousWorkflowOperatorIds,
      currentWorkflowOperatorIds,
    );

    const addedOperatorsIds = difference(
      currentWorkflowOperatorIds,
      previousWorkflowOperatorIds,
    );

    if (quickStartRedirectingToWorkflow) {
      dispatch(quickStartRedirectionCompleted());
      addActiveItems([currentWorkflowOperatorIds?.at(-1)]);

      return;
    }

    if (!isEmpty(removedOperatorsIds)) {
      removeActiveItems(removedOperatorsIds);
    }

    if (!isEmpty(addedOperatorsIds) && isFullScreenOperatorPreference) {
      const fullScreenOperatorId = addedOperatorsIds?.at(-1);
      const isClonedOperator = getMutationCacheByKey(
        operatorMutationKeys.cloneOperator,
        {
          predicate: ({ state }) => state?.data?.id === fullScreenOperatorId,
        },
      );

      if (isClonedOperator) return;

      dispatch(setFullScreenOperatorId(fullScreenOperatorId));

      return;
    }

    if (!isEmpty(addedOperatorsIds)) {
      addActiveItems(addedOperatorsIds);
    }
  }, [
    dispatch,
    workflow?.id,
    operators,
    previousOperators,
    addActiveItems,
    removeActiveItems,
    resetActiveItems,
    getIsTemplateExploaded,
    quickStartRedirectingToWorkflow,
    isFullScreenOperatorPreference,
    getMutationCacheByKey,
  ]);

  const workflowMoreDropDownMenu = useMemo(
    () =>
      getSelectedWorkflowMoreDropDownMenu({
        onExpandAll: expandAll,
        onCollapseAll: collapseAll,
      }),
    [getSelectedWorkflowMoreDropDownMenu, collapseAll, expandAll],
  );

  const reviewUpdateAction = useMemo(
    () =>
      isWorkflowEditable &&
      workflowMoreDropDownMenu?.find(
        ({ reviewUpdateAction }) => reviewUpdateAction,
      ),
    [isWorkflowEditable, workflowMoreDropDownMenu],
  );

  const reviewUpdatePrinterAction = useMemo(
    () =>
      workflowMoreDropDownMenu?.find(
        ({ reviewUpdatePrinterAction }) => reviewUpdatePrinterAction,
      ),
    [workflowMoreDropDownMenu],
  );

  const onDismissDeprecatedPanel = useCallback(() => {
    addToLocalStorage(HIDE_UPGRADE_BANNER_KEY, true);
    addToLocalStorage(LAST_UPGRADE_VERSION_DISMISSED_KEY, version);
    setSkipDeprecatedMessage(true);
  }, [version]);

  const onDismissDeprecatedPrinterPanel = useCallback(() => {
    addToLocalStorage(HIDE_UPDATE_PRINTER_BANNER_KEY, true);
    addToLocalStorage(
      LAST_UPDATE_PRINTER_TIMESTAMP_DISMISSED_KEY,
      workflowPrinter.lastUpdatedAt,
    );
    setSkipDeprecatedPrinterMessage(true);
  }, [workflowPrinter]);

  useEffect(() => {
    expandedOperatorObservable();
  }, [expandedOperatorObservable]);

  useEffect(() => {
    const lastDismissedVersion = getFromLocalStorage(
      LAST_UPGRADE_VERSION_DISMISSED_KEY,
    );

    if (!lastDismissedVersion || compare(version, lastDismissedVersion, '>')) {
      setSkipDeprecatedMessage(false);
    }
  }, [version, setSkipDeprecatedMessage]);

  useEffect(() => {
    if (!workflowPrinter) {
      return;
    }

    const lastDismissedPrinterUpdateTime = getFromLocalStorage(
      LAST_UPDATE_PRINTER_TIMESTAMP_DISMISSED_KEY,
    );

    if (
      !lastDismissedPrinterUpdateTime ||
      new Date(workflowPrinter.lastUpdatedAt) >
        new Date(lastDismissedPrinterUpdateTime)
    ) {
      setSkipDeprecatedPrinterMessage(false);
    }
  }, [workflowPrinter, setSkipDeprecatedPrinterMessage]);

  return (
    <SettingBar
      dataTestId="workflow-setting-bar"
      id={WORKFLOW_SETTING_BAR_ID}
      headerButtonDropdown={{
        children: isWorkflowPublic
          ? `${workflow.name} (${intl.formatMessage({
              id: 'general.public',
              defaultMessage: 'Public',
            })})`
          : workflow.name,
        dropDownMenuItems: getWorkflowsListDropDownMenu(),
        dropDownMenuPosition: DROP_DOWN_MENU_POSITION_BOTTOM_CENTER,
      }}
      headerEndingIconButtonDropDownProps={{
        dropDownMenuItems: workflowMoreDropDownMenu,
      }}
      onHeaderLeadingIconButtonClick={handleSettingBarCollapse}
      footerPrimaryButtonSubmitType
      footerPrimaryButtonDisabled
      {...optionalSettingBarProps}
    >
      <ContentWrapper ref={contentWrapperRef}>
        {!isWorkflowBackwardsCompatible && reviewUpdateAction && (
          <MessageBox
            dataTestId="workflow-setting-bar__update-required-message"
            leadingIconName="info_0"
            endingButtonDisabled={isWorkflowComputing}
            endingButtonTitle={intl.formatMessage({
              id: 'workflowsettingbar.non_backwards_compatible_workflow.update_required.button_title',
              defaultMessage: 'Review Update',
            })}
            onEndingButtonClick={reviewUpdateAction?.onClick?.bind(null, {
              workflowIsComputable: form?.formState?.isValid,
            })}
          >
            <FormattedMessage
              id="workflowsettingbar.non_backwards_compatible_workflow.update_required.message"
              defaultMessage="Update required. To apply changes to this workflow, please update it to the latest version of Aibuild."
            />
          </MessageBox>
        )}

        {isWorkflowBackwardsCompatible &&
          reviewUpdateAction &&
          !skipDeprecatedMessage && (
            <MessageBox
              dataTestId="workflow-setting-bar__deprecated-message"
              leadingIconName="info_0"
              endingButtonDisabled={isWorkflowComputing}
              endingButtonTitle={intl.formatMessage({
                id: 'workflowsettingbar.deprecated_workflow.review_button_title',
                defaultMessage: 'Review',
              })}
              onEndingButtonClick={reviewUpdateAction?.onClick?.bind(null, {
                workflowIsComputable: form?.formState?.isValid,
              })}
              endingIconButtonIconName="close_0"
              onEndingIconButtonClick={onDismissDeprecatedPanel}
            >
              <FormattedMessage
                id="workflowsettingbar.deprecated_workflow"
                defaultMessage="New Aibuild update available"
              />
              <br />
              <FormattedMessage
                id="upgrade.banner.version"
                defaultMessage="Version {version}"
                values={{ version }}
              />
            </MessageBox>
          )}
        {reviewUpdatePrinterAction && !skipDeprecatedPrinterMessage && (
          <MessageBox
            dataTestId="workflow-setting-bar__deprecated-printer-message"
            leadingIconName="info_0"
            endingButtonDisabled={isWorkflowComputing}
            endingButtonTitle={intl.formatMessage({
              id: 'workflowsettingbar.deprecated_workflow.review_button_title',
              defaultMessage: 'Review',
            })}
            onEndingButtonClick={reviewUpdatePrinterAction?.onClick}
            endingIconButtonIconName="close_0"
            onEndingIconButtonClick={onDismissDeprecatedPrinterPanel}
          >
            {DESKTOP_APP && (
              <FormattedMessage
                id={
                  reviewUpdateAction
                    ? 'Workflow setup update available.\nTo apply changes, please update to the latest Aibuild version.'
                    : 'Workflow setup update available'
                }
              />
            )}
            {!DESKTOP_APP && (
              <FormattedMessage
                id={
                  reviewUpdateAction
                    ? 'workflowsettingbar.deprecated_workflow_printer_and_new_version'
                    : 'workflowsettingbar.deprecated_workflow_printer'
                }
                defaultMessage={
                  reviewUpdateAction
                    ? 'Workflow setup update available.\nTo apply changes, please update to the latest Aibuild version.'
                    : 'Workflow setup update available'
                }
              />
            )}
          </MessageBox>
        )}

        <WorkflowOperators
          drag={drag}
          expandedOperatorIds={activeItems}
          toggleOperatorExpansion={toggleOperatorExpansion}
          isWorkflowSettingBarVisible={isWorkflowSettingBarVisible}
          isWorkflowEmpty={isWorkflowEmpty}
          reviewWorkflowUpgrade={reviewUpdateAction?.onClick?.bind(null, {
            workflowIsComputable: form?.formState?.isValid,
          })}
          resetPreviousSettingBarCollapseState={
            resetPreviousSettingBarCollapseState
          }
        />
      </ContentWrapper>
    </SettingBar>
  );
};

WorkflowSetting.propTypes = {
  handleSettingBarCollapse: PropTypes.func.isRequired,
  drag: PropTypes.shape({
    daragEnabled: PropTypes.bool,
    closestDragItems: PropTypes.object,
    onDragStart: PropTypes.func,
    onDragEnd: PropTypes.func,
  }),
  isWorkflowSettingBarVisible: PropTypes.bool,
  resetPreviousSettingBarCollapseState: PropTypes.func,
};

export default WorkflowSetting;
