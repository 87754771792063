import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch, batch } from 'react-redux';
import usePrevious from '@hooks/usePrevious';
import useApplication from '@hooks/application/useApplication';
import useWorkflow from '@hooks/workflows/useWorkflow';
import useProjectQueries from '@hooks/projects/useProjectQueries';
import usePrinterQueries from '@hooks/printers/usePrinterQueries';
import useOperatorQueries from '@hooks/operators/useOperatorQueries';
import useWorkflowQueries from '@hooks/workflows/useWorkflowQueries';
import useFileQueries from '@hooks/files/useFileQueries';
import {
  resetFullScreenOperatorId,
  resetFullScreenOperatorVisibility,
} from '@reducers/workflowSlice';
import { getSelectedOperatorOutputId } from '@selectors/conceptSelectors';
import {
  selectOperatorOutput,
  updateEnclosureVisibility,
  updatePrintingBedVisibility,
  updateRobotVisibility,
  updateWorkspaceVisibility,
} from '@actions/conceptActions';
import { resetNavigationBarHistory } from '@actions/applicationActions';
import PageLoaderPortal from '@components/2-molecules/PageLoaderPortal';
import LoadingEditorLayout from '@containers/LoadingEditorLayout';
import { isUndefined } from 'lodash';
import { resetShowSafetyCheckResults } from '@actions/toolBarActions';

const WorkflowLoader = ({ children }) => {
  const dispatch = useDispatch();
  const { itemId: workflowId } = useParams();
  const selectedOperatorOutputId = useSelector(getSelectedOperatorOutputId());
  const { getSelectedWorkflow, getIsPrinterUpdateAvailable } = useWorkflow();
  const workflow = getSelectedWorkflow();
  const isPrinterUpdateAvailable = getIsPrinterUpdateAvailable(workflow);
  const selectedWorkflowProjectId = workflow?.workspaceId;
  const selectedWorkflowOperators = workflow?.operators;
  const previousSelectedWorkflowOperators = usePrevious(
    selectedWorkflowOperators,
  );
  const { getIsApplicationLoading } = useApplication();
  const { projectQuery } = useProjectQueries({
    projectId: selectedWorkflowProjectId,
  });
  const { printersQuery, printerQuery, materialsQuery, allNozzlesQuery } =
    usePrinterQueries({ printerId: workflow?.printerId });
  const {
    defaultOperatorsQuery,
    featuredOperatorsQuery,
    templatesQuery,
    recommendedOperatorsQuery,
  } = useOperatorQueries({ workflowId });
  const { projectWorkflowsQuery, workflowQuery } = useWorkflowQueries({
    projectId: selectedWorkflowProjectId,
    workflowId,
  });

  const isApplicationLoading = getIsApplicationLoading();
  const previousWorkflowIdRef = useRef(workflowId);

  const { projectFilesQuery } = useFileQueries({
    projectId: selectedWorkflowProjectId,
  });

  const swithedWorkflow = useMemo(
    () =>
      workflowQuery.isRefetching &&
      workflowId !== previousWorkflowIdRef.current,
    [workflowQuery.isRefetching, workflowId],
  );

  const workflowOperatorLengthUpdated = useMemo(
    () =>
      !isUndefined(previousSelectedWorkflowOperators) &&
      selectedWorkflowOperators?.length !==
        previousSelectedWorkflowOperators?.length,
    [previousSelectedWorkflowOperators, selectedWorkflowOperators],
  );

  const refetchPrintersQuery = printersQuery?.refetch;
  const refetchMaterialsQuery = materialsQuery?.refetch;
  const refetchAllNozzlesQuery = allNozzlesQuery?.refetch;
  const refetchTemplatesQuery = templatesQuery?.refetch;
  const refetchRecommendedOperatorsQuery = recommendedOperatorsQuery?.refetch;

  const pageDataIsFetched = useMemo(
    () =>
      projectQuery.isFetched &&
      printersQuery.isFetched &&
      printerQuery.isFetched &&
      materialsQuery.isFetched &&
      allNozzlesQuery.isFetched &&
      defaultOperatorsQuery.isFetched &&
      featuredOperatorsQuery.isFetched &&
      templatesQuery.isFetched &&
      workflowQuery.isFetched &&
      !swithedWorkflow &&
      projectWorkflowsQuery.isFetched &&
      projectFilesQuery.isFetched,
    [
      projectQuery.isFetched,
      printerQuery.isFetched,
      printersQuery.isFetched,
      materialsQuery.isFetched,
      allNozzlesQuery.isFetched,
      defaultOperatorsQuery.isFetched,
      featuredOperatorsQuery.isFetched,
      templatesQuery.isFetched,
      workflowQuery.isFetched,
      projectWorkflowsQuery.isFetched,
      projectFilesQuery.isFetched,
      swithedWorkflow,
    ],
  );

  const isInitialLoadingRef = useRef(true);
  const previousPageDataIsFetched = usePrevious(pageDataIsFetched);

  useEffect(() => {
    if (workflowOperatorLengthUpdated) {
      refetchRecommendedOperatorsQuery();
    }
  }, [workflowOperatorLengthUpdated, refetchRecommendedOperatorsQuery]);

  useEffect(() => {
    if (
      workflowQuery.isFetched &&
      workflowId !== previousWorkflowIdRef.current
    ) {
      previousWorkflowIdRef.current = workflowId;
    }
  }, [workflowQuery.isFetched, workflowId]);

  useEffect(() => {
    const shouldResetSelectedOutputId =
      !pageDataIsFetched && selectedOperatorOutputId;

    if (shouldResetSelectedOutputId) {
      dispatch(selectOperatorOutput(null));
    }
  }, [dispatch, pageDataIsFetched, selectedOperatorOutputId]);

  useEffect(() => {
    const shouldDisablePrinterVisibility =
      pageDataIsFetched && isPrinterUpdateAvailable;

    if (shouldDisablePrinterVisibility) {
      batch(() => {
        dispatch(updatePrintingBedVisibility(false));
        dispatch(updateEnclosureVisibility(false));
        dispatch(updateRobotVisibility(false));
        dispatch(updateWorkspaceVisibility(false));
      });
    }
  }, [dispatch, pageDataIsFetched, isPrinterUpdateAvailable]);

  useEffect(() => {
    if (
      isInitialLoadingRef.current &&
      !previousPageDataIsFetched &&
      pageDataIsFetched
    ) {
      isInitialLoadingRef.current = false;
    }
  }, [previousPageDataIsFetched, pageDataIsFetched]);

  useEffect(() => {
    if (!printersQuery.isFetched) {
      refetchPrintersQuery();
    }
    if (!materialsQuery.isFetched) {
      refetchMaterialsQuery();
    }
    if (!allNozzlesQuery.isFetched) {
      refetchAllNozzlesQuery();
    }
    if (!templatesQuery.isFetched) {
      refetchTemplatesQuery();
    }
  }, [
    printersQuery.isFetched,
    materialsQuery.isFetched,
    allNozzlesQuery.isFetched,
    templatesQuery.isFetched,
    refetchPrintersQuery,
    refetchMaterialsQuery,
    refetchAllNozzlesQuery,
    refetchTemplatesQuery,
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetNavigationBarHistory());
      dispatch(resetFullScreenOperatorId());
      dispatch(resetFullScreenOperatorVisibility());
      dispatch(resetShowSafetyCheckResults());
    };
  }, [dispatch]);

  if (!pageDataIsFetched) {
    return (
      <>
        <PageLoaderPortal show={!isApplicationLoading} />
        <LoadingEditorLayout
          settingBarCollapsed={isInitialLoadingRef.current}
        />
      </>
    );
  }

  const childrenWithProps = React.Children.map(
    children,
    (child) =>
      React.cloneElement(child, {
        defaultSettingBarCollapsed: isInitialLoadingRef.current,
      }),
    [],
  );

  return childrenWithProps;
};

WorkflowLoader.propTypes = {
  children: PropTypes.node,
};

export default WorkflowLoader;
