import * as THREE from 'three';
import { ExternalAxis } from '../ExternalAxis';
import { Printer } from '../Printer';
import { degToRad } from 'three/src/math/MathUtils';
import { printerConstants as constants } from '../../constants/printers/printerConstants';

export class TwoAxisPositioner extends ExternalAxis {
  constructor(
    printerSettings,
    machineDefaults,
    printingBedDefinitions,
    plinthDefinitions,
  ) {
    super(
      printerSettings,
      machineDefaults,
      printingBedDefinitions,
      plinthDefinitions,
    );
    this.setPropertiesConfiguration();
    this.joint1HomePosition = parseFloat(
      Printer.getSettingValueFromTypeOrDefault(
        printerSettings,
        constants.TWO_AXIS_POSITIONER_JOINT_1_HOME_ANGLE,
        0,
      ),
    );
    this.joint2HomePosition = parseFloat(
      Printer.getSettingValueFromTypeOrDefault(
        printerSettings,
        constants.TWO_AXIS_POSITIONER_JOINT_2_HOME_ANGLE,
        0,
      ),
    );
  }

  setPropertiesConfiguration() {
    const configuration = this.getConfiguration();
    this.a2 = configuration[1].x;
    this.d3 = configuration[2].z;
  }

  getNumberOfJoints() {
    return 2;
  }

  getOffsetParameter() {
    return parseFloat(
      Printer.getSettingValueFromTypeOrDefault(
        this.printerSettings,
        constants.TWO_AXIS_POSITIONER_OFFSET,
        0,
      ),
    );
  }

  setJointPosition(jointNumber, jointValue) {
    const { joint1Inverted, joint2Inverted } = this.printingBedDefaults;

    const inverted =
      (jointNumber == 1 && joint1Inverted) ||
      (jointNumber == 2 && joint2Inverted);
    const correctedJointValue = inverted ? -jointValue : jointValue;

    super.setJointPosition(jointNumber, correctedJointValue);
  }

  moveToHome() {
    this[constants.axis + 1].rotation.set(
      0,
      0,
      degToRad(this.joint1HomePosition),
    );
    this[constants.axis + 2].rotation.set(
      0,
      0,
      degToRad(this.joint2HomePosition),
    );
  }

  getRotatedBaseTransformationMatrix(joint1Value, joint2Value) {
    const joint2Rotation = new THREE.Matrix4();
    //joint 1 rotation axis assumed to be about the same axis as base x-axis
    const joint1Rotation = new THREE.Matrix4();
    const offset = this.a2 - this.d3;
    const yOffset = offset * Math.sin(joint1Value);
    const zOffset = offset - offset * Math.cos(joint1Value);
    if (this.printingBedDefaults.joint1Inverted) {
      joint1Value = -joint1Value;
    }
    if (this.printingBedDefaults.joint2Inverted) {
      joint2Value = -joint2Value;
    }
    joint2Rotation.makeRotationZ(joint2Value);
    joint1Rotation.makeRotationX(joint1Value);
    joint1Rotation.setPosition(0, yOffset, zOffset);
    joint1Rotation.multiply(joint2Rotation);
    joint1Rotation.multiply(this.baseTransformationMatrix.clone());
    return joint1Rotation;
  }
}
