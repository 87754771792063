const CUSTOM_EXTRUDER_TYPE = 'CUSTOM';

export const getPrinterStatus =
  (printerId) =>
  ({ printers }) =>
    printers?.data?.printerStatuses?.find(
      (printerStatus) => printerStatus.printerId === printerId,
    );
export const getUnsavedPrinterModifications =
  (printerId) =>
  ({ printers }) =>
    printers.ui.unsavedModifications[printerId];

export const getPrintProgressFromPrinter =
  (printerId) =>
  ({ printers }) =>
    printers.data.printProgresses.find(
      (printProgress) => printProgress.printerId === printerId,
    );

export const getMachineModifications =
  (printerId) =>
  ({ printers }) =>
    printers.ui.machineModifications[printerId];

export const getGeneralPrinterSettingState =
  () =>
  ({ printers }) => {
    return printers.ui.generalPrinterSetting;
  };

export const getPostProcessorConfigVisibility =
  () =>
  ({ printers }) => {
    return !!printers.ui.showPostProcessorConfig;
  };

export const getPostProcessorConfigs =
  () =>
  ({ printers }) =>
    printers.data.postProcessorConfigs;

export const getNozzleConfig =
  () =>
  ({ printers }) =>
    printers.ui.nozzleConfig;

export const getExtruderDefinition = (printer, extruderDefinitions) => () => {
  const extruderType = printer?.extruderType;

  if (extruderType == null) {
    return null;
  }

  if (extruderType !== CUSTOM_EXTRUDER_TYPE) {
    return extruderDefinitions.find(
      (definition) => definition.displayName === extruderType,
    );
  }

  return { extrusionType: printer?.customExtruderDefinitionExtrusionType };
};

export const getExtruderDefinitionByPrinters =
  (printersList, extruderDefintions) => () =>
    printersList.reduce((acc, printer) => {
      const { extruderType } = printer;

      if (extruderType == null) {
        acc[printer?.id] = null;
        return acc;
      }

      let extruderDef = {
        extrusionType: printer?.customExtruderDefinitionExtrusionType,
      };

      if (extruderType !== CUSTOM_EXTRUDER_TYPE) {
        extruderDef = extruderDefintions.find(
          (definition) => definition.displayName === extruderType,
        );
      }

      acc[printer?.id] = extruderDef;
      return acc;
    }, {});
