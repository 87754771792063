import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { defaultPrinterProps } from '../constants/defaultPrinterProps';
import Extruder from './extruders/Extruder.js';
import LinearRail from './plinths/LinearRail';
import Workspace from './Workspace';
import { printerConstants as constants } from '@constants/printers/printerConstants';
import getBed from './beds/BedUtils';
import { getMachine, getMachineBrand } from './machines/MachineUtils';
import { getPlinth } from './plinths/PlinthUtils.js';
import { isObject } from 'lodash';
import LinearRailCustom from './plinths/LinearRailCustom.js';
import getEnclosure from './enclosures/EnclosureUtils.js';

/**
 * Represents the complete group of components used to visualise the printer system
 * e.g. the bed, the robot, the tool, the enclosure etc.
 */
export class Printer extends THREE.Group {
  constructor(
    machineType,
    printerSettings,
    machineDefinitions,
    extruderDefinitions,
    printingBedDefinitions,
    plinthDefinitions,
    enclosureDefinitions,
  ) {
    super();
    this.printerSettings = printerSettings;
    this.robotType = machineType;
    this.machineDefaults = machineDefinitions.find(
      ({ displayName }) => displayName === machineType,
    );
    this.printerProps = defaultPrinterProps;
    this.printerProps.machine = {
      modelName: machineType,
    };
    this.scaleRatio = constants.scaleRatio;
    this.scale.set(this.scaleRatio, this.scaleRatio, this.scaleRatio);

    this.addBed(
      printerSettings,
      this.machineDefaults,
      printingBedDefinitions,
      plinthDefinitions,
    );
    this.addMachine(machineDefinitions, machineType, printerSettings, this.bed);
    this.addEnclosure(
      printerSettings,
      this.machineDefaults,
      enclosureDefinitions,
    );
    this.addPlinths(printerSettings, this.machineDefaults, plinthDefinitions);
    this.addWorkspace(printerSettings);
    const machineBrand = getMachineBrand(
      machineDefinitions,
      machineType,
      printerSettings,
    );
    this.addExtruder(
      printerSettings,
      this.machineDefaults,
      extruderDefinitions,
      machineBrand,
    );
  }

  /**
   * Creates a new Printer object from the input machine type, using the default
   * values stored in the machine definition files.
   * @param {*} machineType machine type from the selection defined in the definition
   * file
   * @returns new Printer object constructed from the default settings for this machine
   * type
   */
  static createFromMachineType(
    machineType,
    machineDefinitions,
    extruderDefinitions,
    printingBedDefinitions,
    plinthDefinitions,
    enclosureDefinitions,
  ) {
    return new Printer(
      machineType,
      [],
      machineDefinitions,
      extruderDefinitions,
      printingBedDefinitions,
      plinthDefinitions,
      enclosureDefinitions,
    );
  }

  /**
   * Loads all the models required to visualise the machine in sequence and sets
   * the machine to its home position.
   */
  initializeMachineGeometry() {
    this.machine.moveHomePosition();
    this.workspace.initializeModels();
    return new Promise((resolve) => {
      Promise.all([
        this.machine.initializeMachineGeometry(),
        this.extruder?.initializeModels(),
        this.bed?.initializeModels(),
        this.enclosure?.initializeModels(),
        this.plinth?.initializeModels(),
        this.basePlinth?.initializeModels(),
      ]).then(() => {
        resolve();
      });
    });
  }

  /**
   * Moves the kinematic elements of the printer to reflect the simulation data provided
   * @param {Object} simulationData object containing data about the simulation
   * @param {boolean} safetyCheckGrayscale if safety check mode is active
   */
  simulate(simulationData, safetyCheckGrayscale) {
    if (!simulationData.currentStep) return;
    this.machine?.simulate?.(simulationData);
    this.bed?.simulate?.(simulationData, this.machine);
    this.plinth?.simulate?.(simulationData);
    this.basePlinth?.simulate?.(simulationData);

    if (safetyCheckGrayscale) {
      this.enterSafetyCheckGrayscale();
    } else {
      this.exitSafetyCheckGrayscale();
    }
  }

  enterSafetyCheckGrayscale() {
    this.traverse((child) => {
      if (child.isMesh && child.material?.color) {
        if (!child.userData.originalMaterial) {
          child.userData.originalMaterial = child.material;
          child.material = child.material.clone();
        }

        // Convert to grayscale
        const color = child.material.color;
        const gray = 0.299 * color.r + 0.587 * color.g + 0.114 * color.b;
        child.material.color.setRGB(gray, gray, gray);
        child.material.needsUpdate = true;
      }
    });
  }

  exitSafetyCheckGrayscale() {
    this.traverse((child) => {
      if (child.isMesh && child.userData?.originalMaterial) {
        child.material.dispose();
        child.material = child.userData.originalMaterial;
        delete child.userData.originalMaterial;
      }
    });
  }

  /**
   * Adds the correct machine object as a child of the printer and assigns the printer
   * props the correct values.
   * @param {*} machineDefinitions
   * @param {*} robotType
   * @param {*} printerSettings
   * @param {*} bed
   */
  addMachine(machineDefinitions, robotType, printerSettings, bed) {
    this.machine = getMachine(
      machineDefinitions,
      robotType,
      printerSettings,
      bed,
    );
    if (!this.machine) return;
    const axes = new THREE.AxesHelper(150);
    this.machine.add(axes);
    this.add(this.machine);
    this.printerProps.machine.type = this.machine.type;
    this.printerProps.machine = this.machine.props;
  }

  /**
   * Creates a new enclosure object from the printerSettings. If the printerSettings
   * array is empty, it means a new printer is being created and the default values
   * should be selected from the robot settings provided. The created enclosure is added
   * as a property of the printer.
   * @param {Array} printerSettings array containing printer settings, empty if
   *  a new printer is being created
   * @param {Object} machineDefaults object containing default robot settings for the
   *  selected machine
   */
  addEnclosure(printerSettings, machineDefaults, enclosureDefinitions) {
    this.printerProps.enclosureType = defaultPrinterProps.enclosureType;
    this.enclosure = getEnclosure(
      printerSettings,
      machineDefaults,
      enclosureDefinitions,
    );
    if (this.enclosure) {
      this.add(this.enclosure);
      this.printerProps.enclosureType = this.enclosure?.enclosureType;
    }
  }

  getEnclosureType(printerSettings, machineDefaults) {
    const enclosureType = Printer.getPrinterSettingValueOrDefault(
      printerSettings,
      constants.enclosureType,
      machineDefaults.enclosureType,
    );
    return enclosureType;
  }

  /**
   * Creates a new extruder object from the printerSettings. If the printerSettings
   * array is empty, it means a new printer is being created and the default values
   * should be selected from the robot settings provided. The created extruder is
   * set as the active tool of the printer.
   * @param {Array} printerSettings array containing printer settings, empty if
   *  a new printer is being created
   * @param {Object} machineDefaults object containing default robot settings for the
   *  selected machine
   */
  addExtruder(
    printerSettings,
    machineDefaults,
    extruderDefinitions,
    machineBrand,
  ) {
    this.extruder = new Extruder(
      printerSettings,
      machineDefaults,
      extruderDefinitions,
      machineBrand,
    );
    this.setTool(this.extruder, this.extruder.calibration);
    this.printerProps.toolX = this.extruder.calibration[0].toolX;
    this.printerProps.toolY = this.extruder.calibration[0].toolY;
    this.printerProps.toolZ = this.extruder.calibration[0].toolZ;
    this.printerProps.toolA = this.extruder.calibration[0].toolA;
    this.printerProps.toolB = this.extruder.calibration[0].toolB;
    this.printerProps.toolC = this.extruder.calibration[0].toolC;
    this.printerProps.extruderType = this.extruder.extruderType;
    this.printerProps.extruderSettingsType = this.extruder.extruderSettingsType;
  }

  /**
   * Replaces the current extruder model with the supplied model, and updates
   * the TCP value.
   * @param {*} tool
   * @param {*} toolCalibration {x, y, z, A, B, C}
   */
  setTool(tool, toolCalibration) {
    this.machine.setTool(tool, toolCalibration);
  }

  addPlinths(printerSettings, machineDefaults, plinthDefinitions) {
    this.printerProps.plinthType = defaultPrinterProps.plinthType;
    this.printerProps.basePlinthType = defaultPrinterProps.basePlinthType;

    this.plinth = getPlinth(
      printerSettings,
      machineDefaults,
      constants.ROBOT,
      plinthDefinitions,
    );
    this.basePlinth = getPlinth(
      printerSettings,
      machineDefaults,
      constants.BASE,
      plinthDefinitions,
    );
    if (this.plinth) {
      this.add(this.plinth);
      this.plinth.addAttachment(this.machine);
      this.printerProps.plinthType = this.plinth.plinthType;
      this.printerProps.plinthSettingsType = this.plinth.plinthSettingsType;
    }
    if (this.basePlinth) {
      this.add(this.basePlinth);
      this.basePlinth.addAttachment(this.bed);
      this.printerProps.basePlinthType = this.basePlinth.plinthType;
    }
    if (
      this.plinth instanceof LinearRail ||
      this.plinth instanceof LinearRailCustom
    ) {
      this.plinth.moveToHome();
    }
  }

  /**
   * Creates a new workspace object based on the supplied printer settings and
   * adds it as a child of the Printer. If the printerSettings object is empty,
   * default values are used.
   * @param {*} printerSettings
   */
  addWorkspace(printerSettings) {
    this.workspace = new Workspace(printerSettings);
    this.add(this.workspace);
  }

  /**
   * Constructs the bed groups and attaches them to the printer and sets the props
   * of the printer to contain the relevant information
   * @param {*} printerSettings
   * @param {*} machineDefaults
   * @param {*} printingBedDefinitions
   */
  addBed(
    printerSettings,
    machineDefaults,
    printingBedDefinitions,
    plinthDefinitions,
  ) {
    this.bed = getBed(
      printerSettings,
      machineDefaults,
      printingBedDefinitions,
      plinthDefinitions,
    );
    if (!this.bed) return;
    this.add(this.bed);
    this.printingBedType = this.bed.printingBedType;
    this.printerProps.printingBedType = this.bed.printingBedType;
    this.printerProps.baseRef1X = this.bed.origin.x;
    this.printerProps.baseRef1Y = this.bed.origin.y;
    this.printerProps.baseRef1Z = this.bed.origin.z;
    this.printerProps.baseRef2X = this.bed.yPoint.x;
    this.printerProps.baseRef2Y = this.bed.yPoint.y;
    this.printerProps.baseRef2Z = this.bed.yPoint.z;
    this.printerProps.baseRef3X = this.bed.xPoint.x;
    this.printerProps.baseRef3Y = this.bed.xPoint.y;
    this.printerProps.baseRef3Z = this.bed.xPoint.z;
    this.printerProps.printingBedSettingsType =
      this.bed.printingBedSettingsType;
  }

  /**
   * Searches the 'printerSettings' property of the printer object for a specific setting type
   * and returns the value of the property associated with that type.
   * The printerSettings property is what contains the additional dynamic settings.
   * @param {*} printerSettingType type of printer setting
   * @returns value of printer setting type.
   */
  static getSettingValueFromType(printerSettings, printerSettingType) {
    return printerSettings
      .filter((s) => s.settingName === constants.printerSettings)[0]
      .value.filter((s) => s.type === printerSettingType)[0].value;
  }

  static getSettingValueFromTypeOrDefault(
    printerSettings,
    printerSettingType,
    defaultValue,
  ) {
    if (printerSettings.length === 0) return defaultValue;
    const value = printerSettings
      .filter((s) => s.settingName === constants.printerSettings)[0]
      .value.filter((s) => s.type === printerSettingType)[0]?.value;
    return value ? value : defaultValue;
  }

  /**
   * @param url -> Could be a specific path to a file or it could be an object of type {fileUrl, fileName}
   * @returns {Promise<unknown>}
   */
  static getModel(url) {
    return new Promise((resolve) => {
      let fileUrl;
      let isObjFile;
      if (isObject(url)) {
        fileUrl = url.fileUrl;
        isObjFile = url.fileName.toLowerCase().endsWith('.obj');
      } else {
        fileUrl = url;
        const pattern = /\/[^*"/\\<>:|?]*?(?=\.obj\?)\.obj\?/; // Pattern for containing a "/____.obj" file reference in the URL
        isObjFile = pattern.test(fileUrl.toLowerCase());
      }

      if (isObjFile) {
        Printer.objLoader.load(
          fileUrl,
          (obj) => {
            resolve(obj);
          },
          () => {},
          (err) => {
            // eslint-disable-next-line
            console.error(`Error during obj loading of ${fileUrl}: ${err}`);
          },
        );
      } else {
        Printer.gltfLoader.load(
          fileUrl,
          (gltf) => {
            resolve(gltf.scene);
          },
          () => {},
          (err) => {
            // eslint-disable-next-line
            console.error(`Error during gltf loading of ${fileUrl}: ${err}`);
          },
        );
      }
    });
  }

  /**
   * Filters the printerSettings property of this object, finds the setting with the target name
   * and returns the value of that setting
   * @param {*} settingName name of the setting to find the value of
   * @returns value of target setting
   */
  static getPrinterSettingValue(printerSettings, settingName) {
    return printerSettings.find((setting) => setting.settingName == settingName)
      .value;
  }

  static getPrinterSettingValueOrDefault(
    printerSettings,
    settingName,
    defaultValue,
  ) {
    const setting = printerSettings.find(
      (setting) => setting.settingName == settingName,
    );
    return setting ? setting.value : defaultValue;
  }
}

Printer.gltfLoader = new GLTFLoader();
Printer.objLoader = new OBJLoader();
Printer.dracolaLoader = new DRACOLoader();

Printer.dracolaLoader.setDecoderPath('/models/dracoDecoder/');
Printer.gltfLoader.setDRACOLoader(Printer.dracolaLoader);
