import endpoints from '@api/endpoints';
import client from '@api/client';
import actionTypes from '@app/actions';

import { takeEvery, put } from 'redux-saga/effects';
import {
  enterToolpathSimulationWithSafetyCheck,
  fetchSimulationDataSafetyCheckFailure,
  fetchSimulationDataSafetyCheckSuccess,
} from '@actions/conceptActions';
import { showErrorDialog } from '@actions/errorActions';
import getIntlProvider from '@utils/getIntlProvider';
import React from 'react';

const intl = getIntlProvider();

export function* fetchSimulationDataSafetyCheckImpl({ payload }) {
  let requestUrl = endpoints.printerSimulationSafetyCheck.replace(
    ':operatorId',
    payload?.operatorId,
  );
  if (payload?.organizationIdOverride) {
    requestUrl += '?organizationId=' + payload?.organizationIdOverride;
  }

  try {
    const response = yield client.get(requestUrl);
    yield put(fetchSimulationDataSafetyCheckSuccess());

    const simulationData = response?.data || {};
    const printerSteps = simulationData?.printerSteps;
    const gradients = simulationData?.gradients;
    if (printerSteps && printerSteps.length > 0) {
      yield put(
        enterToolpathSimulationWithSafetyCheck(
          printerSteps,
          gradients,
          payload?.safetyCheckMode,
        ),
      );
    }
    if (
      simulationData?.lastCollisionDetectionStepIndex &&
      simulationData?.lastCollisionDetectionStepIndex !== printerSteps.length
    ) {
      yield put(
        showErrorDialog(
          intl.formatMessage({
            id: 'fetch.simulation.collisions.partial.result.title',
            defaultMessage: 'Unable to proceed with the safety check',
          }),
          '',
          'fetch.simulation.collisions.partial.result.label',
          {
            br: () => <br />,
          },
        ),
      );
    }
  } catch (error) {
    yield put(fetchSimulationDataSafetyCheckFailure());

    yield put(
      showErrorDialog(
        intl.formatMessage({
          id: 'fetch.simulation.failed.title',
          defaultMessage: 'Unable to simulate',
        }),
        '',
        'fetch.simulation.failed.message',
      ),
    );
    // eslint-disable-next-line
    console.error(error);
  }
}

export default [
  takeEvery(
    actionTypes.FETCH_SIMULATION_DATA_SAFETY_CHECK_REQUEST,
    fetchSimulationDataSafetyCheckImpl,
  ),
];
