import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import OperatorHeader from '@components/2-molecules/OperatorHeader';
import Skeleton, {
  SKELETON_VARIANT_ONE_LINE,
} from '@components/1-atoms/Skeleton';
import SkeletonList from '@components/1-atoms/SkeletonList';
import ActionFooter, {
  ACTION_FOOTER_VARIANT_FULL_WIDTH,
} from '@components/2-molecules/ActionFooter';
import {
  BodyContent,
  ContentSkeletonWrapper,
  OperatorBody,
  SkeletonWrapper,
  Sticky,
  StickyContainer,
  Wrapper,
  Footer,
} from './Operator.styled';

const Operator = ({
  bodySkeleton,
  children,
  contentInitializationDelay,
  contentVisibilityDelay,
  dataTestId = 'operator',
  diffAdded,
  diffModified,
  diffRemoved,
  disableActions,
  disabled,
  disableDrag,
  disableExpand,
  dragged,
  dragHandleProps,
  expanded,
  footer,
  highlighted,
  id,
  loading,
  loadingContent,
  moreButtonDropDownMenuItems,
  moreButtonDropDownMenuItemsGetter,
  observeResize = true,
  onComputeButtonClick,
  onExpandButtonClick,
  onHeaderDoubleClick,
  onSelect,
  operatorIcon = 'network_node_0',
  operatorName,
  readOnlyHeader,
  selectable,
  selected,
  skeleton,
  status,
  statusOnlyHeader,
  stickyHeader = true,
}) => {
  const [showContent, setShowContent] = useState(
    !contentInitializationDelay && !contentVisibilityDelay && !loadingContent,
  );
  const headerRef = useRef(null);
  const operatorBodyRef = useRef(null);
  const bodyContentRef = useRef(null);

  const previousExpandedRef = useRef(expanded);
  const contentInitializationDelayTimeoutRef = useRef(null);

  const showBodySkeleton =
    loadingContent ||
    (bodySkeleton && !showContent) ||
    (!!contentInitializationDelay && !showContent);

  const showFooter =
    !showBodySkeleton &&
    (footer?.primaryButtonLabel || footer?.secondaryButtonLabel);

  const bodyContentResizeObserver = useMemo(
    () =>
      new ResizeObserver(() => {
        const operatorBodyElement = operatorBodyRef.current;

        if (!operatorBodyElement) return;

        operatorBodyElement.style.maxHeight = `${bodyContentRef.current?.scrollHeight}px`;
      }),
    [],
  );

  useEffect(() => {
    const operatorBodyElement = operatorBodyRef.current;
    const bodyContentElement = bodyContentRef.current;

    if (!bodyContentElement || !operatorBodyElement) return;

    if (!previousExpandedRef.current && expanded) {
      bodyContentResizeObserver.observe(bodyContentElement);
    } else if (previousExpandedRef.current && !expanded) {
      if (operatorBodyElement) {
        operatorBodyElement.style.maxHeight = '';
      }

      bodyContentResizeObserver.disconnect();
      bodyContentResizeObserver.unobserve(bodyContentElement);
    }

    return () => {
      bodyContentResizeObserver.disconnect();
      bodyContentResizeObserver.unobserve(bodyContentElement);
    };
  }, [expanded, bodyContentResizeObserver, observeResize]);

  useEffect(() => {
    if (!observeResize) {
      const bodyContentElement = bodyContentRef.current;

      bodyContentResizeObserver.disconnect();
      bodyContentResizeObserver.unobserve(bodyContentElement);
    }
  }, [observeResize, bodyContentResizeObserver]);

  useEffect(() => {
    previousExpandedRef.current = expanded;
    contentInitializationDelayTimeoutRef.current &&
      clearTimeout(contentInitializationDelayTimeoutRef.current);
  }, [expanded]);

  useEffect(() => {
    const runVisibilityTimeout =
      contentVisibilityDelay && expanded && !showContent;

    if (!runVisibilityTimeout) return;

    contentInitializationDelayTimeoutRef.current = setTimeout(() => {
      setShowContent(true);
    }, contentVisibilityDelay);
  }, [showContent, expanded, contentVisibilityDelay]);

  useEffect(() => {
    if (!contentInitializationDelay) return;

    setTimeout(() => {
      setShowContent(true);
    }, contentInitializationDelay);
  }, [contentInitializationDelay]);

  const getHeader = useCallback(
    () => (
      <OperatorHeader
        dataTestId={`${dataTestId}__header`}
        diffAdded={diffAdded}
        diffModified={diffModified}
        diffRemoved={diffRemoved}
        disabled={disabled}
        disableDrag={disableDrag}
        disableActions={disableActions}
        disableExpand={disableExpand}
        dragged={dragged}
        dragHandleProps={dragHandleProps}
        expanded={expanded}
        highlighted={highlighted}
        iconName={operatorIcon}
        loading={loading}
        moreButtonDropDownMenuItems={moreButtonDropDownMenuItems}
        moreButtonDropDownMenuItemsGetter={moreButtonDropDownMenuItemsGetter}
        onExpandButtonClick={onExpandButtonClick}
        onSelect={onSelect}
        onDoubleClick={onHeaderDoubleClick}
        onComputeButtonClick={onComputeButtonClick}
        ref={headerRef}
        selectable={selectable}
        selected={selected}
        status={status}
        title={operatorName}
        readOnly={readOnlyHeader}
        statusOnly={statusOnlyHeader}
      />
    ),
    [
      dataTestId,
      diffAdded,
      diffModified,
      diffRemoved,
      disabled,
      disableDrag,
      disableActions,
      disableExpand,
      dragged,
      dragHandleProps,
      expanded,
      highlighted,
      operatorIcon,
      loading,
      moreButtonDropDownMenuItems,
      moreButtonDropDownMenuItemsGetter,
      onExpandButtonClick,
      onSelect,
      onHeaderDoubleClick,
      onComputeButtonClick,
      operatorName,
      selectable,
      selected,
      status,
      readOnlyHeader,
      statusOnlyHeader,
    ],
  );

  if (skeleton) {
    return (
      <SkeletonWrapper
        dataTestId={`${dataTestId}__skeleton`}
        {...dragHandleProps}
      >
        <Skeleton variant={SKELETON_VARIANT_ONE_LINE} height={24} />
      </SkeletonWrapper>
    );
  }

  return (
    <Wrapper
      id={id ? `operator-${id}` : undefined}
      data-testid={dataTestId}
      expanded={expanded}
      stickyHeader={stickyHeader}
    >
      {stickyHeader && (
        <StickyContainer>
          <Sticky>{getHeader()}</Sticky>
        </StickyContainer>
      )}

      {!stickyHeader && getHeader()}

      <OperatorBody
        dataTestId={`${dataTestId}__body`}
        ref={operatorBodyRef}
        expand={expanded}
        maxHeight={bodyContentRef.current?.scrollHeight}
      >
        <BodyContent ref={bodyContentRef}>
          {showBodySkeleton && (
            <ContentSkeletonWrapper>
              <SkeletonList
                dataTestId={`${dataTestId}__body-skeleton`}
                length={2}
                withIcon={false}
              />
            </ContentSkeletonWrapper>
          )}

          {!showBodySkeleton && children}
        </BodyContent>
      </OperatorBody>

      {showFooter && (
        <Footer>
          <ActionFooter
            dataTestId={`${dataTestId}__footer`}
            variant={ACTION_FOOTER_VARIANT_FULL_WIDTH}
            primaryButtonLabel={footer.primaryButtonLabel}
            primaryButtonLoading={footer.primaryButtonLoading}
            primaryButtonDisabled={footer.primaryButtonDisabled}
            secondaryButtonLabel={footer.secondaryButtonLabel}
            secondaryButtonDisabled={footer.secondaryButtonDisabled}
            onPrimaryButtonClick={footer.onPrimaryButtonClick}
            onSecondaryButtonClick={footer.onSecondaryButtonClick}
          />
        </Footer>
      )}
    </Wrapper>
  );
};

Operator.propTypes = {
  bodySkeleton: PropTypes.bool,
  children: PropTypes.node,
  contentInitializationDelay: PropTypes.number,
  contentVisibilityDelay: PropTypes.number,
  dataTestId: PropTypes.string,
  diffAdded: PropTypes.bool,
  diffModified: PropTypes.bool,
  diffRemoved: PropTypes.bool,
  disableActions: PropTypes.bool,
  disabled: PropTypes.bool,
  disableDrag: PropTypes.bool,
  disableExpand: PropTypes.bool,
  dragged: PropTypes.bool,
  dragHandleProps: PropTypes.object,
  expanded: PropTypes.bool,
  footer: PropTypes.shape({
    primaryButtonLabel: PropTypes.string,
    primaryButtonLoading: PropTypes.bool,
    primaryButtonDisabled: PropTypes.bool,
    secondaryButtonLabel: PropTypes.string,
    secondaryButtonDisabled: PropTypes.bool,
    onPrimaryButtonClick: PropTypes.func,
    onSecondaryButtonClick: PropTypes.func,
  }),
  fullScreen: PropTypes.bool,
  highlighted: PropTypes.bool,
  id: PropTypes.string,
  loading: PropTypes.bool,
  loadingContent: PropTypes.bool,
  moreButtonDropDownMenuItems: PropTypes.array,
  moreButtonDropDownMenuItemsGetter: PropTypes.func,
  observeResize: PropTypes.bool,
  onComputeButtonClick: PropTypes.func,
  onExpandButtonClick: PropTypes.func,
  onHeaderDoubleClick: PropTypes.func,
  onSelect: PropTypes.func,
  operatorIcon: PropTypes.string,
  operatorName: PropTypes.string,
  readOnlyHeader: PropTypes.bool,
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  skeleton: PropTypes.bool,
  status: PropTypes.string,
  statusOnlyHeader: PropTypes.bool,
  stickyHeader: PropTypes.bool,
};

export default Operator;
