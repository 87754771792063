import { useCallback } from 'react';
import useOperator from '@hooks/operators/useOperator';
import { TOOLPATH_GENERATOR_OPERATOR_NAME } from '@constants/operatorsConstants';
import { useIntl } from 'react-intl';
import { logError } from '@utils/logs';
import { useDispatch, useSelector } from 'react-redux';
import { SAFETY_CHECK_MODES } from '../../constants/safetyChecks';
import { setSafetyChecksMode } from '../../actions/conceptActions';
import { getSafetyChecksMode } from '../../selectors/conceptSelectors';

export default function useSafetyCheck() {
  const intl = useIntl();
  const { getSelectedOperator } = useOperator();
  const dispatch = useDispatch();
  const safetyCheckMode = useSelector(getSafetyChecksMode);

  const updateSafetyCheckValues = useCallback(
    (selectedOperator, safetyCheckValues) => {
      selectedOperator.values.forEach((item) => {
        Object.keys(safetyCheckValues).forEach((key) => {
          const { input, output, error } = safetyCheckValues[key];
          if (item.name === input.name) {
            input.value = item.value;
          }
          if (item.name === output.name && input.value) {
            output.value = item.value;
          }
          if (item.name === error?.name && input.value && item.value) {
            try {
              error.values = JSON.parse(item.value);
            } catch (e) {
              logError(e);
            }
          }
        });
      });
    },
    [],
  );

  const handleSafetyCheckSelect = useCallback(
    (collisionModeKey) => {
      dispatch(setSafetyChecksMode(collisionModeKey));
    },
    [dispatch],
  );

  const getSafetyCheckValues = useCallback(
    () => ({
      [SAFETY_CHECK_MODES.AXIS_LIMITS]: {
        label: 'workflows.safety_check_results.AxisLimitFailures.label',
        input: { name: 'Check Axis Limits', value: false },
        output: { name: 'Number of Axis Limit Failures', value: 0 },
        error: {
          name: 'Axis Limit Failures',
          key: 'workflows.safety_check_results.AxisLimit.error',
          defaultMessage:
            'Axis {jointNumber} exceeds its limit in the toolpath at layer {layerNumber}, with a motion outside the allowable range of {minAxisLimit} to {maxAxisLimit} degrees.',
          values: {
            jointNumber: 1,
            targetNumber: 1,
            minAxisLimit: 1,
            maxAxisLimit: 1,
            deg: 1,
            layerNumber: 1,
          },
        },
        recommendation: {
          key: 'workflows.safety_check_results.AxisLimit.recommendation',
          defaultMessage:
            'Rotate the tool or reposition the part to avoid excessive axis motion.',
        },
      },
      [SAFETY_CHECK_MODES.WORKSPACE_LIMITS]: {
        label: 'workflows.safety_check_results.WorkspaceLimitFailures.label',
        input: { name: 'Check Workspace Limits', value: false },
        output: { name: 'Number of Workspace Limit Failures', value: 0 },
        warning: {
          key: 'workflows.safety_check_results.WorkspaceLimit.warning',
          defaultMessage:
            'The machine is operating outside of the defined safe working space. Unexpected issues can occur. The machine may hit a wall or obstacle. It may be unsafe to people outside the defined workspace.',
        },
        recommendation: {
          key: 'workflows.safety_check_results.WorkspaceLimit.recommendation',
          defaultMessage: 'Move the part fully inside the workspace.',
        },
      },
      [SAFETY_CHECK_MODES.REACH_LIMITS]: {
        color: 'fixedError',
        label: 'workflows.safety_check_results.ReachLimitFailures.label',
        input: { name: 'Check Reach Limits', value: false },
        output: { name: 'Number of Reach Limit Failures', value: 0 },
        recommendation: {
          key: 'workflows.safety_check_results.ReachLimit.recommendation',
          defaultMessage:
            'Move the part so that every position is within the reach of the machine.',
        },
        error: {
          name: 'Reach Limit Failures',
          key: 'workflows.safety_check_results.ReachLimit.error',
          defaultMessage:
            'The machine cannot reach the defined position at X:{xPoint}, Y:{yPoint}, Z:{zPoint} in layer {layerNumber}. Move the part closer to the robot to ensure accessibility.',
          values: {
            xPoint: 0,
            zPoint: 0,
            yPoint: 0,
            layerNumber: 0,
          },
        },
      },
      [SAFETY_CHECK_MODES.WRIST_SINGULARITY]: {
        label: 'workflows.safety_check_results.WristSingularityFailures.label',
        input: { name: 'Check Wrist Singularities', value: false },
        output: { name: 'Number of Wrist Singularity Failures', value: 0 },
        error: {
          name: 'Wrist Singularity Failures',
          key: 'workflows.safety_check_results.WristSingularity.error',
          defaultMessage:
            'A wrist singularity occurred at X:{xPoint}, Y:{yPoint}, Z:{zPoint} in layer {layerNumber}. This may cause unexpected movements or a temporary stop, but the machine may continue printing.',
          values: {
            xPoint: 0,
            zPoint: 0,
            yPoint: 0,
            layerNumber: 0,
          },
        },
      },
      [SAFETY_CHECK_MODES.COLLISION_DETECTION]: {
        color: 'fixedError',
        label: 'workflows.safety_check_results.CollisionPoints.label',
        input: { name: 'Enable Collision Detection', value: false },
        output: { name: 'Number of Collision Points', value: 0 },
        warning: {
          key: 'workflows.safety_check_results.CollisionDetection.warning',
          defaultMessage:
            'One of more collisions were detected over the course of the machine simulation. This can damage your system. Recalibration may be required after a collision. ',
        },
        recommendation: {
          key: 'workflows.safety_check_results.CollisionDetection.recommendation',
          defaultMessage: '-Move the part\n-Edit tool rotations',
        },
      },
    }),
    [],
  );

  const getSafetyCheck = useCallback(() => {
    const selectedOperator = getSelectedOperator();
    const safetyCheckValues = getSafetyCheckValues();
    if (selectedOperator?.name === TOOLPATH_GENERATOR_OPERATOR_NAME) {
      updateSafetyCheckValues(selectedOperator, safetyCheckValues);
    }
    return safetyCheckValues;
  }, [getSelectedOperator, getSafetyCheckValues, updateSafetyCheckValues]);

  const getSafetyCheckIconColor = useCallback(() => {
    const safetyCheckValues = getSafetyCheck();
    const isSafetyCheckEnabled = Object.values(safetyCheckValues).some(
      (check) => check.input.value === 'true',
    );
    const isSafetyCheckPassed = Object.values(safetyCheckValues).every(
      (check) =>
        !check.input.value ||
        check.output.value === '0' ||
        check.output.value === '',
    );

    if (!isSafetyCheckEnabled) {
      return 'outlineVariant';
    }
    return isSafetyCheckPassed ? 'success' : 'error';
  }, [getSafetyCheck]);

  const getSafetyCheckDropDownValues = useCallback(() => {
    const safetyChecks = getSafetyCheck();
    if (!safetyChecks) return [];

    const enabledSafetyChecks = Object.entries(safetyChecks)
      .filter(
        ([key, check]) =>
          //temporary, until additional modes exposed
          (key === SAFETY_CHECK_MODES.COLLISION_DETECTION ||
            key === SAFETY_CHECK_MODES.REACH_LIMITS) &&
          check.input.value === 'true',
      )
      .map(([key, check]) => ({ ...check, mode: key }));

    if (enabledSafetyChecks.length === 0) return [];

    const failedChecks = enabledSafetyChecks.filter(
      (check) => check.output.value !== '0' && check.output.value !== '',
    );

    if (failedChecks.length === 0) return [];

    return failedChecks.map((check) => ({
      leadingIconName: 'Dot_1',
      leadingIconColor: check.color,
      id: check.mode,
      mode: check.mode,
      label: intl.formatMessage({
        id: check.label,
        defaultMessage: check.input?.name || check.mode,
      }),
      selected: check.mode === safetyCheckMode,
      onClick: () => handleSafetyCheckSelect(check.mode),
    }));
  }, [getSafetyCheck, intl, handleSafetyCheckSelect, safetyCheckMode]);

  const getSafetyCheckPassed = useCallback(() => {
    const safetyCheckValues = getSafetyCheck();
    const isSafetyCheckEnabled = Object.values(safetyCheckValues).some(
      (check) => check.input.value === 'true',
    );
    const isSafetyCheckPassed = Object.values(safetyCheckValues).every(
      (check) =>
        !check.input.value ||
        check.output.value === '0' ||
        check.output.value === '',
    );

    if (!isSafetyCheckEnabled) {
      return true;
    }
    return isSafetyCheckPassed;
  }, [getSafetyCheck]);

  const getFailedSafetyCheckLabels = useCallback(() => {
    const safetyCheckValues = getSafetyCheck();

    return Object.values(safetyCheckValues)
      .filter(
        (check) =>
          check.input.value === 'true' &&
          check.output.value !== '0' &&
          check.output.value !== '',
      )
      .map((check) => check.label);
  }, [getSafetyCheck]);

  const getSafetyCheckIconText = useCallback(() => {
    const color = getSafetyCheckIconColor();
    switch (color) {
      case 'success':
        return intl.formatMessage({
          id: 'workflows.safety_check_results.icon_text_result_success.label',
          defaultMessage: 'All good',
        });
      case 'error':
        return intl.formatMessage({
          id: 'workflows.safety_check_results.icon_text_result_error.label',
          defaultMessage: 'Issues detected',
        });
      default:
        return '';
    }
  }, [intl, getSafetyCheckIconColor]);

  return {
    getSafetyCheck,
    getSafetyCheckIconColor,
    getSafetyCheckPassed,
    getFailedSafetyCheckLabels,
    getSafetyCheckIconText,
    getSafetyCheckDropDownValues,
  };
}
