import { OperatorInputTypes } from '@constants/operatorInputTypes';

export const OPERATORS_WITH_ADD_BUTTON = ['Combine'];

/* Input Types */
export const DROP_DOWN_INPUT_FIELD_TYPES = [
  OperatorInputTypes.GEOMETRY_TRIANGLE_MESH,
  OperatorInputTypes.LIST_GEOMETRY_CLASSIFIED_POLYLINES,
  OperatorInputTypes.GEOMETRY_PLANES,
  OperatorInputTypes.GEOMETRY_POINTS,
  OperatorInputTypes.GEOMETRY_CLASSIFIED_POLYLINES,
  OperatorInputTypes.TOOLPATH_ANALYSIS_DATA,
  OperatorInputTypes.GEOMETRY_BREP,
];

export const DROP_DOWN_FILE_FIELD_TYPES = [
  OperatorInputTypes.FILE_GENERAL,
  OperatorInputTypes.FILE_TRIANGLE_MESH,
  OperatorInputTypes.FILE_CLASSIFIED_POLYLINES,
  OperatorInputTypes.FILE_GCODE,
];

export const DROP_DOWN_DATA_FIELD_TYPES = [
  OperatorInputTypes.ANALYSIS_DATA_CLASSIFIED_POLYLINES,
];

export const DROP_DOWN_FIELD_TYPES = [
  ...DROP_DOWN_INPUT_FIELD_TYPES,
  ...DROP_DOWN_DATA_FIELD_TYPES,
  OperatorInputTypes.SELECTION,
];

export const OLD_DROP_DOWN_FIELD_TYPES = [
  ...DROP_DOWN_FIELD_TYPES,
  ...DROP_DOWN_FILE_FIELD_TYPES,
];

export const INPUT_FIELD_TYPES = [
  OperatorInputTypes.STRING,
  OperatorInputTypes.INTEGER,
  OperatorInputTypes.DECIMAL,
];

export const NUMBER_FIELD_TYPES = [
  OperatorInputTypes.INTEGER,
  OperatorInputTypes.DECIMAL,
];

export const CHECKBOX_FIELD_TYPES = [OperatorInputTypes.BOOLEAN];
/* End of Input Types */

/* Output Types */
export const GEO_OUTPUT_TYPES = [
  OperatorInputTypes.GEOMETRY_TRIANGLE_MESH,
  OperatorInputTypes.GEOMETRY_CLASSIFIED_POLYLINES,
  OperatorInputTypes.GEOMETRY_PLANES,
  OperatorInputTypes.GEOMETRY_POINTS,
  OperatorInputTypes.GLTF_BYTE_ARRAY,
  OperatorInputTypes.GEOMETRY_BREP,
];

export const VISUALLY_HIDDEN_OUTPUT_TYPES = [
  OperatorInputTypes.TOOLPATH_SIMULATION,
  OperatorInputTypes.TOOLPATH_ANALYSIS_DATA,
  OperatorInputTypes.TOOLPATH_SIMULATION_STEPS,
];

export const TOOLPATH_OUTPUT_TYPES = [OperatorInputTypes.TOOLPATH_INSTRUCTIONS];
/* End of Output Types */
