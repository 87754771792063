import { FeatureFlagContext } from '@app/containers/Providers/FeatureFlagProvider';
import { useCallback, useContext } from 'react';

/**
 * A hook that returns a function to check if a feature flag is enabled or not.
 * This is a better alternative to useFeatureFlagValue in cases where you don't want
 * to evaluate the flag every render, and instead want to evaluate it on demand e.g.
 * inside a function call.
 * @returns a function isFeatureFlagEnabled to check if a flag is enabled
 */
export default function useFeatureFlags() {
  const client = useContext(FeatureFlagContext);

  /**
   * Determines if a given feature is enabled or not for the current
   * user. If there is no feature flag client configured, or if the check
   * fails, returns the default value provided (or false).
   */
  const isFeatureFlagEnabled = useCallback(
    (featureEnum) => {
      if (DESKTOP_APP || !client) return featureEnum.defaultValue;
      return client.checkGate(featureEnum.flagName);
    },
    [client],
  );

  return { isFeatureFlagEnabled };
}
