import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { BufferGeometryUtils } from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import { LineTypes } from '../constants/lineTypes';
import { ViewModes } from '../constants/viewModes';
import { isUndefined } from 'lodash';

const CIRCLE_IMAGE_PATH = '/img/circle.png';

const POINT_LINE_REGEX_AIB =
  /^[+-]?(\d+\.?\d*|\.\d+|\d+|(?:0|[1-9]\d*)(?:\.\d+)?(?:[eE][+\\-]?\d+)?)\s[+-]?(\d+\.?\d*|\.\d+|\d+).+/;
const POLYLINE_HEADER_REGEX_AIB = /^p.+|^p/;
const POINT_PARAMETER_LINE_REGEX_AIB = /x\sy\sz?.+/;
const INDIVIDUAL_POINT_LINE_REGEX_AIB = /v\s.+/;

const lineColorLayerAndSequenceIfAllSame = new THREE.Color(
  'hsl(222, 100%, 39%)',
);
const lineColorPointDataIfAllSame = new THREE.Color('hsl(42, 100%, 50%)');

const LINEAR_GRADIENT = new THREE.TextureLoader().load(
  '/img/textures/box-linear-gradient-01.png',
);
const RADIAL_GRADIENT = new THREE.TextureLoader().load(
  '/img/textures/radial-gradient-01.png',
);

const PolylineClippingMode = {
  Layer: 'layer',
  Sequence: 'sequence',
};

class VisualizationUtils {
  static convertTo3DObject(object, displayMode, applyScaling = true) {
    const initialScale = 0.02;
    if (applyScaling) {
      object.scale.set(initialScale, initialScale, initialScale);
    }

    object.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        const mesh = child;

        mesh.userData.clipEnabled = true;

        switch (displayMode) {
          case ViewModes.SOLID: {
            const materialFront = new THREE.MeshStandardMaterial({
              side: THREE.FrontSide,
              color: '#ffffff',
              wireframe: false,
              roughness: 1.0,
              metalness: 0.5,
            });
            const materialBack = new THREE.MeshStandardMaterial({
              side: THREE.BackSide,
              color: '#ff0000',
              wireframe: false,
              roughness: 1.0,
              metalness: 0.5,
            });
            mesh.material = materialFront;
            const meshClone = mesh.clone();
            meshClone.material = materialBack;
            object.add(meshClone);
            break;
          }
          case ViewModes.WIREFRAME:
            mesh.material = new THREE.MeshStandardMaterial({
              color: '#ffffff',
              wireframe: true,
              transparent: true,
              opacity: 0.2,
            });
            break;
          case ViewModes.SHADED_WIREFRAME: {
            mesh.material = new THREE.MeshStandardMaterial({
              side: THREE.DoubleSide,
              color: '#ffffff',
              wireframe: false,
              polygonOffset: true,
              polygonOffsetFactor: 1,
              polygonOffsetUnits: 1,
            });
            const geo = new THREE.WireframeGeometry(mesh.geometry);
            const mat = new THREE.LineBasicMaterial({
              color: 0x000000,
              transparent: true,
              opacity: 0.2,
            });
            const wireframe = new THREE.LineSegments(geo, mat);
            wireframe.userData.clipEnabled = true;

            mesh.add(wireframe);

            break;
          }
          case ViewModes.GHOSTED:
            mesh.material = new THREE.MeshStandardMaterial({
              side: THREE.DoubleSide,
              color: '#ffffff',
              wireframe: false,
              roughness: 1.0,
              metalness: 0.0,
              transparent: true,
              opacity: 0.3,
              depthFunc: THREE.NeverDepth,
              depthWrite: false,
            });
            break;
          default:
            mesh.material = new THREE.MeshStandardMaterial({
              side: THREE.DoubleSide,
              color: '#ffffff',
              wireframe: false,
              roughness: 1.0,
              metalness: 0.0,
            });
            break;
        }
      }
    });
    return object;
  }

  static convertObjToObject3D(obj, displayMode) {
    const loader = new OBJLoader();
    const object = loader.parse(obj);
    return this.convertTo3DObject(object, displayMode);
  }

  static convertGlbToObject3D(glb, applyMaterial = true) {
    const initialScaleX = 20.0812;
    const initialScaleY = 20.0812;
    const initialScaleZ = 20.0812;
    const object = glb.scene.children[0];
    object.scale.set(initialScaleX, initialScaleY, initialScaleZ);
    if (applyMaterial) {
      object.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          const mesh = child;
          mesh.material = new THREE.MeshStandardMaterial({
            side: THREE.DoubleSide,
            color: '#ffffff',
            wireframe: false,
            roughness: 1.0,
            metalness: 0.0,
          });
          mesh.userData.clipEnabled = true;
        }
      });
    } else {
      object.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          const mesh = child;
          mesh.material.side = THREE.DoubleSide;
          mesh.userData.clipEnabled = true;
        }
      });
    }
    return object;
  }

  static getLegendRangeFromAib(aib, lineData) {
    const result = [];
    const lines = aib.split('\n');
    if (!lines[0]?.includes('v2')) {
      return;
    }
    const polylineStartIndices = [];
    if (lineData === 'LAYERS') {
      const layers = [];
      for (let i = 1; i < lines.length - 1; i++) {
        const ln = lines[i];
        if (ln.match(POLYLINE_HEADER_REGEX_AIB)) {
          polylineStartIndices.push(i);
          const s = ln.split(' ');
          if (s.length > 1) {
            const nextLn = lines[i + 1].split(' ');
            for (let j = 1; j < s.length; j++) {
              if (s[j] === 'l') {
                layers.push(nextLn[j - 1]);
              }
            }
          }
        }
      }

      if (layers.length === 0) {
        result.push(NaN);
        result.push(NaN);
      } else {
        result.push(layers.toSorted((a, b) => a - b)[0]);
        result.push(layers.toSorted((a, b) => b - a)[0]);
      }
    } else if (lineData === 'SEQUENCE') {
      let polylineCount = 0;
      for (let i = 1; i < lines.length - 1; i++) {
        const ln = lines[i];
        if (ln.match(POLYLINE_HEADER_REGEX_AIB)) {
          polylineCount++;
        }
      }
      result.push(0);
      result.push(polylineCount - 1);
    } else if (
      lineData === 'THICKNESS' ||
      lineData === 'HEIGHT' ||
      lineData === 'SPEED_MULTIPLIER'
    ) {
      const checkData = [];
      let checkValue = null;
      if (lineData === 'THICKNESS') {
        checkValue = 'w';
      } else if (lineData === 'HEIGHT') {
        checkValue = 'h';
      } else if (lineData === 'SPEED_MULTIPLIER') {
        checkValue = 't';
      }
      let ptParameters = '';
      for (let i = 0; i < lines.length; i++) {
        const line = lines[i];
        if (line.match(POINT_PARAMETER_LINE_REGEX_AIB)) {
          if (ptParameters !== line) {
            ptParameters = line;
          }
        } else if (line.match(POINT_LINE_REGEX_AIB)) {
          const parameters = ptParameters.split(' ');
          const ptValues = line.split(' ');
          for (let k = 0; k < parameters.length; k++) {
            if (parameters[k] === checkValue) {
              checkData.push(parseFloat(ptValues[k]));
            }
          }
        }
      }

      if (checkData.length === 0) {
        result.push(NaN);
        result.push(NaN);
        return result;
      }
      checkData.sort((a, b) => a - b);
      const min = checkData[0];
      const max = checkData[checkData.length - 1];
      if (min != undefined && max != undefined) {
        result.push(+min.toFixed(2));
        result.push(+max.toFixed(2));
      }
    }
    return result;
  }

  static getLineDataValueRangeAndUniqueCustomDataValues(
    lines,
    lineData,
    valueExtremes,
    uniqueCustomDataValues,
  ) {
    if (
      lineData === 'DEFAULT' ||
      lineData === 'LINETYPE' ||
      lineData === 'LAYERS' ||
      lineData === 'SEQUENCE'
    ) {
      return;
    }

    if (lineData === 'THICKNESS') {
      valueExtremes.push(
        ...this.getMinMax(this.getPointDataFromAibLines(lines, 'w')),
      );
    } else if (lineData === 'HEIGHT') {
      valueExtremes.push(
        ...this.getMinMax(this.getPointDataFromAibLines(lines, 'h')),
      );
    } else if (lineData === 'SPEED_MULTIPLIER') {
      valueExtremes.push(
        ...this.getMinMax(this.getPointDataFromAibLines(lines, 't')),
      );
    } else {
      this.getCustomDataRangeAndUniqueValues(
        lines,
        lineData,
        valueExtremes,
        uniqueCustomDataValues,
      );
    }
  }

  static getCustomDataRangeAndUniqueValues(
    lines,
    lineData,
    valueExtremes,
    uniqueCustomDataValues,
  ) {
    const polylineCustomValues = [];
    const perPointCustomvalues = [];
    const customDataType = { value: null };

    this.getPolylineCustomNumericDataFromAibLines(
      lines,
      lineData,
      polylineCustomValues,
      perPointCustomvalues,
      customDataType,
    );

    if (polylineCustomValues.length > 0) {
      if (customDataType.value === 'number') {
        valueExtremes.push(...this.getMinMax(polylineCustomValues));
      } else {
        uniqueCustomDataValues.push(...new Set(polylineCustomValues));
        valueExtremes.push(...[0, 0]);
      }
    } else if (perPointCustomvalues.length > 0) {
      if (customDataType.value === 'number') {
        valueExtremes.push(...this.getMinMax(perPointCustomvalues));
      } else {
        uniqueCustomDataValues.push(...new Set(perPointCustomvalues));
        valueExtremes.push(...[0, 0]);
      }
    } else {
      valueExtremes.push(...[0, 0]);
    }
  }

  static getPointDataFromAibLines(lines, targetProperty) {
    const result = [];
    let parameters = [];

    const parseLineParameters = (line) => line.split(' ');
    const extractPropertyValue = (property, value) => {
      const [, type] = property.split(':');
      return type ? this.castCustomDataByType(value, type) : parseFloat(value);
    };

    lines.forEach((line) => {
      if (line.match(POINT_PARAMETER_LINE_REGEX_AIB)) {
        parameters = parseLineParameters(line);
      } else if (line.match(POINT_LINE_REGEX_AIB) && parameters.length) {
        const values = parseLineParameters(line);

        values.forEach((value, index) => {
          const [propertyName] = parameters[index].split(':');
          if (propertyName === targetProperty) {
            result.push(extractPropertyValue(parameters[index], value));
          }
        });
      }
    });

    return result;
  }

  static getPolylineCustomNumericDataFromAibLines(
    lines,
    targetProperty,
    polylineCustomValues,
    perPointCustomValues,
    customDataType,
  ) {
    let pointParameters = [];

    const parseLineParameters = (line) => line.split(' ');
    const getPropertyDetails = (property) => {
      const [name, type] = property.split(':');
      return { name, type };
    };

    lines.forEach((line, index) => {
      if (line.match(POLYLINE_HEADER_REGEX_AIB)) {
        const polylineData = new Map();
        this.getCustomPolylineData(
          line,
          lines[index + 1],
          polylineData,
          customDataType,
          targetProperty,
        );

        if (polylineData.has(targetProperty)) {
          polylineCustomValues.push(polylineData.get(targetProperty));
        }
      } else if (line.match(POINT_PARAMETER_LINE_REGEX_AIB)) {
        pointParameters = parseLineParameters(line);
      } else if (line.match(POINT_LINE_REGEX_AIB) && pointParameters.length) {
        const pointValues = parseLineParameters(line);

        pointParameters.forEach((parameter, idx) => {
          const { name: propertyName, type: propertyType } =
            getPropertyDetails(parameter);

          if (propertyName === targetProperty) {
            const value = pointValues[idx];
            const castValue = propertyType
              ? this.castCustomDataByType(value, propertyType)
              : value;

            if (propertyType) {
              customDataType.value = this.getCustomDataType(propertyType);
            }
            perPointCustomValues.push(castValue);
          }
        });
      }
    });
  }

  static getMinMax(values) {
    if (values.length === 0) {
      return [-1, -1];
    }

    let min = values[0];
    let max = values[0];

    for (let i = 1; i < values.length; i++) {
      const value = values[i];
      if (value < min) min = value;
      if (value > max) max = value;
    }

    return [min, max];
  }

  static getPolylineLayerRange(polylines) {
    const layer = [];
    for (const child of polylines.children) {
      if (
        ['Object3D', 'LineSegments'].includes(child.type) &&
        child.userData.isPolyline
      ) {
        const childLayer = child.userData?.layer;
        if (!isUndefined(childLayer) && childLayer != null) {
          layer.push(childLayer);
        }
      }
    }

    return this.getMinMax(layer);
  }

  static getPolylineSequenceRange(polylines) {
    let count = -1;
    for (const child of polylines.children) {
      if (
        ['Object3D', 'LineSegments'].includes(child.type) &&
        child.userData.isPolyline
      ) {
        const childLayer = child.userData?.sequence;
        if (!isUndefined(childLayer)) {
          count++;
        }
      }
    }
    return [0, count];
  }

  static clipPolylines(polylines, polylineClippingMode, minValue, maxValue) {
    const result = new THREE.Group();
    for (const child of polylines.children) {
      if (
        child instanceof THREE.Object3D ||
        child instanceof THREE.LineSegments ||
        child instanceof THREE.Line
      ) {
        let value = null;
        switch (polylineClippingMode) {
          case PolylineClippingMode.Layer:
            value = child.userData?.layer;
            break;
          case PolylineClippingMode.Sequence:
            value = child.userData?.sequence;
            break;
          default:
            value = child.userData?.sequence;
            break;
        }
        if (!isUndefined(value) && value >= minValue && value <= maxValue) {
          result.add(child.clone());
        }
      }
    }
    return result;
  }

  static convertAibToObject3D(
    aib,
    lineData,
    basis,
    rotationCorrectionsMatrix,
    bedAlignmentData,
    lineTypeVisibilitySettings,
    customLineData,
    customLineDataSelection,
    isShowSeams,
    isShowSolid,
    isShowRotations,
    isShowVertices,
  ) {
    const result = new THREE.Object3D();
    result.userData.isPolylineClippable = true;

    const areSeamsVisible = isShowSeams && !isShowSolid;
    const areRotationsVisible = isShowRotations && !isShowSolid;

    const allVertices = [];
    const individualPoints = [];

    const layerData = [];

    const polylineAttributes = {
      vertices: [],
      rotations: [],
      layerHeight: [],
      lineThickness: [],
      speedMultiplier: [],
    };

    let linetype = LineTypes.OUTER_WALL;

    const customPolylineData = new Map();
    const customPointData = new Map();

    const customDataType = { value: null };

    const lines = aib.split('\n').slice(1);

    const valueExtremes = [];
    const uniqueCustomDataValues = [];

    this.getLineDataValueRangeAndUniqueCustomDataValues(
      lines,
      lineData,
      valueExtremes,
      uniqueCustomDataValues,
    );

    let currentLayer = 0;
    let currentPolylineIndex = 0;
    let ptParameters = [];
    let vertexCount = 0;

    lines.forEach((ln, i) => {
      if (ln.match(POLYLINE_HEADER_REGEX_AIB)) {
        if (polylineAttributes.vertices.length > 0) {
          this.addPolyline(
            result,
            polylineAttributes,
            linetype,
            lineData,
            customLineData,
            customLineDataSelection,
            valueExtremes,
            basis,
            rotationCorrectionsMatrix,
            currentLayer,
            currentPolylineIndex,
            isShowSolid,
            customPolylineData,
            customPointData,
            uniqueCustomDataValues,
            lineTypeVisibilitySettings,
            isShowVertices,
            areSeamsVisible,
            areRotationsVisible,
          );
          currentPolylineIndex++;
        }

        this.clearPolylineAttributes(polylineAttributes);

        vertexCount = 0;

        currentLayer = this.getLayerDataFromLine(ln, lines[i + 1]);
        layerData.push(currentLayer);
        linetype = this.getLinetype(ln, lines[i + 1]);
        this.getCustomPolylineData(
          ln,
          lines[i + 1],
          customPolylineData,
          customDataType,
          lineData,
        );
        customPointData.clear();
      } else if (ln.match(POINT_PARAMETER_LINE_REGEX_AIB)) {
        ptParameters = ln.split(' ');
      } else if (ln.match(POINT_LINE_REGEX_AIB)) {
        this.appendAibPerPointData(
          ln,
          ptParameters,
          polylineAttributes,
          isShowSolid,
          vertexCount,
          allVertices,
          bedAlignmentData,
          customPointData,
        );
        vertexCount++;
      } else if (ln.match(INDIVIDUAL_POINT_LINE_REGEX_AIB)) {
        const vertexLineData = ln.split(' ');
        const ptX = parseFloat(vertexLineData[1]) * this.getObjScaleFactor();
        const ptY = parseFloat(vertexLineData[2]) * this.getObjScaleFactor();
        const ptZ = parseFloat(vertexLineData[3]) * this.getObjScaleFactor();
        let point = new THREE.Vector3(ptX, ptY, ptZ);
        point = this.transformPointToBed(point, bedAlignmentData);
        individualPoints.push(point.x, point.y, point.z);
      }
    });

    if (polylineAttributes.vertices.length > 0) {
      this.addPolyline(
        result,
        polylineAttributes,
        linetype,
        lineData,
        customLineData,
        customLineDataSelection,
        valueExtremes,
        basis,
        rotationCorrectionsMatrix,
        currentLayer,
        currentPolylineIndex,
        isShowSolid,
        customPolylineData,
        customPointData,
        uniqueCustomDataValues,
        lineTypeVisibilitySettings,
        isShowVertices,
        areSeamsVisible,
        areRotationsVisible,
      );
    }

    this.updatePolylineDisplayColors(result, lineData, layerData);
    this.visualisePoints(result, individualPoints);
    this.addBoundingBox(result, allVertices, isShowSolid);

    if (isShowSolid) {
      result.userData.isSolidView = true;
    }

    return result;
  }

  static addBoundingBox(result, allVertices, isShowSolid) {
    const bboxGeom = this.generateBoundingGeometryFromPoints(
      allVertices,
      isShowSolid,
    );
    const invisibleMaterial = new THREE.MeshBasicMaterial({ visible: false });
    result.add(new THREE.Mesh(bboxGeom, invisibleMaterial));
  }

  // Checks if the polyline should be visible and creates the object
  // Visualises additional polyline data based on the specified visiblity
  // rules (vertices, seams, rotations)
  static addPolyline(
    result,
    polylineAttributes,
    linetype,
    lineData,
    customLineData,
    customLineDataSelection,
    valueExtremes,
    basis,
    rotationCorrectionsMatrix,
    currentLayer,
    currentPolylineIndex,
    isShowSolid,
    customPolylineData,
    customPointData,
    uniqueCustomDataValues,
    lineTypeVisibilitySettings,
    isShowVertices,
    areSeamsVisible,
    areRotationsVisible,
  ) {
    const isPolylineVisible = this.isPolylineVisible(
      lineData,
      linetype,
      lineTypeVisibilitySettings,
      customLineData,
      customPolylineData,
      customLineDataSelection,
    );

    if (isPolylineVisible) {
      result.add(
        this.createPolyline(
          polylineAttributes,
          linetype,
          lineData,
          valueExtremes,
          basis,
          rotationCorrectionsMatrix,
          currentLayer,
          currentPolylineIndex,
          isShowSolid,
          customPolylineData,
          customPointData,
          uniqueCustomDataValues,
          customLineDataSelection,
          customLineData?.type,
        ),
      );

      this.visualiseVertices(
        result,
        polylineAttributes.vertices,
        isShowVertices,
        isShowSolid,
        currentLayer,
        currentPolylineIndex,
      );

      this.visualiseSeams(
        result,
        polylineAttributes.vertices,
        areSeamsVisible,
        currentLayer,
        currentPolylineIndex,
      );

      this.visualiseRotations(
        result,
        polylineAttributes.vertices,
        polylineAttributes.rotations,
        basis,
        rotationCorrectionsMatrix,
        areRotationsVisible,
        currentLayer,
        currentPolylineIndex,
      );
    }
  }

  // Helper function to reset data for a new polyline
  static clearPolylineAttributes(polylineAttributes) {
    polylineAttributes.vertices.length = 0;
    polylineAttributes.rotations.length = 0;
    polylineAttributes.layerHeight.length = 0;
    polylineAttributes.lineThickness.length = 0;
    polylineAttributes.speedMultiplier.length = 0;
  }

  static isFixedLineData(lineData) {
    return (
      lineData === 'DEFAULT' ||
      lineData === 'LINETYPE' ||
      lineData === 'LAYERS' ||
      lineData === 'SEQUENCE' ||
      lineData === 'LAYER_HEIGHT' ||
      lineData === 'LINE_THICKNESS' ||
      lineData === 'SPEED_MULTIPLIER'
    );
  }

  static getPolylineSeamData(vertices) {
    const seam = new THREE.Vector3(vertices[0], vertices[1], vertices[2]);

    const minSegmentLength = 0.01;
    let pt0 = seam.clone();
    let pt1 = new THREE.Vector3(vertices[3], vertices[4], vertices[5]);
    let traversedDistance = pt0.distanceTo(pt1);
    let count = 1;
    while (traversedDistance < minSegmentLength) {
      pt0 = pt1;
      const i = count * 3;
      pt1 = new THREE.Vector3(vertices[i], vertices[i + 1], vertices[i + 2]);
      traversedDistance += pt0.distanceTo(pt1);
      if (count > 10 || count * 3 >= vertices.length) {
        break;
      }
      count++;
    }
    const dir = pt1.clone().sub(seam.clone()).normalize();
    return [seam, dir];
  }

  static appendAibPerPointData(
    ln,
    ptParameters,
    polylineAttributes,
    isShowSolid,
    vertexCount,
    allVertices,
    bedAlignmentData,
    customPointData,
  ) {
    const ptValues = ln.split(' ');

    const pt = { x: 0, y: 0, z: 0 };
    const rotation = { a: 0, b: 0, c: 0 };
    const attributes = { thickness: null, height: null, speed: null };

    ptParameters.forEach((parameterName, index) => {
      const parameterValue = ptValues[index];
      this.assignParameterValue(
        parameterName,
        parameterValue,
        pt,
        rotation,
        attributes,
        customPointData,
      );
    });

    let point = new THREE.Vector3(pt.x, pt.y, pt.z);
    point = this.transformPointToBed(point, bedAlignmentData);

    if (isShowSolid) {
      polylineAttributes.vertices.push(point);
      polylineAttributes.rotations.push(
        new THREE.Vector3(rotation.a, rotation.b, rotation.c),
      );
      polylineAttributes.layerHeight.push(attributes.height);
      polylineAttributes.lineThickness.push(attributes.thickness);
      polylineAttributes.speedMultiplier.push(attributes.speed);
      allVertices.push(point);
    } else {
      polylineAttributes.vertices.push(point.x, point.y, point.z);
      polylineAttributes.rotations.push(rotation.a, rotation.b, rotation.c);
      polylineAttributes.layerHeight.push(attributes.height);
      polylineAttributes.lineThickness.push(attributes.thickness);
      polylineAttributes.speedMultiplier.push(attributes.speed);
      allVertices.push(point.x, point.y, point.z);
    }
  }

  static assignParameterValue(
    parameterName,
    parameterValue,
    pt,
    rotation,
    attributes,
    customPointData,
  ) {
    const scale = this.getObjScaleFactor();
    const parsedValue = parseFloat(parameterValue);

    const parameterActions = {
      x: () => (pt.x = parsedValue * scale),
      y: () => (pt.y = parsedValue * scale),
      z: () => (pt.z = parsedValue * scale),
      a: () => (rotation.a = parsedValue),
      b: () => (rotation.b = parsedValue),
      c: () => (rotation.c = parsedValue),
      w: () => (attributes.thickness = parsedValue),
      h: () => (attributes.height = parsedValue),
      t: () => (attributes.speed = parsedValue),
      default: () => {
        const [name, type] = parameterName.split(':');
        const castedValue = this.castCustomDataByType(parameterValue, type);
        this.addValueToCustomPointDataMap(customPointData, name, castedValue);
      },
    };
    (parameterActions[parameterName] || parameterActions.default)();
  }

  static addValueToCustomPointDataMap(
    customPointData,
    parameterName,
    parameterValue,
  ) {
    if (customPointData.has(parameterName)) {
      customPointData.get(parameterName).push(parameterValue);
    } else {
      customPointData.set(parameterName, [parameterValue]);
    }
  }

  static visualiseRotations(
    result,
    vertices,
    rotations,
    basis,
    rotationCorrectionsMatrix,
    areRotationsVisible,
    currentLayer,
    currentPolylineIndex,
  ) {
    if (!areRotationsVisible || vertices.length === 0) {
      return;
    }

    const axisSize = 0.08;
    const rotationGeometries = [];

    for (let i = 0; i < vertices.length; i += 3) {
      rotationGeometries.push(
        this.createAxisGeometryAtPoint(
          vertices[i],
          vertices[i + 1],
          vertices[i + 2],
          rotations[i],
          rotations[i + 1],
          rotations[i + 2],
          axisSize,
          basis,
          rotationCorrectionsMatrix,
        ),
      );
    }

    if (rotationGeometries.length === 0) {
      return;
    }
    const mergedRotations = BufferGeometryUtils.mergeBufferGeometries(
      rotationGeometries,
      false,
    );
    const rotationsMaterial = new THREE.LineBasicMaterial({
      vertexColors: true,
    });
    const output = new THREE.LineSegments(mergedRotations, rotationsMaterial);
    output.userData.clipEnabled = true;
    output.userData.layer = currentLayer;
    output.userData.sequence = currentPolylineIndex;

    result.add(output);
  }

  static visualisePoints(result, individualPoints) {
    if (individualPoints.length > 0) {
      result.add(this.createPoints(individualPoints));
    }
  }

  static visualiseVertices(
    result,
    vertices,
    isShowVertices,
    isShowSolid,
    layer,
    polylineIndex,
  ) {
    if (!isShowVertices || isShowSolid || vertices.length === 0) {
      return;
    }
    const verticesMaterial = new THREE.PointsMaterial({
      color: '#FFFFFF',
      size: 5,
      sizeAttenuation: false,
      alphaTest: 0.5,
      transparent: false,
      map: VisualizationUtils.circle,
    });
    const vertexGeometry = new THREE.BufferGeometry();
    vertexGeometry.setAttribute(
      'position',
      new THREE.Float32BufferAttribute(vertices, 3),
    );
    const pts = new THREE.Points(vertexGeometry, verticesMaterial);
    pts.userData.clipEnabled = true;
    pts.userData.sequence = polylineIndex;
    pts.userData.layer = layer;
    result.add(pts);
  }

  static visualiseSeams(
    result,
    vertices,
    areSeamsVisible,
    layer,
    polylineIndex,
  ) {
    const seamArrow = new THREE.Object3D();
    if (!areSeamsVisible) {
      return;
    }
    const seamData = this.getPolylineSeamData(vertices);
    const seam = seamData[0];
    const seamDirection = seamData[1];

    const seamMaterial = new THREE.PointsMaterial({
      color: '#FF0000',
      size: 8,
      sizeAttenuation: false,
      alphaTest: 0.5,
      transparent: false,
      map: VisualizationUtils.circle,
    });
    const seamGeometry = new THREE.BufferGeometry().setFromPoints([seam]);
    const point = new THREE.Points(seamGeometry, seamMaterial);
    seamArrow.add(point);

    const arrowColor = 0xff0000;
    const arrowLength = 0.5;

    const arrow = new THREE.ArrowHelper(
      seamDirection,
      seam,
      arrowLength,
      arrowColor,
    );
    seamArrow.add(arrow);

    seamArrow.userData.clipEnabled = true;
    seamArrow.userData.layer = layer;
    seamArrow.userData.sequence = polylineIndex;
    seamArrow.userData.isPolyline = false;
    result.add(seamArrow);
  }

  static transformPointToBed(point, alignmentData) {
    const { origin, dirX, dirY, dirZ } = alignmentData;
    const transformedPoint = origin.clone();
    const scaleFactor = this.getObjScaleFactor();

    // First, we remove the scaling to align with backend coordinates
    const unscaledPoint = point.clone().divideScalar(scaleFactor);

    transformedPoint.add(dirX.clone().multiplyScalar(unscaledPoint.x));
    transformedPoint.add(dirY.clone().multiplyScalar(unscaledPoint.y));
    transformedPoint.add(dirZ.clone().multiplyScalar(unscaledPoint.z));

    // Finally, scale the point back for the frontend visualization
    return transformedPoint.multiplyScalar(scaleFactor);
  }

  static updatePolylineDisplayColors(result, lineData, layerData) {
    if (lineData === 'SEQUENCE') {
      this.updateColorBasedOnPolylineSequence(result);
    } else if (lineData === 'LAYERS') {
      const range = this.getPolylineLayerRange(result);
      const minLayer = range[0];
      const maxLayer = range[1];

      this.updateColorBasedOnLayers(result, layerData, minLayer, maxLayer);
    }
  }

  static setPolylineColor(polylines, newColor) {
    const polylineCount = polylines.children.length;
    for (let i = 0; i < polylineCount; i++) {
      const polyline = polylines.children[i];
      if (polyline.userData.isPolyline) {
        for (const line of polyline.children) {
          if (
            line instanceof THREE.Mesh ||
            line instanceof THREE.LineSegments ||
            line instanceof THREE.Line
          ) {
            line.material.color = newColor;
          }
        }
      }
    }
    return polylines;
  }

  static updateColorBasedOnPolylineSequence(polylines) {
    const polylineCount = polylines.children.length;
    const maxValue = this.getPolylineSequenceRange(polylines)[1];

    for (let i = 0; i < polylineCount; i++) {
      const polyline = polylines.children[i];
      if (polyline.userData.isPolyline) {
        const index = polyline.userData.sequence;
        let newColor = lineColorLayerAndSequenceIfAllSame;
        if (!this.areMinAndMaxValuesSame(maxValue, 0)) {
          const multiplier = (maxValue - index) / maxValue;
          newColor = new THREE.Color(
            'hsl(' + multiplier * 240 + ', 100%, 50%)',
          );
        }

        for (const line of polyline.children) {
          if (
            line instanceof THREE.Mesh ||
            line instanceof THREE.LineSegments ||
            line instanceof THREE.Line
          ) {
            line.material.color = newColor;
          }
        }
      }
    }
    return polylines;
  }

  static updateColorBasedOnLayers(polylines, layerData, minLayer, maxLayer) {
    const polylineCount = polylines.children.length;
    for (let i = 0; i < polylineCount; i++) {
      const polyline = polylines.children[i];
      if (polyline.userData.isPolyline) {
        const newColor = this.getColorFromLayerData(
          polyline.userData.layer,
          minLayer,
          maxLayer,
        );

        for (const line of polyline.children) {
          if (
            line instanceof THREE.Mesh ||
            line instanceof THREE.LineSegments ||
            line instanceof THREE.Line
          ) {
            line.material.color = newColor;
          }
        }
      }
    }
    return polylines;
  }

  static getColorFromLayerData(currentLayer, minLayer, maxLayer) {
    let lineColor = new THREE.Color(0xffffff);
    if (currentLayer != null) {
      lineColor = lineColorLayerAndSequenceIfAllSame;
      if (!this.areMinAndMaxValuesSame(minLayer, maxLayer)) {
        const multiplier = (maxLayer - currentLayer) / (maxLayer - minLayer);
        lineColor = new THREE.Color('hsl(' + multiplier * 240 + ', 100%, 50%)');
      }
    }
    return lineColor;
  }

  static isPolylineVisible(
    lineData,
    linetype,
    lineTypeVisibilitySettings,
    customLineData,
    customPolylineData,
    customLineDataSelection,
  ) {
    if (
      customLineData?.selectionValues?.length > 0 &&
      customLineData?.scope === 'POLYLINE'
    ) {
      return !!customLineDataSelection?.includes(
        customPolylineData.get(lineData),
      );
    }

    if (lineData !== 'LINETYPE') {
      return true;
    }

    if (linetype === LineTypes.OUTER_WALL) {
      return lineTypeVisibilitySettings.OUTER_WALL;
    } else if (linetype === LineTypes.INNER_WALL) {
      return lineTypeVisibilitySettings.INNER_WALL;
    } else if (linetype === LineTypes.SUPPORT_INTERFACE) {
      return lineTypeVisibilitySettings.SUPPORT_INTERFACE;
    } else if (linetype === LineTypes.SUPPORT) {
      return lineTypeVisibilitySettings.SUPPORT;
    } else if (linetype === LineTypes.BRIM) {
      return lineTypeVisibilitySettings.BRIM;
    } else if (linetype === LineTypes.SKIN) {
      return lineTypeVisibilitySettings.SKIN;
    } else if (linetype === LineTypes.INFILL) {
      return lineTypeVisibilitySettings.INFILL;
    } else if (linetype === LineTypes.MILLING) {
      return lineTypeVisibilitySettings.MILLING;
    } else {
      return true;
    }
  }

  static getLinetype(headerLine, polylineDataLine) {
    const s = headerLine.split(' ');

    if (s.length < 2) {
      return LineTypes.OUTER_WALL;
    }

    const s2 = polylineDataLine.split(' ');

    for (let i = 1; i < s.length; i++) {
      if (s[i] === 't') {
        return this.getLinetypeFromString(s2[i - 1]);
      }
    }

    return LineTypes.UNDEFINED;
  }

  static getCustomPolylineData(
    polylineHeaderLine,
    polylineDataLine,
    customPolylineData,
    customDataType,
    targetProperty,
  ) {
    const polylinePropertyNames = polylineHeaderLine.split(' ');
    if (polylinePropertyNames.length < 2) {
      return customPolylineData;
    }
    const polylineValues = polylineDataLine.split(' ');

    customPolylineData.clear();

    for (let i = 0; i < polylinePropertyNames.length; i++) {
      const propertyNameWithType = polylinePropertyNames[i];

      if (
        propertyNameWithType !== 'p' &&
        propertyNameWithType !== 't' &&
        propertyNameWithType !== 'l'
      ) {
        const splitName = propertyNameWithType.split(':');
        const propertyName = splitName[0];

        if (propertyName === targetProperty) {
          const propertyType = splitName[1];
          const propertyValue = polylineValues[i - 1].trim();

          customDataType.value = this.getCustomDataType(propertyType);
          customPolylineData.set(
            propertyName,
            this.castCustomDataByType(propertyValue, propertyType),
          );
        }
      }
    }
  }

  static castCustomDataByType(propertyValue, propertyType) {
    if (propertyType === 'i') {
      return parseInt(propertyValue);
    } else if (propertyType === 'd') {
      return parseFloat(propertyValue);
    } else if (propertyType === 'b') {
      return propertyValue.toLowerCase() === 'true';
    } else {
      return propertyValue;
    }
  }

  static getCustomDataType(propertyType) {
    if (propertyType === 'i' || propertyType === 'd') {
      return 'number';
    } else {
      return 'discrete';
    }
  }

  static getLayerDataFromLine(headerLine, polylineDataLine) {
    const s = headerLine.split(' ');
    if (s.length < 2) {
      return null;
    }
    const s2 = polylineDataLine.split(' ');
    for (let i = 1; i < s.length; i++) {
      if (s[i] === 'l') {
        return parseInt(s2[i - 1]);
      }
    }
    return null;
  }

  static getLinetypeFromString(linetype) {
    if (linetype.includes(LineTypes.OUTER_WALL)) {
      return LineTypes.OUTER_WALL;
    } else if (linetype.includes(LineTypes.INNER_WALL)) {
      return LineTypes.INNER_WALL;
    } else if (linetype.includes(LineTypes.INFILL)) {
      return LineTypes.INFILL;
    } else if (linetype.includes(LineTypes.SUPPORT_INTERFACE)) {
      return LineTypes.SUPPORT_INTERFACE;
    } else if (linetype.includes(LineTypes.SUPPORT)) {
      return LineTypes.SUPPORT;
    } else if (linetype.includes(LineTypes.SKIN)) {
      return LineTypes.SKIN;
    } else if (linetype.includes(LineTypes.BRIM)) {
      return LineTypes.BRIM;
    } else if (linetype.includes(LineTypes.MILLING)) {
      return LineTypes.MILLING;
    } else {
      return LineTypes.OUTER_WALL;
    }
  }

  static getPlaneNormalFromRotations(
    a,
    b,
    c,
    basis,
    rotationCorrectionsMatrix,
  ) {
    const zDir = new THREE.Vector3(0, 0, 1);
    if (a === null || b === null || c === null) {
      return zDir;
    }
    const matrix = this.getCorrectedRotationMatrix(
      a,
      b,
      c,
      basis,
      rotationCorrectionsMatrix,
    );

    return new THREE.Vector3(
      matrix.elements[8],
      matrix.elements[9],
      matrix.elements[10],
    );
  }

  static createPolyline(
    polylineAttributes,
    linetype,
    lineData,
    valueExtremes,
    basis,
    rotationCorrectionsMatrix,
    layer,
    index,
    isShowSolid,
    customPolylineData,
    customPointData,
    uniqueCustomDataValues,
    customLineDataSelection,
    customLineDataType,
  ) {
    const result = new THREE.Object3D();
    result.userData = {
      layer,
      sequence: index,
      clipEnabled: true,
      isPolyline: true,
    };

    const minValue = valueExtremes[0];
    const maxValue = valueExtremes[1];

    const color = this.getPolylineColorFromPolylineData(
      polylineAttributes.vertices,
      lineData,
      linetype,
      customPolylineData,
      minValue,
      maxValue,
      uniqueCustomDataValues,
    );

    const isPerPointMode =
      lineData === 'THICKNESS' ||
      lineData === 'HEIGHT' ||
      lineData === 'SPEED_MULTIPLIER' ||
      customPointData.has(lineData);

    if (isShowSolid) {
      const solidPolyline = this.createPolylineAsSolid(
        polylineAttributes,
        linetype,
        lineData,
        minValue,
        maxValue,
        basis,
        rotationCorrectionsMatrix,
        isPerPointMode,
        color,
        customPointData,
        uniqueCustomDataValues,
        customLineDataSelection,
        customLineDataType,
      );
      for (const child of solidPolyline.children) {
        result.add(child.clone());
      }
    } else {
      const simplePolyline = this.createPolylineAsLines(
        polylineAttributes,
        linetype,
        lineData,
        minValue,
        maxValue,
        basis,
        rotationCorrectionsMatrix,
        isPerPointMode,
        color,
        customPointData,
        uniqueCustomDataValues,
        customLineDataSelection,
        customLineDataType,
      );
      for (const child of simplePolyline.children) {
        result.add(child.clone());
      }
    }
    return result;
  }

  /**
   * Converts a polyline defined by an array of vertices into a threejs
   * polyline comprised of THREE.LineSegments
   */
  static createPolylineAsLines(
    polylineAttributes,
    linetype,
    lineData,
    minValue,
    maxValue,
    basis,
    rotationCorrectionsMatrix,
    isPerPointMode,
    color,
    customPointData,
    uniqueCustomDataValues,
    customLineDataSelection,
    customLineDataType,
  ) {
    const result = new THREE.Object3D();
    const geometry = new THREE.BufferGeometry();

    const lineMaterial = new THREE.LineBasicMaterial({
      color: color,
      opacity: 1.0,
      transparent: true,
      linewidth: 2,
    });

    const vertices = [];
    const vertexColors = [];

    const segmentVisibility = [polylineAttributes.layerHeight.length];
    let indexCount = 0;

    const vertexLastIndex = polylineAttributes.vertices.length - 3;

    for (let i = 0; i < polylineAttributes.vertices.length; i += 3) {
      const isLineSegmentVisible = this.isLineSegmentVisible(
        lineData,
        customPointData,
        indexCount,
        customLineDataSelection,
        customLineDataType,
      );

      segmentVisibility[indexCount] = isLineSegmentVisible;

      if (i > 0 && segmentVisibility[indexCount - 1]) {
        vertices.push(
          polylineAttributes.vertices[i],
          polylineAttributes.vertices[i + 1],
          polylineAttributes.vertices[i + 2],
        );
      }

      if (isLineSegmentVisible && i !== vertexLastIndex) {
        vertices.push(
          polylineAttributes.vertices[i],
          polylineAttributes.vertices[i + 1],
          polylineAttributes.vertices[i + 2],
        );
      }
      indexCount++;
    }

    geometry.setAttribute(
      'position',
      new THREE.Float32BufferAttribute(vertices, 3),
    );

    const layerHeightLastIndex = polylineAttributes.layerHeight.length - 1;

    if (isPerPointMode) {
      for (let i = 0; i < polylineAttributes.layerHeight.length; i++) {
        if (i > 0 && segmentVisibility[i - 1]) {
          vertexColors.push(
            vertexColors[vertexColors.length - 3],
            vertexColors[vertexColors.length - 2],
            vertexColors[vertexColors.length - 1],
          );
        }

        if (segmentVisibility[i] && i !== layerHeightLastIndex) {
          const instanceColor = this.getPerPointDataColor(
            lineData,
            polylineAttributes.layerHeight[i],
            polylineAttributes.lineThickness[i],
            polylineAttributes.speedMultiplier[i],
            minValue,
            maxValue,
            customPointData,
            i,
            uniqueCustomDataValues,
          );

          vertexColors.push(instanceColor.r, instanceColor.g, instanceColor.b);
        }
      }

      geometry.setAttribute(
        'color',
        new THREE.Float32BufferAttribute(vertexColors, 3),
      );

      lineMaterial.vertexColors = true;
    }

    result.add(new THREE.LineSegments(geometry, lineMaterial));
    return result;
  }

  static isLineSegmentVisible(
    lineData,
    customPointData,
    index,
    customLineDataSelection,
    customLineDataType,
  ) {
    if (
      !customPointData.has(lineData) ||
      customLineDataType === 'DECIMAL' ||
      customLineDataType === 'INTEGER'
    ) {
      return true;
    }

    const value = customPointData.get(lineData)[index];
    return !!customLineDataSelection?.includes(value);
  }

  /**
   * Converts a polyline defined by an array of vertices into a solid using
   * instance meshes.
   * Each line segment is comprised of a box and two hemispheres (one at the
   * start and one at the end of each line segment).
   */
  static createPolylineAsSolid(
    polylineAttributes,
    linetype,
    lineData,
    minValue,
    maxValue,
    basis,
    rotationCorrectionsMatrix,
    isPerPointMode,
    color,
    customPointData,
    uniqueCustomDataValues,
    customLineDataSelection,
    customLineDataType,
  ) {
    const result = new THREE.Object3D();

    const scalingFactor = 0.0222;
    const xAxis = new THREE.Vector3(1, 0, 0);
    const zAxis = new THREE.Vector3(0, 0, 1);
    const maxRadAngleForColinearCheck = 0.02; // equals to about 1 degree
    const cornerResolution = 6;

    const boxMaterial = new THREE.MeshBasicMaterial({
      side: THREE.FrontSide,
      color: color,
      wireframe: false,
      map: LINEAR_GRADIENT,
    });
    const cornerMaterial = new THREE.MeshBasicMaterial({
      side: THREE.DoubleSide,
      color: color,
      wireframe: false,
      map: RADIAL_GRADIENT,
    });

    const radius = 0.5;
    const boxGeom = new THREE.CylinderGeometry(radius, radius, 1, 6, 1);
    boxGeom.rotateZ(Math.PI / 2);

    const cornerGeomStart = new THREE.SphereGeometry(
      radius,
      cornerResolution,
      3,
      0,
      Math.PI,
      0,
      Math.PI,
    );
    cornerGeomStart.rotateX(Math.PI / 2);
    cornerGeomStart.rotateZ(-Math.PI / 2);

    const cornerGeomEnd = new THREE.SphereGeometry(
      radius,
      cornerResolution,
      3,
      0,
      Math.PI,
      0,
      Math.PI,
    );
    cornerGeomEnd.rotateX(Math.PI / 2);
    cornerGeomEnd.rotateZ(Math.PI / 2);

    const length = polylineAttributes.vertices.length - 1;
    const boxMesh = new THREE.InstancedMesh(boxGeom, boxMaterial, length);
    const cornerMeshStart = new THREE.InstancedMesh(
      cornerGeomStart,
      cornerMaterial,
      length,
    );
    const cornerMeshEnd = new THREE.InstancedMesh(
      cornerGeomEnd,
      cornerMaterial,
      length,
    );

    // loop through the line segments of the polyline
    for (let i = 0; i < length; i++) {
      const isLineSegmentVisible = this.isLineSegmentVisible(
        lineData,
        customPointData,
        i,
        customLineDataSelection,
        customLineDataType,
      );

      if (!isLineSegmentVisible) continue;

      const v1 = polylineAttributes.vertices[i];
      const v2 = polylineAttributes.vertices[i + 1];
      const dir = v2.clone().sub(v1.clone());
      const dirNorm = dir.clone().normalize();

      const rot = polylineAttributes.rotations[i];
      const planeNormal = this.getPlaneNormalFromRotations(
        rot.x,
        rot.y,
        rot.z,
        basis,
        rotationCorrectionsMatrix,
      );

      const boxMatrix = new THREE.Matrix4();
      const boxTranslation = v1
        .clone()
        .add(dir.clone().multiplyScalar(0.5))
        .add(
          planeNormal
            .clone()
            .multiplyScalar(
              (-polylineAttributes.layerHeight[i] * scalingFactor) / 2,
            ),
        );
      const boxScale = new THREE.Vector3(
        dir.length(),
        polylineAttributes.lineThickness[i] * scalingFactor,
        polylineAttributes.layerHeight[i] * scalingFactor,
      );

      const quaternionBox = this.computeQuaternion(
        xAxis,
        zAxis,
        dirNorm,
        planeNormal,
      );

      boxMatrix.compose(boxTranslation, quaternionBox, boxScale);
      boxMesh.setMatrixAt(i, boxMatrix);

      const instanceColor = this.getPerPointDataColor(
        lineData,
        polylineAttributes.layerHeight[i],
        polylineAttributes.lineThickness[i],
        polylineAttributes.speedMultiplier[i],
        minValue,
        maxValue,
        customPointData,
        i,
        uniqueCustomDataValues,
      );
      if (isPerPointMode) {
        boxMesh.setColorAt(i, instanceColor);
      }

      const cornerStartTranslation = v1
        .clone()
        .add(
          planeNormal
            .clone()
            .multiplyScalar(
              (-polylineAttributes.layerHeight[i] * scalingFactor) / 2,
            ),
        );
      const cornerScale = new THREE.Vector3(
        polylineAttributes.lineThickness[i] * scalingFactor,
        polylineAttributes.lineThickness[i] * scalingFactor,
        polylineAttributes.layerHeight[i] * scalingFactor,
      );

      const cornerStartMatrix = new THREE.Matrix4();
      cornerStartMatrix.compose(
        cornerStartTranslation,
        quaternionBox,
        cornerScale,
      );

      const isIncludeStartCap = this.isLineCapStartIncluded(
        polylineAttributes.vertices,
        i,
        maxRadAngleForColinearCheck,
        dirNorm,
      );
      if (isIncludeStartCap) {
        cornerMeshStart.setMatrixAt(i, cornerStartMatrix);
        if (isPerPointMode) {
          cornerMeshStart.setColorAt(i, instanceColor);
        }
      }

      const isIncludeEndCap = this.isLineCapEndIncluded(
        polylineAttributes.vertices,
        i,
        maxRadAngleForColinearCheck,
        dirNorm,
      );
      if (isIncludeEndCap) {
        const cornerEndTranslation = cornerStartTranslation
          .clone()
          .add(dir.clone());

        const cornerEndMatrix = new THREE.Matrix4();
        cornerEndMatrix.compose(
          cornerEndTranslation,
          quaternionBox,
          cornerScale,
        );

        cornerMeshEnd.setMatrixAt(i, cornerEndMatrix);
        if (isPerPointMode) {
          cornerMeshEnd.setColorAt(i, instanceColor);
        }
      }
    }

    result.add(boxMesh);
    result.add(cornerMeshStart);
    result.add(cornerMeshEnd);
    return result;
  }

  static isLineCapStartIncluded(vertices, i, maxRadAngleForColinearCheck, dir) {
    if (i < vertices.length - 1) {
      const v1 = vertices[i];
      const v2 = vertices[i + 1];
      const dist = v1.distanceTo(v2);
      if (dist < 0.0001) {
        return false;
      }
    }

    if (i > 0) {
      const v0 = vertices[i - 1];
      const v1 = vertices[i];
      const dir0 = v1.clone().sub(v0.clone()).normalize();
      if (dir.angleTo(dir0) < maxRadAngleForColinearCheck) {
        return false;
      }
    }
    return true;
  }

  static isLineCapEndIncluded(vertices, i, maxRadAngleForColinearCheck, dir) {
    if (i < vertices.length - 1) {
      const v1 = vertices[i];
      const v2 = vertices[i + 1];
      const dist = v1.distanceTo(v2);
      if (dist < 0.0001) {
        return false;
      }
    }

    if (i < vertices.length - 2) {
      const v2 = vertices[i + 1];
      const v3 = vertices[i + 2];
      const dir2 = v3.clone().sub(v2.clone()).normalize();
      if (dir.angleTo(dir2) < maxRadAngleForColinearCheck) {
        return false;
      }
    }
    return true;
  }

  static getPerPointDataColor(
    lineData,
    height,
    thickness,
    speedMultiplier,
    minValue,
    maxValue,
    customPointData,
    index,
    uniqueCustomDataValues,
  ) {
    let value = null;

    if (customPointData.has(lineData)) {
      value = customPointData.get(lineData)[index];

      if (typeof value !== 'number') {
        const numberOfValues = uniqueCustomDataValues.length;
        if (numberOfValues === 1) {
          return lineColorPointDataIfAllSame;
        }
        const index = uniqueCustomDataValues.indexOf(value);
        const multiplier = 1 - index / (numberOfValues - 1);

        return new THREE.Color('hsl(' + multiplier * 240 + ', 100%, 50%)');
      }
    } else if (lineData === 'THICKNESS') {
      value = thickness;
    } else if (lineData === 'HEIGHT') {
      value = height;
    } else if (lineData === 'SPEED_MULTIPLIER') {
      value = speedMultiplier;
    }

    let color = new THREE.Color(0xffffff);
    if (value === null) {
      return color;
    }

    color = lineColorPointDataIfAllSame;
    if (!this.areMinAndMaxValuesSame(minValue, maxValue)) {
      const multiplier = (maxValue - value) / (maxValue - minValue);
      color = new THREE.Color('hsl(' + multiplier * 240 + ', 100%, 50%)');
    }
    return color;
  }

  static computeQuaternion(x1, z1, x2, z2) {
    const quaternionA = new THREE.Quaternion();
    quaternionA.setFromUnitVectors(x1, x2);

    const zRotated = z1.clone().applyQuaternion(quaternionA);

    const plane = new THREE.Plane(x2);
    const z2projected = new THREE.Vector3();
    plane.projectPoint(z2.clone(), z2projected);

    const quaternionB = new THREE.Quaternion();
    const dot = z2projected.dot(zRotated);
    if (dot < -0.999) {
      quaternionB.set(0, -1, 0, 0);
    } else {
      quaternionB.setFromUnitVectors(zRotated, z2projected);
    }

    const result = new THREE.Quaternion();
    result.multiplyQuaternions(quaternionB, quaternionA);
    return result;
  }

  static getPolylineColorFromPolylineData(
    vertices,
    lineData,
    linetype,
    customPolylineData,
    minValue,
    maxValue,
    uniqueCustomDataValues,
  ) {
    if (lineData === 'LINETYPE') {
      return this.getLinetypeColor(vertices, linetype);
    } else if (customPolylineData.has(lineData)) {
      const value = customPolylineData.get(lineData);
      const uniqueValuesCount = uniqueCustomDataValues.length;

      if (uniqueValuesCount > 0) {
        // non-numeric custom data
        const index = uniqueCustomDataValues.indexOf(value);
        const multiplier = 1 - index / (uniqueValuesCount - 1);
        return new THREE.Color('hsl(' + multiplier * 240 + ', 100%, 50%)');
      } else if (maxValue === minValue) {
        return new THREE.Color('hsl(240, 100%, 50%)');
      } else {
        // numeric custom data
        const multiplier = (maxValue - value) / (maxValue - minValue);
        return new THREE.Color('hsl(' + multiplier * 240 + ', 100%, 50%)');
      }
    }
    return new THREE.Color(0xffffff);
  }

  static getLinetypeColor(vertices, linetype) {
    switch (linetype) {
      case LineTypes.OUTER_WALL:
        return new THREE.Color('#007d7d');
      case LineTypes.INNER_WALL:
        return new THREE.Color('#D200D2');
      case LineTypes.INFILL:
        return new THREE.Color('#a33e00');
      case LineTypes.SUPPORT:
        return new THREE.Color('#03C700');
      case LineTypes.SUPPORT_INTERFACE:
        return new THREE.Color('#026E00');
      case LineTypes.BRIM:
        return new THREE.Color('#a61100');
      case LineTypes.SKIN:
        return new THREE.Color('#1B228C');
      case LineTypes.MILLING:
        return new THREE.Color('#9356E4');
      case LineTypes.UNDEFINED:
      default:
        return new THREE.Color('#FFFFFF');
    }
  }

  static createPoints(pointData) {
    const pointsMaterial = new THREE.PointsMaterial({
      color: '#FFFFFF',
      size: 10,
      sizeAttenuation: false,
      alphaTest: 0.5,
      transparent: false,
      map: VisualizationUtils.circle,
    });

    const pointsGeometry = new THREE.BufferGeometry();
    pointsGeometry.setAttribute(
      'position',
      new THREE.Float32BufferAttribute(pointData, 3),
    );

    return new THREE.Points(pointsGeometry, pointsMaterial);
  }

  static createDirArrowV1(line, nextLine) {
    const x1 = parseFloat(line[0].split('{')[1]) * this.getObjScaleFactor();
    const y1 = parseFloat(line[1]) * this.getObjScaleFactor();
    const z1 = parseFloat(line[2].split('}')[0]) * this.getObjScaleFactor();
    const x2 = parseFloat(nextLine[0].split('{')[1]) * this.getObjScaleFactor();
    const y2 = parseFloat(nextLine[1]) * this.getObjScaleFactor();
    const z2 = parseFloat(nextLine[2].split('}')[0]) * this.getObjScaleFactor();
    const direction = new THREE.Vector3(x2 - x1, y2 - y1, z2 - z1);
    const arrowColor = 0xff0000;
    const arrowLength = 0.5;
    return new THREE.ArrowHelper(
      new THREE.Vector3().copy(direction).normalize(),
      new THREE.Vector3(x1, y1, z1),
      arrowLength,
      arrowColor,
    );
  }

  /**
   *
   * @param {*} x
   * @param {*} y
   * @param {*} z
   * @param {*} a
   * @param {*} b
   * @param {*} c
   * @param {*} size
   * @param {*} basis
   * @param {Matrix4x4} rotationCorrectionsMatrix
   * @returns
   */

  static getCorrectedRotationMatrix(a, b, c, basis, rotationCorrectionsMatrix) {
    const zRotation = new THREE.Matrix4().makeRotationAxis(
      new THREE.Vector3(basis[2].x, basis[2].y, basis[2].z),
      this.toRadians(c),
    );
    const yRotation = new THREE.Matrix4().makeRotationAxis(
      new THREE.Vector3(basis[1].x, basis[1].y, basis[1].z),
      this.toRadians(b),
    );
    const xRotation = new THREE.Matrix4().makeRotationAxis(
      new THREE.Vector3(basis[0].x, basis[0].y, basis[0].z),
      this.toRadians(a),
    );

    const matrix = new THREE.Matrix4().makeBasis(basis[0], basis[1], basis[2]);
    matrix.premultiply(zRotation);
    matrix.premultiply(yRotation);
    matrix.premultiply(xRotation);
    matrix.multiply(rotationCorrectionsMatrix);
    return matrix;
  }

  static createAxisGeometryAtPoint(
    x,
    y,
    z,
    a,
    b,
    c,
    size,
    basis,
    rotationCorrectionsMatrix,
  ) {
    if (a === null || b === null || c === null) {
      return null;
    }
    const xAxisGeom = new THREE.BufferGeometry();
    const yAxisGeom = new THREE.BufferGeometry();
    const zAxisGeom = new THREE.BufferGeometry();

    const matrix = this.getCorrectedRotationMatrix(
      a,
      b,
      c,
      basis,
      rotationCorrectionsMatrix,
    );

    const positionX = [
      0,
      0,
      0,
      size * matrix.elements[0],
      size * matrix.elements[1],
      size * matrix.elements[2],
    ];
    const positionY = [
      0,
      0,
      0,
      size * matrix.elements[4],
      size * matrix.elements[5],
      size * matrix.elements[6],
    ];
    const positionZ = [
      0,
      0,
      0,
      size * matrix.elements[8],
      size * matrix.elements[9],
      size * matrix.elements[10],
    ];

    const colorX = [1, 0, 0, 1, 0.5, 0];
    const colorY = [0, 1, 0, 0, 1, 0];
    const colorZ = [0, 0, 1, 0, 0.5, 1];

    xAxisGeom.setAttribute(
      'position',
      new THREE.Float32BufferAttribute(positionX, 3),
    );
    xAxisGeom.setAttribute(
      'color',
      new THREE.Float32BufferAttribute(colorX, 3),
    );

    yAxisGeom.setAttribute(
      'position',
      new THREE.Float32BufferAttribute(positionY, 3),
    );
    yAxisGeom.setAttribute(
      'color',
      new THREE.Float32BufferAttribute(colorY, 3),
    );

    zAxisGeom.setAttribute(
      'position',
      new THREE.Float32BufferAttribute(positionZ, 3),
    );
    zAxisGeom.setAttribute(
      'color',
      new THREE.Float32BufferAttribute(colorZ, 3),
    );

    const allAxisGeometries = [xAxisGeom, yAxisGeom, zAxisGeom];
    const mergedGeometry = BufferGeometryUtils.mergeBufferGeometries(
      allAxisGeometries,
      false,
    );
    mergedGeometry.translate(x, y, z);
    return mergedGeometry;
  }

  static toRadians(degrees) {
    return (degrees * Math.PI) / 180.0;
  }

  static generateBoundingGeometryFromPoints(points, isVectorList) {
    const box = new THREE.Box3();
    if (isVectorList) {
      box.setFromPoints(points);
    } else {
      box.setFromArray(points);
    }
    const geometry = new THREE.BoxGeometry(
      box.max.x - box.min.x,
      box.max.y - box.min.y,
      box.max.z - box.min.z,
    );
    geometry.applyMatrix4(
      new THREE.Matrix4().makeTranslation(
        (box.max.x + box.min.x) / 2,
        (box.max.y + box.min.y) / 2,
        (box.max.z + box.min.z) / 2,
      ),
    );
    // Handle NaN values in the 'position' attribute
    const positionAttribute = geometry.attributes.position;
    const positions = positionAttribute.array;

    for (let i = 0; i < positions.length; i++) {
      if (isNaN(positions[i])) {
        positions[i] = 0;
      }
    }

    // Recompute bounding sphere after handling NaN values
    geometry.computeBoundingSphere();
    return geometry;
  }

  static generateBoundingGeometryFromObject3D(object, inputType) {
    const box = this.getBoxFromObject(object, inputType);
    const geometry = new THREE.BoxGeometry(
      box.max.x - box.min.x,
      box.max.y - box.min.y,
      box.max.z - box.min.z,
    );
    geometry.applyMatrix4(
      new THREE.Matrix4().makeTranslation(
        (box.max.x + box.min.x) / 2,
        (box.max.y + box.min.y) / 2,
        (box.max.z + box.min.z) / 2,
      ),
    );
    return geometry;
  }

  static displayBoundingGeometry(object, inputType) {
    const boundingGeometry = this.generateBoundingGeometryFromObject3D(
      object,
      inputType,
    );
    boundingGeometry.computeBoundingBox();
    const geo = new THREE.EdgesGeometry(boundingGeometry);
    const mat = new THREE.LineBasicMaterial({
      color: 0x4c4c4c,
      linewidth: 1,
    });
    const wireframe = new THREE.LineSegments(geo, mat);
    return wireframe;
  }

  static generateDimensionArrows(object, inputType) {
    const dimColor = '#dbdbdb';
    const boundingGeometry = this.generateBoundingGeometryFromObject3D(
      object,
      inputType,
    );
    boundingGeometry.computeBoundingBox();
    const dirX = new THREE.Vector3(1, 0, 0);
    const dirY = new THREE.Vector3(0, 1, 0);
    const dirZ = new THREE.Vector3(0, 0, 1);

    const origin = new THREE.Vector3(
      boundingGeometry.boundingBox.min.x,
      boundingGeometry.boundingBox.min.y,
      boundingGeometry.boundingBox.min.z,
    );
    const lengthX =
      boundingGeometry.boundingBox.max.x - boundingGeometry.boundingBox.min.x;
    const lengthY =
      boundingGeometry.boundingBox.max.y - boundingGeometry.boundingBox.min.y;
    const lengthZ =
      boundingGeometry.boundingBox.max.z - boundingGeometry.boundingBox.min.z;

    const arrowOffset = 1;
    const dimensionsInfoGroup = new THREE.Group();
    const millimetersXYZ = this.computeBoundingMillimeters(object, inputType);
    const textSize = 0.2;
    this.addTextToObject(
      dimensionsInfoGroup,
      `${millimetersXYZ[0].toPrecision(4)}mm`,
      textSize,
      0,
      dimColor,
      {
        x: origin.x + lengthX / 2 - 0.7,
        y: origin.y - 2.5 * textSize,
        z: origin.z,
      },
    );
    this.addTextToObject(
      dimensionsInfoGroup,
      `${millimetersXYZ[1].toPrecision(4)}mm`,
      textSize,
      0,
      dimColor,
      {
        x: origin.x - 2.5 * textSize,
        y: origin.y + lengthY / 2 + 0.7,
        z: origin.z,
        rotZ: -Math.PI / 2,
      },
    );
    this.addTextToObject(
      dimensionsInfoGroup,
      `${millimetersXYZ[2].toPrecision(4)}mm`,
      textSize,
      0,
      dimColor,
      {
        x: origin.x + lengthX + 5 * textSize,
        y: origin.y,
        z:
          origin.z +
          (boundingGeometry.boundingBox.max.z -
            boundingGeometry.boundingBox.min.z) /
            2 -
          textSize / 2,
        rotX: Math.PI / 2,
        rotY: -Math.PI / 2,
      },
    );

    const arrowColor = dimColor;
    // This is required as Matrix.inverse used to generate arrow heads
    // fails when the arrow head is longer than the arrow
    const arrowLength = Math.min(
      0.3,
      lengthX - 0.1,
      lengthY - 0.1,
      lengthZ - 0.1,
    );
    const arrowWidth = 0.8 * arrowLength;
    const extensionLineMaterial = new THREE.LineDashedMaterial({
      color: 0x4c4c4c,
      linewidth: 1,
      scale: 1,
      dashSize: 0.3,
      gapSize: 0.1,
    });

    const points1 = [];
    points1.push(
      new THREE.Vector3(origin.x, origin.y, origin.z),
      new THREE.Vector3(origin.x - arrowOffset, origin.y, origin.z),
    );
    const line1 = new THREE.Line(
      new THREE.BufferGeometry().setFromPoints(points1),
      extensionLineMaterial,
    );
    line1.computeLineDistances();

    const points2 = [];
    points2.push(
      new THREE.Vector3(origin.x, origin.y + lengthY, origin.z),
      new THREE.Vector3(origin.x - arrowOffset, origin.y + lengthY, origin.z),
    );
    const line2 = new THREE.Line(
      new THREE.BufferGeometry().setFromPoints(points2),
      extensionLineMaterial,
    );
    line2.computeLineDistances();

    const points3 = [];
    points3.push(
      new THREE.Vector3(origin.x, origin.y, origin.z),
      new THREE.Vector3(origin.x, origin.y - arrowOffset, origin.z),
    );
    const line3 = new THREE.Line(
      new THREE.BufferGeometry().setFromPoints(points3),
      extensionLineMaterial,
    );
    line3.computeLineDistances();

    const points4 = [];
    points4.push(
      new THREE.Vector3(origin.x + lengthX, origin.y, origin.z),
      new THREE.Vector3(origin.x + lengthX, origin.y - arrowOffset, origin.z),
    );
    const line4 = new THREE.Line(
      new THREE.BufferGeometry().setFromPoints(points4),
      extensionLineMaterial,
    );
    line4.computeLineDistances();

    const points5 = [];
    points5.push(
      new THREE.Vector3(origin.x + lengthX, origin.y, origin.z + lengthZ),
      new THREE.Vector3(
        origin.x + lengthX + arrowOffset,
        origin.y,
        origin.z + lengthZ,
      ),
    );
    const line5 = new THREE.Line(
      new THREE.BufferGeometry().setFromPoints(points5),
      extensionLineMaterial,
    );
    line5.computeLineDistances();

    const points6 = [];
    points6.push(
      new THREE.Vector3(origin.x + lengthX, origin.y, origin.z),
      new THREE.Vector3(origin.x + lengthX + arrowOffset, origin.y, origin.z),
    );
    const line6 = new THREE.Line(
      new THREE.BufferGeometry().setFromPoints(points6),
      extensionLineMaterial,
    );
    line6.computeLineDistances();

    dimensionsInfoGroup.add(
      new THREE.ArrowHelper(
        dirX,
        new THREE.Vector3(origin.x + lengthX / 2, origin.y, origin.z),
        lengthX / 2,
        arrowColor,
        arrowLength,
        arrowWidth,
      ).translateX(arrowOffset),
      new THREE.ArrowHelper(
        new THREE.Vector3(-1, 0, 0),
        new THREE.Vector3(origin.x + lengthX / 2, origin.y, origin.z),
        lengthX / 2,
        arrowColor,
        arrowLength,
        arrowWidth,
      ).translateX(-arrowOffset),
      new THREE.ArrowHelper(
        dirY,
        new THREE.Vector3(origin.x, origin.y + lengthY / 2, origin.z),
        lengthY / 2,
        arrowColor,
        arrowLength,
        arrowWidth,
      ).translateX(-arrowOffset),
      new THREE.ArrowHelper(
        new THREE.Vector3(0, -1, 0),
        new THREE.Vector3(origin.x, origin.y + lengthY / 2, origin.z),
        lengthY / 2,
        arrowColor,
        arrowLength,
        arrowWidth,
      ).translateX(-arrowOffset),
      new THREE.ArrowHelper(
        dirZ,
        new THREE.Vector3(origin.x + lengthX, origin.y, origin.z + lengthZ / 2),
        lengthZ / 2,
        arrowColor,
        arrowLength,
        arrowWidth,
      ).translateX(arrowOffset),
      new THREE.ArrowHelper(
        new THREE.Vector3(0, 0, -1),
        new THREE.Vector3(origin.x + lengthX, origin.y, origin.z + lengthZ / 2),
        lengthZ / 2,
        arrowColor,
        arrowLength,
        arrowWidth,
      ).translateX(arrowOffset),

      line1,
      line2,
      line3,
      line4,
      line5,
      line6,
    );
    return dimensionsInfoGroup;
  }

  static addTextToObject(
    object,
    content,
    size,
    thickness,
    color,
    positionOptions = {
      x: 0,
      y: 0,
      z: 0,
      rotX: 0,
      rotY: 0,
      rotZ: 0,
    },
  ) {
    THREE.Cache.enabled = true;
    const loader = new THREE.FontLoader();
    loader.load('/fonts/helvetiker.typeface.json', (font) => {
      const geometry = new THREE.TextGeometry(content, {
        font: font,
        size: size,
        height: thickness,
        curveSegments: 12,
      });
      geometry.rotateX(positionOptions.rotX || 0);
      geometry.rotateY(positionOptions.rotY || 0);
      geometry.rotateZ(positionOptions.rotZ || 0);
      geometry.applyMatrix4(
        new THREE.Matrix4().makeTranslation(
          positionOptions.x || 0,
          positionOptions.y || 0,
          positionOptions.z || 0,
        ),
      );
      const material = new THREE.MeshBasicMaterial({ color });
      const textMesh = new THREE.Mesh(geometry, material);
      object.add(textMesh);
    });
  }

  static getBoxFromObject(object, inputType) {
    const box = new THREE.Box3();
    if (inputType === 'MESH') {
      box.setFromObject(object);
    } else {
      const updatedObject = new THREE.Object3D();
      for (const child of object.children) {
        if (child instanceof THREE.Mesh) {
          updatedObject.add(child.clone());
        }
      }
      box.setFromObject(updatedObject);
    }
    return box;
  }

  static computeBoundingMillimeters(object, inputType) {
    const box = this.getBoxFromObject(object, inputType);
    // There is a conversion factor of 100 between .obj and ThreeJS units
    return [
      (box.max.x - box.min.x) / this.getObjScaleFactor(),
      (box.max.y - box.min.y) / this.getObjScaleFactor(),
      (box.max.z - box.min.z) / this.getObjScaleFactor(),
    ];
  }

  static getObjScaleFactor() {
    // The units of .obj files are by convention in millimeters,
    // this isn't a very natural unit for ThreeJS (e.g. grids are
    // subdivded into units of size one, so we scale the objects
    // first.
    return 0.02;
  }

  static areMinAndMaxValuesSame(min, max) {
    return (+min)?.toFixed(2) == (+max)?.toFixed(2);
  }

  static circle = new THREE.TextureLoader().load(CIRCLE_IMAGE_PATH);
}

export const NULL_OBJECT_3D = new THREE.Object3D();

export default VisualizationUtils;
