import { useEffect } from 'react';
import useOrganizationQueries from '@hooks/organization/useOrganizationQueries';
import usePrinterQueries from '@hooks/printers/usePrinterQueries';
import useBannerNotifications from '@hooks/useBannerNotifications';

const AppLoader = () => {
  const { usersQuery } = useOrganizationQueries();
  const { allPrinterDefinitionsQuery, robotBrandDefinitionsQuery } =
    usePrinterQueries();
  const refetchAllPrinterDefinitions = allPrinterDefinitionsQuery.refetch;
  const refetchRobotBrandDefinitionsQuery = robotBrandDefinitionsQuery.refetch;
  const refetchUsersQuery = usersQuery.refetch;
  useBannerNotifications();

  useEffect(() => {
    refetchUsersQuery();
    refetchAllPrinterDefinitions();
    refetchRobotBrandDefinitionsQuery();
  }, [
    refetchUsersQuery,
    refetchAllPrinterDefinitions,
    refetchRobotBrandDefinitionsQuery,
  ]);

  return null;
};

AppLoader.propTypes = {};

export default AppLoader;
