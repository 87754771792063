import { ModalDataTypes } from '@constants/modalDataTypes';
import React from 'react';

export const showDownstreamMouseOperatorWarning = (
  intl,
  showDialog,
  hideDialog,
  downstreamSelectionAffectedOperators,
) => {
  const title = intl.formatMessage({
    id: 'workflow.mouse-selection.input-change.title',
    defaultMessage: 'This Change May Impact Your Workflow',
  });
  const subtitle = intl.formatMessage(
    {
      id: 'workflow.mouse-selection.input-change.subtitle',
      defaultMessage:
        'New changes will reset the inputs of the following operators <br></br> <br></br> {affectedOperators} <br></br> Do you want to proceed with the update?',
    },
    {
      affectedOperators: downstreamSelectionAffectedOperators?.map((name) => (
        <>
          • {name}
          <br />
        </>
      )),
      b: (str) => <b>{str}</b>,
      br: () => <br />,
    },
  );
  return new Promise((resolve) =>
    showDialog(ModalDataTypes.PROMPT, {
      dataTestId: 'warn-mouse-selection-input-dialog',
      title,
      subtitle,
      secondaryButtonLabel: intl.formatMessage({
        id: 'general.cancel',
        defaultMessage: 'Cancel',
      }),
      primaryButtonLabel: intl.formatMessage({
        id: 'general.confirm',
        defaultMessage: 'Confirm',
      }),
      onPrimaryButtonClick: async () => {
        resolve(true);
      },
      onSecondaryButtonClick: async () => {
        resolve(false);
      },
      onCloseIconButtonClick: async () => {
        await hideDialog(ModalDataTypes.PROMPT);
        resolve(false);
      },
    }),
  );
};
