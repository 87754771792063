import styled, { css } from 'styled-components';
import { flexCenterVertical, when } from '@stylesheets/helpers';
import InfoRow from '@components/1-atoms/InfoRow';
import { Icon, Label } from '@components/1-atoms/InfoRow/InfoRow.styled';
import SpriteSVG from '@components/1-atoms/SpriteSVG';

export const Content = styled.div`
  ${flexCenterVertical}

  ${({ withContent, theme: { spacing } }) => css`
    gap: ${spacing.level1};

    ${when(
      withContent,
      `
        padding-left: ${spacing.level2};
      `,
    )}
  `}
`;

export const LoaderWrapper = styled.div`
  width: min(450px, 100%);
`;

export const ColorInfoRow = styled(InfoRow)`
  ${({ color, theme: { colors, spacing } }) => css`
    padding-left: ${spacing.level3};
    padding-right: ${spacing.level3};

    ${Icon} {
      ${when(
        color,
        `
          fill: ${colors?.[color] || color};
        `,
      )}
    }

    ${Label} {
      ${when(
        color,
        `
          color: ${colors?.[color] || color};
        `,
      )}
    }
  `}
`;

export const ColorIcon = styled(SpriteSVG)`
  min-width: fit-content;
  width: 18px;
  height: 18px;
  flex-shrink: 0;

  ${({ errorColor, theme: { colors } }) => css`
    fill: ${colors?.[errorColor]};
  `}
`;
