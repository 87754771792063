import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import {
  hideGeneralPrinterSetting,
  uploadPrinterComponentModels,
} from '@actions/printerActions';
import SettingBar from '@components/2-molecules/SettingBar';
import React, { useCallback, useMemo } from 'react';
import { getGeneralPrinterSettingState } from '@selectors/printerSelectors';
import { Formik } from 'formik';
import { printerConstants as constants } from '@constants/printers/printerConstants';
import {
  initDataMachineType,
  MachineTypeSetting,
  MachineTypeSettingValidationSchema,
} from '@components/Printers/GeneralPrinterSetting/MachineTypeSetting/MachineTypeSetting';
import {
  initDataToolType,
  ToolTypeSetting,
  ToolTypeSettingValidationSchema,
} from '@components/Printers/GeneralPrinterSetting/ToolTypeSetting/ToolTypeSetting';
import {
  initDataPlinthType,
  PlinthTypeSetting,
  PlinthTypeSettingValidationSchema,
} from '@components/Printers/GeneralPrinterSetting/PlinthSetting/PlinthSetting';
import {
  EnclosureSetting,
  EnclosureTypeSettingValidationSchema,
  initDataEnclosureType,
} from '@components/Printers/GeneralPrinterSetting/EncloseSetting/EnclosureSetting';
import {
  BedTypeSetting,
  BedTypeSettingValidationSchema,
  initDataBedType,
} from '@components/Printers/GeneralPrinterSetting/BedTypeSetting/BedTypeSetting';
import { componentDiscriminators } from '@constants/printers/componentDiscriminators';
import { showErrorDialog } from '@actions/errorActions';
import { isFetchInProgess } from '@selectors/fetchSelector';
import { robotTypeDefinitions } from '@constants/printers/machineDefaultProperties';
import PropTypes from 'prop-types';
import usePrinter from '@hooks/printers/usePrinter';

const GeneralPrinterSetting = ({ selectedPrinter }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const generalPrinterState = useSelector(getGeneralPrinterSettingState());
  const setting = generalPrinterState.setting;
  const formKey = `form-${setting}`;
  const hideConfig = () => dispatch(hideGeneralPrinterSetting());
  const isPrinterComponentUpdating = useSelector(
    isFetchInProgess('uploadPrinterComponent'),
  );
  const { invalidatePrinterQuery } = usePrinter();

  const validationSchemaMap = useMemo(
    () => ({
      [constants.robotType]: {
        schema: MachineTypeSettingValidationSchema,
        setting: 'machineTypeSetting',
      },
      [constants.extruderType]: {
        schema: ToolTypeSettingValidationSchema,
        setting: 'toolTypeSetting',
      },
      [constants.plinthType]: {
        schema: PlinthTypeSettingValidationSchema,
        setting: 'plinthTypeSetting',
      },
      [constants.basePlinthType]: {
        schema: PlinthTypeSettingValidationSchema,
        setting: 'plinthTypeSetting',
      },
      [constants.enclosureType]: {
        schema: EnclosureTypeSettingValidationSchema,
        setting: 'enclosureTypeSetting',
      },
      [constants.printingBedType]: {
        schema: BedTypeSettingValidationSchema,
        setting: 'bedTypeSetting',
      },
    }),
    [],
  );

  const mapDbConfigToFormInitialValues = (printer) => {
    const initialValues = {
      machineTypeSetting: {
        machineType: '',
        brand: '',
        files: {},
        machineProperties: {},
      },
      toolTypeSetting: {
        toolType: '',
        files: {},
        toolProperties: {},
      },
      plinthTypeSetting: {
        plinthType: '',
        files: {},
        plinthProperties: {},
      },
      enclosureTypeSetting: {
        files: {},
      },
      bedTypeSetting: {
        bedType: '',
        files: {},
        bedProperties: {},
      },
    };

    if (printer?.machineDefinitionResponse) {
      initialValues.machineTypeSetting = initDataMachineType(
        printer.machineDefinitionResponse,
        initialValues.machineTypeSetting,
      );
    }

    if (printer?.extruderDefinitionResponse) {
      initialValues.toolTypeSetting = initDataToolType(
        printer.extruderDefinitionResponse,
        initialValues.toolTypeSetting,
      );
    }

    if (printer?.bedDefinitionResponse) {
      initialValues.bedTypeSetting = initDataBedType(
        printer.bedDefinitionResponse,
        initialValues.bedTypeSetting,
      );
    }

    if (printer?.plinthDefinitionResponse) {
      initialValues.plinthTypeSetting = initDataPlinthType(
        printer.plinthDefinitionResponse,
        initialValues.plinthProperties,
      );
    }

    if (printer?.enclosureDefinitionResponse) {
      initialValues.enclosureTypeSetting = initDataEnclosureType(
        printer.enclosureDefinitionResponse,
        initialValues.enclosureTypeSetting,
      );
    }
    return initialValues;
  };

  const handleUpdateCallback = useCallback(() => {
    dispatch(hideGeneralPrinterSetting());
    invalidatePrinterQuery(selectedPrinter?.id);
  }, [selectedPrinter, invalidatePrinterQuery, dispatch]);

  const onFormSubmit = useCallback(
    (
      {
        machineTypeSetting,
        toolTypeSetting,
        plinthTypeSetting,
        enclosureTypeSetting,
        bedTypeSetting,
      },
      { setSubmitting },
    ) => {
      let request = {};
      let files = {};

      switch (setting) {
        case constants.robotType: {
          const isGantry =
            machineTypeSetting.machineType.value ===
            robotTypeDefinitions.GANTRY.value;

          request = {
            name: machineTypeSetting.machineType.value,
            brand: machineTypeSetting.brand.value,
            componentType:
              componentDiscriminators[machineTypeSetting.machineType.value],
            ...machineTypeSetting.machineProperties,
            ...(isGantry && {
              axis1Attachment:
                machineTypeSetting.machineProperties.axis1Attachment.value,
              axis2Attachment:
                machineTypeSetting.machineProperties.axis2Attachment.value,
              axis3Attachment:
                machineTypeSetting.machineProperties.axis3Attachment.value,
            }),
          };
          files = machineTypeSetting.files;
          break;
        }

        case constants.plinthType:
        case constants.basePlinthType: {
          request = {
            name:
              setting === constants.basePlinthType ? 'basePlinth' : 'plinth',
            componentType:
              componentDiscriminators[plinthTypeSetting.plinthType.value],
            ...plinthTypeSetting.plinthProperties,
          };
          files = plinthTypeSetting.files;
          break;
        }

        case constants.enclosureType: {
          request = {
            name: 'enclosure',
            componentType: componentDiscriminators.ENCLOSURE,
            ...enclosureTypeSetting.enclosureTypeSetting,
          };
          files = enclosureTypeSetting.files;
          break;
        }

        case constants.printingBedType: {
          request = {
            name: 'bed',
            componentType:
              componentDiscriminators[bedTypeSetting.bedType.value],
            ...bedTypeSetting.bedProperties,
          };
          files = bedTypeSetting.files;
          break;
        }

        case constants.extruderType: {
          request = {
            name: toolTypeSetting.toolType.value,
            componentType:
              componentDiscriminators[toolTypeSetting.toolType.value],
            extrusionType: toolTypeSetting.toolProperties.extrusionType.value,
          };
          files = toolTypeSetting.files;
          break;
        }

        default:
        // Handle other cases if needed
      }

      dispatch(
        uploadPrinterComponentModels(
          selectedPrinter.id,
          request,
          files,
          handleUpdateCallback,
        ),
      );
      setSubmitting(false);
    },
    [handleUpdateCallback, selectedPrinter, setting, dispatch],
  );

  const formInitialValues = useMemo(() => {
    return setting && mapDbConfigToFormInitialValues(selectedPrinter);
  }, [selectedPrinter, setting]);

  const validateForm = async (values) => {
    try {
      await validationSchemaMap[setting]?.schema.validate(
        values[validationSchemaMap[setting].setting],
        {
          abortEarly: false, // Collect all validation errors
        },
      );
    } catch (validationError) {
      dispatch(
        showErrorDialog(
          'Error',
          undefined,
          'printers.settings.components.settings.error',
        ),
      );
      return { validationError };
    }
    return {};
  };

  const renderContent = () => {
    const componentMap = {
      [constants.robotType]: <MachineTypeSetting />,
      [constants.extruderType]: <ToolTypeSetting />,
      [constants.plinthType]: <PlinthTypeSetting />,
      [constants.basePlinthType]: <PlinthTypeSetting />,
      [constants.enclosureType]: <EnclosureSetting />,
      [constants.printingBedType]: <BedTypeSetting />,
    };

    return componentMap[setting] || <></>;
  };

  return (
    <Formik
      key={formKey}
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={formInitialValues}
      validate={validateForm}
      onSubmit={onFormSubmit}
    >
      {({ handleSubmit, dirty }) => (
        <SettingBar
          headerTitle={generalPrinterState.displayName}
          footerPrimaryButtonDisabled={!dirty}
          footerPrimaryButtonLabel={intl.formatMessage({
            id: 'general.confirm',
            defaultMessage: 'Confirm',
          })}
          footerSecondaryButtonLabel={intl.formatMessage({
            id: 'general.cancel',
            defaultMessage: 'Cancel',
          })}
          headerLeadingIconButtonIconName={'close_0'}
          headerEndingIconButtonIconName={''}
          onHeaderLeadingIconButtonClick={hideConfig}
          onFooterSecondaryButtonClick={hideConfig}
          onSubmit={handleSubmit}
          loading={isPrinterComponentUpdating}
          withFooterDivider
          renderAsForm
        >
          {renderContent()}
        </SettingBar>
      )}
    </Formik>
  );
};

GeneralPrinterSetting.propTypes = {
  selectedPrinter: PropTypes.object.isRequired,
};

export default GeneralPrinterSetting;
