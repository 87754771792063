import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import useModal from '@hooks/useModal';
import ModalPortal from '@components/2-molecules/ModalPortal';
import PageHeader from '@components/2-molecules/PageHeader';
import PdfViewer from '@components/PdfViewer';
import { MODAL_IDS } from '@constants/modalDataTypes';
import { Wrapper } from './LoginEulaModal.styled';

const MODAL_ID = MODAL_IDS.LOGIN_EULA;

const EULA_PDF_ENV_URLS = {
  [PROD_ENV]:
    'https://aibuild-public-assets-bucket.s3.eu-west-1.amazonaws.com/eula.pdf',
  [TEST_ENV]:
    'https://aibuild-public-assets-bucket-test.s3.eu-west-1.amazonaws.com/eula.pdf',
  [EPHEMERAL_ENV]:
    'https://aibuild-public-assets-bucket-test.s3.eu-west-1.amazonaws.com/eula.pdf',
  [DEVELOPMENT_ENV]:
    'http://localhost:9000/aibuild-public-assets-bucket-dev/eula.pdf',
  [DESKTOP_APP]: '/documents/Aibuild - Cloud EULA.pdf',
};

const eualPDFUrl = EULA_PDF_ENV_URLS[true];

const LoginEulaModal = () => {
  const intl = useIntl();
  const [isDocumentLoadError, setIsDocumentLoadError] = useState();
  const [isDocumentLoading, setIsDocumentLoading] = useState(true);
  const { hideModal, getModalData } = useModal();
  const { primaryButtonHandler, handleClose } = getModalData(MODAL_ID);

  const onClose = useCallback(() => {
    hideModal(MODAL_ID);
    handleClose?.();
  }, [handleClose, hideModal]);

  const onPrimaryButtonClick = useCallback(async () => {
    await primaryButtonHandler?.();
    onClose();
  }, [primaryButtonHandler, onClose]);

  const onDocumentLoadError = useCallback(() => {
    setIsDocumentLoadError(true);
    setIsDocumentLoading(false);
  }, []);

  const onDocumentLoadSuccess = useCallback(() => {
    setIsDocumentLoading(false);
  }, []);

  return (
    <ModalPortal
      dataTestId="login-eula-modal"
      modalId={MODAL_ID}
      primaryButtonDisabled={isDocumentLoading || isDocumentLoadError}
      primaryButtonLoading={isDocumentLoading}
      secondaryButtonLabel={intl.formatMessage({
        id: 'general.decline',
        defaultMessage: 'Decline',
      })}
      primaryButtonLabel={intl.formatMessage({
        id: 'general.accept',
        defaultMessage: 'Accept',
      })}
      onSecondaryButtonClick={onClose}
      onPrimaryButtonClick={onPrimaryButtonClick}
      onClose={onClose}
    >
      <Wrapper>
        <PageHeader
          title={intl.formatMessage({
            id: 'login.eula.title',
            defaultMessage: 'End User License Agreement Update',
          })}
          subtitle={intl.formatMessage({
            id: 'login.eula.subtitle',
            defaultMessage:
              'Please read and accept the following terms and conditions to continue using the Aibuild platform. Please if you have any question contact us.',
          })}
        />

        <PdfViewer
          file={eualPDFUrl}
          pageScale={1.4}
          onDocumentLoadError={onDocumentLoadError}
          onDocumentLoadSuccess={onDocumentLoadSuccess}
        />
      </Wrapper>
    </ModalPortal>
  );
};

LoginEulaModal.propTypes = {};

export default LoginEulaModal;
