import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { concat, isEmpty, omit } from 'lodash';
import useWorkflow from '@hooks/workflows/useWorkflow';
import useOperator from '@hooks/operators/useOperator';
import useFile from '@hooks/files/useFile';
import { operatorsQueryKeys } from '@hooks/operators/useOperatorQueries';
import useOperatorSettingUtils from '@hooks/operatorSettings/useOperatorSettingUtils';
import EnglishTranslation from '@app/languages/en-GB.json';
import useOrganization from '@hooks/organization/useOrganization';
import {
  getFrozenOutputIds,
  getSelectedOperatorOutput,
  getSelectedOperatorOutputId,
} from '@selectors/conceptSelectors';
import { showErrorDialog } from '@actions/errorActions';
import {
  enterToolpathSimulation,
  exitToolpathSimulation,
  fetchToolpathSimulation,
  selectOperatorOutput as selectOperatorOutputAction,
} from '@actions/conceptActions';
import { fetchToolpathOperatorInstructions } from '@actions/toolpathActions';
import { selectHiddenInputNames } from '@reducers/workflowSlice';
import { fileTypesByInputType, loadPrintingObject } from '@utils/model';
import {
  DROP_DOWN_DATA_FIELD_TYPES,
  DROP_DOWN_FILE_FIELD_TYPES,
  DROP_DOWN_INPUT_FIELD_TYPES,
  GEO_OUTPUT_TYPES,
  TOOLPATH_OUTPUT_TYPES,
  VISUALLY_HIDDEN_OUTPUT_TYPES,
} from '@constants/operatorFieldTypes';
import { OperatorInputTypes } from '@constants/operatorInputTypes';
import { HIDDEN_INPUT_TYPES } from '@constants/utilityConstants';
import useCanvasSelection from '@hooks/canvasselection/useCanvasSelection';
import useOperatorUtils from '@hooks/operators/useOperatorUtils';
import { doesMeetTheConditions } from '@utils/visibleConditions';

export default function useOperatorSettings() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { getIsOrganizationIdOverride } = useOrganization();
  const workflowHiddenInputNames = useSelector(selectHiddenInputNames);
  const selectedOperatorOutputId = useSelector(getSelectedOperatorOutputId());
  const selectedOperatorOutput = useSelector(getSelectedOperatorOutput());
  const organizationIdOverride = getIsOrganizationIdOverride();
  const frozenOutputIds = useSelector(getFrozenOutputIds());
  const {
    getOperatorFromProvidedArg,
    getDefaultOperators,
    getDefaultOperator,
    getNormalizedDefaultOperators,
    getOperator,
  } = useOperatorUtils();
  const { getOperatorInput, getSelectedOperatorByValueId } =
    useOperatorSettingUtils();
  const { getOperatorPreviousDepended, getIsOperatorComputed } = useOperator();
  const {
    getSelectedWorkflow,
    getSelectedWorkflowOperators,
    getSelectedWorkflowProjectId,
  } = useWorkflow();
  const { doesFieldSupportCanvasSelection, doesOutputSupportCanvasSelection } =
    useCanvasSelection();
  const { getProjectFiles } = useFile();
  const selectedWorkflow = getSelectedWorkflow();
  const selectedWorkflowId = selectedWorkflow?.id;

  const getValuesVisibilityConditions = useCallback(
    () =>
      queryClient.getQueryData(
        operatorsQueryKeys.defaultOperators(selectedWorkflowId),
      )?.inputsVisibilityConditions,
    [queryClient, selectedWorkflowId],
  );

  const getDefaultOperatorSettings = useCallback(
    (operatorName) => getDefaultOperator(operatorName)?.settings,
    [getDefaultOperator],
  );

  const getOperatorInputsAndOutputs = useCallback(
    (operatorOrId) => {
      return getOperatorFromProvidedArg(operatorOrId)?.values;
    },
    [getOperatorFromProvidedArg],
  );

  const getOperatorInputs = useCallback(
    (operatorOrId) => {
      const operator = getOperatorFromProvidedArg(operatorOrId);

      return operator?.values?.filter(({ isinput }) => isinput) || [];
    },
    [getOperatorFromProvidedArg],
  );

  const getInput = useCallback(
    (inputId) => {
      const operatorsToIterate = getSelectedWorkflowOperators();
      let targetInput;

      operatorsToIterate.forEach((operator) => {
        const input = operator?.values?.find(({ id }) => id === inputId);

        if (input) {
          targetInput = input;
        }
      });

      return targetInput;
    },
    [getSelectedWorkflowOperators],
  );

  const getOperatorOutput = useCallback(
    (operatorOrId, inputIdOrName) => {
      const operator = getOperatorFromProvidedArg(operatorOrId);

      return operator?.values?.find(({ id, name }) => {
        return (
          id === inputIdOrName ||
          name?.toLowerCase() === inputIdOrName?.toLowerCase()
        );
      });
    },
    [getOperatorFromProvidedArg],
  );

  const getOperatorOutputs = useCallback(
    (operatorOrId) => {
      const operator = getOperatorFromProvidedArg(operatorOrId);

      return operator?.values?.filter(({ isinput }) => !isinput) || [];
    },
    [getOperatorFromProvidedArg],
  );

  const calculateOperatorHiddenValues = useCallback(
    (operatorOrId, { formValues = {}, normalizeOutput = false } = {}) => {
      const operator = getOperatorFromProvidedArg(operatorOrId);
      const operatorSettings = getOperatorInputsAndOutputs(operator);
      const files = getProjectFiles(getSelectedWorkflowProjectId());
      const visibilityConditions = getValuesVisibilityConditions();
      const operatorVisibilityConditions =
        visibilityConditions?.[operator.name];

      const valueNameConditions = operatorSettings.reduce((acc, input) => {
        const inputName = input.name;
        const inputValue = formValues?.[input.id] || input.value;
        const inputType = input.type;

        return {
          ...acc,
          [inputName]: { value: inputValue, type: inputType },
        };
      }, {});

      const hiddenValues = Object.keys(operatorVisibilityConditions).reduce(
        (acc, inputName) => {
          const conditions = operatorVisibilityConditions?.[inputName] || [];

          const isVisible = doesMeetTheConditions(
            conditions,
            valueNameConditions,
            files,
          );

          if (!isVisible) {
            const input = operatorSettings.find(
              (input) => input.name === inputName,
            );

            if (isEmpty(input)) {
              return acc;
            }

            const cleanInput = omit(input, [
              'inputTemplateId',
              'locked',
              'exposed',
              'triggerComputation',
            ]);

            if (normalizeOutput) {
              return {
                ...acc,
                [input.id]: cleanInput,
              };
            }

            return [...acc, cleanInput];
          }

          return acc;
        },
        normalizeOutput ? {} : [],
      );

      return hiddenValues;
    },
    [
      getOperatorInputsAndOutputs,
      getOperatorFromProvidedArg,
      getValuesVisibilityConditions,
      getProjectFiles,
      getSelectedWorkflowProjectId,
    ],
  );

  const calculateWorkflowHiddenValues = useCallback(
    ({ formValues = {}, normalizeOutput = false } = {}) => {
      const workflowOperators = getSelectedWorkflowOperators();
      const hiddenValues = workflowOperators.reduce(
        (acc, operator) => {
          const operatorHiddenValues = calculateOperatorHiddenValues(operator, {
            formValues,
            normalizeOutput,
          });

          if (normalizeOutput) {
            return { ...acc, ...operatorHiddenValues };
          }

          return [...acc, ...operatorHiddenValues];
        },
        normalizeOutput ? {} : [],
      );

      return hiddenValues;
    },
    [getSelectedWorkflowOperators, calculateOperatorHiddenValues],
  );

  const getWorkflowHiddenInputNames = useCallback(
    () => workflowHiddenInputNames || [],
    [workflowHiddenInputNames],
  );

  const getOperatorHiddenInputNames = useCallback(
    (operatorId) => workflowHiddenInputNames?.[operatorId] || [],
    [workflowHiddenInputNames],
  );

  const getOperatorHiddenInputs = useCallback(
    (operatorOrId, { cleanFromApiNotSupportedProps } = {}) => {
      const operator = getOperatorFromProvidedArg(operatorOrId);
      const operatorInputs = getOperatorInputs(operator);
      const hiddenInputNames = getOperatorHiddenInputNames(operator?.id);
      const normalizedDefaultOperators = getNormalizedDefaultOperators();

      const isCombineDataField = (input) => {
        const isListType =
          input?.type === OperatorInputTypes.LIST_GEOMETRY_CLASSIFIED_POLYLINES;

        return isListType && input?.name?.startsWith('Data');
      };

      const filteredInputs = operatorInputs?.filter(
        (input) =>
          !isCombineDataField(input) &&
          (!normalizedDefaultOperators?.[operator.name]?.settings?.[
            input.name
          ] ||
            HIDDEN_INPUT_TYPES.includes(input?.type) ||
            hiddenInputNames.includes(input.name)),
      );

      if (cleanFromApiNotSupportedProps) {
        return filteredInputs?.map((input) =>
          omit(input, [
            'inputTemplateId',
            'locked',
            'exposed',
            'triggerComputation',
          ]),
        );
      }

      return filteredInputs;
    },
    [
      getOperatorFromProvidedArg,
      getOperatorInputs,
      getOperatorHiddenInputNames,
      getNormalizedDefaultOperators,
    ],
  );

  const getIsOperatorInputHidden = useCallback(
    (operatorOrId, input) => {
      const operator = getOperatorFromProvidedArg(operatorOrId);
      const hiddenInputs = getOperatorHiddenInputs(operator);

      return hiddenInputs.some((hiddenInput) => hiddenInput.id === input?.id);
    },
    [getOperatorFromProvidedArg, getOperatorHiddenInputs],
  );

  const getOperatorVisibleInputs = useCallback(
    (operatorOrId) => {
      const operator = getOperatorFromProvidedArg(operatorOrId);
      const operatorInputs = getOperatorInputs(operator);
      const hiddenInputs = getOperatorHiddenInputs(operator);

      return operatorInputs?.filter(
        (input) =>
          !hiddenInputs?.some((hiddenInput) => hiddenInput.id === input.id),
      );
    },
    [getOperatorFromProvidedArg, getOperatorInputs, getOperatorHiddenInputs],
  );

  const getWorkflowHiddenInputs = useCallback(
    ({ cleanFromApiNotSupportedProps } = {}) => {
      const hiddenInputs = getSelectedWorkflowOperators().reduce(
        (acc, operator) => {
          const operatorHiddenInputs = getOperatorHiddenInputs(operator, {
            cleanFromApiNotSupportedProps,
          });

          return [...acc, ...operatorHiddenInputs];
        },
        [],
      );

      return hiddenInputs;
    },
    [getOperatorHiddenInputs, getSelectedWorkflowOperators],
  );

  const getOperatorHiddenOutputs = useCallback(
    (operatorOrId) => {
      const operator = getOperatorFromProvidedArg(operatorOrId);
      const operatorOutputs = getOperatorOutputs(operator);
      const hiddenInputNames = getOperatorHiddenInputNames(operator.id);
      const normalizedDefaultOperators = getNormalizedDefaultOperators();

      const hiddenOutputs = operatorOutputs?.filter(
        (output) =>
          !normalizedDefaultOperators?.[operator.name]?.settings?.[
            output.name
          ] || hiddenInputNames.includes(output.name),
      );

      return hiddenOutputs || [];
    },
    [
      getOperatorFromProvidedArg,
      getOperatorOutputs,
      getOperatorHiddenInputNames,
      getNormalizedDefaultOperators,
    ],
  );

  const getOperatorVisibleOutputs = useCallback(
    (operatorOrId) => {
      const operator = getOperatorFromProvidedArg(operatorOrId);
      const operatorOutputs = getOperatorOutputs(operator);
      const hiddenOutputs = getOperatorHiddenOutputs(operator);

      return operatorOutputs?.filter(
        (value) =>
          !hiddenOutputs.some((hiddenOutput) => hiddenOutput.id === value.id),
      );
    },
    [getOperatorFromProvidedArg, getOperatorOutputs, getOperatorHiddenOutputs],
  );

  const getWorkflowHiddenOutputs = useCallback(() => {
    const hiddenInputs = getSelectedWorkflowOperators().reduce(
      (acc, operator) => {
        const operatorHiddenOutputs = getOperatorHiddenOutputs(operator);

        return [...acc, ...operatorHiddenOutputs];
      },
      [],
    );

    return hiddenInputs;
  }, [getOperatorHiddenOutputs, getSelectedWorkflowOperators]);

  const getOperatorInputDescription = useCallback(
    (operatorOrId, inputId) => {
      const operator = getOperatorFromProvidedArg(operatorOrId);
      const input = getOperatorInput(operator, inputId);
      const inputName = input?.name;
      const isListType =
        input?.type === OperatorInputTypes.LIST_GEOMETRY_CLASSIFIED_POLYLINES;

      // Check if inputName contains "Data" with an optional prefix and an optional numerical suffix, but not just "Data" by itself.
      const dataRegex = /^(.*\.)?Data\d+$/;
      let descriptorInputName = inputName;

      // Test if inputName matches the updated pattern and is a list type.
      if (isListType && dataRegex.test(inputName)) {
        // Extract everything before "Data" (including the dot, if present) and append "Data"
        // This will work for "Data1", "Combine.Data2", "banana1.Data33", etc.
        descriptorInputName = inputName.replace(/(.*\.)?Data\d+$/, '$1Data');
      }

      const descriptor =
        operator?.operatorDescriptor?.operatorValuesDescriptor[
          descriptorInputName
        ];
      const preTranslatedValue = descriptor?.detail;
      const inputDescriptorKey = descriptor?.detailKey;

      const descriptionIsAvailable = !!EnglishTranslation[inputDescriptorKey];
      const description =
        preTranslatedValue ||
        (descriptionIsAvailable
          ? intl.formatMessage({ id: inputDescriptorKey })
          : '');

      return description;
    },
    [intl, getOperatorFromProvidedArg, getOperatorInput],
  );

  const getIsOperatorOutputHidden = useCallback(
    (operatorOrId, output) => {
      const operator = getOperatorFromProvidedArg(operatorOrId);
      const hiddenOutputs = getOperatorHiddenOutputs(operator);

      return hiddenOutputs.some(
        (hiddenOutput) => hiddenOutput.id === output?.id,
      );
    },
    [getOperatorFromProvidedArg, getOperatorHiddenOutputs],
  );

  const getWorkflowFrozenHiddenOutputs = useCallback(
    () =>
      getWorkflowHiddenOutputs().filter((output) =>
        frozenOutputIds?.includes(output.id),
      ),
    [getWorkflowHiddenOutputs, frozenOutputIds],
  );

  const getOperatorVisibleInputsAndOutputs = useCallback(
    (operatorOrId) => {
      const operator = getOperatorFromProvidedArg(operatorOrId);
      const visibleInputs = getOperatorVisibleInputs(operator);
      const visibleOutputs = getOperatorVisibleOutputs(operator);

      return concat(visibleInputs, visibleOutputs);
    },
    [
      getOperatorFromProvidedArg,
      getOperatorVisibleInputs,
      getOperatorVisibleOutputs,
    ],
  );

  const getCategorizedVisibleOperatorInputs = useCallback(
    (operatorOrId, { comparisonAware = false } = {}) => {
      const operator = getOperatorFromProvidedArg(operatorOrId);
      let visibleInputs = getOperatorVisibleInputs(operator);
      const defaultOperatorSettings = getDefaultOperatorSettings(
        operator?.name,
      );

      if (comparisonAware) {
        const comparisonAwareOperatorValues = visibleInputs.filter(
          (fieldInput) => {
            // Check if the field input is modified or removed
            const isModifiedOrRemoved =
              fieldInput?.diffModified || fieldInput?.diffRemoved;

            // If operatorDiff is true, only include modified or removed items
            if (operator.diffOperator) {
              return isModifiedOrRemoved;
            }

            return true;
          },
        );

        visibleInputs = comparisonAwareOperatorValues;
      }

      const categorizedInputs = visibleInputs?.reduce(
        (acc, input) => {
          const defaultOperatorSetting = defaultOperatorSettings?.[input?.name];
          const defaultOperatorSettingCategory =
            defaultOperatorSetting?.settingCategory;
          const noCategory =
            !defaultOperatorSettingCategory ||
            defaultOperatorSettingCategory === 'No Category';

          if (noCategory) {
            acc.default.push(input);

            return acc;
          }

          if (!acc[defaultOperatorSettingCategory]) {
            acc[defaultOperatorSettingCategory] = [];
          }

          acc[defaultOperatorSettingCategory].push(input);

          return acc;
        },
        { default: [] },
      );

      return categorizedInputs;
    },
    [
      getOperatorFromProvidedArg,
      getOperatorVisibleInputs,
      getDefaultOperatorSettings,
    ],
  );

  const getFrozenOperatorInputs = useCallback(
    (operatorOrId) => {
      const operator = getOperatorFromProvidedArg(operatorOrId);

      return operator.values.filter((value) =>
        frozenOutputIds?.includes(value.id),
      );
    },
    [getOperatorFromProvidedArg, frozenOutputIds],
  );

  const getIsOperatorOutputFrozen = useCallback(
    (outputId) => frozenOutputIds?.includes(outputId),
    [frozenOutputIds],
  );

  const getOperatorOutputGroups = useCallback(
    (operator = {}) => {
      const initialOutputGroups = {
        geoOutputs: [],
        nonGeoOutputs: [],
        toolpathOutputs: [],
      };

      if (!operator.computed) {
        return initialOutputGroups;
      }

      const outputs = getOperatorVisibleOutputs(operator);

      if (!outputs) {
        return initialOutputGroups;
      }

      const formattedOutputs = outputs?.reduce((acc, output) => {
        const outputType = output?.type;

        if (GEO_OUTPUT_TYPES.includes(outputType)) {
          acc.geoOutputs.push({ ...output, isGeometricOutput: true });
        } else if (TOOLPATH_OUTPUT_TYPES.includes(outputType)) {
          acc.toolpathOutputs.push({ ...output, isToolpathOutput: true });
        } else if (!VISUALLY_HIDDEN_OUTPUT_TYPES.includes(outputType)) {
          acc.nonGeoOutputs.push(output);
        }

        return acc;
      }, initialOutputGroups);

      return formattedOutputs;
    },
    [getOperatorVisibleOutputs],
  );

  const getOperatorGeometryOutputs = useCallback(
    (operator = {}) => {
      const { geoOutputs = [], toolpathOutputs = [] } =
        getOperatorOutputGroups(operator);
      const geometryOutputs = [...geoOutputs, ...toolpathOutputs];

      return geometryOutputs;
    },
    [getOperatorOutputGroups],
  );

  const getOperatorInfoOutputs = useCallback(
    (operator = {}) => {
      const { nonGeoOutputs = [] } = getOperatorOutputGroups(operator);

      return nonGeoOutputs;
    },
    [getOperatorOutputGroups],
  );

  const getSelectedOperatorOutputGeometryId = useCallback(() => {
    const selectedInput = getOperatorInput(
      selectedOperatorOutput?.operatorId,
      selectedOperatorOutput?.id,
    );

    return selectedInput?.value;
  }, [selectedOperatorOutput, getOperatorInput]);

  const getOperatorInputDefaultsTypes = useCallback(
    (operatorOrId, input) => {
      const normalizedDefaultOperators = getNormalizedDefaultOperators();
      const operator = getOperatorFromProvidedArg(operatorOrId);
      const operatorSettings =
        normalizedDefaultOperators?.[operator.name]?.settings;
      const inputDefaults = operatorSettings?.[input.name];

      return inputDefaults?.types;
    },
    [getNormalizedDefaultOperators, getOperatorFromProvidedArg],
  );

  const getIsOperatorInputMultiType = useCallback(
    (operatorOrId, input) => {
      const types = getOperatorInputDefaultsTypes(operatorOrId, input);

      return types?.length > 1;
    },
    [getOperatorInputDefaultsTypes],
  );

  const getOperatorFieldOptions = useCallback(
    (
      operator = {},
      fieldInput = {},
      {
        getValues = undefined,
        withNestedMenu = false,
        shortOptionLabel = false,
      } = {},
    ) => {
      const { type } = fieldInput;
      const isDropDownFieldType = DROP_DOWN_INPUT_FIELD_TYPES.concat(
        DROP_DOWN_DATA_FIELD_TYPES,
      ).includes(type);
      const isDropDownFileFieldType = DROP_DOWN_FILE_FIELD_TYPES.includes(type);
      const inputDefaultsTypes = getOperatorInputDefaultsTypes(
        operator,
        fieldInput,
      );
      const isMultiInputType = inputDefaultsTypes?.length > 1;

      if (isDropDownFieldType) {
        let inputType = type;
        const previousDependedOperators = getOperatorPreviousDepended(operator);

        if (type === OperatorInputTypes.LIST_GEOMETRY_CLASSIFIED_POLYLINES) {
          inputType = OperatorInputTypes.GEOMETRY_CLASSIFIED_POLYLINES;
        }

        const isCanvasSelectionField = doesFieldSupportCanvasSelection(
          operator,
          fieldInput,
        );

        const dependedOperatorsValues = previousDependedOperators?.reduce(
          (acc, dependedOperator = {}) => {
            const filteredValues = dependedOperator?.values?.filter(
              (value = {}) => {
                const isOutput = !value?.isinput;
                const isSameType = isMultiInputType
                  ? true
                  : value?.type === inputType;
                const isMultiInputMatched = isMultiInputType
                  ? inputDefaultsTypes.includes(value.type)
                  : true;
                const isCanvasSelectionOption =
                  isCanvasSelectionField &&
                  doesOutputSupportCanvasSelection(
                    dependedOperator,
                    value,
                    getValues,
                  );
                const isFromDiffOperator = value?.operatorId !== operator.id;
                const isNotHidden = !getIsOperatorOutputHidden(
                  dependedOperator,
                  value,
                );

                return (
                  isOutput &&
                  isMultiInputMatched &&
                  (isCanvasSelectionOption || isSameType) &&
                  isFromDiffOperator &&
                  isNotHidden
                );
              },
            );

            if (isEmpty(filteredValues)) {
              return acc;
            }

            return {
              ...acc,
              [dependedOperator.id]: filteredValues,
            };
          },
          {},
        );

        let options = [];

        if (withNestedMenu) {
          const dependedOperators = Object.keys(dependedOperatorsValues).map(
            (operatorId) => {
              const dependedOperator = previousDependedOperators?.find(
                ({ id }) => id === operatorId,
              );

              return dependedOperator;
            },
          );

          options = dependedOperators?.map((dependedOperator) => {
            const dependedOperatorsOutputs =
              dependedOperatorsValues[dependedOperator?.id];
            const isSingleOutput = dependedOperatorsOutputs?.length === 1;

            const option = {
              label: dependedOperator?.tag,
              value: dependedOperator?.id,
              tag: dependedOperator?.tag,
              operatorId: dependedOperator?.id,
              isCanvasSelectionOption: false,
              dependencyIsComputed: dependedOperator?.computed,
            };

            if (isSingleOutput) {
              const output = dependedOperatorsOutputs?.[0];

              option.label = `${dependedOperator?.tag || ''}.${output?.name}`;
              option.value = output?.id;
              option.isCanvasSelectionOption =
                isCanvasSelectionField &&
                doesOutputSupportCanvasSelection(
                  output?.operatorId,
                  output,
                  getValues,
                );
            } else {
              const nestedOptions = getOperatorFieldOptions(
                operator,
                fieldInput,
                { getValues, withNestedMenu: false, shortOptionLabel: true },
              );
              const filteredByOperatorOptions = nestedOptions.filter(
                ({ operatorId }) => operatorId === dependedOperator?.id,
              );
              const sortedOptions = filteredByOperatorOptions.sort(
                (a, b) => b.isCanvasSelectionOption - a.isCanvasSelectionOption,
              );

              option.options = sortedOptions;
            }

            return option;
          });
        } else {
          const flattenDependedOperatorsValues = Object.values(
            dependedOperatorsValues,
          ).flat();

          options = flattenDependedOperatorsValues?.map((value) => {
            const { id, operatorId, name } = value;
            const dependedOperator = previousDependedOperators?.find(
              ({ id }) => id === operatorId,
            );

            const fullLabel = `${dependedOperator?.tag || ''}.${name}`;
            const shortTitle = name;
            const title = shortOptionLabel ? shortTitle : fullLabel;

            return {
              label: title,
              fullLabel,
              tag: dependedOperator?.tag,
              value: id,
              operatorId: dependedOperator?.id,
              isCanvasSelectionOption:
                isCanvasSelectionField &&
                doesOutputSupportCanvasSelection(operatorId, value, getValues),
              dependencyIsComputed: dependedOperator?.computed,
            };
          });
        }

        if (isMultiInputType) {
          const duplicatedCanvasSelectionOptions = (options = []) => {
            const haveCanvasSelection = options.some(
              ({ isCanvasSelectionOption }) => isCanvasSelectionOption,
            );

            if (!haveCanvasSelection) {
              return options;
            }

            return options.reduce((acc, option) => {
              const isCanvasSelectionOption = option?.isCanvasSelectionOption;
              const updateOption = option;

              updateOption.options = option?.options
                ? duplicatedCanvasSelectionOptions(option.options)
                : undefined;

              if (!isCanvasSelectionOption) {
                return [...acc, updateOption];
              }

              const notCanvasSelectionDuplicatedOption = {
                ...updateOption,
                isCanvasSelectionOption: false,
              };

              return [...acc, option, notCanvasSelectionDuplicatedOption];
            }, []);
          };

          options = duplicatedCanvasSelectionOptions(options);
        }

        return options;
      }

      if (isDropDownFileFieldType) {
        const targetFileType = fileTypesByInputType[type];

        const sortedDesigns = getProjectFiles(
          getSelectedWorkflowProjectId(),
        ).sort((a, b) =>
          a.filename.toLowerCase() > b.filename.toLowerCase() ? 1 : -1,
        );

        const filteredDesigns = sortedDesigns.filter(
          (design) =>
            design.visible && targetFileType?.includes(design?.filetype),
        );

        const options = filteredDesigns?.map(({ id, filename: title }) => ({
          label: title,
          value: id,
        }));

        return options;
      }

      const defaultOperator = getDefaultOperators().find(
        ({ name }) => name === operator?.name,
      );

      const defaultOperatorTargetSetting = defaultOperator?.settings.find(
        ({ name }) => name === fieldInput?.name,
      );
      const { allowedValues = [], metaAllowedValues = [] } =
        defaultOperatorTargetSetting || {};

      const operatorInputs = getOperatorInputs(operator);
      const files = getProjectFiles(getSelectedWorkflowProjectId());
      const inputNameConditions = operatorInputs.reduce((acc, input) => {
        const inputName = input.name;
        const inputValue = input.value;

        return {
          ...acc,
          [inputName]: `${inputValue}`,
        };
      }, {});

      const options = allowedValues
        ?.filter((value) => {
          const metadata = metaAllowedValues?.find(
            ({ value: metaValue }) => metaValue === value,
          );

          const conditions = metadata?.visibilityRules?.conditions || [];

          return doesMeetTheConditions(conditions, inputNameConditions, files);
        })
        ?.map((value) => {
          const metadata = metaAllowedValues?.find(
            ({ value: metaValue }) => metaValue === value,
          );

          // Return the option if it meets the conditions
          return {
            label: value,
            value: value,
            assetIcon: metadata?.assetIcon,
            assetIconUrl: metadata?.assetIconUrl,
            assetImageUrl: metadata?.assetImageUrl,
            description: metadata?.description,
            descriptionKey: metadata?.descriptionKey,
          };
        });

      return options;
    },
    [
      getOperatorInputs,
      getProjectFiles,
      getSelectedWorkflowProjectId,
      getDefaultOperators,
      getOperatorPreviousDepended,
      getIsOperatorOutputHidden,
      doesFieldSupportCanvasSelection,
      doesOutputSupportCanvasSelection,
      getOperatorInputDefaultsTypes,
    ],
  );

  const selectOperatorGeometricOutput = useCallback(
    (output = {}) => {
      const operators = getSelectedWorkflowOperators();
      const operatorId = output?.operatorId;
      const operator = operators?.find(
        (operator) => operator.id === operatorId,
      );

      if (!operator) return;

      const showError =
        !output?.value || !operator?.computed || operator?.modified;

      if (showError) {
        dispatch(
          showErrorDialog(
            intl.formatMessage({
              id: 'notvalidcomputationoutputdialog.title',
              defaultMessage: 'Missing data!',
            }),
            intl.formatMessage({
              id: 'notvalidcomputationoutputdialog.description',
              defaultMessage:
                'This output is missing, recompute the operator please',
            }),
          ),
        );

        return;
      }

      dispatch(exitToolpathSimulation());
      dispatch(selectOperatorOutputAction(output));
    },
    [dispatch, intl, getSelectedWorkflowOperators],
  );

  const selectOperatorToolpathOutput = useCallback(
    (output = {}) => {
      const operatorId = output?.operatorId;
      const operator = getOperator(operatorId);

      if (!operator) return;

      if (!operator?.computed) {
        dispatch(
          showErrorDialog(
            intl.formatMessage({
              id: 'notvalidcomputationoutputdialog.title',
              defaultMessage: 'Missing data!',
            }),
            intl.formatMessage({
              id: 'notvalidcomputationoutputdialog.description',
              defaultMessage:
                'This output is missing, recompute the operator please',
            }),
          ),
        );

        return;
      }

      const isSelected = output?.id === selectedOperatorOutputId;
      const isToolpathInstructionType =
        output?.type === OperatorInputTypes.TOOLPATH_INSTRUCTIONS;

      if (isSelected) return;

      dispatch(exitToolpathSimulation());
      dispatch(selectOperatorOutputAction(output));

      if (!isToolpathInstructionType) {
        return;
      }

      dispatch(fetchToolpathOperatorInstructions(operator?.id));
      dispatch(
        fetchToolpathSimulation(
          operatorId,
          (toolpathSimulationUrl, toolpathSimulationPrinterStep) =>
            loadPrintingObject(toolpathSimulationUrl).then((glb) => {
              dispatch(
                enterToolpathSimulation(glb, toolpathSimulationPrinterStep),
              );
            }),
          organizationIdOverride,
        ),
      );
    },
    [
      dispatch,
      intl,
      getOperator,
      selectedOperatorOutputId,
      organizationIdOverride,
    ],
  );

  const selectOperatorOutput = useCallback(
    (output = {}) => {
      const operatorId = output?.operatorId;

      if (!getIsOperatorComputed(operatorId)) {
        return;
      }

      return output?.isGeometricOutput
        ? selectOperatorGeometricOutput(output)
        : selectOperatorToolpathOutput(output);
    },
    [
      getIsOperatorComputed,
      selectOperatorGeometricOutput,
      selectOperatorToolpathOutput,
    ],
  );

  const selectFirstOperatorOutput = useCallback(
    (operator = {}) => {
      const operatorOutputs = getOperatorOutputGroups(operator);
      const { geoOutputs, toolpathOutputs } = operatorOutputs;
      const outputs = [...toolpathOutputs, ...geoOutputs];
      const [firstOutput] = outputs;

      if (!firstOutput) return;

      selectOperatorOutput(firstOutput);
    },
    [getOperatorOutputGroups, selectOperatorOutput],
  );

  return {
    calculateOperatorHiddenValues,
    calculateWorkflowHiddenValues,
    getCategorizedVisibleOperatorInputs,
    getDefaultOperatorSettings,
    getFrozenOperatorInputs,
    getInput,
    getIsOperatorInputHidden,
    getIsOperatorInputMultiType,
    getIsOperatorOutputFrozen,
    getIsOperatorOutputHidden,
    getOperatorFieldOptions,
    getOperatorGeometryOutputs,
    getOperatorHiddenInputNames,
    getOperatorHiddenInputs,
    getOperatorHiddenOutputs,
    getOperatorInfoOutputs,
    getOperatorInput,
    getOperatorInputDefaultsTypes,
    getOperatorInputDescription,
    getOperatorInputs,
    getOperatorInputsAndOutputs,
    getOperatorOutput,
    getOperatorOutputGroups,
    getOperatorOutputs,
    getOperatorVisibleInputs,
    getOperatorVisibleInputsAndOutputs,
    getOperatorVisibleOutputs,
    getSelectedOperatorByValueId,
    getSelectedOperatorOutputGeometryId,
    getValuesVisibilityConditions,
    getWorkflowFrozenHiddenOutputs,
    getWorkflowHiddenInputNames,
    getWorkflowHiddenInputs,
    getWorkflowHiddenOutputs,
    selectFirstOperatorOutput,
    selectOperatorGeometricOutput,
    selectOperatorOutput,
    selectOperatorToolpathOutput,
  };
}
